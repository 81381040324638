import * as dotenv from 'dotenv';
dotenv.config({path:".env.production"});

import React, { Component } from 'react';
import { Collapse, Button, NavLink, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input } from 'reactstrap';
import { IUser, User } from '../../models/User';
import Icon from '../../custom/Icon';
import Identity from '../../user/Identity';
import Util from '../../custom/Util';
import { AxiosResponse, AxiosError } from 'axios';
import { TextAreaInput } from '../../../common/components/widgets/form/input/TextAreaInput';
import { PrivateMessage, IPrivateMessage } from '../../models/PrivateMessage';
import moment from 'moment';
import { Role } from '../../user/Role';
import { Link } from 'react-router-dom';
import { Path } from '../../container/Path';
import { GoogleLogout } from 'react-google-login';

interface IUserActions {
    collapseReg, collapsePhone, collapseMembershipExpires, modalMessage: boolean, reload: boolean, favoritesLoaded: boolean
}

export default class UserActions extends Component<{ user: IUser, addFavoriteUser: any, isFavorited: boolean, isLoading: boolean }, IUserActions> {

    private userModel: User = new User();
    private privateMessageModel: PrivateMessage = new PrivateMessage();
    private submitted = false;


    private inputs: { [attr: string]: React.RefObject<any> } = {
        content: React.createRef(),
    };


    constructor(props: any) {
        super(props);
        this.toggleReg = this.toggleReg.bind(this);
        this.togglePhone = this.togglePhone.bind(this);
        this.toggleMembershipExpires = this.toggleMembershipExpires.bind(this);
        this.toggleMessage = this.toggleMessage.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.handleValidate = this.handleValidate.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.readInfo = this.readInfo.bind(this);

        this.state = {
            collapseReg: false,
            collapsePhone: false,
            collapseMembershipExpires: false,
            modalMessage: false,
            reload: false,
            favoritesLoaded: false
        };
    }

    private readInfo(): IPrivateMessage {
        const plainObject: IPrivateMessage = {};

        for (const key in this.inputs) {

            if (this.inputs.hasOwnProperty(key) && this.inputs[key].current) {
                plainObject[key] = this.inputs[key].current.getValue();
                this.inputs[key].current.removeError();
            }
        }


        plainObject.from_user = Identity.id;
        plainObject.to_user = this.props.user.id;
        plainObject.subject = "Poruka od korisnika";
        plainObject.date_sent = (moment().format("YYYY-MM-DD HH:mm:ss").toString());

        this.privateMessageModel.setFromPlainObject(plainObject);

        return plainObject;
    }

    private handleValidate(event: any): boolean {
        this.readInfo();
        const valid = this.privateMessageModel.validate();

        if (this.submitted) {
            if (!valid) {
                const errors: { [attr: string]: string } = this.privateMessageModel.getErrors();
                this.fillInputsWithErrors(errors);
            }
        }

        event.preventDefault();

        return valid;
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }) {
        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                this.inputs[key].current.setError(errors[key]);
            }
        }
    }

    protected sendMessage(event: React.MouseEvent<HTMLElement>): void {
        this.submitted = true;

        const plainObject: IPrivateMessage = this.readInfo();
        const valid: boolean = this.handleValidate(event);

        this.privateMessageModel.setFromPlainObject(plainObject);

        if (valid) {
            this.privateMessageModel.createNew().then((response: AxiosResponse | void) => {
                Util.notification("success", "Poruka je uspješno poslata.", 1500)
                this.setState({
                    modalMessage: !this.state.modalMessage
                });
            }).catch((error: AxiosError) => {

                Util.notification("error", "Desila se greška, poruka nije poslata.", 1500)
            });
        }

        event.preventDefault();
    }


    toggleReg() {
        this.setState({ collapseReg: !this.state.collapseReg });
    }

    togglePhone() {
        this.setState({ collapsePhone: !this.state.collapsePhone });
    }

    toggleMembershipExpires() {
        this.setState({ collapseMembershipExpires: !this.state.collapseMembershipExpires });
    }

    toggleMessage() {
        this.setState({
            modalMessage: !this.state.modalMessage
        });
    }

    saveUser(event: React.MouseEvent<HTMLAnchorElement>) {

        this.userModel.addFavorite(this.props.user.id)
            .then((response: AxiosResponse) => {

                Util.notification("error", "Uspješno ste dodali korisnika.", 1500)
            })
            .catch((error: AxiosError) => {
                Util.notification("error", "Desila se greška, korisnik nije dodat.", 1500)
            })
    }

    protected logout(event: React.MouseEvent<HTMLElement>): void {

        const user: User = new User(false);

        user.logout().then(() => {

            Identity.eraseUserIdentity();

            const logoutG = new GoogleLogout({
                "clientId": process.env.REACT_APP_GOOGLE_LOGIN_APP_ID,
                "buttonText": "",
                "onLogoutSuccess": () => { console.log("logout") }
            });

            logoutG.signOut;
            if (typeof window !== 'undefined') {

                document.location.pathname = Path.HOMEPAGE.toString();
            }
        })
            .catch((error) => {
                console.log(error)
            });

        event.preventDefault();
    }


    render() {

        let phone;

        if (this.props.user.phone_number && (Identity.id != this.props.user.id) && !Identity.isGuest) {

            phone = (
                <>
                    <NavLink onClick={this.togglePhone} tag="a"><Icon name="telefon" />Broj telefona</NavLink>
                    <Collapse isOpen={this.state.collapsePhone}>
                        <div>
                            Kontakt broj korisnika: <span>{Util.phoneFormat(this.props.user.phone_number)}</span>
                        </div>
                    </Collapse>
                </>
            )
        } else if (this.props.user.phone_number && Identity.isGuest) {
            phone = (
                <>
                    <NavLink onClick={this.togglePhone} tag="a"><Icon name="telefon" />Broj telefona</NavLink>
                    <Collapse isOpen={this.state.collapsePhone}>
                        <div>
                            Broj telefona je vidljiv samo za registrovane korisnike. <Link to={Path.REGISTER}>Registruj se</Link>
                        </div>
                    </Collapse>
                </>
            )
        }

        let memberSince;
        if (this.props.user.member_since && Identity.id != this.props.user.id) {
            memberSince = (
                <>
                    <NavLink onClick={this.toggleReg} tag="a"><Icon name="time" /> Datum registracije</NavLink>
                    <Collapse isOpen={this.state.collapseReg}>
                        <div>
                            Korisnika se registrovao: <span>{Util.formatDatetime(this.props.user.member_since)}</span>
                        </div>
                    </Collapse>
                </>
            )
        }


        let saveUser;
        if (Identity.id != this.props.user.id && !Identity.isGuest && !this.props.isFavorited) {
            saveUser = (
                <>
                    <NavLink onClick={this.props.addFavoriteUser}><Icon name="sacuvaj" /> Sačuvaj korisnika</NavLink>
                </>
            )
        }

        let membershipExpires;
        if (this.props.user.role == Role.CLIENT) {
            membershipExpires = (
                <>
                    <NavLink onClick={this.toggleMembershipExpires} tag="a"><Icon name="expire" /> EPK članstvo</NavLink>
                    <Collapse isOpen={this.state.collapseMembershipExpires}>
                        <div>
                            Vaše članstvo ističe: <span>{Util.formatDatetime(this.props.user.membership_expires_at)}</span>
                        </div>
                    </Collapse>
                </>
            )
        }

        let messages;
        if (Identity.id != this.props.user.id && !Identity.isGuest) {
            messages = (
                <>
                    <NavLink className="btn btn-epk-primary" onClick={this.toggleMessage}><Icon name="poruka" /> Pošalji poruku</NavLink>
                    <Modal isOpen={this.state.modalMessage} toggle={this.toggleMessage}>
                        <ModalHeader toggle={this.toggleMessage}>Poruka za {this.props.user.username}</ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup>
                                    <TextAreaInput
                                        rows={5}
                                        ref={this.inputs.content}
                                        onBlur={this.handleValidate}
                                    />
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.sendMessage}>Pošalji</Button>
                            <Button color="secondary" onClick={this.toggleMessage}>Zatvori</Button>
                        </ModalFooter>
                    </Modal>
                </>
            )
        }

        let isUserProfile;

        if (Identity.id == this.props.user.id && !this.props.isLoading) {
            isUserProfile = (
                <>
                    {membershipExpires}
                    <NavLink tag={Link} to={Path.MESSAGES}>Poruke</NavLink>
                    <NavLink tag={Link} to={Path.ADS_FAVORITES}>Spremljeni oglasi</NavLink>
                    <NavLink tag={Link} to={Path.USER_FAVORITES}>Spremljeni korisnici</NavLink>
                    <NavLink tag={Link} to={Path.USER_PROFILE.toString() + "/" + Identity.id + "/" + Identity.username + "/postavke"}>Postavke</NavLink>
                    <NavLink onClick={this.logout}>Odjavi se</NavLink>
                </>
            )
        }

        return (
            <>
                {this.props.isLoading ? <i className="content-loader w100" /> : memberSince}
                {this.props.isLoading ? <i className="content-loader w100" /> : phone}
                {this.props.isLoading ? <i className="content-loader w100" /> : saveUser}
                {this.props.isLoading ? <i className="content-loader w100" /> : messages}
                {isUserProfile}
            </>
        );
    }
}
