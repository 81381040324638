import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

interface IBreadcrumbNavProps {
    catName, subName, catSlug: string,
    catID, subID: number,
    loaded: boolean,
}

function BreadcrumbNav(props: IBreadcrumbNavProps) {
    const loadercontent = <i className="title-loader-small">_______________</i>;
    const category = <Link to={"/pretraga/"+ props.catSlug + "/" + props.catID} >{props.catName}</Link>
    const subCategory = <Link to={"/pretraga/"+ props.catSlug + "/" + props.catID + "?podkategorija=" + props.subID} >{props.subName}</Link>
    return (
        <Breadcrumb>
            <BreadcrumbItem><Link to={"/pretraga/"+ props.catSlug + "/" + props.catID} >{props.catName}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={"/pretraga/"+ props.catSlug + "/" + props.catID + "?podkategorija=" + props.subID} >{props.subName}</Link></BreadcrumbItem>
        </Breadcrumb>
    );
}

export default BreadcrumbNav;

