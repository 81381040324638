import React, { Component } from 'react';
import { Col, ListGroupItem } from 'reactstrap';
import { AdSubcategory, IAdSubcategory } from '../../models/AdSubcategory';
import { AxiosError, AxiosResponse } from "axios";
import Icon from '../../custom/Icon';
import { Link } from 'react-router-dom';
import { Path } from '../../container/Path';
import Util from '../../custom/Util';

interface IHighlightedCategoriesState {
    highlightedCategories: IAdSubcategory[];
    error: string;
    isLoaded: boolean;
}

class HighlightedCategories extends Component<unknown, IHighlightedCategoriesState> {
    private subcategoryModel: AdSubcategory = new AdSubcategory();

    constructor(props: any) {
        super(props);

        this.state = {
            error: "",
            isLoaded: false,
            highlightedCategories: []
        };
    }

    public componentDidMount() {

        this.subcategoryModel.createDataSource(this.subcategoryModel.resourceNamePlural + "?pagination=1,7&sort=sort_key").getListPlain({})
            .then((response: AxiosResponse) => {

                this.setState({
                    isLoaded: true,
                    highlightedCategories: response.data
                });

            }).catch((error: AxiosError) => {

                Util.notification("error", "Podkategorije ne mogu biti učitane.", 1500)
            });

    }

    render() {
        const { error, isLoaded, highlightedCategories } = this.state;

        const search = Path.SEARCH;

        let content;

        if (error.length > 0) {
            content = <Col sm="12"> Error: {error}</Col>;
        } else if (!isLoaded) {
            content = (
                <>
                    <ListGroupItem> <i className="title-loader">________________________________</i></ListGroupItem>
                    <ListGroupItem> <i className="title-loader">___________________</i></ListGroupItem>
                    <ListGroupItem> <i className="title-loader">________________________________</i></ListGroupItem>
                    <ListGroupItem> <i className="title-loader">_________________________</i></ListGroupItem>
                    <ListGroupItem> <i className="title-loader">__________________</i></ListGroupItem>
                    <ListGroupItem> <i className="title-loader">__________________________</i></ListGroupItem>
                    <ListGroupItem> <i className="title-loader">______________</i></ListGroupItem>
                </>
            )
        } else {
            content = highlightedCategories.map(singleCategory => (
                <ListGroupItem key={singleCategory.id} tag={Link} to={search + "/" + singleCategory.ad_category.slug + "/" + singleCategory.ad_category.id + "?podkategorija=" + singleCategory.id}><Icon name={singleCategory.ad_category.slug} />{singleCategory.name}</ListGroupItem>
            ));

        }

        return (
            <Col lg="4" className="epk-top-category-section">
                <div className="epk-top-category">
                    <ListGroupItem className="epk-top-category-title"><Icon name="checked"></Icon>Izdvojene kategorije</ListGroupItem>
                    {content}
                </div>
            </Col>
        );

    }
}

export default HighlightedCategories;
