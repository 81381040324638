import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Path } from "../container/Path";
import { withRouter } from "../../common/components/routing/Router";
import EpkPage from "./EpkPage";
import { Container as PageContainer } from "../../index";
import { AdCategory, IAdCategory } from '../models/AdCategory';
import { AxiosError, AxiosResponse } from "axios";
import { Link } from 'react-router-dom';
import Icon from '../custom/Icon';
import Util from '../custom/Util';

interface ICategoriesState {
    isLoaded: boolean;
    categoryList: IAdCategory[];
}

@withRouter(Path.CATEGORIES.toString(), PageContainer)
class Categories extends EpkPage<unknown, ICategoriesState> {

    private categoryModel: AdCategory = new AdCategory();

    pageTitle() {
        return "Kategorije" + super.pageTitle();
    }

    getDestroyableMembers() {
        return [];
    }

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            categoryList: []
        }
    }

    public componentDidMount() {

        this.categoryModel.createDataSource(this.categoryModel.resourceNamePlural + "/subcategories?sort=sort_key").getListPlain({})
            .then((response: AxiosResponse) => {

                this.setState({
                    isLoaded: true,
                    categoryList: response.data
                });

            }).catch((error: AxiosError) => {

                Util.notification("error", "Kategorije ne mogu biti učitane.", 1500)
            });

    }


    renderContent() {
        const categories = this.state.categoryList;
        let content;

        const search = Path.SEARCH;

        const listCategoryLoader = []

        for (let i = 1; i <= 20; i++) {
            listCategoryLoader.push(
                <Col xs="12" sm="6" md="3" key={i}>
                    <div className="epk-single-cat">
                        <div className="category"><h2><i className="content-loader w70" /></h2></div>
                        <div className="subcategory">
                            <i className="content-loader-small w50" />
                            <i className="content-loader-small w70" />
                            <i className="content-loader-small w30" />
                            <i className="content-loader-small w50" />
                            <i className="content-loader-small w100" />
                            <i className="content-loader-small w30" />
                        </div>
                    </div>
                </Col>
            )
        }

        if (this.state.isLoaded) {
            content = (
                <>
                    {categories.map(category =>
                        <Col xs="12" sm="6" md="3" key={category.id}>
                            <div className="epk-single-cat">
                                <div className="category"><h2><Link to={search + "/" + category.slug + "/" + category.id}>{category.name}</Link></h2></div>
                                <div className="subcategory">
                                    {category.subcategories.map(subcategory =>
                                        <li key={subcategory.id}><Icon name="podkategorija" /><Link to={search + "/" + category.slug + "/" + category.id + "?podkategorija=" + subcategory.id}>{subcategory.name} </Link></li>
                                    )}
                                </div>
                            </div>
                        </Col>
                    )}
                </>
            );
        } else {
            content = (
                <>
                    {listCategoryLoader}
                </>
            );
        }

        return (
            <div className="epk-category-single">
                <Container>
                    <div className="epk-category-single-section epk-section">
                        <Row>
                            <Col md="12">
                                <h1 className="epk-container-h1">{!this.state.isLoaded ? <i className="content-loader w30" /> : 'Kategorije'}</h1>
                            </Col>
                            {content}
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }
}

export default Categories;
