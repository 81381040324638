import {DataModel, IDataModelState} from "./DataModel";
import {DataSource} from "../dataSource/DataSource";
import {AnyAction} from "redux";
import {DataModelValidator, IDataModelValidator} from "../components/validators/DataModelValidator";
import {AxiosError, AxiosResponse} from "axios";

export interface IDataProvider<I> {
    items: I[],
    listInfo?: IDataProviderListInfo
}

export interface IDataProviderListInfo {
    itemsCount: number;
    pageMax: number;
    pageNumber: number;
    pageSize: number;
}

/**
 * Data Provider class allows managing of collections of different @see DataModel instance.
 *
 * <I> Interface type
 */
export class DataProvider<I> extends DataModel<IDataProvider<I>> implements IDataProvider<I> {
    private _items: I[] = [];

    private _listInfo?: IDataProviderListInfo;

    /**
     * @constructor
     */
    public constructor(promise?: Promise<I[] | AxiosError>, saveToStore: boolean = true) {
        super(saveToStore);

        if (promise) {
            this.refresh(promise);
        }
    }

    public refreshWithHeaders(promise: Promise<AxiosResponse>):void {
        promise.then((response: AxiosResponse) => {
            this.setFromPlainObject({
                items: response.data,
                listInfo: {
                    itemsCount: +response.headers['x-pagination-itemscount'],
                    pageMax: +response.headers['x-pagination-pagemax'],
                    pageNumber: +response.headers['x-pagination-pagenumber'],
                    pageSize: +response.headers['x-pagination-pagesize']
                }
            });
        });
    }

    public refresh(promise: Promise<I[] | AxiosError>):Promise<I[] | AxiosError> {
        promise.then((data: I[] | AxiosError) => {
            if ((<I[]>data).length) {
                const items: I[] = [];
                (<I[]>data).forEach((rawItem: I) => {
                    items.push(rawItem);
                });

                this.setFromPlainObject({
                    items: items
                });

                return items;
            } else {
                this.setFromPlainObject({
                    items: []
                });

                return [];
            }
        });

        return promise;
    }

    /**
     * @inheritDoc
     */
    public createDataSource(): DataSource<IDataProvider<I>> {
        throw "Not implemented!";
    }

    /**
     * @inheritDoc
     */
    public createDataProvider(): DataProvider<IDataProvider<I>> {
        throw "Not implemented!";
    }

    protected createValidator(): IDataModelValidator<IDataProvider<I>> {
        return new DataModelValidator<IDataProvider<I>>({}, {});
    }

    /**
     * @inheritDoc
     */
    protected modelReducer(state: IDataModelState<IDataProvider<I>>,
                           action: AnyAction): IDataModelState<IDataProvider<I>> {
        return state;
    }

    /**
     * @inheritDoc
     */
    public getStoreKey(): string {
        return 'DATA_PROVIDER';
    }

    /**
     * @inheritDoc
     */
    protected setFromObj(data: IDataProvider<I>): void {
        this._items = data.items;
        this._listInfo = DataModel.safeGet(data.listInfo, this._listInfo);
    }

    /**
     * @inheritDoc
     */
    protected toObj(): IDataProvider<I> {
        return {
            items: this._items,
            listInfo: this._listInfo
        };
    }

    get items(): I[] {
        return this._items;
    }

    set items(value: I[]) {
        this._items = value;
    }

    public getItems(): I[] {
        return this._items;
    }

    get listInfo(): IDataProviderListInfo | undefined {
        return this._listInfo;
    }

    set listInfo(value: IDataProviderListInfo | undefined) {
        this._listInfo = value;
    }
}
