import React from "react";
import EpkPage from "./EpkPage";
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, } from 'reactstrap';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { IDestroy } from "../../common/components/pages/IDestroy";
import { AxiosResponse } from "axios";
import { User } from "../models/User";
import * as qs from 'query-string';
import Icon from "../custom/Icon";
import { Link } from "react-router-dom";

@withRouter(Path.VERIFY.toString(), PageContainer)
export default class EmailVerificationPage extends EpkPage {

    private user: User = new User();

    constructor(props: any) {
        super(props);

        const token = qs.parse(props.location.search).token as string;

        this.state = {
            isTokenValid: false,
            token,
            loaded: false
        }
    }

    pageTitle() {
        return "Verifikacija email adrese " + super.pageTitle();
    }

    getDestroyableMembers(): IDestroy[] {
        return [
            this.user
        ];
    }

    componentDidMount() {
        super.componentDidMount();


        this.user.verifyToken(this.state.token)
            .then((response: AxiosResponse) => {
                this.setState({
                    isTokenValid: true,
                    loaded: true
                });
            });
    }

    renderContent() {

        let content = null;

        if (this.state.loaded) {
            if (this.state.isTokenValid) {
                content = "Uspješno ste verifikovali svoj email.";
            } else {

                content = "Verifikacija nije uspjela, link nije validan.";
            }
        }

        return (
            <>
                <div className="epk-validation-content">
                    <Container>
                        <div className="epk-validation-section epk-section">
                            <Row>
                                <Col md="12" className="error-icon">
                                    <Icon name={this.state.isTokenValid ? "verify" : "stop"} />
                                </Col>
                                <Col md="12">
                                    <h1 className="epk-container-h1">{this.state.isTokenValid ? "Uspjeh" : "Greška"}</h1>
                                </Col>
                                <Col md="12">
                                    <div className="content">
                                        <p>{content}</p>
                                        <Button tag={Link} to={Path.HOMEPAGE} color="primary">Vrati se na početnu</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }
}
