import * as dotenv from 'dotenv';
dotenv.config({path:".env.production"});

import React, { Component } from 'react';
import { DropdownToggle, DropdownMenu, Label, Form, Button, FormGroup, Dropdown, Badge, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Icon from '../../custom/Icon';
import { Link } from 'react-router-dom';
import { User, IUser } from '../../models/User';
import { AxiosError, AxiosResponse } from 'axios';
import { Path } from '../../container/Path';
import { TextInput } from '../../../common/components/widgets/form/input/TextInput';
import { PasswordInput } from '../../../common/components/widgets/form/input/PasswordInput';
import Identity from '../../user/Identity';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { ISocialLogin, SocialLogin } from '../../models/SocialLogin';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import { Role } from '../../user/Role';
import { CheckboxInput } from '../../../common/components/widgets/form/input/CheckboxInput';
import Util from '../../custom/Util';
import { PrivateMessage } from '../../models/PrivateMessage';

interface IHeaderLoginState {
    dropdownOpen: boolean;
    notificationCount: number;
    modalAd: boolean;
}

class HeaderLogin extends Component<unknown, IHeaderLoginState> {

    private submitted: boolean = false;

    private user: User = new User(true, User.SCENARIO.LOGIN);
    private socialLoginModel: SocialLogin = new SocialLogin();
    private privateMessagesModel: PrivateMessage = new PrivateMessage();


    private inputs: { [attr: string]: React.RefObject<any> } = {
        username: React.createRef(),
        password: React.createRef(),
        remember_me: React.createRef(),
    };

    constructor(props: any) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.logout = this.logout.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            dropdownOpen: false,
            notificationCount: null,
            modalAd: false
        };
    }

    toggleModal() {
        this.setState(prevState => ({
            modalAd: !prevState.modalAd
        }));
    }

    componentDidMount() {

        if (!Identity.isGuest) {
            this.privateMessagesModel.conversationStarters().then((response: AxiosResponse) => {
                let messages = response.data;

                messages = messages.filter(x => {
                    const to_user = JSON.parse(x.to_user);

                    if (to_user.id == Identity.id && x.date_read == null) {
                        return x.to_user_hidden_at == null
                    }
                });

                this.setState({ notificationCount: messages.length })
            }).catch((error: AxiosError) => {
                Util.notification("error", "Poruke ne mogu biti učitane", 15000);
            })
        }

    }

    private readInfo(): IUser {
        const plainObject: IUser = {};

        for (const key in this.inputs) {

            if (this.inputs.hasOwnProperty(key) && this.inputs[key].current) {
                plainObject[key] = this.inputs[key].current.getValue();
                this.inputs[key].current.removeError();
            }
        }

        this.user.setFromPlainObject(plainObject);

        return plainObject;
    }

    private handleValidate(event: any): boolean {
        this.readInfo();
        const valid = this.user.validate();

        if (this.submitted) {
            if (!valid) {
                const errors: { [attr: string]: string } = this.user.getErrors();
                this.fillInputsWithErrors(errors);
            }
        }

        event.preventDefault();

        return valid;
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }) {
        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                this.inputs[key].current.setError(errors[key]);
            }
        }
    }

    protected handleLogIn(event: React.MouseEvent<HTMLElement>): void {
        this.submitted = true;

        const plainObject: IUser = this.readInfo();
        const valid: boolean = this.handleValidate(event);

        if (valid) {
            this.user.login(plainObject).then((error: AxiosResponse | void | AxiosError) => {
                if (typeof window !== 'undefined') {
                    document.location.reload(); //reload zbog drugačijeg prikaza headera
                }
            }).catch((error: AxiosError) => {
                const aError: AxiosError = error;
                console.log(error);
                if (aError.response.data.errorCode == 1065) {
                    Util.notification("alert", "Vaš nalog nije verifikovan. Da biste ga verifikovali, <a href='/registracija'>registrujte se kao član EKI Poslovnog Kluba.</a>", 10000, "bootstrap-v4", "center");
                } else if (aError.response.data.errorCode == 1060) {
                    Util.notification("alert", "Vaš nalog je izbrisan ili blokiran.", 10000, "bootstrap-v4", "center");
                } else
                    this.fillInputsWithErrors({
                        password: "Username i lozinka se ne poklapaju!"
                    });

            });
        }

        event.preventDefault();
    }

    protected logout(event: React.MouseEvent<HTMLElement>): void {

        const user: User = new User(false);

        user.logout().then(() => {

            new GoogleLogout({
                "clientId": process.env.REACT_APP_GOOGLE_LOGIN_APP_ID,
                "buttonText": "",
                "onLogoutSuccess": () => { console.log("logout") }
            }).signOut;

            return Identity.eraseUserIdentity();

        }).then(() => {
            if (typeof window !== 'undefined') {
                document.location.pathname = Path.HOMEPAGE.toString();
            }
        }).catch((error) => {
            console.log(error)
        });

        Identity.eraseUserIdentity();


        event.preventDefault();
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    responseFacebook = (response) => {
        if (response) {

            const profile_cache = {
                email: response.email ? response.email : null,
                avatar: response.picture ? response.picture.data.url : null
            }

            const socialLoginParams: ISocialLogin = {
                provider: "facebook",
                username: response.name,
                identifier: response.userID,
                profile_cache: profile_cache
            }

            this.socialLoginModel.setFromPlainObject(socialLoginParams);
            this.socialLoginModel.createNew()
                .then((response: any) => {
                    setTimeout(() => {
                        let suffix = "";
                        if (response.exists) {
                            suffix = "exists=true"
                        }

                        let connectWithUrl = "";

                        //redirect after login
                        if (typeof window !== 'undefined') {
                            const pattern = new RegExp(/\?.+=.*/g);
                            if (pattern.test(document.location.href)) {
                                connectWithUrl = suffix ? '&' : '';
                                document.location.assign(document.location.href + connectWithUrl + suffix)
                            } else {
                                connectWithUrl = suffix ? '?' : '';
                                document.location.assign(document.location.href + connectWithUrl + suffix)
                            }
                        }
                    }, 1000)
                })
                .catch((error: AxiosError) => {
                    const aError: AxiosError = error;

                    if (aError.response.data.errorCode == 1065) {
                        Util.notification("alert", "Vaš nalog nije verifikovan. Da biste ga verifikovali, <a href='/registracija'>registrujte se kao član EKI Poslovnog Kluba.</a>", 10000, "bootstrap-v4", "center");
                    } else if (aError.response.data.errorCode == 1060) {
                        Util.notification("alert", "Vaš nalog je izbrisan ili blokiran.", 10000, "bootstrap-v4", "center");
                    } else
                        this.fillInputsWithErrors({
                            password: "Username i lozinka se ne poklapaju!"
                        });
                });
        } else {
            Util.notification("error", "Desila se greška, prijava nije uspjela.", 1500);
        }
    }

    responseGoogle = (response) => {
        if (!response.error) {

            const profileObj = response.profileObj;

            const profile_cache = {
                email: profileObj.email ? profileObj.email : null,
                avatar: profileObj.imageUrl ? profileObj.imageUrl : null
            }

            const socialLoginParams: ISocialLogin = {
                provider: "google",
                username: response.profileObj.name,
                identifier: response.googleId,
                profile_cache: profile_cache
            }

            this.socialLoginModel.setFromPlainObject(socialLoginParams);
            this.socialLoginModel.createNew()
                .then((response: any) => {
                    setTimeout(() => {
                        let suffix = "";

                        if (response.exists) {
                            suffix = "exists=true"
                        }

                        let connectWithUrl = "";

                        if (typeof window !== 'undefined') {
                            //redirect after login
                            const pattern = new RegExp(/\?.+=.*/g);
                            if (pattern.test(document.location.href)) {
                                connectWithUrl = suffix ? '&' : '';
                                document.location.assign(document.location.href + connectWithUrl + suffix)
                            } else {
                                connectWithUrl = suffix ? '?' : '';
                                document.location.assign(document.location.href + connectWithUrl + suffix)
                            }
                        }

                    }, 1000)
                })
                .catch((error: AxiosError) => {
                    const aError: AxiosError = error;

                    if (aError.response.data.errorCode == 1065) {
                        Util.notification("alert", "Vaš nalog nije verifikovan. Da biste ga verifikovali, <a href='/registracija'>registrujte se kao član EKI Poslovnog Kluba.</a>", 10000, "bootstrap-v4", "center");
                    } else if (aError.response.data.errorCode == 1060) {
                        Util.notification("alert", "Vaš nalog je izbrisan ili blokiran.", 10000, "bootstrap-v4", "center");
                    } else
                        this.fillInputsWithErrors({
                            password: "Username i lozinka se ne poklapaju!"
                        });
                });
        }/* else {
            Util.notification("error", "Desila se greška, prijava nije uspjela.", 1500);
        }*/
    }

    render() {

        const content = Identity.isGuest ?
            (<>
                <Form className="px-4 py-3">
                    <FormGroup>
                        <Label for="DropdownUsername">Korisničko ime</Label>
                        <TextInput id="DropdownUsername" ref={this.inputs.username}
                            onBlur={this.handleValidate.bind(this)} autoFocus={this.state.dropdownOpen} tabIndex={1} autoComplete="username" />
                    </FormGroup>
                    <div>
                        <Label for="DropdownPassword">Lozinka</Label>
                        <Label tabIndex={6} className="header-forgotpassword" for="DropdownFormForgotpassword1"><Link to={Path.FORGOTTEN_PASSWORD.toString()}>Zaboravljena lozinka?</Link></Label>
                        <PasswordInput id="DropdownPassword" ref={this.inputs.password}
                            tabIndex={2} autoComplete="current-password" />
                    </div>
                    <FormGroup>
                        <div>
                            <CheckboxInput tabIndex={10} id="Check"
                                label="Zapamti me" ref={this.inputs.remember_me}
                            ></CheckboxInput>

                        </div>
                    </FormGroup>
                    <Button color="epk-primary" type="submit" onClick={this.handleLogIn.bind(this)} tabIndex={4}>Prijavi se</Button>

                    <FacebookLogin
                        appId={process.env.REACT_APP_FB_APP_ID}
                        autoLoad={true}
                        size="medium"
                        fields="name,email,picture"
                        textButton=""
                        version="3.2"
                        isMobile={true}
                        disableMobileRedirect={true}
                        redirectUri={process.env.REACT_APP_URL}
                        callback={this.responseFacebook}
                        cssClass="social-button facebook btn btn-social-button"
                        icon={<Icon name="facebook" tabIndex={5} />}
                    />

                    <GoogleLogin
                        autoLoad={false}
                        onAutoLoadFinished={true}
                        clientId={process.env.REACT_APP_GOOGLE_LOGIN_APP_ID}
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        buttonText=""
                        style={{ marginRight: 0 }}
                        className="social-button google btn btn-social-button"
                    />
                </Form>
                <div className="dropdown-divider"></div>
                <Link tabIndex={7} className="dropdown-item registruj-se" to={Path.REGISTER}>Prvi put ste ovdje? <b>Registrujte se!</b></Link>
            </>
            )
            :
            (<>
                <DropdownItem disabled>{Identity.username}</DropdownItem>
                <Link className="dropdown-item" to={Path.USER_PROFILE + "/" + Identity.id + "/" + Identity.username}>Moj profil</Link>
                <Link className="dropdown-item" to={Path.MESSAGES}>Poruke {this.state.notificationCount ? <Badge color="primary">{this.state.notificationCount}</Badge> : null}</Link>
                <Link className="dropdown-item" to={Path.ADS_FAVORITES}>Spremljeni oglasi</Link>
                <Link className="dropdown-item" to={Path.USER_FAVORITES}>Spremljeni korisnici</Link>
                <Link className="dropdown-item" to={Path.USER_PROFILE.toString() + "/" + Identity.id + "/" + Identity.username + "/postavke"}>Postavke</Link>
                <Link className="dropdown-item" to="#" onClick={this.logout}>Odjavi se</Link>

            </>);

        let additionalContent

        if (Identity.role == Role.CLIENT || Identity.role == Role.LOAN_OFFICER) {
            additionalContent = (
                <div className="btn float-sm-right objavi">
                    <Button color="header-login" tag={Link} to={Path.AD_ADD.toString()} className="float-xl-right align-items-center objavi-btn" >
                        <Icon name="add" /> OBJAVI
                    </Button>
                </div>
            )
        } else if (Identity.role == Role.USER) {
            additionalContent = (
                <div className="btn float-sm-right objavi">
                    <Button color="header-login" onClick={this.toggleModal} className="float-xl-right align-items-center objavi-btn" >
                        <Icon name="add" /> OBJAVI
                    </Button>
                    <Modal isOpen={this.state.modalAd} toggle={this.toggleModal}>
                        <ModalHeader toggle={this.toggleModal}>Poštovani, Vi niste član EKI Poslovnog kluba</ModalHeader>
                        <ModalBody>
                            <p>EKI Poslovni klub (EPK) predstavlja dodatnu BESPLATNU uslugu za EKI-jeve klijente. EPK je usluga BESPLATNOG oglašavanja na EPK portalu za sve aktivne članove EPK. Članovi <Link to={Path.EPK}>EKI Poslovnog kluba</Link> mogu biti svi klijenti EKI-ja koji imaju aktivan kredit.</p>
                            <p>Vi trenutno možete pregledati oglase, slati privatne i javne poruke, ali niste u mogućnosti da objavljujete svoje oglase, niti da pristupite izdvojenim sadržajima.
                            Da bi pristupio svom profilu i mogao samostalno objavljivati oglase, član EPK mora izvršiti registraciju profila unosom korisničkih podataka dodijeljenih od strane EKI-ja.</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.toggleModal}>Zatvori</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            )
        }

        return (
            <>{additionalContent}
                <Dropdown className="dropdown btn float-sm-right align-items-center epk-display-header-login" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle caret color="header-login" className="btn-block dropdown-toggle">
                        <Icon name="user" /> {Identity.isGuest ? "PRIJAVA" : "PROFIL"}  {!Identity.isGuest && this.state.notificationCount ? <Badge color="primary">{this.state.notificationCount}</Badge> : ''}
                    </DropdownToggle>
                    <DropdownMenu right className="dropdown-menu-right epk-header-login">
                        {content}
                    </DropdownMenu>
                </Dropdown >
            </>
        );
    }
}

export default HeaderLogin;
