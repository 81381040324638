import React from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem, Media, Pagination, PaginationItem, PaginationLink, Alert, Spinner } from 'reactstrap';
import { Path } from "../container/Path";
import { withRouter } from "../../common/components/routing/Router";
import EpkPage from "./EpkPage";
import { Container as PageContainer } from "../../index";
import { Blog, IBlog } from '../models/Blog';
import { AxiosError, AxiosResponse } from 'axios';
import Util from '../custom/Util';
import { Link } from 'react-router-dom';
import qs from 'qs';

@withRouter(Path.EDUCATION.toString(), PageContainer)
@withRouter(Path.EDUCATION_VIDEO.toString(), PageContainer)
export default class EducationPage extends EpkPage {

    private blog: Blog = new Blog();
    private defaultBlogImage = "/files/images/edukacija.jpg";

    constructor(props) {
        super(props);

        const getPageFromUrl= qs.parse(props.location.search);
        const page = getPageFromUrl["?stranica"];

        this.state = {
            isLoading: true,
            pathname: this.props.location.pathname,
            currentPage: page ? Number(page) : 1,
            maxPage: 1,
            pageSize: 12,
            isLoaded: false,
            blogList: [],
            blogCategoryList: [],
            mostViews: [],
            categoryName: "",
            ...this.props.data
        };

        this.getBlogs = this.getBlogs.bind(this);
        this.handlePages = this.handlePages.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);

    }

    pageTitle() {
        return "Edukacija" + this.state.categoryName + super.pageTitle();
    }

    getBlogs(page?) {
        this.setState({
            currentPage: page ? Number(page) : this.state.currentPage,
        });

        const blogParams = {
            filters: [{
                attr: "status_id",
                val: 1,
                operator: "eq"
            }],
            sort: {
                attr: "created_at",
                ascending: false
            },
            paging: { 
                pageNumber: page ? page : this.state.currentPage, 
                pageSize: this.state.pageSize
            }
        }

        
        const type = this.props.location.pathname === Path.EDUCATION ? 'text' : 'video';
        this.blog.getListPlain(type, blogParams).then((response: AxiosResponse | AxiosError) => {
            const aError: AxiosError = response as AxiosError;
            const resultSet: AxiosResponse = response as AxiosResponse;

            if (!aError.response && resultSet.data != undefined) {
                const resultCount = resultSet.headers["x-pagination-itemscount"] != undefined ? Math.ceil(resultSet.headers["x-pagination-itemscount"] / this.state.pageSize)
                    : (Math.ceil(resultSet.data.length / this.state.pageSize) || 0);

                this.setState({
                    blogList: resultSet.data,
                    maxPage: resultCount,
                    isLoaded: true,
                });

                Util.topFunction();
            }

            }).catch((error: AxiosError) => {
                Util.notification("error", "Desila se greška, blogovi ne mogu biti učitani.", 1500)
            });
    }

    componentDidMount() {
        this.blog.loadMostViews(this.state.pathname === Path.EDUCATION.toString() ? 'most-read' : 'most-viewed').then((response) => {
            this.setState({
                mostViews: response
            })
        })

        this.blog.loadCategories().then((response) => {
            this.setState({
                blogCategoryList: response
            })
        })

        this.getBlogs();

        super.componentDidMount();
    }

    getDestroyableMembers() {
        return [
            this.blog,
        ];
    }
    
    changePageUrl(page) {
        const pageName = this.state.pathname === Path.EDUCATION.toString() ? Path.EDUCATION.toString() : Path.EDUCATION_VIDEO.toString(); 
        history.replaceState({}, "", pageName + "?stranica=" + page); //promjeni url bez reloada
        this.getBlogs(page) //opali api sa novim pageom
    }

    handlePages(event) {
        const el = event.target as HTMLElement;
        const newPage = el.getAttribute('data-page') || '1';
        this.changePageUrl(newPage);
    }

    handleNextPage() {
        if (this.state.currentPage < this.state.maxPage) {
            const nextPage = this.state.currentPage + 1;
            this.changePageUrl(nextPage);
        }
    }

    handlePreviousPage() {
        if (this.state.currentPage <= this.state.maxPage) {
            const previousPage = this.state.currentPage - 1;
            this.changePageUrl(previousPage);
        }
    }

    range(start, count) { return Array.apply(0, Array(count)).map(function (element, index) { return index + start; }); }
    renderContent() {
        const pageNumbers = this.range(1, this.state.maxPage);
        let blogCategoryList;
        if(this.state.pathname === Path.EDUCATION) {
            blogCategoryList = this.state.blogCategoryList.filter(function(a){ return a.is_video == false});
        } else {
            blogCategoryList = this.state.blogCategoryList.filter(function(a){ return a.is_video == true});
        }

        let content;
        if (!this.state.isLoaded) {
            content =
                <Col sm="12">
                    <Alert color="empty">
                        <Spinner style={{ width: '1rem', height: '1rem' }} color="primary" type="grow" /> Učitavanje edukacija
                    </Alert>
                </Col>;
        } else if (this.state.blogList.length === 0) {
            content =
                <Col sm="12">
                    <Alert color="empty">
                        Nema rezultata
                    </Alert>
                </Col>;
        } else {
            content = (
                <>
                    <Row className={"w-100"}>
                        {this.state.blogList.map((blog) => {

                            const imagePath = blog.cover_image ? blog.cover_image : (blog.category.image_path ? blog.category.image_path : this.defaultBlogImage);

                            return (<Col md="6" lg="4" className="d-flex" key={blog.id}>
                                <div className="card mb-3 w-100">
                                    <div className="education-conatiner">
                                        <Link className="education-image-container" to={Path.EDUCATION.toString() + "/" + blog.id + "/" + blog.slug}>
                                            <img className="card-img-top " src={imagePath} />
                                            <div className="education-category">{blog.category ? blog.category.name : "/"}</div>
                                        </Link>
                                        <div className="education-body pt-2">
                                            <p className="education-text"><small className="text-muted">Objavljeno: {Util.formatDatetime(blog.published_at)}</small></p>
                                            <Link to={Path.EDUCATION.toString() + "/" + blog.id + "/" + blog.slug}><h5 className="education-title">{blog.title}</h5></Link>
                                            <p className="education-text mb-0" dangerouslySetInnerHTML={{ __html: Util.shortenContent(blog.content, 300, ['.', '!', '?']) }} />
                                        </div>
                                    </div>
                                </div>
                            </Col>)
                        })}
                    
                        <Col md="12">
                            <Pagination className="col-12 epk-pagination" aria-label="Navigacija pretrage">
                                <PaginationItem disabled={this.state.currentPage === 1}>
                                    <PaginationLink previous onClick={this.handlePreviousPage} />
                                </PaginationItem>

                                {this.state.currentPage > 3 ? (
                                    <PaginationItem active={1 === this.state.currentPage}>
                                        <PaginationLink data-page="1" onClick={this.handlePages}>
                                            Prva
                                        </PaginationLink>
                                    </PaginationItem>
                                ) : (null)}

                                {pageNumbers.slice(Math.max(0, this.state.currentPage - 3), Math.min(this.state.maxPage, this.state.currentPage + 2)).map(pageNumber => (
                                    <PaginationItem active={pageNumber === this.state.currentPage} key={pageNumber} >
                                        <PaginationLink data-page={pageNumber} onClick={this.handlePages}>
                                            {pageNumber}
                                        </PaginationLink>
                                    </PaginationItem>
                                ))}

                                {this.state.currentPage < this.state.maxPage - 2 ? (
                                    <PaginationItem active={this.state.maxPage === this.state.currentPage}>
                                        <PaginationLink data-page={this.state.maxPage} onClick={this.handlePages}>
                                            Zadnja
                                        </PaginationLink>
                                    </PaginationItem>
                                ) : (null)}

                                <PaginationItem disabled={this.state.currentPage === this.state.maxPage}>
                                    <PaginationLink next onClick={this.handleNextPage} />
                                </PaginationItem>
                            </Pagination>
                        </Col>

                    </Row>
                </>
        )}

        return (
            <div className="epk-education">
                <Container>
                    <div className="epk-education-section epk-section">
                        <Row>
                            <Col xs="12">
                                <h1 className={"epk-container-h1" + (this.props.match.params.slug ? 'mb-0' : '')}>Edukacija</h1>
                            </Col>

                            {!this.props.match.params.slug ? <Col xs="12"><p>Članovi EKI Poslovnog kluba i ostali posjetioci sajta EKI Oglasi imaju priliku da putem ove sekcije dobijaju tekstualne i video informacije i uputstva, savjete stručnjaka iz oblasti poljoprivrede i malog biznisa, kao i da se upoznaju sa osnovnim pojmovima i novinama iz oblasti energetske efikasnosti, finansija i interneta. Korisnici stranice će imati mnoštvo korisnih informacija koje mogu primjenjivati u njihovom poslu, njegovom proširenju i jačanju.</p></Col> : ''}
                        </Row>
                    </div>
                    <div className={"epk-education-section epk-section " + (!this.props.match.params.slug ? 'mt-top' : '')}>
                        <Row>
                            <Col lg="9">
                                <div className="card-group">
                                     {content}   
                                </div>
                            </Col>
                            <Col lg="3">
                                <div className="education-widget">
                                    <h5 className="education-widget-title">Kategorije</h5>
                                    <ListGroup>
                                        <ListGroupItem key="0" tag="a" href={this.state.pathname === Path.EDUCATION.toString() ? Path.EDUCATION.toString() : Path.EDUCATION_VIDEO.toString()}>Sve</ListGroupItem>
                                        {blogCategoryList.filter(function(element) { return element.slug !== process.env.REACT_APP_CPP_CATEGORY_SLUG}).map((cat) => {return (<ListGroupItem key={cat.id} tag="a" href={Path.EDUCATION_CATEGORY.toString() + "/" + cat.slug}>{cat.name}</ListGroupItem>);})}
                                    </ListGroup>
                                </div>

                                <div className="education-widget mt-top">
                                    <h5 className="education-widget-title">Najčitanije</h5>


                                    {this.state.mostViews.map((blog) => {

                                        const imagePath = blog.cover_image ? blog.cover_image : (blog.category.image_path ? blog.category.image_path : this.defaultBlogImage);

                                        return (<Media className="most-viewed" key={blog.id + "" + blog.name}>
                                            <Media left href={Path.EDUCATION.toString() + "/" + blog.id + "/" + blog.slug}>
                                                <Media className="most-viewed-img" object src={imagePath} />
                                            </Media>
                                            <Media body>
                                                <a href={Path.EDUCATION.toString() + "/" + blog.id + "/" + blog.slug}>{blog.title}</a>
                                                <div className="most-viewed-text" dangerouslySetInnerHTML={{ __html: Util.shortenContent(blog.content, 100) }} ></div>
                                            </Media>
                                        </Media>)
                                    })
                                    }

                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container >
            </div >
        );
    }
}
