import React from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Button, Alert, Spinner } from 'reactstrap';
import { Path } from "../container/Path";
import { withRouter } from "../../common/components/routing/Router";
import EpkPage from "./EpkPage";
import { Container as PageContainer } from "../../index";
import { User, IUser } from "../models/User";
import { IDestroy } from "../../common/components/pages/IDestroy";
import Axios, { AxiosError, AxiosResponse } from "axios";
import UserActions from './user/Actions';
import classnames from 'classnames';
import Ad from '../custom/Ad';
import Icon from '../custom/Icon';
import Identity from '../user/Identity';
import Util from '../custom/Util';
import { Role } from '../user/Role';
import { AdStatus } from '../custom/enum/AdStatus';

@withRouter(Path.USER.toString(), PageContainer)
export default class UserPage extends EpkPage {
    constructor(props) {

        super(props);

        this.toggle = this.toggle.bind(this);
        this.activeAds = this.activeAds.bind(this);
        this.closedAds = this.closedAds.bind(this);
        this.closedByUserAds = this.closedByUserAds.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.addFavoriteUser = this.addFavoriteUser.bind(this);
        this.loadUser = this.loadUser.bind(this);

        this.state = {
            isLoading: true,
            isLoadingAds: true,
            error: "",
            activeTab: '1',
            adsPerPage: 9, // broj prikazanih oglasa
            activeAdsPage: 1,
            closedAdsPage: 1,
            closedByUserAdsPage: 1,
            activeAds: [],
            closedAds: [],
            closedByUserAds: [],
            user: this.user,
            isFavorited: true
        };
    }

    private user: User = new User();

    pageTitle() {
        return this.user.username ? this.user.username + " -" + process.env.REACT_APP_TITLE_PREFIX : process.env.REACT_APP_TITLE_PREFIX;
    }

    getDestroyableMembers(): IDestroy[] {
        return [
            this.user
        ];
    }

    loadUser(): Promise<void> {
        return this.user.loadById(this.props.match.params.id).then((response: IUser | AxiosError) => {
            const aError: AxiosError = response as AxiosError;
            const users: IUser = response as IUser;
            const isFavorited = true;

            if (!aError.response) {
                this.user.setFromPlainObject(users);

                this.setState({
                    user: this.user.asPlainObject(),
                    isLoading: false,
                })

                this.user.newView(this.user.id, {
                    ip_address: Identity.ip_address,
                    user_agent: Identity.user_agent,
                    user_id: Identity.id == 0 ? null : Identity.id
                })
                if (typeof window !== 'undefined') {

                    document.title = this.pageTitle();
                }
            } else {
                if (typeof window !== 'undefined') {
                    document.location.pathname = Path.NOT_FOUND;
                }
            }


            if (!Identity.isGuest) {

                this.user.favorites()
                    .then((response: AxiosResponse) => {
                        if (response.data && response.data.length > 0) {
                            response.data.forEach((element) => {
                                if (element.user_id == Identity.id && element.favorite_user_id != this.user.id) {
                                    this.setState({ isLoading: false, isFavorited: false })
                                }
                            });
                        } else {
                            this.setState({ isLoading: false, isFavorited: false })
                        }
                    })
                    .catch((error: AxiosError) => {
                        Util.notification("error", "Desila se greška. Korisnik nije učitan.", 15000);

                    });
            }

            if (!this.user.id) {
                window.location.replace(Path.NOT_FOUND);
            }

            if (this.user.id && !this.user.verified && Identity.id != this.user.id) {
                window.location.replace(Path.FORBIDDEN);
            }

        })
    }

    public componentDidMount() {
        this.loadUser().then(() => {
            super.componentDidMount()
            this.activeAds(),
                this.closedAds();
            this.closedByUserAds();
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id != this.props.match.params.id) {
            this.loadUser()
        }
    }

    activeAds() {
        if (this.user.id) {
            const statusAd = this.user.id !== Identity.id ? ',status_id:eq:1' : '';
            const sortAd = '&sort=-created_at,-published_at';
            const userCondition = Identity.role == Role.LOAN_OFFICER ? `created_by:eq:${this.user.id}` : `user_id:eq:${this.user.id}`;
            this.user.createDataSource('oglasi').getListPlain({ filter: `${userCondition},closed_at:is:NULL${statusAd}${sortAd}` })
                .then((response: AxiosResponse) => {

                    let data = response.data;
                    data = data.filter(ad => {
                        return ad.ad_status_id == AdStatus.APPROVED || ad.ad_status_id == AdStatus.DUPLICATE_APPROVED;
                    }).map(el => {
                        if (el.ad_images.length > 0) {
                            el.ad_images = el.ad_images.filter(el => {
                                return el.status_id == 1;
                            })
                        }
                        return el;
                    })

                    this.setState({
                        activeAds: data,
                    });

                }).catch((e: AxiosError) => {
                    this.setState({
                        error: e.message
                    })
                }).then(() => {
                    this.setState({
                        isLoadingAds: false
                    })
                });
        }
    }

    closedAds() {
        if (this.user.id) {
            this.user.createDataSource('oglasi').getListPlain({ filter: `user_id:eq:${this.user.id},status_id:eq:1&sort=-created_at` })
                .then((response: AxiosResponse) => {
                    let data = response.data;

                    data = data.filter(ad => {
                        if (ad.user_id == Identity.id) {
                            return ad.ad_status_id == AdStatus.FINISHED || ad.ad_status_id == AdStatus.EXPIRED;
                        }
                        else {
                            return ad.ad_status_id == AdStatus.FINISHED
                        }
                    }).map(el => {
                        if (el.ad_images.length > 0) {
                            el.ad_images = el.ad_images.filter(el => {
                                return el.status_id == 1;
                            })
                        }
                        return el;
                    })

                    this.setState({
                        closedAds: data,
                    });

                }).catch((e: AxiosError) => {
                    this.setState({
                        error: e.message
                    })
                });
        }
    }

    closedByUserAds() {
        if (this.user.id) {
            this.user.createDataSource('oglasi').getListPlain({ filter: `closed_at:is:NOT NULL,closed_by:eq:${this.user.id},status_id:eq:1&sort=-created_at` })
                .then((response: AxiosResponse) => {
                    let data = response.data;
                    data = data.map(el => {
                        if (el.ad_images.length > 0) {
                            el.ad_images = el.ad_images.filter(el => {
                                return el.status_id == 1;
                            })
                        }
                        return el;
                    })

                    this.setState({
                        closedByUserAds: data,
                    });

                }).catch((e: AxiosError) => {
                    this.setState({
                        error: e.message
                    })
                });
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    loadMore(event: React.MouseEvent<HTMLInputElement>) {
        const tab = event.target as HTMLInputElement;

        switch (tab.id) {
            case '1':
                this.setState({
                    activeAdsPage: this.state.activeAdsPage + 1
                });
                break;
            case '2':
                this.setState({
                    closedAdsPage: this.state.closedAdsPage + 1
                });
                break;
            case '3': this.setState({
                closedAdsByUserPage: this.state.closedAdsByUserPage + 1
            });
                break;
        }
    }

    addFavoriteUser(event: React.MouseEvent<HTMLElement>) {

        this.user.addFavorite(this.user.id)
            .then((response: AxiosResponse) => {

                Util.notification("success", "Uspješno ste sačuvali korisnika", 1000);
                this.setState({ isFavorited: true })
            })
            .catch((error: AxiosError) => {

                Util.notification("error", "Desila se greška", 1000);
            });
    }

    renderContent() {

        const loadMore = (
            <div className="epk-load-more">
                <Button onClick={this.loadMore} color="epk-primary" id={this.state.activeTab}><Icon name="vise" /> Učitaj više</Button>
            </div>
        )

        const loadingRun = (
            <Alert color="empty">
                <Spinner style={{ width: '1rem', height: '1rem' }} color="primary" type="grow" /> Učitavanje oglasa
            </Alert>
        )

        let activeAds;
        if (this.state.isLoadingAds) {
            activeAds = loadingRun
        } else {
            if (this.state.activeAds.length != 0) {
                activeAds = (
                    <Row>
                        {this.state.activeAds.slice(0, this.state.adsPerPage * this.state.activeAdsPage).map(ad => (
                            <Ad {...ad} key={ad.id} />
                        ))}
                    </Row>
                )
            } else {
                if (this.user.id == Identity.id) {
                    activeAds = (
                        <Alert color="empty">
                            Nemate aktivne oglase.
                        </Alert>
                    )
                } else {
                    activeAds = (
                        <Alert color="empty">
                            Korisnik <strong>{this.user.username}</strong> nema aktivne oglase.
                        </Alert>
                    )
                }
            }
        }

        let closedAds;
        if (this.state.isLoading) {
            activeAds = loadingRun
        } else {
            if (this.state.closedAds.length != 0) {
                closedAds = (
                    <Row>
                        {this.state.closedAds.slice(0, this.state.adsPerPage * this.state.closedAdsPage).map(ad => (
                            <Ad {...ad} key={ad.id} />
                        ))}
                    </Row>
                )
            } else {
                if (this.user.id == Identity.id) {
                    closedAds = (
                        <Alert color="empty">
                            Nemate zatvorene oglase.
                        </Alert>
                    )
                } else {
                    closedAds = (
                        <Alert color="empty">
                            Korisnik <strong>{this.user.username}</strong> nema zatvorene oglase.
                        </Alert>
                    )
                }
            }
        }

        let closedByUserAds;
        if (this.state.isLoading) {
            activeAds = loadingRun
        } else {
            if (this.state.closedByUserAds.length != 0) {
                closedByUserAds = (
                    <Row>
                        {this.state.closedByUserAds.slice(0, this.state.adsPerPage * this.state.closedByUserAdsPage).map(ad => (
                            <Ad {...ad} key={ad.id} />
                        ))}
                    </Row>
                )
            } else {
                if (this.user.id == Identity.id) {
                    closedByUserAds = (
                        <Alert color="empty">
                            Nemate kupljene oglase.
                        </Alert>
                    )
                } else {
                    closedByUserAds = (
                        <Alert color="empty">
                            Korisnik <strong>{this.user.username}</strong> nema kupljene oglase.
                        </Alert>
                    )
                }
            }
        }

        const isUserAdmin = Identity.id == this.user.id ? 'Moji oglasi' : 'Aktivni';

        const thumb = !Util.isAbsolutePath(this.user.avatar) ? '/thumb' : '';

        return (
            <div className="epk-user-profile">
                <Container>
                    <div className="epk-user-profile-section epk-section">
                        <Row>
                            <Col xs="12">
                                <h1 className="epk-container-h1">{this.state.isLoading ? <i className="content-loader w30" /> : this.user.username}</h1>
                            </Col>
                            <Col xl="3">
                                <div className="epk-profile-info">
                                    <div className="epk-profile-info-section">
                                        <div className="epk-profile-image">
                                            {this.state.isLoading ?
                                                <Spinner color="primary" /> :
                                                <img title={this.user.username} src={this.user.avatar != null ? thumb + this.user.avatar : "/profile/no-img-profile.png"} />
                                            }
                                        </div>
                                        {this.state.isLoading ?
                                            <i className="content-loader w100" /> :
                                            <div className="epk-profile-id">ID korisnika <span>{this.user.id}</span></div>
                                        }
                                    </div>
                                    <div className="epk-profile-actions-section">
                                        <UserActions
                                            user={this.user}
                                            addFavoriteUser={this.addFavoriteUser}
                                            isFavorited={this.state.isFavorited}
                                            isLoading={this.state.isLoading}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl="9">
                                <div>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === '1' })}
                                                onClick={() => { this.toggle('1'); }}>
                                                {this.state.isLoading ? <i className="title-loader">____________</i> : isUserAdmin + ' (' + this.state.activeAds.length + ')'}
                                            </NavLink>
                                        </NavItem>


                                        <><NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === '2' })}
                                                onClick={() => { this.toggle('2'); }} >
                                                {this.state.isLoading ? <i className="title-loader">____________</i> : 'Završeni (' + this.state.closedAds.length + ')'}
                                            </NavLink>
                                        </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '3' })}
                                                    onClick={() => { this.toggle('3'); }}>
                                                    {this.state.isLoading ? <i className="title-loader">____________</i> : 'Kupljeni (' + this.state.closedByUserAds.length + ')'}
                                                </NavLink>
                                            </NavItem></>

                                    </Nav>
                                    <TabContent activeTab={this.state.activeTab}>
                                        <TabPane tabId="1">

                                            {activeAds}
                                            {this.state.adsPerPage * this.state.activeAdsPage <= this.state.activeAds.length ? loadMore : ``}

                                        </TabPane>
                                        <TabPane tabId="2">

                                            {closedAds}
                                            {this.state.adsPerPage * this.state.closedAdsPage <= this.state.closedAds.length ? loadMore : ``}

                                        </TabPane>

                                        <TabPane tabId="3">

                                            {closedByUserAds}
                                            {this.state.adsPerPage * this.state.closedAdsByUserPage <= this.state.closedByUserAds.length ? loadMore : ``}

                                        </TabPane>
                                    </TabContent>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }
}

