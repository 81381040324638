/**
 * User roles.
 */
export enum Role {

    GUEST = "guest",

    USER = "user",

    CLIENT = "client",

    ADMIN = "admin",

    LOAN_OFFICER = "lo",
}
