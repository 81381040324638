import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";

export interface IConfig {
    id?: number,
    key?: string,
    caption?: string,
    value?: string
}


export class Config extends DataModel<IConfig> {

    public resourceName = "sys-config";
    public resourceNamePlural = "sys-config";

    private _id: number;
    private _key: string;
    private _caption: string;
    private _value: string;


    protected getDefaultValues(): IConfig {
        return {
        };
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IConfig> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IConfig> {
        return new DataModelValidator<IConfig>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IConfig>, action: AnyAction): IDataModelState<IConfig> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<IConfig | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }


    getStoreKey(): string {
        return "SYSCONFIG";
    }

    protected setFromObj(data: IConfig): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._caption = DataModel.safeGet(data.caption, this._caption);
        this._key = DataModel.safeGet(data.key, this._key);
        this._value = DataModel.safeGet(data.value, this._value);
    }


    protected toObj(): IConfig {
        return {
            id: this._id,
            caption: this._caption,
            key: this._key,
            value: this._value
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get caption() {
        return this._caption;
    }


    get key() {
        return this._key;
    }

    get value() {
        return this._value;
    }

}
