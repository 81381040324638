import React, { Component } from 'react';
import { Col, Collapse, ListGroup, ListGroupItem, Button } from 'reactstrap';
import Select from 'react-select';
import { customStyles } from './selectstyles';
import { Municipality, IMunicipality } from '../../models/Municipality';
import { AxiosResponse, AxiosError } from 'axios';
import { IQuery } from '../../models/Search';
import Util from '../../custom/Util';

interface ISearchLocationState {
    collapse: boolean;
    error: string;
    isLoaded: boolean;
    selectedOption: any;
    isButtonVisible: boolean;
    municipalityList?: []
}

type Option = {
    value: string;
    label: string;
}

class SearchLocation extends Component<{ filterSearch: any, search: IQuery }, ISearchLocationState> {

    private municipalityModel: Municipality = new Municipality();


    constructor(props: any) {
        super(props);

        this.toggle = this.toggle.bind(this);

        if (this.props.search.opstina) {
            this.props.search.opstina = Array.isArray(this.props.search.opstina) && this.props.search.opstina.length > 1
                ? this.props.search.opstina.map(o => {
                    return parseInt(o);
                })
                : parseInt(this.props.search.opstina);
        }

        this.state = {
            collapse: true,
            error: "",
            isLoaded: false,
            selectedOption: [],
            isButtonVisible: false,
            municipalityList: []
        };
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    handleChange = (selectedOption) => {

        this.setState({
            selectedOption,
            isButtonVisible: true
        });
    }

    componentDidMount() {

        this.setState({
            isLoaded: true
        });

        this.municipalityModel.createDataSource(this.municipalityModel.resourceNamePlural + "?sort=name").getListPlain({})
            .then((response: AxiosResponse) => {

                let defaultValues = [];

                if (this.props.search.opstina != undefined) {

                    defaultValues = response.data.filter(municipality => {
                        return Array.isArray(this.props.search.opstina)
                            ? this.props.search.opstina.indexOf(municipality.id) != -1
                            : this.props.search.opstina == municipality.id;
                    });

                    if (defaultValues.length > 0) {

                        defaultValues = defaultValues.map(municipality => {
                            return { value: municipality.id, label: municipality.name, key: municipality.code }
                        });
                    }
                }

                this.setState({
                    municipalityList: response.data.map(municipality => {
                        return { value: municipality.id, label: municipality.name, }
                    }),
                    selectedOption: defaultValues
                });

            }).catch((error: AxiosError) => {
                Util.notification("error", "Opštine nisu učitane", 1500)
            });


    }

    filterSearch = () => {
        this.props.filterSearch({ opstina: this.state.selectedOption.map(function (option: Option) { return option.value; }) });
    }

    render() {
        const { error, isLoaded, municipalityList, selectedOption, isButtonVisible } = this.state;
        let content;

        if (error.length > 0) {
            content = <Col sm="12"> Error: {error}</Col>;
        } else if (!isLoaded) {
            content = <Col sm="12">Loading...</Col>;
        } else {
            content = (
                <Select
                    styles={customStyles}
                    value={selectedOption}
                    onChange={this.handleChange}
                    options={municipalityList}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Sve"
                />
            )
        }

        return (
            <>
                <ListGroupItem tag="a" className="epk-filter-mod dropdown-toggle" onClick={this.toggle}>Opštine</ListGroupItem>
                <Collapse isOpen={this.state.collapse}>
                    <div className="card-body card-with-button">
                        <ListGroup tag="ul" className="list-group-flush epk-filter-mod-category">
                            {content}
                            {isButtonVisible ?
                                <Button onClick={this.filterSearch} type="button" color="reload">Osvježi pretragu</Button> : null}
                        </ListGroup>
                    </div>
                </Collapse >
            </>
        );
    }
}

export default SearchLocation;
