import React from 'react';
import { Col, Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import Icon from '../../custom/Icon';
import Identity from '../../user/Identity';
import { Path } from "../../container/Path";
import Util from '../../custom/Util';
import { Role } from '../../user/Role';


class MessagesSingle extends React.Component<{ message: any, deleteConversation: any }, unknown> {

    render() {

        const from_user = JSON.parse(this.props.message.from_user);
        const to_user = JSON.parse(this.props.message.to_user);
        const user = from_user.id == Identity.id ? to_user : from_user;

        let messageContent;
        const messageContentLength = this.props.message.content.length;

        if (messageContentLength > 40) {
            messageContent = this.props.message.content.substring(0, 40) + '...';
        } else {
            messageContent = this.props.message.content;
        }

        if (this.props.message) {
            const thumb = !Util.isAbsolutePath(user.avatar) ? '/thumb' : '';
            return (
                <>
                    <Media className="message">
                        <Media left top>
                            <Media object src={user.avatar ? thumb + user.avatar : "/profile/no-img-profile.png"}
                                alt={user.username} />
                        </Media>
                        <Media body>
                            <Media>
                                <Col md="7">
                                    {user.role == Role.ADMIN
                                        ? <Link to="#"
                                            className="message-username">ADMINISTRATOR</Link>
                                        : <Link to={Path.USER_PROFILE.toString() + "/" + user.id + "/" + user.username}
                                            className="message-username">{user.username}</Link>
                                    }
                                </Col>
                                <Col md="5">
                                    <div
                                        className="message-date">{Util.formatDatetime(this.props.message.date_sent, true)}</div>
                                </Col>
                            </Media>
                            <Media>
                                <Col md="5" className="subject"><Icon
                                    name={from_user.id == Identity.id ? 'sent' : 'received'} /> <Link
                                        to={"/poruke/" + this.props.message.message_id}
                                        className={(this.props.message.date_read == null && to_user.id == Identity.id) ? 'message-subject unread' : 'message-subject'}>{this.props.message.subject}</Link></Col>
                                <Col md="5" className="d-none d-md-block"><Link to={"/poruke/" + this.props.message.message_id}
                                    className="message-short-content">{messageContent}</Link></Col>
                                <Col md="2" >
                                    <div className="message-delete" onClick={this.props.deleteConversation} id={this.props.message.message_id}>
                                        <Icon name="trash" />
                                    </div>
                                </Col>
                            </Media>
                        </Media>
                    </Media>
                </>
            );
        } else {
            return null;
        }

    }

}

export default MessagesSingle;

