import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";

export interface IBlogCategory {
    id?: number,
    name?: string,
    image_path?: string,
    sort_key?: number,
    highlighted?: number,
    slug?: string
    is_video?: boolean
}


export class BlogCategory extends DataModel<IBlogCategory> {

    public resourceName = "post-category";
    public resourceNamePlural = "post-categories";

    private _id: number;
    private _name: string;
    private _image_path: string;
    private _sort_key: number;
    private _highlighted: number;
    private _slug: string;
    private _is_video: boolean;


    protected getDefaultValues(): IBlogCategory {
        return {};
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IBlogCategory> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IBlogCategory> {
        return new DataModelValidator<IBlogCategory>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IBlogCategory>, action: AnyAction): IDataModelState<IBlogCategory> {
        return state;
    }

    public loadBlogCategories(): Promise<IBlogCategory[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<IBlogCategory | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }


    getStoreKey(): string {
        return "POST-CATEGORY";
    }

    protected setFromObj(data: IBlogCategory): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._image_path = DataModel.safeGet(data.image_path, this._image_path);
        this._name = DataModel.safeGet(data.name, this._name);
        this._slug = DataModel.safeGet(data.slug, this._slug);
        this._sort_key = DataModel.safeGet(data.sort_key, this._sort_key);
        this._highlighted = DataModel.safeGet(data.highlighted, this._highlighted);
        this._is_video = DataModel.safeGet(data.is_video, this._is_video);
    }


    protected toObj(): IBlogCategory {
        return {
            id: this._id,
            image_path: this._image_path,
            name: this._name,
            slug: this._slug,
            sort_key: this._sort_key,
            highlighted: this._highlighted,
            is_video: this._is_video
        };
    }


    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get image_path() {
        return this._image_path;
    }


    get name() {
        return this._name;
    }

    get slug() {
        return this._slug;
    }

    get sort_key() {
        return this._sort_key;
    }

    get highlighted() {
        return this._highlighted === 1;
    }

    get is_video() {
        return this._is_video;
    }

}
