import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import { IPage, Page } from '../../models/Page';
import { AxiosError } from 'axios';

interface IModal {
  modal: boolean;
  page: any;
}

class Terms extends Component<unknown, IModal> {
  private pageModel: Page = new Page();

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      page: this.pageModel
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentDidMount() {
    this.pageModel.loadBySlug("uslovi-koristenja").then((response: IPage | AxiosError) => {

      const aError: AxiosError = response as AxiosError;
      const pageModel: IPage = response as IPage;

      if (!aError.response) {
        this.pageModel.setFromPlainObject(pageModel);
        this.setState({
          page: this.pageModel
        });
      }

    });
  }

  render() {

    const __html = this.state.page.content;

    return (
      <>
        <label className="custom-control-label" htmlFor="termsAccept">Slažem se sa </label> <a onClick={this.toggle}>Uslovima korištenja</a>
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
          <ModalHeader toggle={this.toggle}>Uslovi korištenja</ModalHeader>
          <ModalBody>
            <div dangerouslySetInnerHTML={{ __html }}></div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Zatvori</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Terms;

