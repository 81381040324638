import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IDropdownListItem } from "../../common/components/widgets/form/input/DropdownInput";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { IPublicMessage } from "./PublicMessage";
import { User, IUser } from "./User";

export interface IPrivateMessage {
    id?: number,
    message_id?: number,
    parent_id?: number,
    from_user?: number | IUser,
    to_user?: number | IUser,
    from_user_hidden_at?: string,
    to_user_hidden_at?: string,
    content?: string,
    subject?: string,
    date_sent?: string
    date_read?: string
}


export class PrivateMessage extends DataModel<IPrivateMessage> {

    private resourceName = "private-message";
    private resourceNamePlural = "private-messages";

    private _id: number;
    private _message_id: number;
    private _parent_id: number;
    private _from_user: number | IUser;
    private _to_user: number | IUser;
    private _from_user_hidden_at: string;
    private _to_user_hidden_at: string;
    private _content: string;
    private _subject: string;
    private _date_sent: string;
    private _date_read: string;


    protected getDefaultValues(): IPrivateMessage {
        return {};
    }


    public createDataSource(resourcesubject: string = this.resourceNamePlural): RestDataSource<IPrivateMessage> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourcesubject, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IPrivateMessage> {
        return new DataModelValidator<IPrivateMessage>({
            content: [new RequiredValidator()],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IPrivateMessage>, action: AnyAction): IDataModelState<IPrivateMessage> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListItems(params: IRestDataSourceParams): Promise<IDropdownListItem[]> {
        return this.getListPlain(params)
            .then((response: AxiosResponse) => {
                const messages: IPrivateMessage[] = response.data as IPrivateMessage[];

                const result: IDropdownListItem[] = [];

                messages.forEach((message: IPrivateMessage) => {
                    result.push({
                        id: message.id,
                        name: message.message_id.toString()
                    });
                });

                return result;
            });
    }

    public loadById(id: number): Promise<IPrivateMessage | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public loadByParentId(id: number): Promise<AxiosResponse | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .addCustomPath('/' + id)
            .plainGetOperation({});
    }

    public conversationStarters(): Promise<AxiosResponse | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .plainGetOperation({});
    }

    public createNew(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .plainPostOperation({
                from_user: this._from_user,
                to_user: this._to_user,
                subject: this._subject,
                content: this._content,
                date_sent: this._date_sent,
                parent_id: this._parent_id,
            });
    }

    public read(id: number | string): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainPatchOperation({});
    }

    public del(id: number | string): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    getStoreKey(): string {
        return "PRIVATE_MESSAGE";
    }

    protected setFromObj(data: IPrivateMessage): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._message_id = DataModel.safeGet(data.message_id, this._message_id);
        this._from_user = DataModel.safeGet(data.from_user, this._from_user);
        this._to_user = DataModel.safeGet(data.to_user, this._to_user);
        this._to_user_hidden_at = DataModel.safeGet(data.to_user_hidden_at, this._to_user_hidden_at);
        this._from_user_hidden_at = DataModel.safeGet(data.from_user_hidden_at, this._from_user_hidden_at);
        this._content = DataModel.safeGet(data.content, this._content);
        this._subject = DataModel.safeGet(data.subject, this._subject);
        this._date_sent = DataModel.safeGet(data.date_sent, this._date_sent);
        this._date_read = DataModel.safeGet(data.date_read, this._date_read);
        this._parent_id = DataModel.safeGet(data.parent_id, this._parent_id);
    }


    protected toObj(): IPrivateMessage {
        return {
            id: this._id,
            message_id: this._message_id,
            from_user: this._from_user,
            to_user: this._to_user,
            from_user_hidden_at: this._from_user_hidden_at,
            to_user_hidden_at: this._to_user_hidden_at,
            content: this._content,
            subject: this._subject,
            date_sent: this._date_sent,
            date_read: this._date_read,
            parent_id: this._parent_id,
        };
    }


    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get message_id() {
        return this._message_id;
    }

    get parent_id() {
        return this._parent_id;
    }


    get from_user() {
        return this._from_user;
    }

    get to_user() {
        return this._to_user
    }

    get from_user_hidden_at() {
        return this._from_user_hidden_at
    }

    get to_user_hidden_at() {
        return this._to_user_hidden_at;
    }

    get content() {
        return this._content;
    }

    get subject() {
        return this._subject;
    }

    get date_sent() {
        return this._date_sent;
    }

    get date_read() {
        return this._date_read;
    }
}
