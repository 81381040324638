import React from 'react';

interface IIconProps {
    name: string,
    id?: any,
    tabIndex?: number,
}

function Icon(props: IIconProps) {
    const additionalProps: any = {};

    if (props.tabIndex) {
        additionalProps['tabIndex'] = props.tabIndex;
    }

    if (props.id) {
        additionalProps['id'] = props.id;
    }

    return (
        <i className={"epk-icon epk-" + props.name} {...additionalProps}></i>
    )
}

export default Icon;
