export const customStyles = {
    control: (base, state) => ({
        ...base,
        background: "#FFF",
        borderColor: state.isFocused ? "#ffae80" : "#cccccc",
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            borderColor: state.isFocused ? "#ffae80" : "#cccccc",
            boxShadow: state.isFocused ? "0 0 1.1px #ff5c00" : "0 0 1.1px #ff5c00",
        }
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? "#ff7426" : "#FFF",
        ':active': {
            backgroundColor: state.isSelected ? "#ff7426" : "#ff7426",
            color: state.isSelected ? "#FFF" : "#FFF"
        },
        '&:hover': {
            backgroundColor: state.isSelected ? "#ff7426" : "#ff7426",
            color: state.isSelected ? "#FFF" : "#FFF"
        }
    })
}