import React from 'react';
import { Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Path } from "../../container/Path";
import { Link } from 'react-router-dom';

function HeaderNav() {
  const headernavlist = [
    {
      id: 1, name: "Početna", url: Path.HOMEPAGE.toString(), navClass: "", dropdown: false, onClick: () => {
        if (typeof window !== 'undefined') {
          window.location.pathname === Path.HOMEPAGE
            ? window.location.reload()
            : null
        }
      }
    },
    { id: 2, name: "Impresum", url: Path.IMPRESUM.toString(), navClass: "", dropdown: false },
    { id: 3, name: "ČPP", url: Path.FAQ.toString(), navClass: "", dropdown: false },
    { id: 4, name: "Edukacija", url: Path.EDUCATION.toString(), navClass: "", dropdown: true },
    { id: 3, name: "Poslovno-tehnička saradnja", url: Path.PTS.toString(), navClass: "", dropdown: false },
    { id: 5, name: "Preuzimanje oglasnika", url: Path.DOWNLOAD_ADVERTISEMENTS.toString(), navClass: "", dropdown: false },
    { id: 6, name: "Kontakt", url: Path.CONTACT.toString(), navClass: "", dropdown: false },
    { id: 8, name: "EKI Krediti", url: Path.EDUCATION.toString(), navClass: "", dropdown: true },
    { id: 7, name: "Kategorije", url: Path.CATEGORIES.toString(), navClass: "sve-kategorije", dropdown: false }
  ];


  return (
    <Nav className="navbar-nav mr-auto epk-header-navigation">
      {headernavlist.map((headernavsingle, index) => {
        if(!headernavsingle.dropdown){
          return (
            <NavItem className={headernavsingle.navClass} key={index}>
              <NavLink tag={Link} to={headernavsingle.url} onClick={headernavsingle.onClick ? headernavsingle.onClick : null}>{headernavsingle.name}</NavLink>
            </NavItem>
          )
        }
        else if (headernavsingle.id === 4) {
          return (
            <UncontrolledDropdown key={index} nav inNavbar>
              <DropdownToggle nav caret>
                Edukacija
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <NavLink tag={Link} to={Path.EDUCATION}>Blog</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink tag={Link} to={Path.EDUCATION_CATEGORY + '/cesto-postavljana-pitanja'}>Često postavljana pitanja</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink tag={Link} to={Path.EDUCATION_VIDEO}>Video</NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        } else if (headernavsingle.id === 8) {
          return (
            <UncontrolledDropdown key={index} nav inNavbar>
              <DropdownToggle nav caret>
                EKI krediti
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <NavLink tag="a" href="https://www.mkdeki.ba/krediti-za-poljoprivredu-mkd" target="_blank" rel="noopener">Krediti za poljoprivredu</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink tag="a" href="https://www.mkdeki.ba/krediti-za-agro-biznise-mkd" target="_blank" rel="noopener">Krediti za agro biznise</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink tag="a" href="https://www.mkfeki.ba/startup-krediti" target="_blank" rel="noopener">Startup krediti</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink tag="a" href="https://www.mkfeki.ba/ekspres-pozajmice-mkf" target="_blank" rel="noopener">Ekspres pozajmice</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink tag="a" href="https://www.mkdeki.ba/krediti-za-refinansiranje-mkd" target="_blank" rel="noopener">Krediti za refinansiranje</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink tag="a" href="https://www.mkdeki.ba/krediti-za-domacinstvo-mkd" target="_blank" rel="noopener">Krediti za domaćinstvo</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink tag="a" href="https://www.mkdeki.ba/krediti-za-registrovane-biznise-mkd" target="_blank" rel="noopener">Krediti za registrovane biznise</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink tag="a" href="https://www.mkdeki.ba/stambeni-krediti-mkd" target="_blank" rel="noopener">Stambeni krediti</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink tag="a" href="https://www.mkdeki.ba/krediti-za-nabavku-automobila-mkd" target="_blank" rel="noopener">Krediti za nabavku automobila</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink tag="a" href="https://www.mkdeki.ba/robni-krediti-mkd" target="_blank" rel="noopener">Robni krediti</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink tag="a" href="https://www.mkdeki.ba/landing-page-krediti-za-zaposlene-u-inostranstvu" target="_blank" rel="noopener">Krediti za zaposlene u inostranstvu</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink tag="a" href="https://www.mkdeki.ba/krediti-za-energijsku-efikasnost-mkd" target="_blank" rel="noopener">Krediti za energijsku efikasnost</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink tag="a" href="https://www.mkdeki.ba/paket-za-penzionere-mkd" target="_blank" rel="noopener">Paket za penzionere</NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        })}
    </Nav>
  );
}

export default HeaderNav;
