import React from 'react';
import { Container, Row, Col, Button, Alert, Spinner, } from 'reactstrap';
import { Path } from "../container/Path";
import { withRouter } from "../../common/components/routing/Router";
import EpkPage from "./EpkPage";
import { Container as PageContainer } from "../../index";
import FavoriteAd from './favorites/FavoriteAd';
import Icon from '../custom/Icon';
import { Ad, IAd } from "../models/Ad";
import { AxiosError, AxiosResponse } from "axios";
import Identity from "../user/Identity";
import { IUser } from "../models/User";
import Util from "../custom/Util";
import Noty from "noty";
import { withSecurity } from "../../common/security/Security";
import { Role } from "../user/Role";

@withRouter(Path.ADS_FAVORITES.toString(), PageContainer)
@withSecurity([Role.USER.toString(), Role.CLIENT.toString(), Role.LOAN_OFFICER.toString()], Identity, Path.HOMEPAGE)
export default class AdFavoritesPage extends EpkPage {

    private ad: Ad = new Ad();


    constructor(props) {
        super(props);
        this.state = {
            favoriteAdPerPage: 9,
            activeFavoriteAdPage: 1,
            favoritesList: [],
            favoritesLoaded: false,
        };

        this.loadMore = this.loadMore.bind(this)
        this.deleteFromFavorites = this.deleteFromFavorites.bind(this)
    }


    pageTitle(): string {
        return "Spremljeni oglasi " + super.pageTitle();
    }

    getDestroyableMembers() {
        return [
            this.ad
        ];
    }

    loadMore() {
        this.setState({
            activeFavoriteAdPage: this.state.activeFavoriteAdPage + 1
        })
    }

    componentDidMount() {

        super.componentDidMount();

        this.ad.favorites()
            .then((response: AxiosResponse) => {
                const favorited = response.data.filter((favorite) => {
                    return favorite.user_id == Identity.id
                });

                if (favorited.length > 0) {
                    favorited.map(favorite => {
                        this.ad.loadById(favorite.ad_id).then((response: IAd | AxiosError) => {
                            const aError: AxiosError = response as AxiosError;
                            const ad: IAd = response as IUser;

                            if (!aError.response) {
                                this.ad.setFromPlainObject(ad);
                                const favorites = this.state.favoritesList;
                                favorites.push(ad)
                                this.setState({ favoritesList: favorites, favoritesLoaded: true })
                            }
                        })
                    })
                } else {
                    this.setState({ favoritesLoaded: true })
                }
            })
            .catch((error: AxiosError) => {
                Util.notification("error", "Desila se greška. Oglas nije učitan.", 15000);

            });
    }

    deleteFromFavorites(event: React.MouseEvent<HTMLElement>) {

        const target = event.target as HTMLElement;
        const id = target.closest(".epk-ad").getAttribute("data-id");

        const n = new Noty({
            text: "Jeste li sigurni da želite izbrisati?",
            buttons: [
                Noty.button('DA', 'btn btn-primary', () => {
                    this.ad.delFavorite(id)
                        .then(() => {
                            Util.notification("success", "Oglas je uspješno izbrisan.", 1500);
                            if (typeof window !== 'undefined') {
                                document.location.reload();
                            }
                        })
                        .catch((error: AxiosError) => {
                            Util.notification("error", "Desila se greška. Oglas nije izbrisan.", 1500);
                        })
                    n.close();
                }, { id: 'button1', 'data-status': 'ok' }),

                Noty.button('NE', 'btn btn-secondary', () => {

                    n.close();
                })
            ],
            theme: 'bootstrap-v4',
            layout: 'center',
        });

        n.show();
    }

    renderContent() {

        const loadMore = (
            <div className="epk-load-more">
                <Button onClick={this.loadMore} color="epk-primary" ><Icon name="vise" /> Učitaj više</Button>
            </div>
        )

        let favoriteList;


        if (!this.state.favoritesLoaded) {
            favoriteList = (
                <Col xs="12">
                    <Alert color="empty">
                        <Spinner style={{ width: '1rem', height: '1rem' }} color="primary" type="grow" /> Učitavanje spremljenih oglasa
                    </Alert>
                </Col>
            )
        } else if (this.state.favoritesLoaded && this.state.favoritesList.length > 0) {
            favoriteList = (
                this.state.favoritesList.slice(0, this.state.favoriteAdPerPage * this.state.activeFavoriteAdPage).map(single => (
                    <FavoriteAd
                        key={single.id}
                        ad={single}
                        deleteFromFavorites={this.deleteFromFavorites}
                    />
                ))
            )
        } else if (this.state.favoritesLoaded && this.state.favoritesList.length == 0) {
            favoriteList = (
                <Col xs="12">
                    <Alert color="empty">
                        Trenutno nemate spremljene oglase.
                        </Alert>
                </Col>
            )
        }

        return (
            <div className="epk-oglasi-favorites" >
                <Container>
                    <div className="epk-oglasi-favorites-section epk-section">
                        <Row>
                            <Col xs="12">
                                <h1 className="epk-container-h1">Spremljeni oglasi</h1>
                            </Col>
                            {favoriteList}
                        </Row>
                        {this.state.favoriteAdPerPage * this.state.activeFavoriteAdPage <= favoriteList.length ? loadMore : ``}
                    </div>
                </Container>
            </div >
        );
    }
}

