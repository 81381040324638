import React from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { Path } from "../../container/Path";
import Util from "../../custom/Util";

export default function PublicMessages(props) {

    return (
        <>
            <div className="epk-public-messages">
                <Row>
                    {(props.messages.length > 0) ? props.messages.map(pmessage =>
                        <div className={props.ad.user.id == pmessage.from_user.id ? "epk-author-say" : "epk-customer-say"} key={pmessage.id}>
                            <Col xs="2" className="epk-public-message-margin">
                                {props.ad.user.id != pmessage.from_user.id ?
                                    <a href={Path.USER_PROFILE + '/' + pmessage.from_user.id + '/' + pmessage.from_user.username} title="Korisnik">
                                        <img className="img-thumbnail epk-image"
                                            src={pmessage.from_user.avatar != null ? pmessage.from_user.avatar : "/profile/no-img-profile.png"} />
                                    </a>
                                    : null
                                }
                            </Col>
                            <Col xs="10" className="epk-public-message-margin">
                                <div className={props.ad.user.id == pmessage.from_user.id ? "epk-author-message" : "epk-customer-message"}>
                                    {pmessage.message.content}
                                    <div
                                        className="epk-message-date">{Util.formatDatetime(pmessage.message.date_sent, true)}</div>
                                </div>
                            </Col>
                        </div>
                    ) : <Col xs="12">
                            <div className="epk-info-detail epk-info-first">Nema javnih pitanja</div>
                        </Col>}
                </Row>
            </div>
        </>
    );
}
