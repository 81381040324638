import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IBlogCategory } from "./BlogCategory";

export interface IBlog {
    id?: number,
    title?: string,
    content?: string,
    description?: string,
    published_at?: string,
    slug?: string,
    view_count?: number,
    cover_photo?: string,
    category?: IBlogCategory,
    category_id?: number,
}


export class Blog extends DataModel<IBlog> {

    public resourceName = "post";
    public resourceNamePlural = "posts";

    private _id: number;
    private _title: string;
    private _content: string;
    private _description: string;
    private _published_at: string;
    private _slug: string;
    private _cover_photo: string;
    private _view_count: number;
    private _category: IBlogCategory;
    private _category_id: number;


    protected getDefaultValues(): IBlog {
        return {
            category: {}
        };
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IBlog> {
        const identity = Object.assign(UserIdentity.getRequestHeaders(), { "Cache-Control": "no-cache, no-store, must-revalidate" });
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, identity);
    }

    protected createValidator(scenario: string): IDataModelValidator<IBlog> {
        return new DataModelValidator<IBlog>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IBlog>, action: AnyAction): IDataModelState<IBlog> {
        return state;
    }

    public loadBlogs(): Promise<IBlog[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural)
            .getList({});
    }

    public loadMostViews(type: string): Promise<IBlog[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural + '/' + type + '?filter=status_id:eq:1')
            .getList({});
    }

    public loadCategories(): Promise<IBlog[] | AxiosError> {
        return this.createDataSource('/post-categories')
            .getList({});
    }

    public getListPlain(type: string, params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural + '/' + type).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<IBlog | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }


    public increaseViewCount(id: number, view_count: number): Promise<AxiosResponse<any>> {
        const view = view_count ? view_count : 0;
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + Number(id))
            .plainPatchOperation({
                view_count: Number(view) + 1
            });
    }

    public getByCategoryId(id: number, params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural)
            .addCustomPath("/" + id)
            .getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getByCategorySlug(slug: string, params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural)
            .addCustomPath("/slug/" + slug)
            .getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }


    getStoreKey(): string {
        return "POST";
    }

    protected setFromObj(data: IBlog): void {
        this._id = DataModel.safeGet(data.id, this._id) as number;
        this._content = DataModel.safeGet(data.content, this._content);
        this._description = DataModel.safeGet(data.description, this._description);
        this._title = DataModel.safeGet(data.title, this._title);
        this._slug = DataModel.safeGet(data.slug, this._slug);
        this._published_at = DataModel.safeGet(data.published_at, this._published_at);
        this._cover_photo = DataModel.safeGet(data.cover_photo, this._cover_photo);
        this._view_count = DataModel.safeGet(data.view_count, this._view_count);
        this._category_id = DataModel.safeGet(data.category_id, this._category_id);
    }


    protected toObj(): IBlog {
        return {
            id: this._id,
            content: this._content,
            description: this._description,
            title: this._title,
            slug: this._slug,
            published_at: this._published_at,
            cover_photo: this._cover_photo,
            view_count: this._view_count,
            category: this._category,
            category_id: this._category_id
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get content() {
        return this._content;
    }

    get description() {
        return this._description;
    }

    get title() {
        return this._title;
    }

    get slug() {
        return this._slug;
    }

    get published_at() {
        return this._published_at;
    }

    get view_count() {
        return this._view_count;
    }

    get cover_photo() {
        return this._cover_photo;
    }

    get category() {
        return this._category;
    }

    get category_id() {
        return this._category_id;
    }

}
