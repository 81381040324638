import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Icon from '../../custom/Icon';
import { Path } from '../../container/Path';
import { IUser } from '../../models/User';

export default class FavoriteUser extends Component<{ user: IUser, deleteFromFavorites: any }, unknown> {

    render() {
        const pathurl = Path.USER_PROFILE;
        return (
            <Col xs="12" md="6" xl="4">
                <div className="epk-ad-profile" data-id={this.props.user.id} >
                    <Link onClick={this.props.deleteFromFavorites} to={"#"} className="remove"><Icon
                        name="trash" /></Link>
                    <Link to={pathurl + "/" + this.props.user.id + "/" + this.props.user.username} className="ad-author">
                        <div className="row align-items-center">
                            <Col xs="3"><img
                                src={this.props.user.avatar ? this.props.user.avatar : "/profile/no-img-profile.png"}
                                className="img-thumbnail epk-image" /></Col>
                            <Col xs="9">
                                <div className="ad-author-info">
                                    <div className="ad-author-name">{this.props.user.username}</div>
                                </div>
                            </Col>
                        </div>
                    </Link>
                </div>
            </Col>
        );
    }

}

