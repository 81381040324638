import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios'
import Util from '../../custom/Util';

interface IHomeBlogState {
    blogs: Blog[];
    error: string;
    isLoaded: boolean;
}

type Blog = {
    id: number;
    title: string;
    text: string;
    url: string;
    img: string;
}

class HomeBlog extends Component<unknown, IHomeBlogState> {
    constructor(props: any) {
        super(props);

        this.state = {
            error: "",
            isLoaded: false,
            blogs: []
        };
    }

    componentDidMount() {

        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            }
        };

        axios.get('https://eki.ba/api/oglasi-za-posao?limit=2&page=0', axiosConfig)
            .then((res) => {
                let Blogs = [];
                if (res.data.length > 0) {
                    if (res.data.length === 1) {
                        Blogs = [
                            {
                                id: res.data[0].id,
                                title: res.data[0].title,
                                text: Util.stripHtml(res.data[0].message),
                                url: res.data[0].link,
                                img: "https://eki.ba"+res.data[0].featured_image
                            },
                        ];
                    } else {
                        Blogs = [
                            {
                                id: res.data[0].id,
                                title: res.data[0].title,
                                text: Util.stripHtml(res.data[0].message),
                                url: res.data[0].link,
                                img: "https://eki.ba"+res.data[0].featured_image
                            },
                            {
                                id: res.data[1].id,
                                title: res.data[1].title,
                                text: Util.stripHtml(res.data[1].message),
                                url: res.data[1].link,
                                img: "https://eki.ba"+res.data[1].featured_image
                            },
                        ];
                    }
                }
                this.setState({
                    blogs: Blogs,
                    isLoaded: true,
                });
            })
            .catch((err) => {
                const Blogs = [];
                this.setState({
                    isLoaded: false,
                    error: err
                });
            })





    }

    render() {
        const listBlogLoader = []

        for (let i = 1; i <= 2; i++) {
            listBlogLoader.push(
                <Col md="6" key={i}>
                    <div className="single-blog">
                        <Row>
                            <Col md="6">
                                <h4 className="blog-title"><i className="title-loader">______________</i></h4>
                                <p className="blog-text"><i className="title-loader">_____________ ______________ ____________________ ____________________ _________________ _________ __________</i></p>
                            </Col>
                            <Col md="6">
                                <div className="blog-img">
                                    <div className="image-loader" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            )
        }

        const { error, isLoaded, blogs } = this.state;

        let content;
        if (error.length > 0) {
            content = <Col sm="12"> Error: {error}</Col>;
        } else if (!isLoaded) {
            content = <>{listBlogLoader}</>;
        } else if (blogs.length === 1) {
            content = blogs.map(singleblog => (
                <Col md="12" key={singleblog.id} >
                    <div className="single-blog">
                        <Row>
                            <Col md="6">
                                <h4 className="blog-title"> <a href={singleblog.url} target="_blank">{singleblog.title}</a></h4>
                                <p className="blog-text" dangerouslySetInnerHTML={{ __html: singleblog.text.length < 360 ? singleblog.text : singleblog.text.slice(0, 361) + '...' }}></p>
                            </Col>
                            <Col md="6">
                                <div className="blog-img">
                                    <img className="img-thumbnail" src={singleblog.img} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            ));
        } else {
            content = blogs.map(singleblog => (
                <Col md="6" key={singleblog.id} >
                    <div className="single-blog">
                        <Row>
                            <Col md="6">
                                <h4 className="blog-title"> <a href={singleblog.url} target="_blank">{singleblog.title}</a></h4>
                                <p className="blog-text" dangerouslySetInnerHTML={{ __html: singleblog.text.length < 170 ? singleblog.text : singleblog.text.slice(0, 171) + '...' }}></p>
                            </Col>
                            <Col md="6">
                                <div className="blog-img">
                                    <img className="img-thumbnail" src={singleblog.img} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            ));
        }

        return (
            <div className="epk-blog">
                <Container>
                    <div className="epk-blog-section">
                        <Row>
                            {content}
                        </Row>
                    </div>
                </Container>
            </div>
        );

    }
}

export default HomeBlog;
