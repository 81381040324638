import React, { Component } from 'react';
import { Col, Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption, Alert, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { IBanner } from '../../models/Banner';

interface IBannerSliderState {
    error: string;
    activeIndex: number;
    animating: boolean;
}

class BannerSlider extends Component<{ sliderItems: IBanner[], isLoading: boolean }, IBannerSliderState> {
    constructor(props) {
        super(props);

        this.state = {
            error: "",
            activeIndex: 0,
            animating: false,
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    onExiting() {
        this.setState({ animating: true });
    }

    onExited() {
        this.setState({ animating: false });
    }

    next() {
        if (this.state.animating) return;
        const sliderNum = this.props.sliderItems.length > 0 ? this.props.sliderItems.length : 1;
        const nextIndex = this.state.activeIndex === sliderNum - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.state.animating) return;
        const sliderNum = this.props.sliderItems.length > 0 ? this.props.sliderItems.length : 1;
        const nextIndex = this.state.activeIndex === 0 ? sliderNum - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.state.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        const { error, activeIndex } = this.state;
        let content;
        if (error.length > 0) {
            content = <Col sm="12"> Error: {error}</Col>;
        }
        else {
            let contentItems;
            const sliderItems2 = [{
                id: 1,
                url: '#',
                image_path: '/slider/no-img.jpg',
                name: 'Default slide'
            }]

            if (this.props.sliderItems.length > 0) {
                contentItems = (
                    this.props.sliderItems.map((item) => {
                        return <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item.id} >
                            <a href={item.url} target="_blank">
                                <img className="d-block img-fluid" src={item.image_path} alt={item.name} />
                            </a>
                        </CarouselItem>
                    })
                )
            } else {
                contentItems = (
                    sliderItems2.map((item) => {
                        return <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item.id} >
                            <a href={item.url} target="_blank">
                                <img className="d-block img-fluid" src={item.image_path} alt={item.name} />
                            </a>
                        </CarouselItem>
                    })
                )
            }

            if (this.props.isLoading) {
                content = (
                    <div className="slider-loader">
                        <Spinner style={{ width: '1rem', height: '1rem' }} color="primary" type="grow" />
                    </div>
                )
            } else {
                content = (
                    <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous} >
                        {contentItems}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                    </Carousel>
                )
            }
        }

        return (
            <Col lg="8" className="epk-top-slider-section">
                <div className="top-slider form-inline justify-content-center">
                    {content}
                </div>
            </Col>
        );

    }
}

export default BannerSlider;
