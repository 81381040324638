import React from "react";
import EpkPage from "./EpkPage";
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, FormText, CustomInput, } from 'reactstrap';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { IDestroy } from "../../common/components/pages/IDestroy";
import Identity from "../user/Identity";
import { withSecurity } from "../../common/security/Security";
import { Role } from "../user/Role";
import { User, IUser } from "../models/User";
import { AxiosResponse, AxiosError } from "axios";
import { TextInput } from "../../common/components/widgets/form/input/TextInput";
import Noty from "noty";
import { EmailInput } from "../../common/components/widgets/form/input/EmailInput";
import Util from "../custom/Util";

@withRouter(Path.FORGOTTEN_PASSWORD.toString(), PageContainer)
@withSecurity([Role.GUEST.toString()], Identity, Path.HOMEPAGE)
export default class ForgottenPasswordPage extends EpkPage {

    private user: User = new User();

    private submitted: boolean = false;

    private emailInput: { [attr: string]: React.RefObject<any> } = {
        email: React.createRef()
    };

    private phoneInput: { [attr: string]: React.RefObject<any> } = {
        phone_number: React.createRef()
    };

    private tokenInput: { [attr: string]: React.RefObject<any> } = {
        token: React.createRef()
    };

    constructor(props: any) {
        super(props);

        this.state = {
            whichFrom: 'resetEmail',
            emailValue: '',
            phoneValue: ''
        }

        this.formDisplay = this.formDisplay.bind(this);
        this.sendSms = this.sendSms.bind(this);
        this.readInfoEmail = this.readInfoEmail.bind(this);
        this.readInfoPhone = this.readInfoPhone.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.showEmailSuccessNotification = this.showEmailSuccessNotification.bind(this);
        this.setDefaultInputs = this.setDefaultInputs.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.checkCode = this.checkCode.bind(this);
        this.onPhoneChange = this.onPhoneChange.bind(this);
    }

    formDisplay(event: React.MouseEvent<HTMLInputElement>) {
        const radio = event.target as HTMLInputElement;

        this.setDefaultInputs();
        this.submitted = false;

        this.setState({
            whichFrom: radio.id,
            phoneValue: '',
            emailValue: ''
        })
    }

    sendSms(event: React.MouseEvent<HTMLElement>) {

        this.submitted = true;

        if (this.validatePhone(event)) {
            this.setState({
                whichFrom: 'resetCode'
            })
            this.sendRequest(event, this.phoneInput);
        }
    }

    sendEmail(event: React.MouseEvent<HTMLElement>) {

        this.submitted = true;

        if (this.validateEmail(event)) {
            this.sendRequest(event, this.emailInput);
        }
    }

    checkCode(event: React.MouseEvent<HTMLElement>) {


        event.preventDefault();
        const token = this.tokenInput["token"].current.getValue();

        this.user.forgottenPasswordCheckToken(token + "_")
            .then((response: AxiosResponse) => {
                if (typeof window !== 'undefined') {
                    if (response.data.password_reset_token) {
                        document.location.pathname = Path.NEW_PASSWORD + response.data.password_reset_token;
                    } else {
                        document.location.pathname = Path.NEW_PASSWORD + token;
                    }
                }
            })
            .catch((error: AxiosError) => {
                const aError: AxiosError = error;

                switch (aError.response.status) {
                    case 404:
                    case 403:
                        Util.notification("error", "Desila se greška!", 1500);
                        break;
                    case 422:
                    case 500:
                        const errors: { [attr: string]: string } = {};
                        errors["token"] = "Kod nije validan";
                        this.fillInputsWithErrors(errors, this.tokenInput);

                        break;
                    default:
                }
            });
    }

    showEmailSuccessNotification() {

        new Noty({
            type: 'success',
            theme: 'bootstrap-v4',
            layout: 'topRight',
            text: 'Email je uspješno poslat',
        }).show();
    }

    sendRequest(event: React.MouseEvent<HTMLElement>, inputs: any) {

        this.submitted = true;
        let plainObject: IUser;
        this.user = new User();

        if (inputs.hasOwnProperty("email")) {
            plainObject = this.readInfoEmail();
        } else {
            plainObject = this.readInfoPhone();
        }

        this.user.setFromPlainObject(plainObject);

        this.user.forgottenPasswordRequest(this.user)
            .then((response: AxiosResponse) => {

                this.setDefaultInputs();

                if (inputs.hasOwnProperty("email")) {
                    this.showEmailSuccessNotification();
                    this.setState({ emailValue: "" });
                }
            })
            .catch((error: AxiosError) => {
                Util.notification("success", "Kod za promjenu lozinke je poslat", 2000);
            });


    }

    pageTitle() {
        return "Zaboravljena lozinka" + super.pageTitle();
    }

    getDestroyableMembers(): IDestroy[] {
        return [
            this.user
        ];
    }

    setDefaultInputs(): void {

        this.emailInput = {
            email: React.createRef()
        }

        this.phoneInput = {
            phone_number: React.createRef()
        }
    }

    private readInfoEmail(): IUser {

        const plainObject: IUser = {};

        for (const key in this.emailInput) {

            if (this.emailInput.hasOwnProperty(key) && this.emailInput[key].current) {
                plainObject[key] = this.emailInput[key].current.getValue();
                this.emailInput[key].current.removeError();
            }
        }

        this.user.setFromPlainObject(plainObject);

        return plainObject;
    }

    private readInfoPhone(): IUser {

        const plainObject: IUser = {};

        for (const key in this.phoneInput) {

            if (this.phoneInput.hasOwnProperty(key) && this.phoneInput[key].current) {
                plainObject[key] = this.phoneInput[key].current.getValue();
                this.phoneInput[key].current.removeError();
            }
        }

        this.user.setFromPlainObject(plainObject);

        return plainObject;
    }


    private validateEmail(event: any): boolean {
        this.readInfoEmail();

        //promjena scenarija
        this.user.changeScenario(User.SCENARIO.CHANGE_PASSWORD_EMAIL);

        const valid = this.user.validate();

        if (this.submitted) {
            if (!valid) {
                const errors: { [attr: string]: string } = this.user.getErrors();
                this.fillInputsWithErrors(errors, this.emailInput);
            }
        }

        event.preventDefault();

        return valid;
    }

    private validatePhone(event: any): boolean {
        this.readInfoPhone();

        //promjena scenarija
        this.user.changeScenario(User.SCENARIO.CHANGE_PASSWORD_PHONE);

        const valid = this.user.validate();

        if (this.submitted) {
            if (!valid) {
                const errors: { [attr: string]: string } = this.user.getErrors();
                this.fillInputsWithErrors(errors, this.phoneInput);
            }
        }

        event.preventDefault();

        return valid;
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                inputs[key].current.setError(errors[key]);
            }
        }
    }

    onEmailChange(event: React.FormEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        this.setState({ emailValue: target.value });
    }

    onPhoneChange(event: React.FormEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        this.setState({ phoneValue: target.value });
    }

    renderContent() {

        let formDisplay;

        if (this.state.whichFrom == "resetEmail") {
            formDisplay = (
                <>
                    <Form>
                        <FormGroup id="prva">
                            <Label for="Email">Email</Label>
                            <EmailInput id="Email" ref={this.emailInput.email}
                                onChange={this.onEmailChange}
                                initialValue={this.state.emailValue}
                            />
                            <FormText>
                                Molimo napišite Vaš email. Link za promjenu lozinke će biti poslan na taj email.
                        </FormText>
                        </FormGroup>
                        <Button color="epk-primary" onClick={this.sendEmail} type="submit">Pošalji</Button>
                    </Form>
                </>
            )
        } else if (this.state.whichFrom == "resetPhone") {
            formDisplay = (
                <>
                    <Form>
                        <FormGroup id="druga">
                            <Label for="Phone">Telefon</Label>
                            <TextInput type="text" name="Phone" id="Phone" placeholder="3876xxxxxxx" ref={this.phoneInput.phone_number}
                                initialValue={this.state.phoneValue}
                                onChange={this.onPhoneChange}
                            />
                            <FormText>
                                Molimo napišite Vaš broj telefona. Kod za promjenu lozinke će Vam biti poslan SMS porukom.
                        </FormText>
                        </FormGroup>
                        <Button onClick={this.sendSms} color="epk-primary" type="submit">Pošalji</Button>
                    </Form>
                </>
            )
        }

        let phoneCode;

        if (this.state.whichFrom == "resetCode") {
            phoneCode = (
                <>
                    <Form>
                        <FormGroup>
                            <Label for="Code">Upišite KOD</Label>
                            <TextInput
                                ref={this.tokenInput.token}
                                name="Code"
                                id="Code" placeholder="XXXX"
                            //autoFocus={this.state.whichFrom == "resetCode" ? true : false}
                            />
                            <FormText>
                                Na Vaš telefon (3876xxxxxxx) je poslana SMS poruka sa kodom za resetovanje lozinke. <a>Nisam dobio SMS poruku</a>
                            </FormText>
                        </FormGroup>
                        <Button color="epk-primary" onClick={this.checkCode} type="submit">Pošalji</Button>
                    </Form>
                </>
            )
        }

        return (
            <>
                <div className="epk-forgotten-password-content">
                    <Container>
                        <Row>
                            <Col xl={{ size: 6, offset: 3 }}>
                                <div className="epk-forgotten-password-content-section epk-section">
                                    <div>
                                        <h1 className="epk-container-h1">Zaboravljena lozinka</h1>
                                    </div>
                                    <div className="epk-member">
                                        <FormGroup>
                                            <div>
                                                <CustomInput defaultChecked type="radio" onClick={this.formDisplay} id="resetEmail" name="whichFrom" label="Znam svoju email adresu" />
                                                <CustomInput type="radio" onClick={this.formDisplay} id="resetPhone" name="whichFrom" label="Znam svoj broj telefona" />
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div>
                                        {formDisplay}
                                        {phoneCode}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}
