import * as React from 'react';
import { CheckboxInput } from "../../common/components/widgets/form/input/CheckboxInput";
import Terms from '../pages/register/Terms';

export class EpkCheckboxInput extends CheckboxInput {

    protected fullFieldRender(): React.ReactNode {
        return (<div className="row no-gutters">
            <div className="col-12 lt-form-input">
                <div className="row no-gutters">
                    <div className="col-12">
                        <div className="custom-checkbox custom-control">
                            {this.renderInput()}
                            <Terms />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 lt-error">
                        {this.renderError()}
                    </div>
                </div>
            </div>
        </div>);
    }

    protected renderInput(): React.ReactNode {
        return (<input type="checkbox" id="termsAccept" ref={this.inputRef} onBlur={(this.props.onBlur) ? this.props.onBlur : null}
            onChange={(this.props.onChange) ? this.props.onChange : null}
            className={"custom-control-input form-control " + this.getInputErrorClass()} />);
    }
}