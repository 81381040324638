import React from "react";
import { Container, Row } from 'reactstrap';
import EpkPage from "./EpkPage";
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import Newsletter from "./homepage/Newsletter";
import HomeBlog from "./homepage/HomeBlog";
import HighlightedCategories from "./homepage/HighlightedCategories";
import BannerSlider from "./homepage/BannerSlider";
import EpkInfoCustomer from "../custom/EpkInfoCustomer";
import { Banner, IBanner } from "../models/Banner";
import { AxiosError, AxiosResponse } from "axios";
import LatestAds from "./homepage/LatestAds";
import { Search as SearchModel } from "../models/Search";
import LazyLoad from 'react-lazyload';

@withRouter(Path.HOMEPAGE.toString(), PageContainer)
export default class HomePage extends EpkPage {

    private bannerModel: Banner = new Banner();


    constructor(props) {
        super(props);

        let data = this.props.data || null;

        if (typeof window !== 'undefined') {
            const hydrationMarker = document.getElementById("hydration-marker");

            if (hydrationMarker !== null && hydrationMarker.innerHTML.length > 0) {
                data = JSON.parse(hydrationMarker.innerHTML.replace(/(&quot\;)/g, "\""));
            }

        }

        this.state = {
            banners: [],
            isLoading: data === null,
            ...data
        };

    }

    pageTitle() {
        return "Početna" + super.pageTitle();
    }

    componentDidMount() {

        this.bannerModel.loadHomepageBanners()
            .then((response: IBanner[] | AxiosError) => {

                this.setState({
                    banners: response,
                    isLoading: false,
                });

            }).catch((error: AxiosError) => {
                console.log(error);
            });
        super.componentDidMount();
    }

    static fetchData(match) {

        const bannerModel = new Banner(false);

        return {
            banners: new Promise((resolve, reject) => {
                bannerModel.loadHomepageBanners()
                    .then((response: IBanner[] | AxiosError) => {

                        return resolve(response);

                    }).catch((error: AxiosError) => {
                        console.log(error);
                        return reject(error)
                    })
            }),
            searchResults: new Promise((resolve, reject) => {
                SearchModel.search({}, { paging: { pageNumber: 1, pageSize: 36 } })
                    .then((response: AxiosResponse) => {
                        return resolve(response.data)

                    }).catch((e: AxiosError) => {
                        reject(e)
                    });
            })
        }
    }


    getDefaultSliderItems() {
        return [
            {
                src: 'slider/no-img.jpg',
                altText: 'Slide 1',
                caption: 'Slide 1',
                link: "link1"
            },
            {
                src: 'slider/no-img.jpg',
                altText: 'Slide 2',
                caption: 'Slide 2',
                link: "link2",
            },
            {
                src: 'slider/no-img.jpg',
                altText: 'Slide 3',
                caption: 'Slide 3',
                link: "link3",
            }
        ];
    }

    getDestroyableMembers() {
        return [this.bannerModel];
    }

    renderContent() {

        return (
            <>
                <EpkInfoCustomer />
                <div className="epk-tc-and-slider">
                    <Container>
                        <div className="epk-t-s-background">
                            <Row>
                                <HighlightedCategories />
                                <BannerSlider sliderItems={this.state.banners} isLoading={this.state.isLoading} />
                            </Row>
                        </div>
                    </Container>
                </div>
                <LatestAds data={this.props.data} />
                <Newsletter />
                <LazyLoad height={300} once >
                    <HomeBlog />
                </LazyLoad>
            </>
        );
    }
}
