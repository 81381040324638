import * as dotenv from 'dotenv';
dotenv.config({path:".env.production"});

import { AnyAction } from "redux";
import { destroyState, newItem, updateState } from "./Actions";
import { StoreSingletonFactory } from "./StoreSingletonFactory";
import { IDestroy } from "../components/pages/IDestroy";
import { DataSource } from "../dataSource/DataSource";
import { DataModelValidator, IDataModelValidator } from "../components/validators/DataModelValidator";
import { ActionTypes } from "./ActionTypes";

export interface IDataModelState<U> {
    all: { [key: string]: U }
}

const initialState: IDataModelState<any> = {
    all: {}
};

/**
 * DataModel.
 *
 * Base abstraction of Data Model to be used.
 * Data Model has responsibility of managing model information what includes:
 * - Communication with Data Source
 * - Validation of data
 */
export abstract class DataModel<S> implements IDestroy {

    private static newModelKeySequence: number = 1;

    public serverRendered: boolean = true;

    public reduxModelKey: number = 0;

    private dataModelValidator: IDataModelValidator<S>;

    public constructor(connectToStore: boolean = true, scenario: string = "default") {
        this.dataModelValidator = this.createValidator(scenario);
        this.serverRendered = (typeof window !== 'undefined');

        if (connectToStore) {
            if (!this.serverRendered) {

                this.connectToStoreAsNew();
            }
            this.setDefaultValues(scenario);
        }
    }

    protected setDefaultValues(scenario: string): void {
        this.setFromPlainObject(this.getDefaultValues(scenario));
    }

    protected getDefaultValues(scenario: string): S {
        return {} as S;
    }

    public changeScenario(scenario: string = "default") {
        this.dataModelValidator = this.createValidator(scenario);
    }

    protected applyCustomValidator(validator: DataModelValidator<S>) {
        this.dataModelValidator = validator;
    }

    protected connectToStoreAsNew() {
        this.reduxModelKey = DataModel.newModelKeySequence;
        StoreSingletonFactory.getStore().dispatch(newItem<S>()(this.reduxModelKey, this.getStoreKey(), {}));
        DataModel.newModelKeySequence++;
    }

    /**
     * Create data source to be used.
     */
    public abstract createDataSource(): DataSource<S>;

    /**
     * Evaluate validation on adequate fields by providing validator instance.
     */
    public validate(): boolean {
        this.dataModelValidator.validate(this.toObj());
        return !this.dataModelValidator.hasErrors();
    }

    public getErrors(): { [attr: string]: string } {
        return this.dataModelValidator.getErrors();
    }

    protected abstract createValidator(scenario: string): IDataModelValidator<S>;

    /**
     * Reducer implementation for data model.
     */
    public reducer(state: IDataModelState<S> = initialState, action: AnyAction) {

        state = this.modelReducer(state, action);

        if (action.storeKey === this.getStoreKey()) {
            switch (action.type) {
                case ActionTypes.NEW_ITEM:
                    state.all[action.key] = action.payload;
                    state = { ...state };
                    break;
                case ActionTypes.UPDATE_STATE:
                    state.all[action.key] = action.payload;
                    state = { ...state };
                    break;
                case ActionTypes.DESTROY_STATE:
                    delete state.all[action.key];
                    state = { ...state };
                    break;
            }
        }

        return state;
    }

    protected abstract modelReducer(state: IDataModelState<S>, action: AnyAction): IDataModelState<S>;

    /**
     * Retrieves store key for model. Between DataModels, this one, has to be unique.
     */
    public abstract getStoreKey(): string;

    public destroy(): void {
        if (this.reduxModelKey !== 0) {
            StoreSingletonFactory.getStore()
                .dispatch(destroyState<S>()(this.reduxModelKey, this.getStoreKey()));
        }
    }

    protected static safeGet<T>(providedValue: T, defaultValue: T): T {
        return providedValue === undefined ? defaultValue : providedValue;
    }

    public setFromPlainObject(data: S, shouldStoreUpdate: boolean = true): void {
        this.setFromObj(data);
        if (this.reduxModelKey !== 0 && shouldStoreUpdate) {
            StoreSingletonFactory.getStore()
                .dispatch(updateState<S>()(this.reduxModelKey, this.getStoreKey(), this.toObj()));
        }
    }

    public asPlainObject(): S {
        return this.toObj();
    }

    protected abstract setFromObj(data: S): void;

    protected abstract toObj(): S;
}
