import React from 'react';
import { Link } from 'react-router-dom';

function Description() {

    const description = {
        title: "Info EPK",
        content: "Besplatni mali oglasi za klijente EKI-ja. Preko 40.000 članova EKI Poslovnog kluba, svakog mjeseca novi primjerak publikacije EKI Oglasi, dostupan u cijeloj BiH.",
        imgurl: "https://www.efse.lu"
    }

    return (
        <>
            <h5 className="footer-title">{description.title}</h5>
            <div className="line"></div>
            <p>{description.content}</p>
            <a href={description.imgurl} target="_blank"><img className="efse-logo-footer" src="/img/EFSE-footer.png"></img></a>
        </>
    )
}

export default Description;
