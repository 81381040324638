
/**
 * Ad status enum.
 */
export enum AdStatus {
    APPROVED = 1,

    REJECTED = 2,

    PENDING = 3,

    EXPIRED = 4,

    DUPLICATE_APPROVED = 5,

    PENDING_REPEATED = 6,

    FINISHED = 7
}

interface IAdStatus {
    id: number,
    caption: string
}

export function getAdStatus(status?: string | number): IAdStatus {

    switch (status) {
        case AdStatus.APPROVED.toString():
            return { id: 1, caption: "Odobren" };
        case AdStatus.REJECTED.toString():
            return { id: 2, caption: "Odbijen" };
        case AdStatus.PENDING.toString():
            return { id: 3, caption: "Na čekanju" };
        case AdStatus.EXPIRED.toString():
            return { id: 4, caption: "Istekao" };
        case AdStatus.DUPLICATE_APPROVED.toString():
            return { id: 5, caption: "Odobren, ponovljen" };
        case AdStatus.PENDING_REPEATED.toString():
            return { id: 6, caption: "Vraćen na doradu" };
        case AdStatus.FINISHED.toString():
            return { id: 7, caption: "Realizovan" };

    }
}
