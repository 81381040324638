import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IDropdownListItem } from "../../common/components/widgets/form/input/DropdownInput";
import { IAdSubcategory } from "./AdSubcategory";

export interface IAdCategory {
    id?: number,
    name?: string,
    image_path?: string,
    icon?: string,
    slug?: string,
    sort_key?: number,
    status_id?: number,
    ad_count?: number,
    subcategories?: IAdSubcategory[]
}


export class AdCategory extends DataModel<IAdCategory> {

    public resourceName = "ad-category";
    public resourceNamePlural = "ad-categories";

    private _id: number;
    private _name: string;
    private _image_path: string;
    private _icon: string;
    private _sort_key: number;
    private _ad_count: number;
    private _slug: string;
    private _status_id: number;
    private _subcategories: IAdSubcategory[]


    protected getDefaultValues(): IAdCategory {
        return {
            id: 0,
            name: "",
            image_path: "",
            icon: "",
            sort_key: 1,
            ad_count: 0,
            slug: "",
            status_id: 1,
            subcategories: []
        };
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IAdCategory> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IAdCategory> {
        return new DataModelValidator<IAdCategory>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IAdCategory>, action: AnyAction): IDataModelState<IAdCategory> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListItems(params: IRestDataSourceParams): Promise<IDropdownListItem[]> {
        return this.getListPlain(params)
            .then((response: AxiosResponse) => {
                const adCategories: IAdCategory[] = response.data as IAdCategory[];

                const result: IDropdownListItem[] = [];

                adCategories.forEach((adCategory: IAdCategory) => {
                    result.push({
                        id: adCategory.id,
                        name: adCategory.name
                    });
                });

                return result;
            });
    }

    public loadById(id: number): Promise<IAdCategory | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public getAds(id: number): Promise<IAdCategory[] | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id + '/ads')
            .getList({});
    }

    public createNew(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .plainPostOperation({
                name: this._name,
                sort_key: this._sort_key,
                image_path: this._image_path,
                icon: this._icon,
                ad_count: this._ad_count

            });
    }

    public update(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({

            });
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    getStoreKey(): string {
        return "AD_CATEGORY";
    }

    protected setFromObj(data: IAdCategory): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._sort_key = DataModel.safeGet(data.sort_key, this._sort_key);
        this._image_path = DataModel.safeGet(data.image_path, this._image_path);
        this._icon = DataModel.safeGet(data.icon, this._icon);
        this._slug = DataModel.safeGet(data.slug, this._slug);
        this._subcategories = DataModel.safeGet(data.subcategories, this._subcategories);
    }


    protected toObj(): IAdCategory {
        return {
            id: this._id,
            name: this._name,
            sort_key: this._sort_key,
            ad_count: this._ad_count,
            image_path: this._image_path,
            icon: this._icon,
            slug: this._slug,
            subcategories: this._subcategories,
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }


    get sort_key() {
        return this._sort_key;
    }


    get image_path() {
        return this._image_path;
    }

    get icon() {
        return this._icon;
    }

    get ad_count() {
        return this._ad_count;
    }

    get slug() {
        return this._slug;
    }


    get subcategories() {
        return this._subcategories;
    }

    get status_id() {
        return this._status_id;
    }

    set status_id(status_id: number) {
        this._status_id = status_id;
    }
}
