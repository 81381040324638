import React from "react";
import EpkPage from "./EpkPage";
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, } from 'reactstrap';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { IDestroy } from "../../common/components/pages/IDestroy";
import Identity from "../user/Identity";
import { withSecurity } from "../../common/security/Security";
import { Role } from "../user/Role";
import Icon from "../custom/Icon";
import { Link } from 'react-router-dom';
import { User, IUser } from '../models/User';
import { AxiosError } from 'axios';
import { TextInput } from '../../common/components/widgets/form/input/TextInput';
import { PasswordInput } from '../../common/components/widgets/form/input/PasswordInput';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { ISocialLogin, SocialLogin } from '../models/SocialLogin';
import { CheckboxInput } from "../../common/components/widgets/form/input/CheckboxInput";
import Util from "../custom/Util";

@withRouter(Path.LOGIN.toString(), PageContainer)
@withSecurity([Role.GUEST.toString()], Identity, Path.HOMEPAGE)
export default class LoginPage extends EpkPage {
    private submitted: boolean = false;

    private user: User = new User(true, User.SCENARIO.LOGIN);
    private socialLoginModel: SocialLogin = new SocialLogin();

    constructor(props) {
        super(props);

        this.readInfo = this.readInfo.bind(this);
        this.handleValidate = this.handleValidate.bind(this);
        this.fillInputsWithErrors = this.fillInputsWithErrors.bind(this);
        this.handleLogIn = this.handleLogIn.bind(this);
    }


    private inputs: { [attr: string]: React.RefObject<any> } = {
        username: React.createRef(),
        password: React.createRef(),
        remember_me: React.createRef(),
    };

    private readInfo(): IUser {
        const plainObject: IUser = {};

        for (const key in this.inputs) {

            if (this.inputs.hasOwnProperty(key) && this.inputs[key].current) {
                plainObject[key] = this.inputs[key].current.getValue();
                this.inputs[key].current.removeError();
            }
        }

        this.user.setFromPlainObject(plainObject);

        return plainObject;
    }

    private handleValidate(event: any): boolean {
        this.readInfo();
        const valid = this.user.validate();

        if (this.submitted) {
            if (!valid) {
                const errors: { [attr: string]: string } = this.user.getErrors();
                this.fillInputsWithErrors(errors);
            }
        }

        event.preventDefault();

        return valid;
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }) {
        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                this.inputs[key].current.setError(errors[key]);
            }
        }
    }

    protected handleLogIn(event: React.MouseEvent<HTMLElement>): void {
        this.submitted = true;

        const plainObject: IUser = this.readInfo();
        const valid: boolean = this.handleValidate(event);

        if (valid) {
            this.user.login(plainObject)
                .then((response: any) => {
                    if (typeof window !== 'undefined') {
                        document.location.pathname = Path.HOMEPAGE.toString();
                    }
                }).catch((error: AxiosError) => {
                    const aError: AxiosError = error;

                    if (aError.response.data.errorCode == 1065) {
                        Util.notification("alert", "Vaš nalog nije verifikovan. Da biste ga verifikovali, <a href='/registracija'>registrujte se kao član EKI Poslovnog Kluba.</a>", 10000, "bootstrap-v4", "center");
                    } else if (aError.response.data.errorCode == 1060) {
                        Util.notification("alert", "Vaš nalog je izbrisan ili blokiran.", 10000, "bootstrap-v4", "center");
                    } else
                        this.fillInputsWithErrors({
                            password: "Username i lozinka se ne poklapaju!"
                        });

                });
        }

        event.preventDefault();
    }

    responseFacebook = (response) => {

        if (response) {

            const profile_cache = {
                email: response.email ? response.email : null,
                avatar: response.picture ? response.picture.data.url : null
            }

            const socialLoginParams: ISocialLogin = {
                provider: "facebook",
                username: response.name,
                identifier: response.userID,
                profile_cache: profile_cache
            }

            this.socialLoginModel.setFromPlainObject(socialLoginParams);
            this.socialLoginModel.createNew()
                .then((response: any) => {
                    setTimeout(() => {
                        let suffix = "";
                        if (response.exists) {
                            suffix = "?exists=true"
                        }
                        //redirect after login
                        if (typeof window !== 'undefined') {
                            document.location.assign(document.location.origin + suffix);
                        }
                    }, 1000)
                })
                .catch((error: AxiosError) => {
                    const aError: AxiosError = error;

                    if (aError.response.data.errorCode == 1065) {
                        Util.notification("alert", "Vaš nalog nije verifikovan. Da biste ga verifikovali, <a href='/registracija'>registrujte se kao član EKI Poslovnog Kluba.</a>", 10000, "bootstrap-v4", "center");
                    } else if (aError.response.data.errorCode == 1060) {
                        Util.notification("alert", "Vaš nalog je izbrisan ili blokiran.", 10000, "bootstrap-v4", "center");
                    } else
                        this.fillInputsWithErrors({
                            password: "Username i lozinka se ne poklapaju!"
                        });
                });
        } else {
            Util.notification("error", "Desila se greška, prijava nije uspjela.", 1500);
        }
    }

    responseGoogle = (response) => {

        if (!response.error) {

            const profileObj = response.profileObj;

            const profile_cache = {
                email: profileObj.email ? profileObj.email : null,
                avatar: profileObj.imageUrl ? profileObj.imageUrl : null
            }

            const socialLoginParams: ISocialLogin = {
                provider: "google",
                username: response.profileObj.name,
                identifier: response.googleId,
                profile_cache: profile_cache
            }

            this.socialLoginModel.setFromPlainObject(socialLoginParams);
            this.socialLoginModel.createNew()
                .then((response: any) => {
                    setTimeout(() => {
                        let suffix = "";
                        if (response.exists) {
                            suffix = "?exists=true"
                        }
                        //redirect after login
                        if (typeof window !== 'undefined') {
                            document.location.assign(document.location.origin + suffix);
                        }
                    }, 1000)
                })
                .catch((error: AxiosError) => {
                    const aError: AxiosError = error;

                    if (aError.response.data.errorCode == 1065) {
                        Util.notification("alert", "Vaš nalog nije verifikovan. Da biste ga verifikovali, <a href='/registracija'>registrujte se kao član EKI Poslovnog Kluba.</a>", 10000, "bootstrap-v4", "center");
                    } else if (aError.response.data.errorCode == 1060) {
                        Util.notification("alert", "Vaš nalog je izbrisan ili blokiran.", 10000, "bootstrap-v4", "center");
                    } else
                        this.fillInputsWithErrors({
                            password: "Username i lozinka se ne poklapaju!"
                        });
                });
        }/* else {
            Util.notification("error", "Desila se greška, prijava nije uspjela.", 1500);
        }*/
    }

    pageTitle() {
        return "Prijava" + super.pageTitle();
    }

    getDestroyableMembers(): IDestroy[] {
        return [
        ];
    }

    renderContent() {
        return (
            <>
                <div className="epk-login-content">
                    <Container>
                        <div className="epk-login-content-section epk-section">
                            <div>
                                <h1 className="epk-container-h1">Prijava</h1>
                            </div>
                            <Row>
                                <Col md="6" className="border-right-epk">
                                    <h5>Nisam član</h5>
                                    <h6>Prijavite se putem Facebook ili Gmail naloga:</h6>
                                    <div className="social-login-group">
                                        <FacebookLogin
                                            appId={process.env.REACT_APP_FB_APP_ID}
                                            autoLoad={true}
                                            size="medium"
                                            fields="name,email,picture"
                                            textButton=""
                                            version="3.2"
                                            isMobile={false}
                                            callback={this.responseFacebook}
                                            cssClass="social-button facebook btn btn-social-button"
                                            icon={<Icon name="facebook" tabIndex={12} />}
                                        />

                                        <GoogleLogin
                                            clientId={process.env.REACT_APP_GOOGLE_LOGIN_APP_ID}
                                            onSuccess={this.responseGoogle}
                                            onFailure={this.responseGoogle}
                                            buttonText=""
                                            style={{}}
                                            className="social-button google btn btn-social-button"
                                            autoLoad={false}
                                            onAutoLoadFinished={true}
                                        />
                                    </div>
                                    <div className="dropdown-divider epk-divider"></div>
                                </Col>
                                <Col md="6">
                                    <Form>
                                        <h5>Član sam</h5>
                                        <FormGroup>
                                            <Label for="Username">Korisničko ime</Label>
                                            <TextInput tabIndex={8} id="Username" ref={this.inputs.username}
                                                onBlur={this.handleValidate.bind(this)} autoComplete="username"
                                            />
                                        </FormGroup>
                                        <div>
                                            <Label for="Password">Lozinka</Label>
                                            <Label tabIndex={13} className="header-forgotpassword" for="FormForgotpassword"><Link to={Path.FORGOTTEN_PASSWORD.toString()}>Zaboravljena lozinka?</Link></Label>
                                            <PasswordInput tabIndex={9} id="Password" ref={this.inputs.password} autoComplete="current-password"
                                            ></PasswordInput>
                                        </div>
                                        <FormGroup>
                                            <div>
                                                <CheckboxInput tabIndex={10} id="Check"
                                                    label="Zapamti me" ref={this.inputs.remember_me}
                                                ></CheckboxInput>
                                            </div>
                                        </FormGroup>
                                        <Button tabIndex={11} color="epk-primary" type="submit" onClick={this.handleLogIn.bind(this)}>Prijavi se</Button>
                                    </Form>
                                    <div className="dropdown-divider"></div>
                                    <Link tabIndex={14} className="dropdown-item" to={Path.REGISTER}>Prvi put ste ovdje? <b>Registrujte se!</b></Link>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }
}
