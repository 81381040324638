import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import NotFoundPage from "./components/pages/NotFoundPage";
import ReactGA from 'react-ga4';
import "noty/lib/noty.css";
import "noty/lib/themes/bootstrap-v4.css";


import * as Container from "./common/components/container/Container";
export type ContainerType = typeof Container;

Container.set404(NotFoundPage);

export { Container };

import { ContainerElements } from "./components/container/ContainerElements";
import ErrorBoundary from './components/custom/ErrorBoundary';

if (process.env.REACT_APP_ENV === "prod") {
    ReactGA.initialize('G-5MBHJE8HB1');
}

ContainerElements.addAll();
if (typeof window !== 'undefined') {
    ReactDOM.hydrate(<ErrorBoundary><App /></ErrorBoundary>, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
