import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IDropdownListItem } from "../../common/components/widgets/form/input/DropdownInput";

export interface IAdItemCondition {
    id?: number,
    name?: string,
    caption?: string,
    status_id?: number,
}


export class AdItemCondition extends DataModel<IAdItemCondition> {

    public resourceName = "ad-item-condition";
    public resourceNamePlural = "ad-item-conditions";

    private _id: number;
    private _name: string;
    private _caption: string;
    private _status_id: number;


    protected getDefaultValues(): IAdItemCondition {
        return {};
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IAdItemCondition> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IAdItemCondition> {
        return new DataModelValidator<IAdItemCondition>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IAdItemCondition>, action: AnyAction): IDataModelState<IAdItemCondition> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource('ad-item-conditions').getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListItems(params: IRestDataSourceParams): Promise<IDropdownListItem[]> {
        return this.getListPlain(params)
            .then((response: AxiosResponse) => {
                const adConds: IAdItemCondition[] = response.data as IAdItemCondition[];

                const result: IDropdownListItem[] = [];

                adConds.forEach((adCond: IAdItemCondition) => {
                    result.push({
                        id: adCond.id,
                        name: adCond.name
                    });
                });

                return result;
            });
    }

    public loadById(id: number): Promise<IAdItemCondition | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public createNew(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .plainPostOperation({
                name: this._name,
                caption: this._caption

            });
    }

    public update(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({

            });
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    getStoreKey(): string {
        return "AD_ITEM_CONDITION";
    }

    protected setFromObj(data: IAdItemCondition): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._caption = DataModel.safeGet(data.caption, this._caption);
    }


    protected toObj(): IAdItemCondition {
        return {
            id: this._id,
            name: this._name,
            caption: this._caption
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }


    get caption() {
        return this._caption;
    }


    get status_id() {
        return this._status_id;
    }

    set status_id(status_id: number) {
        this._status_id = status_id;
    }
}
