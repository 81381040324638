import React, { Component } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, Col } from 'reactstrap';
import Icon from '../../custom/Icon';
import { Link } from 'react-router-dom';
import { IAdCategory, AdCategory } from '../../models/AdCategory';
import { AxiosError, AxiosResponse } from "axios";
import { Path } from '../../container/Path';
import LazyLoad from 'react-lazyload';

interface IDropdownCategoryButtonState {
    dropdownOpen: boolean;
    categories: IAdCategory[];
    error: string;
    isLoaded: boolean;
    releaseBugs: boolean;
}

class DropdownCategoryButton extends Component<unknown, IDropdownCategoryButtonState> {
    private categoryModel: AdCategory = new AdCategory();
    constructor(props: any) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
            error: "",
            isLoaded: false,
            categories: [],
            releaseBugs: false,
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    componentDidMount() {
        this.categoryModel.createDataSource(this.categoryModel.resourceNamePlural).getListPlain({})
            .then((response: AxiosResponse) => {
                this.setState({
                    categories: response.data,
                    isLoaded: true
                });
            }).catch((e: AxiosError) => {
                this.setState({
                    releaseBugs: true,
                    error: e.message
                })
            });
    }

    render() {
        const { error, releaseBugs, isLoaded, categories } = this.state;
        let content;

        const search = Path.SEARCH

        if (releaseBugs) {
            content = <Col sm="12">Error: {error}</Col>;
        } else if (!isLoaded) {
            content = <Col sm="12">Loading...</Col>;
        } else {
            const halfdropdowncategorybuttonlist = Math.ceil(categories.length / 3);
            content = (
                <>
                    <ul className="list-unstyled col-sm-4">
                        {this.state.categories.slice(0, halfdropdowncategorybuttonlist).map(dropdowncategorybuttonsingle => (
                            <li key={dropdowncategorybuttonsingle.id}><a href={search + "/" + dropdowncategorybuttonsingle.slug + "/" + dropdowncategorybuttonsingle.id}><Icon name={dropdowncategorybuttonsingle.slug} /> {dropdowncategorybuttonsingle.name}</a></li>
                        ))}
                    </ul>
                    <ul className="list-unstyled col-sm-4">
                        {this.state.categories.slice(halfdropdowncategorybuttonlist, halfdropdowncategorybuttonlist * 2).map(dropdowncategorybuttonsingle => (
                            <li key={dropdowncategorybuttonsingle.id}><a href={search + "/" + dropdowncategorybuttonsingle.slug + "/" + dropdowncategorybuttonsingle.id}><Icon name={dropdowncategorybuttonsingle.slug} /> {dropdowncategorybuttonsingle.name}</a></li>
                        ))}
                    </ul>
                    <ul className="list-unstyled col-sm-4">
                        {this.state.categories.slice(halfdropdowncategorybuttonlist * 2, categories.length).map(dropdowncategorybuttonsingle => (
                            <li key={dropdowncategorybuttonsingle.id}><a href={search + "/" + dropdowncategorybuttonsingle.slug + "/" + dropdowncategorybuttonsingle.id}><Icon name={dropdowncategorybuttonsingle.slug} /> {dropdowncategorybuttonsingle.name}</a></li>
                        ))}
                    </ul>
                    <ul className="list-unstyled col-sm-12 epk-view-all">
                        <li><Link to={Path.CATEGORIES.toString()}><Icon name="menu" /> Sve kategorije</Link></li>
                    </ul>
                </>
            );
        }

        return (
            <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle color="logo-cat-button" className="btn-header-logo-nav logo-cat-button d-none d-lg-inline-block">
                    <Icon name="menu" /> <span>Kategorije</span>
                </DropdownToggle>

                <DropdownMenu direction="left" className="epk-category-dropdown">

                    <LazyLoad height={650} >
                        <li className="row">
                            {content}
                        </li>
                    </LazyLoad>
                </DropdownMenu>
            </ButtonDropdown>
        );

    }
}

export default DropdownCategoryButton;
