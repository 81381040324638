import React from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Alert,
    Spinner,
} from 'reactstrap';
import { Path } from "../container/Path";
import { withRouter } from "../../common/components/routing/Router";
import EpkPage from "./EpkPage";
import { Container as PageContainer } from "../../index";
import FavoriteUser from './favorites/FavoriteUser';
import Icon from '../custom/Icon';
import { AxiosError, AxiosResponse } from "axios";
import Identity from "../user/Identity";
import { IUser, User } from "../models/User";
import Util from "../custom/Util";
import Noty from "noty";
import { withSecurity } from "../../common/security/Security";
import { Role } from "../user/Role";

@withRouter(Path.USER_FAVORITES.toString(), PageContainer)
@withSecurity([Role.USER.toString(), Role.CLIENT.toString(), Role.LOAN_OFFICER.toString()], Identity, Path.HOMEPAGE)
export default class UserFavoritesPage extends EpkPage {

    private userModel: User = new User();

    constructor(props) {
        super(props);
        this.state = {
            favoriteUserPerPage: 18,
            activeFavoriteUserPage: 1,
            favoritesList: [],
            favoritesLoaded: false,
            isLoading: true
        };

        this.loadMore = this.loadMore.bind(this)
        this.deleteFromFavorites = this.deleteFromFavorites.bind(this)
    }

    pageTitle(): string {
        return "Spremljeni korisnici" + super.pageTitle();
    }

    getDestroyableMembers() {
        return [
            this.userModel
        ];
    }

    componentDidMount() {

        super.componentDidMount();

        this.userModel.favorites()
            .then((response: AxiosResponse) => {
                const favorited = response.data.filter((favorite) => {
                    return favorite.user_id == Identity.id
                });

                if (favorited.length > 0) {
                    favorited.map(favorite => {
                        this.userModel.loadById(favorite.favorite_user_id).then((response: IUser | AxiosError) => {
                            const aError: AxiosError = response as AxiosError;
                            const user: IUser = response as IUser;

                            if (!aError.response) {
                                this.userModel.setFromPlainObject(user);
                                const favorites = this.state.favoritesList;
                                favorites.push(user)
                                this.setState({ favoritesList: favorites, favoritesLoaded: true })
                            }
                        })
                    })
                } else {
                    this.setState({ favoritesLoaded: true })
                }
            })

            .catch((error: AxiosError) => {
                Util.notification("error", "Desila se greška. Korisnik nije učitan.", 1500);

            });
    }

    loadMore() {
        this.setState({
            activeFavoriteUserPage: this.state.activeFavoriteUserPage + 1
        })
    }

    deleteFromFavorites(event: React.MouseEvent<HTMLElement>) {

        const target = event.target as HTMLElement;
        const id = target.closest(".epk-ad-profile").getAttribute("data-id");

        const n = new Noty({
            text: "Jeste li sigurni da želite izbrisati?",
            buttons: [
                Noty.button('DA', 'btn btn-primary', () => {
                    this.userModel.delFavorite(id)
                        .then(() => {
                            Util.notification("success", "Korisnik je uspješno izbrisan.", 1500);

                            if (typeof window !== 'undefined') {
                                document.location.reload();
                            }

                        })
                        .catch((error: AxiosError) => {
                            Util.notification("error", "Desila se greška. Korisnik nije izbrisan.", 1500);
                        })
                    n.close();
                }, { id: 'button1', 'data-status': 'ok' }),

                Noty.button('NE', 'btn btn-secondary', () => {

                    n.close();
                })
            ],
            theme: 'bootstrap-v4',
            layout: 'center',
        });

        n.show();
    }

    renderContent() {

        const loadMore = (
            <div className="epk-load-more">
                <Button onClick={this.loadMore} color="epk-primary"><Icon name="vise" /> Učitaj više</Button>
            </div>
        )

        let favoriteList;

        if (!this.state.favoritesLoaded) {
            favoriteList = (
                <Col xs="12">
                    <Alert color="empty">
                        <Spinner style={{ width: '1rem', height: '1rem' }} color="primary" type="grow" /> Učitavanje spremljenih korisnika
                    </Alert>
                </Col>

            )
        } else if (this.state.favoritesLoaded && this.state.favoritesList.length > 0) {
            favoriteList = (
                this.state.favoritesList.slice(0, this.state.favoriteUserPerPage * this.state.activeFavoriteUserPage).map((single, index) => (

                    <FavoriteUser
                        key={index}
                        user={single}
                        deleteFromFavorites={this.deleteFromFavorites}
                    />
                ))
            )
        } else if (this.state.favoritesLoaded && this.state.favoritesList.length == 0) {
            favoriteList = (
                <Col xs="12">
                    <Alert color="empty">
                        Trenutno nemate spremljene korisnike.
                    </Alert>
                </Col>
            )
        }
        return (
            <div className="epk-user-favorites">
                <Container>
                    <div className="epk-user-favorites-section epk-section">
                        <Row>
                            <Col xs="12">
                                <h1 className="epk-container-h1">Spremljeni korisnici</h1>
                            </Col>
                            {favoriteList}
                        </Row>
                        {this.state.favoriteUserPerPage * this.state.activeFavoriteUserPage <= favoriteList.length ? loadMore : ``}
                    </div>
                </Container>
            </div>
        );
    }
}

