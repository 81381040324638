import * as dotenv from 'dotenv';
dotenv.config({path:".env.production"});

import Page from "../../common/components/pages/Page";
import MainLayout from "../layout/MainLayout";
import Util from "../custom/Util";
import * as qs from 'query-string';
import ReactGA from 'react-ga4';

export default abstract class EpkPage<P extends any = any, S extends any = any> extends Page<P, S> {
    constructor(props) {
        super(props);
        if (props.location.search != undefined) {
            const params = qs.parse(props.location.search)

            if (params.exists) {
                Util.notification("info", "Vaš email već iskorišten za registrovanje i vaši računi su povezani.", 10000, "bootstrap-v4", "center");
            }

        }

    }

    fullSizeContent() {
        if (typeof window !== 'undefined') {
            if (document.body.scrollHeight < window.innerHeight) {
                const size = window.innerHeight - document.body.scrollHeight
                document.getElementById("epk-content-section").style.paddingBottom = size + "px";
            }
        }
    }

    sendGaPageView() {

        if (typeof window !== 'undefined' && process.env.REACT_APP_ENV === "prod") {
            ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});
        }
    }

    componentDidMount() {
        this.fullSizeContent()
        this.sendGaPageView();
        super.componentDidMount()
    }

    pageTitle() {
        return " - " + process.env.REACT_APP_TITLE_PREFIX;
    }

    pageLayout() {
        return new MainLayout();
    }
}
