import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IDropdownListItem } from "../../common/components/widgets/form/input/DropdownInput";

export interface IBanner {
    id?: number,
    name?: string,
    image_path?: string,
    url?: string,
    position?: string,
    sort_key?: number,
    status_id?: number,
    ad_count?: number,
    ad_category_id?: number
}


export class Banner extends DataModel<IBanner> {

    public resourceName = "banner";
    public resourceNamePlural = "banners";

    private _id: number;
    private _name: string;
    private _image_path: string;
    private _url: string;
    private _sort_key: number;
    private _ad_count: number;
    private _position: string;
    private _status_id: number;
    private _ad_category_id: number


    protected getDefaultValues(): IBanner {
        return {
            id: 0,
            name: "",
            image_path: "",
            url: "",
            sort_key: 1,
            ad_count: 0,
            position: "",
            status_id: 1,
            ad_category_id: null
        };
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IBanner> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IBanner> {
        return new DataModelValidator<IBanner>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IBanner>, action: AnyAction): IDataModelState<IBanner> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListItems(params: IRestDataSourceParams): Promise<IDropdownListItem[]> {
        return this.getListPlain(params)
            .then((response: AxiosResponse) => {
                const banners: IBanner[] = response.data as IBanner[];

                const result: IDropdownListItem[] = [];

                banners.forEach((banner: IBanner) => {
                    result.push({
                        id: banner.id,
                        name: banner.name
                    });
                });

                return result;
            });
    }

    public loadById(id: number): Promise<IBanner | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public loadByCategoryId(cat_id: number): Promise<IBanner[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural+ "?filter=status_id:eq:1,ad_category_id:eq:"+cat_id)
            .getList({});
    }

    public loadHomepageBanners(): Promise<IBanner[] | AxiosError> {
        return this.createDataSource(this.resourceNamePlural+ "?filter=ad_category_id:is:null,status_id:eq:1&sort=sort_key")
            .getList({});
    }

    public createNew(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .plainPostOperation({
                name: this._name,
                sort_key: this._sort_key,
                image_path: this._image_path,
                url: this._url,
                ad_category_id: this._ad_category_id

            });
    }

    public update(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({

            });
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    getStoreKey(): string {
        return "BANNER";
    }

    protected setFromObj(data: IBanner): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._sort_key = DataModel.safeGet(data.sort_key, this._sort_key);
        this._image_path = DataModel.safeGet(data.image_path, this._image_path);
        this._url = DataModel.safeGet(data.url, this._url);
        this._position = DataModel.safeGet(data.position, this._position);
    }


    protected toObj(): IBanner {
        return {
            id: this._id,
            name: this._name,
            sort_key: this._sort_key,
            ad_count: this._ad_count,
            image_path: this._image_path,
            url: this._url,
            position: this._position,
            ad_category_id: this._ad_category_id,
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }


    get sort_key() {
        return this._sort_key;
    }


    get image_path() {
        return this._image_path;
    }

    get url() {
        return this._url;
    }

    get position() {
        return this._position;
    }


    get ad_category_id() {
        return this._ad_category_id;
    }

    get status_id() {
        return this._status_id;
    }

    set status_id(status_id: number) {
        this._status_id = status_id;
    }
}
