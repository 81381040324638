import * as React from "react";
import {BrowserRouter} from "react-router-dom";
import {Route, Switch, StaticRouter} from "react-router";
import {ContainerType} from "../../../index";
import { withFetchData } from "../ssr/withFetchData";

/**
 * TypeScript Decorator which allows registering needed routes on
 * page level. Container to be used needs to be provided.
 */
export function withRouter(path: string, container: ContainerType, group?: string, isExact: boolean = true) {
    return (comp: React.ComponentType): void => {
        container.addRoute(path, comp, isExact, group);
    };
}

/**
 * Props definition for Router component.
 */
export interface IRouterProps {
    container: ContainerType
}

export interface IRoute {
    isExact: boolean,
    path: string,
    comp: React.ComponentType
}

/**
 * Router component which generates necessary react nodes for routing to work.
 */
export default class Router extends React.Component<IRouterProps> {
    /**
     * @inheritDoc
     */
    public render() {
        const routes: React.ReactNode[] = [];

        const storedRoutes: Array<IRoute> = this.props.container.getRoutes();

        storedRoutes.forEach((storedRoute: IRoute) => {
            routes.push(
                <Route exact={storedRoute.isExact} key={storedRoute.path}
                    path={storedRoute.path} component={withFetchData(storedRoute.comp)}/>
            );
        });

        const groupedRoutes: { [key: string]: IRoute[] } = this.props.container.getGroupedRoutes();

        for (const key in groupedRoutes) {
            if (groupedRoutes.hasOwnProperty(key)) {
                routes.push(
                    <Route key={key} path={key} render={(props: any) => < GroupedRoutes routes={groupedRoutes[key]}/>} />
                );
            }
        }

        // if there is 404, add as last route
        if(this.props.container.get404()) {
            routes.push(<Route key="404" component={this.props.container.get404()}/>);
        }

        return (<div>
            <BrowserRouter>
                <Switch>
                    {routes}
                </Switch>
            </BrowserRouter>
        </div>);
    }
}

interface IGroupedRoutesProps {
    routes: { path: string, comp: React.ComponentType }[]
}

class GroupedRoutes extends React.Component<IGroupedRoutesProps, any> {

    private updateCommonState(newState: any) {
        this.setState(newState);
    }

    public render() {
        const routes: { path: string, comp: React.ComponentType }[] = this.props.routes;
        const renderRoutes: React.ReactNode[] = [];

        const groupedRoutesState = {
            commonState: this.state,
            updateCommonState: this.updateCommonState.bind(this)
        };

        routes.forEach((storedRoute: { path: string, comp: React.ComponentType }) => {
            const render = (props: any) => {
                return React.createElement(storedRoute.comp, {
                    ...props,
                    groupedRoutesState: groupedRoutesState
                });
            };

            renderRoutes.push(
                <Route exact={true} key={storedRoute.path} path={storedRoute.path}
                       render={render} />
            );
        });

        return (<div>{renderRoutes}</div>)
    }
}
