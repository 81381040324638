import React from 'react';
import { Container, Row, Col, Button, Alert, } from 'reactstrap';
import { Path } from "../container/Path";
import { withRouter } from "../../common/components/routing/Router";
import EpkPage from "./EpkPage";
import { Container as PageContainer } from "../../index";
import { IDestroy } from "../../common/components/pages/IDestroy";
import Axios, { AxiosError, AxiosResponse } from "axios";
import Identity from "../user/Identity";
import Icon from '../custom/Icon';
import MessagesSingle from './messages/MessagesSingle';
import { PrivateMessage } from '../models/PrivateMessage';
import Util from '../custom/Util';
import { withSecurity } from '../../common/security/Security';
import { Role } from '../user/Role';
import Noty from "noty";
import { User } from '../models/User';

@withRouter(Path.MESSAGES.toString(), PageContainer)

@withSecurity([Role.CLIENT.toString(), Role.USER.toString(), Role.LOAN_OFFICER.toString()], Identity, Path.LOGIN)
export default class MessagesPage extends EpkPage {

    private privateMessagesModel: PrivateMessage = new PrivateMessage();

    constructor(props) {
        super(props);

        this.deleteConversation = this.deleteConversation.bind(this);
        this.deleteOneMessage = this.deleteOneMessage.bind(this);

        this.state = {
            messagesPerPage: 9, // broj prikazanih oglasa
            activeMessagesPage: 1,
            messageList: [],
            notShown: [],
            isLoading: true,
        };

        this.loadMore = this.loadMore.bind(this);
    }

    pageTitle() {
        return "Poruke";
    }

    getDestroyableMembers(): IDestroy[] {
        return [
            this.privateMessagesModel
        ];
    }

    loadMore() {
        this.setState({
            activeMessagesPage: this.state.activeMessagesPage + 1
        });
    }

    deleteConversation(event: React.MouseEvent<HTMLInputElement>) {

        const target = event.target as HTMLInputElement,
            idToDelete = target.id ? target.id : target.parentElement.id,
            messages = this.state.messageList.concat(this.state.notShown);

        const n = new Noty({
            text: "Jeste li sigurni da želite izbrisati?",
            buttons: [
                Noty.button('DA', 'btn btn-primary', () => {

                    this.deleteOneMessage(idToDelete);

                    Util.notification("success", "Razgovor je uspješno izbrisan", 1000);
                    n.close();
                    if (typeof window !== 'undefined') {
                        document.location.reload();
                    }

                }, { id: 'button1', 'data-status': 'ok' }),

                Noty.button('NE', 'btn btn-secondary', function () {
                    n.close();
                })
            ],
            theme: 'bootstrap-v4',
            layout: 'center',
            progressBar: true
        });

        n.show();
    }

    deleteOneMessage(id: number | string) {
        this.privateMessagesModel.del(id).catch(() => {
            Util.notification("success", "Desila se greška, razgovor je nije izbrisan", 1000);
        })
    }

    public componentDidMount() {

        super.componentDidMount();

        this.privateMessagesModel.conversationStarters().then((response: AxiosResponse) => {
            let messages = response.data;
            const rejectedMessages = [],
                messageListLength = messages.length;

            for (let i = 0; i < messageListLength; i++) {
                for (let j = 0; j < messageListLength; j++) {
                    if (messages[j] && messages[i] && messages[j].parent_id == messages[i].message_id) {
                        rejectedMessages.push(messages[i])
                    }
                }
            }

            messages = messages.filter(x => rejectedMessages.indexOf(x) == -1);

            //Ne prikazujemo arhivirne poruke
            messages = messages.filter(x => {

                const from_user = JSON.parse(x.from_user);

                if (from_user.id == Identity.id) {
                    return x.from_user_hidden_at == null
                } else {
                    return x.to_user_hidden_at == null
                }

            });

            this.setState({ messageList: messages, notShown: rejectedMessages, isLoading: false, })
        }).catch((error: AxiosError) => {
            Util.notification("error", "Poruke ne mogu biti učitane", 15000);
        })
    }

    renderContent() {

        const loadMore = (
            <div className="epk-load-more">
                <Button onClick={this.loadMore} color="epk-primary" ><Icon name="vise" /> Učitaj više</Button>
            </div>
        )

        let displayMessages

        if (this.state.messageList.length > 0) {
            const messages = this.state.messageList;

            displayMessages = (
                messages.slice(0, this.state.messagesPerPage * this.state.activeMessagesPage).map(single => (
                    <MessagesSingle
                        key={single.message_id}
                        message={single}
                        deleteConversation={this.deleteConversation}
                    />
                ))
            )
        } else {
            displayMessages = (
                <Alert color="empty">
                    Trenutno nemate ostvarene konverzacije sa ostalim korisnicima.
                </Alert>
            )
        }

        const messagesLoading = []

        for (let i = 1; i <= 8; i++) {
            messagesLoading.push(
                <Row key={i}>
                    <Col xs="2">
                        <div className="title-loader w100" />
                        <div className="content-loader w100" />
                    </Col>
                    <Col xs="6">
                        <div className="content-loader w30" />
                        <div className="content-loader w70" />
                    </Col>
                    <Col xs="4">
                        <div className="content-loader w100" />
                    </Col>
                </Row>
            )
        }

        let messagesContent
        if (this.state.isLoading) {
            messagesContent = messagesLoading
        } else {
            messagesContent = displayMessages
        }
        return (
            <div className="epk-messages">
                <Container>
                    <div className="epk-messages-section epk-section">
                        <Row>
                            <Col md="12">
                                <h1 className="epk-container-h1">Poruke</h1>
                            </Col>
                            <Col md="12" className="message-list">
                                <Row>
                                    <Col md="12">
                                        {messagesContent}
                                    </Col>
                                </Row>
                                {this.state.messagesPerPage * this.state.activeMessagesPage <= this.state.messageList.length ? loadMore : ``}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div >
        );
    }
}

