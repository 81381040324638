import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IDropdownListItem } from "../../common/components/widgets/form/input/DropdownInput";

export interface IMunicipality {
    id?: number,
    name?: string,
    code?: number,
    status_id?: number,
}


export class Municipality extends DataModel<IMunicipality> {

    public resourceName = "municipality";
    public resourceNamePlural = "municipalities";

    private _id: number;
    private _name: string;
    private _code: number;
    private _status_id: number;


    protected getDefaultValues(): IMunicipality {
        return {};
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IMunicipality> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IMunicipality> {
        return new DataModelValidator<IMunicipality>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IMunicipality>, action: AnyAction): IDataModelState<IMunicipality> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource('municipalities').getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListItems(params: IRestDataSourceParams): Promise<IDropdownListItem[]> {
        return this.getListPlain(params)
            .then((response: AxiosResponse) => {
                const municipalities: IMunicipality[] = response.data as IMunicipality[];

                const result: IDropdownListItem[] = [];

                municipalities.forEach((municipality: IMunicipality) => {
                    result.push({
                        id: municipality.id,
                        name: municipality.name
                    });
                });

                return result;
            });
    }

    public loadById(id: number): Promise<IMunicipality | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public createNew(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .plainPostOperation({
                name: this._name,
                code: this._code

            });
    }

    public update(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({

            });
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    getStoreKey(): string {
        return "MUNICIPALITY";
    }

    protected setFromObj(data: IMunicipality): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._code = DataModel.safeGet(data.code, this._code);
    }


    protected toObj(): IMunicipality {
        return {
            id: this._id,
            name: this._name,
            code: this._code
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }


    get code() {
        return this._code;
    }


    get status_id() {
        return this._status_id;
    }

    set status_id(status_id: number) {
        this._status_id = status_id;
    }
}
