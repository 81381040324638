import moment from "moment";
import Noty, { Type, Theme, Layout } from "noty";
import { parse } from 'node-html-parser';

class Util {

    static formatMoney(price: string | number, currency: string = 'KM') {
        return (price as number).toLocaleString('bs-BA', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' ' + currency;
    }

    static formatDatetime(datetime: string, withTime: boolean = false) {
        let format = 'DD.MM.YYYY';
        if (withTime) {
            format += ' HH:mm:ss';
        }

        return moment(datetime).format(format);
    }

    static objectToParams(params: any) {
        '?' + Object.keys(params)
            .map(param => `${param}=${encodeURIComponent(params[param])}`)
            .join('&');
    }

    static notification(
        type: Type = 'success',
        text: string = '',
        timeout: number | false,
        theme: Theme = 'bootstrap-v4',
        layout: Layout = 'topRight',
        progressBar: boolean = true,
        closeWith: ('click' | 'button')[] = ['click']
    ) {

        if (typeof window !== 'undefined') {
            return new Noty({
                type,
                theme,
                layout,
                text,
                timeout,
                progressBar,
                closeWith
            }).show();
        }

    }

    static dialog(
        text: string,
        yesHandler: any,
        noHandler?: any,
        theme: Theme = 'bootstrap-v4',
        layout: Layout = 'center',
        progressBar: boolean = true
    ) {

        if (typeof window !== 'undefined') {
            const n = new Noty({
                text,
                buttons: [
                    Noty.button('DA', 'btn btn-success', () => {
                        yesHandler;
                        n.close();
                    }, { id: 'button1', 'data-status': 'ok' }),

                    Noty.button('NE', 'btn btn-error', () => {
                        noHandler ? noHandler : null;
                        n.close();
                    })
                ],
                theme,
                layout,
                progressBar
            });

            n.show();
        }
    }

    static slug(str) {
        str = str.replace(/^\s+|\s+$/g, '');
        str = str.toLowerCase();

        const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;čćšđž";
        const to = "aaaaaeeeeeiiiiooooouuuunc------ccsdz";
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-');

        return str;
    }

    static topFunction() {
        if (typeof window !== 'undefined') {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    }


    static isAbsolutePath(path) {
        const url = path;
        const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

        return regexp.test(url);
    }

    static phoneFormat(phoneNumber) {
        return phoneNumber.slice(0, 3) + ' ' + phoneNumber.slice(3, 5) + ' ' + phoneNumber.slice(5, phoneNumber.length)
    }

    static formatPhoneForShortDesc(phoneNumber) {
        let prefix = " ";

        if (phoneNumber.length > 0) {
            if (phoneNumber.slice(0, 3) == "387") {
                prefix += "0";
            } else {
                prefix += "+" + phoneNumber.slice(0, 3) + " ";
            }

            let rest = phoneNumber.slice(5, phoneNumber.length);

            if (rest.length >= 6) {
                rest = rest.slice(0, 3) + " " + rest.slice(3, rest.length);
            }

            return prefix + phoneNumber.slice(3, 5) + ' ' + rest;
        } else {
            return phoneNumber;
        }

    }

    static endSentence(sentence) {
        let suffix = "";

        if (sentence.slice(sentence.length - 1, sentence.length) != ".") {
            suffix = ".";
        }
        return sentence + suffix;
    }

    static stripHtml(html) {
        if (typeof window !== 'undefined') {
            const temporalDivElement = document.createElement("div");
            temporalDivElement.innerHTML = html;
            return temporalDivElement.textContent || temporalDivElement.innerText || "";
        }
    }

    static shortenContent(content, length, delimiter = null) {

        let messageContentShort = "";
        const doc = parse(content, {
            lowerCaseTagName: false,
            blockTextElements: {
                script: false,
                style: false, 
                pre: false
            }       
        });

        content = doc.text.toString() || "";

        if (content.length > length) {
            messageContentShort = content.substring(0, length);
        } else {
            messageContentShort = content;
        }

        let index = -1;

        if (delimiter) {
            if (Array.isArray(delimiter)) {
                const n = delimiter.length;
                for (let i = 0; i < n; i++) {
                    index = messageContentShort.lastIndexOf(delimiter[i]);
                    if (index !== -1) break;
                }
            } else {
                index = messageContentShort.lastIndexOf(delimiter);
            }
        }

        if (index === -1) {
            messageContentShort += "...";
        } else {
            messageContentShort = messageContentShort.substring(0, index);
        }

        return messageContentShort;

    }

}

export default Util;

