import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IDropdownListItem } from "../../common/components/widgets/form/input/DropdownInput";

export interface IAdType {
    id?: number,
    name?: string,
    caption?: string
}


export class AdType extends DataModel<IAdType> {

    public resourceName = "ad-type";
    public resourceNamePlural = "ad-types";

    private _id: number;
    private _name: string;
    private _caption: string;


    protected getDefaultValues(): IAdType {
        return {};
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IAdType> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IAdType> {
        return new DataModelValidator<IAdType>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IAdType>, action: AnyAction): IDataModelState<IAdType> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource('adType').getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListItems(params: IRestDataSourceParams): Promise<IDropdownListItem[]> {
        return this.getListPlain(params)
            .then((response: AxiosResponse) => {
                const adCategories: IAdType[] = response.data as IAdType[];

                const result: IDropdownListItem[] = [];

                adCategories.forEach((adCategory: IAdType) => {
                    result.push({
                        id: adCategory.id,
                        name: adCategory.name
                    });
                });

                return result;
            });
    }

    public loadById(id: number): Promise<IAdType | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public createNew(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .plainPostOperation({
                name: this._name,
                caption: this._caption

            });
    }

    public update(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({

            });
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    getStoreKey(): string {
        return "AD_TYPE";
    }

    protected setFromObj(data: IAdType): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._caption = DataModel.safeGet(data.caption, this._caption);
    }


    protected toObj(): IAdType {
        return {
            id: this._id,
            name: this._name,
            caption: this._caption
        };
    }


    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }


    get caption() {
        return this._caption;
    }
}
