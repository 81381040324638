import React, { Component } from 'react';
import { Col, Collapse, ListGroup, ListGroupItem } from 'reactstrap';
import Select from 'react-select';
import { customStyles } from './selectstyles';
import { AdType } from '../../models/AdType';
import { AxiosResponse, AxiosError } from 'axios';
import { IQuery } from '../../models/Search';

interface ISearchTypeState {
    collapse: boolean;
    error: string;
    isLoaded: boolean;
    options: Option[];
    selectedOption: any;
}

type Option = {
    value: string;
    label: string;
}

class SearchType extends Component<{ filterSearch: any, search: IQuery }, ISearchTypeState> {

    private adType: AdType = new AdType();

    constructor(props: any) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            collapse: true,
            error: "",
            isLoaded: false,
            options: [],
            selectedOption: null
        };
    }


    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    handleChange = (selectedOption) => {

        this.setState({ selectedOption });
        this.props.filterSearch({ tip: selectedOption != null ? selectedOption.value : null });
    }

    componentDidMount() {

        this.adType.createDataSource(this.adType.resourceNamePlural).getListPlain({})
            .then((response: AxiosResponse | AxiosError) => {

                const aError: AxiosError = response as AxiosError;
                const resultSet: AxiosResponse = response as AxiosResponse;

                if (!aError.response && resultSet.data != undefined) {

                    const defaultValue =
                        resultSet.data.filter(type => {
                            return type.id == this.props.search.tip;
                        })[0];

                    this.setState({
                        isLoaded: true,
                        options: resultSet.data.map(type => {
                            return { value: type.id, label: type.caption }
                        }),
                        selectedOption: defaultValue? { value: defaultValue.id, label: defaultValue.caption } : null

                    });

                }

            })
    }

    render() {
        const { error, isLoaded, options } = this.state;

        let content;
        if (error.length > 0) {
            content = <Col sm="12"> Error: {error}</Col>;
        } else if (!isLoaded) {
            content = <Col sm="12">Loading...</Col>;
        } else {
            content = (
                <Select
                    styles={customStyles}
                    onChange={this.handleChange}
                    options={options}
                    value={this.state.selectedOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Sve"
                    isClearable
                    isSearchable={false}
                />
            )
        }

        return (
            <>
                <ListGroupItem tag="a" className="epk-filter-mod dropdown-toggle" onClick={this.toggle}>Vrsta</ListGroupItem>
                <Collapse isOpen={this.state.collapse}>
                    <div className="card card-body">
                        <ListGroup tag="ul" className="list-group-flush epk-filter-mod-category">
                            {content}
                        </ListGroup>
                    </div>
                </Collapse >
            </>
        );
    }
}

export default SearchType;
