import React, { PureComponent } from 'react';
import Icon from '../../custom/Icon';

interface IBackToTop {
    scroll: boolean;
}

class BackToTop extends PureComponent<unknown, IBackToTop> {

    private _isComponentMounted: boolean = false;

    constructor(props: any) {
        super(props);

        this.epkToTop = this.epkToTop.bind(this);
        this.scroll = this.scroll.bind(this);

        this.state = {
            scroll: false,
        };
    }

    epkToTop() {
        if (typeof window !== 'undefined') {
            const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(this.epkToTop);
                window.scrollTo(0, currentScroll - (currentScroll / 5));
            }
        }
    }

    componentDidMount() {

        this._isComponentMounted = true;
        if (typeof window !== 'undefined') {
            window.addEventListener("scroll", this.scroll);
            this.scroll();
        }
    }

    scroll() {
        if (typeof window !== 'undefined') {
            const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (this._isComponentMounted) {
                this.setState({ scroll: currentScroll > 100 });
            }
        }

    }

    componentWillUnmount() {
        this._isComponentMounted = false;
        if (typeof window !== 'undefined') {
            document.removeEventListener("scroll", this.scroll);
        }
    }


    render() {
        return (
            <button onClick={this.epkToTop} className={this.state.scroll ? "epk-totop show" : "epk-totop hide"}>
                <Icon name="vrh"></Icon>
            </button>
        );
    }
}

export default BackToTop;

