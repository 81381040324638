import * as React from 'react';
import {ITextInputProps, TextInput} from "./TextInput";
import {FormInput} from "../FormInput";

export interface ITextAreaInputProps extends ITextInputProps {
    rows?: number
}

export class TextAreaInput extends FormInput<ITextAreaInputProps> {

    protected type: string = "text";

    protected renderLabel(): React.ReactNode {
        return (<label className={"form-control-label " + (this.getErrorClass())}>{this.props.label}</label>);
    }

    protected renderError(): React.ReactNode {
        return (<div className={this.getErrorTextErrorClass()}>{(this.error !== null) ? this.error : ""}</div>);
    }

    protected renderInput(): React.ReactNode {
        return (<textarea ref={this.inputRef}
                       placeholder={this.props.placeholder ? this.props.placeholder : ""}
                       rows={this.props.rows?this.props.rows:20}
                       tabIndex={this.props.tabIndex}
                       onBlur={(this.props.onBlur) ? this.props.onBlur : null}
                       onChange={(this.props.onChange) ? this.props.onChange : null}
                       className={"form-control " + this.getInputErrorClass()}/>);
    }

    public getValue(): any {
        return (this.inputRef.current) ? this.inputRef.current.value : "";
    }

    public getReadOnly(val: any): any {
        return val;
    }
}
