import React, { Component } from 'react';

class SponsorAndIssn extends Component {
    render() {
        return (
            <>
                <span className="navbar-logo" >
                    <a href="https://www.efse.lu" target="_blank"><img className="efse-logo" src="/img/EFSE.png"></img></a>
                </span >
            </>
        );
    }
}

export default SponsorAndIssn;