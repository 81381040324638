/**
 * Paths enum to define page paths.
 */
export enum Path {
    HOMEPAGE = "/",

    CATEGORIES = "/kategorije",

    CATEGORY = "/pretraga/:slug/:id",

    SUBCATEGORY = "/pretraga/:slug/:idcategory/:idsubcategory",

    LOGIN = "/prijava",

    FORGOTTEN_PASSWORD = "/zaboravljena-lozinka",

    FORGOTTEN_PASSWORD_SUCCESS = "/zaboravljena-lozinka-uspjeh",

    NEW_PASSWORD = "/nova-lozinka/",

    FORGOTTEN_PASSWORD_CHANGE = "/nova-lozinka/:token",

    USER = "/profil/:id/:username",

    USEREDIT = "/profil/:id/:username/postavke",

    USER_PROFILE = "/profil",

    USER_ACTIVE_AD = "/profil/:id/:username/aktivni",

    USER_FINISHED_AD = "/profil/:id/:username/zavrseni",

    USER_CLOSED = "/profil/:id/:username/kupljeni",

    SEARCH_RESULTS = "/rezultati-pretrage",

    AD = "/oglas/:id",

    AD_SLUG = "/oglas/:id/:slug",

    ADS = "/oglasi",

    EDUCATION = "/edukacija",

    EDUCATION_VIDEO = "/video-edukacija",

    EDUCATION_CATEGORY = "/edukacija-kategorija",

    EDUCATION_CATEGORY_SLUG = "/edukacija-kategorija/:slug",

    EDUCATION_SINGLE = "/edukacija/:id/:slug",

    REGISTER = "/registracija",

    CONTACT = "/kontakt",

    INFORMATION = "/informacije",

    IMPRESUM = "/impresum",

    FAQ = "/cesto-postavljana-pitanja",

    OBJAVA_OGLASA = "/objava-oglasa",

    EPK = "/epk",

    USER_REGISTRATION = "/registracija-clanova-korisnika",

    TERMS = "/uslovi-koristenja",

    MESSAGES = "/poruke",

    MESSAGES_SENT = "/poruke/poslate",

    MESSAGES_UNREAD = "/poruke/neprocitane",

    MESSAGE = "/poruke/:id",

    MESSAGE_SENT = "/poruke/poslate/:id",

    SEARCH = "/pretraga",

    AD_ADD = "/dodaj-oglas",

    UPDATE_AD = "/azuriraj-oglas/:id",

    VERIFY = "/verifikacija",

    NOT_FOUND = "/nije-pronadjeno",

    FORBIDDEN = "/nedozvoljen-pristup",

    USER_FAVORITES = "/sacuvani-korisnici",

    ADS_FAVORITES = "/sacuvani-oglasi",

    DOWNLOAD_ADVERTISEMENTS = "/preuzimanje-oglasnika",

    PTS = "/poslovno-tehnicka-saradnja",

    PRIVACY = "/privacy",

    PTS0 = "/pts0",
    PTS1 = "/pts1",
    PTS2 = "/pts2",
    PTS3 = "/pts3",
    PTS4 = "/pts4",
    PTS5 = "/pts5",
    PTS6 = "/pts6",
    PTS7 = "/pts7",
    PTS8 = "/pts8",
    PTS9 = "/pts9"
}
