import React from "react";
import { Container, Row, Col, Button } from 'reactstrap';
import EpkPage from "./EpkPage";
import MainLayout from "../layout/MainLayout";
import { Container as PageContainer } from "../../index";
import Icon from "../custom/Icon";
import { Link } from "react-router-dom";
import { Path } from "../container/Path";
import { withRouter } from "../../common/components/routing/Router";

@withRouter(Path.FORBIDDEN.toString(), PageContainer)
export default class ForbiddenPage extends EpkPage {

    constructor(props) {
        super(props);
    }

    pageTitle() {
        return "Nedozvoljen pristup - " + process.env.REACT_APP_TITLE_PREFIX;
    }

    pageLayout() {
        return new MainLayout();
    }

    getDestroyableMembers() {
        return [];
    }

    renderContent() {

        return (
            <>
                <div className="epk-403-content">
                    <Container>
                        <div className="epk-403-section epk-section">
                            <Row>
                                <Col md="12" className="error-icon">
                                    <Icon name="stop" />
                                </Col>
                                <Col md="12">
                                    <h1 className="epk-container-h1">GREŠKA 403</h1>
                                </Col>
                                <Col md="12">
                                    <div className="content">
                                        <p>Nedozvoljen pristup stranici</p>
                                        <Button tag={Link} to={Path.HOMEPAGE} color="primary">Vrati se na početnu</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }
}
