import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { IAd } from '../../models/Ad';
import Util from "../../custom/Util";

interface IInfoDetails {
  ad: IAd;
  loaded: boolean;
}

class InfoDetails extends Component<IInfoDetails> {
  render() {
    const ad = this.props.ad;
    const loadercontent = <i className="title-loader-small">_______________</i>;
    const publishedAt = ad.published_at ? Util.formatDatetime(ad.published_at) : null;
    const expiresAt = ad.expires_at ? Util.formatDatetime(ad.expires_at.toString()): null;

    return (
      <>
        <Row>
          <Col xl="6">
            <div className="epk-info-detail">
              <div className="epk-info-name">Datum objave</div>
              <div className="epk-info-value">{!this.props.loaded ? loadercontent : publishedAt}</div>
            </div>
          </Col>

          <Col xl="6">
            <div className="epk-info-detail">
              <div className="epk-info-name">Datum isteka</div>
              <div className="epk-info-value">{!this.props.loaded ? loadercontent : expiresAt}</div>
            </div>
          </Col>

          <Col xl="6">
            <div className="epk-info-detail">
              <div className="epk-info-name">Vrsta oglasa</div>
              <div className="epk-info-value">{!this.props.loaded ? loadercontent : ad.ad_type.caption}</div>
            </div>
          </Col>

          <Col xl="6">
            <div className="epk-info-detail">
              <div className="epk-info-name">ID oglasa</div>
              <div className="epk-info-value">{!this.props.loaded ? loadercontent : ad.id}</div>
            </div>
          </Col>

          <Col xl="12">
            <div className="epk-info-detail">
              <div className="epk-info-name">Broj pregleda</div>
              <div className="epk-info-value">{!this.props.loaded ? loadercontent : ad.view_count}</div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default InfoDetails;

