import React from 'react';
import { Row, Col, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { IAd } from '../models/Ad'
import { Link } from 'react-router-dom';
import Util from './Util';
import { AdStatus, getAdStatus } from '../custom/enum/AdStatus'

interface IAdWithCol extends IAd {
    col?: Object,
}

function Ad(props: IAdWithCol) {

    let image = "/ad/no-img.jpg";

    if (props.ad_images && props.ad_images.length != 0) {
        
        props.ad_images.sort(function (a, b) {
            return a.sort_key - b.sort_key;
        }); // poredaj array po sort_key

        image = (props.ad_images[0].image_path ? props.ad_images[0].image_path : '/thumb' + props.ad_images[0] as string);
    } else if (props.ad_category_slug) {
        image = "/ad/" + props.ad_category_slug + ".jpg";
    }

    let adStatus;

    if (props.ad_status_id == AdStatus.APPROVED || props.ad_status_id == AdStatus.DUPLICATE_APPROVED
        || props.ad_status_id == AdStatus.EXPIRED || props.ad_status_id == AdStatus.FINISHED
    ) {
        adStatus = Util.formatDatetime(props.published_at)
    } else if (props.ad_status_id == AdStatus.PENDING || props.ad_status_id == AdStatus.PENDING_REPEATED) {
        adStatus = (
            <>
                <a id={"na-cekanju-" + props.id} className="status-of na-cekanju">
                    {getAdStatus("3").caption}
                </a>
                <UncontrolledPopover trigger="legacy" target={"na-cekanju-" + props.id}>
                    <PopoverHeader>Na čekanju</PopoverHeader>
                    <PopoverBody>Nakon unosa vaš oglas će biti pregledan i odobren za objavu. Oglasi će se odobravati za prikazivanje u što kraćem roku, a najkasnije od narednog radnog dana.</PopoverBody>
                </UncontrolledPopover>
            </>
        )
    } else if (props.ad_status_id == AdStatus.REJECTED) {
        adStatus = (
            <>
                <a id={"odbijeno-" + props.id} className="status-of odbijen">
                    {getAdStatus("2").caption}
                </a>
                <UncontrolledPopover trigger="legacy" target={"odbijeno-" + props.id}>
                    <PopoverHeader>Odbijeno</PopoverHeader>
                    <PopoverBody>Molimo vas da se prilikom objave oglasa vodite smjernicama za objavu oglasa koje su navedene u <a href="/uslovi-koristenja" target="_blank">uslovima korištenja</a> EPK portala. Oglasi sa nedozvoljenim sadržajem i neprimjerenim fotografijama neće biti objavljeni (stavka 6. Pravila i <a href="/uslovi-koristenja" target="_blank">uslovi korištenja</a> EPK portala. Oglas treba da sadrži isključivo podatke o predmetu koji se prodaje ili usluzi koja se pruža. Naslov oglasa i opis treba da se odnose samo na predmet prodaje ili uslugu. U oglasima nije dopuštena upotreba fotografija sa drugih oglasnih sajtova ili iz oglasa drugih oglašivača na EPK portalu. Oglas mora svojim sadržajem da pripada kategoriji u kojoj je postavljen.</PopoverBody>
                </UncontrolledPopover>
            </>
        )
    }

    return (
        <Col {...props.col ? props.col : { xs: "12", md: "6", xl: "4" }}>
            <div className="epk-ad">
                <Link to={'/oglas/' + props.id} className="epk-ad-img">
                    <div className="epk-ad-img-background" style={{ backgroundImage: 'url("' + image + '")' }}></div>
                    <div className="epkad-status">{props.ad_item_condition ? props.ad_item_condition.caption : ''}</div>
                </Link>
                <div>
                    <Link to={'/oglas/' + props.id} tabIndex={-1}>
                        <h5 className="epkad-title">{props.name}</h5>
                    </Link>
                    <div className="epk-ad-description">
                        <Row>
                            <Col xs="7">
                                <div className="epkad-price">{props.price ? Util.formatMoney(props.price) : "Po dogovoru"}</div>
                            </Col>
                            <Col xs="5">
                                <div className="epkad-date">{adStatus}</div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </Col>
    );
}

export default Ad;

