import React, { Component } from 'react';
import {
    Col,
    Nav,
    Collapse,
    NavLink,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Input,
    Alert,
    Label,
    CustomInput
} from 'reactstrap';
import { IAd, Ad } from '../../models/Ad';
import { Link } from 'react-router-dom';
import Icon from '../../custom/Icon';
import { Path } from '../../container/Path';
import Identity from '../../user/Identity';
import { TextAreaInput } from '../../../common/components/widgets/form/input/TextAreaInput';
import { PrivateMessage, IPrivateMessage } from '../../models/PrivateMessage';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import Util from '../../custom/Util';
import Noty from "noty";
import { AdType } from '../../custom/enum/AdType';
import { AdStatus } from '../../custom/enum/AdStatus';

interface IProfile {
    modal,
    collapse,
    reload,
    isFavorited,
    loaded,
    modalSellAd: boolean,
    closeRequests: [],
    checkedUserId: string,
    closingRequested: boolean,
    visitorModal: boolean,
    typeOfButton: string
}

class Profile extends Component<{ ad: IAd, loaded: boolean, isReadOnly: boolean }, IProfile> {

    private privateMessageModel: PrivateMessage = new PrivateMessage();
    private adModel: Ad = new Ad(false);
    private submitted = false;


    private inputs: { [attr: string]: React.RefObject<any> } = {
        content: React.createRef(),
    };

    constructor(props: any) {
        super(props);
        this.state = {
            modal: false,
            collapse: false,
            reload: false,
            isFavorited: false,
            loaded: false,
            modalSellAd: false,
            closeRequests: [],
            checkedUserId: null,
            closingRequested: true,
            visitorModal: false,
            typeOfButton: ''
        };

        this.openModal = this.openModal.bind(this);
        this.openCollapse = this.openCollapse.bind(this);
        this.handleValidate = this.handleValidate.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.saveItem = this.saveItem.bind(this);
        this.toggleSellAd = this.toggleSellAd.bind(this);
        this.deleteAd = this.deleteAd.bind(this);
        this.closeAd = this.closeAd.bind(this);
        this.onUserChange = this.onUserChange.bind(this);
        this.finishAd = this.finishAd.bind(this);
        this.finishAdWUser = this.finishAdWUser.bind(this);
        this.updateClosed = this.updateClosed.bind(this);
        this.visitorToggle = this.visitorToggle.bind(this);
    }

    private readInfo(): IPrivateMessage {
        const plainObject: IPrivateMessage = {};

        for (const key in this.inputs) {

            if (this.inputs.hasOwnProperty(key) && this.inputs[key].current) {
                plainObject[key] = this.inputs[key].current.getValue();
                this.inputs[key].current.removeError();
            }
        }


        plainObject.from_user = Identity.id;
        plainObject.to_user = this.props.ad.user.id;
        plainObject.subject = "Poruka za oglas: " + this.props.ad.name;
        plainObject.date_sent = (moment().format("YYYY-MM-DD HH:mm:ss").toString());

        this.privateMessageModel.setFromPlainObject(plainObject);

        return plainObject;
    }

    deleteAd() {
        const n = new Noty({
            text: "Jeste li sigurni da želite izbrisati oglas?",
            buttons: [
                Noty.button('DA', 'btn btn-success', () => {

                    this.adModel.del(this.props.ad.id);
                    n.close();
                    if (typeof window !== 'undefined') {
                        document.location.pathname = Path.USER_PROFILE + "/" + Identity.id + "/" + Identity.username;
                    }
                }, { id: 'button1', 'data-status': 'ok' }),

                Noty.button('NE', 'btn btn-error', function () {
                    n.close();
                })
            ],
            theme: 'bootstrap-v4',
            layout: 'center',
            progressBar: true
        });

        n.show();
    }

    closeAd() {

        this.adModel.closeAdRequest(this.props.ad.id)
            .then((response: AxiosResponse) => {
                Util.notification("success", "Vaš zahtjev je poslat.", 1500);
                this.setState({ closingRequested: true });

            })
            .catch((error: AxiosError) => {
                Util.notification("error", "Desila se greška,Vaš zahtjev nije poslat.", 1500);
            });
    }

    componentDidUpdate() {

        if (this.props.ad.id && !this.state.loaded) {

            if (!Identity.isGuest) {
                this.adModel.favorites()
                    .then((response: AxiosResponse) => {
                        const favorited = response.data.filter((favorite) => {
                            return favorite.user_id == Identity.id && favorite.ad_id == this.props.ad.id;
                        })

                        this.setState({ isFavorited: favorited.length > 0, loaded: true })
                    })
                    .catch((error: AxiosError) => {
                        console.log(error)
                    })

                this.adModel.getCloseAdRequest(this.props.ad.id)
                    .then((response: any) => {
                        if (!response.error) {
                            const closeRequests = response.data,
                                currentUserClosingRequests = closeRequests.filter((req) => {
                                    return req.user_id == Identity.id;
                                })

                            this.setState({ closeRequests, closingRequested: currentUserClosingRequests.length > 0 });
                        }
                    })
                    .catch((error: AxiosError) => { console.log(error) })
            }
        }
    }

    toggleSellAd() {

        this.setState(prevState => ({
            modalSellAd: !prevState.modalSellAd
        }));
    }

    visitorToggle(button) {
        this.setState(prevState => ({
            visitorModal: !prevState.visitorModal,
            typeOfButton: button
        }));
    }

    finishAd() {

        const data: IAd = {
            closed_by: null
        }

        this.updateClosed(data);
    }

    updateClosed(data: IAd) {
        data.id = this.props.ad.id;
        data.closed_at = "<now>";
        data.ad_status_id = AdStatus.FINISHED;

        this.adModel.updateClosed(data)
            .then((response: AxiosResponse) => {
                Util.notification("success", "Vaš oglas je završen.", 1500);
                if (typeof window !== 'undefined') {
                    document.location.pathname = Path.USER_PROFILE + "/" + Identity.id + "/" + Identity.username;
                }
            })
            .catch((error: AxiosError) => {
                Util.notification("error", "Desila se greška,Vaš oglas nije zatvoren.", 1500);
            });
    }


    finishAdWUser() {

        const data: IAd = {
            closed_by: parseInt(this.state.checkedUserId)
        }

        this.updateClosed(data);

    }

    onUserChange(e: React.ChangeEvent<HTMLInputElement>) {

        const target = event.target as HTMLInputElement;

        this.setState({
            checkedUserId: target.id
        })

    }

    private handleValidate(event: any): boolean {
        this.readInfo();
        const valid = this.privateMessageModel.validate();

        if (this.submitted) {
            if (!valid) {
                const errors: { [attr: string]: string } = this.privateMessageModel.getErrors();
                this.fillInputsWithErrors(errors);
            }
        }

        event.preventDefault();

        return valid;
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }) {
        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                this.inputs[key].current.setError(errors[key]);
            }
        }
    }

    protected sendMessage(event: React.MouseEvent<HTMLElement>): void {
        this.submitted = true;

        const plainObject: IPrivateMessage = this.readInfo();
        const valid: boolean = this.handleValidate(event);

        this.privateMessageModel.setFromPlainObject(plainObject);

        if (valid) {
            this.privateMessageModel.createNew().then((response: AxiosResponse | void) => {

                Util.notification("success", "Poruka je uspješno poslata.", 1500)

                this.setState({
                    modal: !this.state.modal,
                });
            }).catch((error: AxiosError) => {
                Util.notification("error", "Desila se greška, poruka nije poslata.", 1500)
            });
        }

        event.preventDefault();
    }


    openModal() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    openCollapse() {
        this.setState({
            collapse: !this.state.collapse
        });
    }

    saveItem(event: React.MouseEvent<HTMLAnchorElement>) {

        this.adModel.addFavorite(this.props.ad.id)
            .then((response: AxiosResponse) => {
                Util.notification("success", "Oglas je uspješno dodat.", 10000);
                this.setState({ reload: true, isFavorited: true });
            })
            .catch((error: AxiosError) => {
                Util.notification("error", "Desila se greška, oglas nije dodat.", 10000);
            })
    }

    render() {

        const pathurl = Path.USER_PROFILE;
        const ad = this.props.ad;
        let phoneNumber

        let visitorModal = null;
        let profileAndOptions = null;

        if (this.props.loaded) {
            if (ad.user.phone_number != null && (Identity.id !== ad.user.id) && !Identity.isGuest) {
                phoneNumber = (
                    <>
                        <NavLink tag="a" color="primary" onClick={this.openCollapse}><Icon name="telefon" /> Broj
                        telefona</NavLink>
                        <Collapse isOpen={this.state.collapse}>
                            {Util.phoneFormat(ad.user.phone_number)}
                        </Collapse>
                    </>
                )
            } else if (ad.user.phone_number && Identity.isGuest) {
                phoneNumber = (
                    <>
                        <NavLink tag="a" color="primary" onClick={this.openCollapse}><Icon name="telefon" /> Broj
                        telefona</NavLink>
                        <Collapse isOpen={this.state.collapse}>
                            Broj telefona je vidljiv samo za registrovane korisnike. <Link to={Path.REGISTER}>Registruj se</Link>
                        </Collapse>
                    </>
                )
            }
            let privateMessage
            if (ad.user.verified && !Identity.isGuest && (Identity.id !== ad.user.id)) {
                privateMessage = (
                    <>
                        <NavLink className="btn btn-epk-primary" onClick={this.openModal}><Icon name="poruka" /> Privatna
                        poruka</NavLink>
                        <Modal isOpen={this.state.modal} toggle={this.openModal}>
                            <ModalHeader toggle={this.openModal}>Poruka za {ad.user.username}</ModalHeader>
                            <ModalBody>
                                <Form>
                                    <FormGroup>
                                        <TextAreaInput
                                            rows={5}
                                            ref={this.inputs.content}
                                            onBlur={this.handleValidate}
                                        />
                                    </FormGroup>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.sendMessage}>Pošalji</Button>
                                <Button color="secondary" onClick={this.openModal}>Zatvori</Button>
                            </ModalFooter>
                        </Modal>
                    </>
                )
            } else if (Identity.isGuest) {
                privateMessage = (
                    <>
                        <NavLink className="btn btn-epk-primary" onClick={this.openModal}><Icon name="poruka" /> Privatna
                        poruka</NavLink>
                        <Modal isOpen={this.state.modal} toggle={this.openModal}>
                            <ModalHeader toggle={this.openModal}>Poruka za {ad.user.username}</ModalHeader>
                            <ModalBody>
                                Poštovani, slanje privatnih i javnih poruka je dostupno samo logovanim i registrovanim
     korisnicima EPK portala. Da biste komunicirali sa oglašivačima možete se logovati putem
     Facebook ili Gmail naloga na sljedećem linku: <Link to={Path.LOGIN}>https://ekioglasi.ba/prijava</Link>. Za detaljnije informacije o
    usluzi <Link to={Path.EPK}>EKI Poslovni klub (EPK)</Link>, <Link to={Path.TERMS}>pravilima i uslovima korištenja EPK portala</Link> i mogućnostima
    koje nude pogledajte na stranici <Link to={Path.FAQ}>Često postavljana pitanja (ČPP)</Link>.
                        </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.openModal}>Zatvori</Button>
                            </ModalFooter>
                        </Modal>
                    </>
                )
            }

            let profile;
            if (ad.user.verified) {

                const thumb = !Util.isAbsolutePath(ad.user.avatar) ? '/thumb' : '';

                profile = (
                    <div className="epk-ad-profile">
                        <Link to={pathurl + "/" + ad.user.id + "/" + ad.user.username} className="ad-author">
                            <div className="row align-items-center">
                                <Col xs="3"><img src={ad.user.avatar ? thumb + ad.user.avatar : "/profile/no-img-profile.png"} className="img-thumbnail epk-image" /></Col>
                                <Col xs="9">
                                    <div className="ad-author-info">
                                        <div className="ad-author-name">{ad.user.username}</div>
                                    </div>
                                </Col>
                            </div>
                        </Link>
                    </div>
                )
            } else {
                profile = (
                    <Alert color="empty">
                        Vlasnik prikazanog oglasa je odabrao da svu komunikaciju vezano za oglas obavlja preko broja telefona koji se nalazi u kratkom opisu oglasa.
                </Alert>
                )
            }

            let typeOfButton;
            if (this.state.typeOfButton === "saveAd") {
                typeOfButton = (
                    <>
                        Poštovani, opcija “Sačuvaj oglas” je dostupna samo logovanim i registrovanim korisnicima
        EPK portala. Da biste sačuvali oglas koji Vas zanima, možete se logovati putem Facebook ili
    Gmail naloga na sljedećem linku: <Link to={Path.LOGIN}>https://ekioglasi.ba/prijava</Link>. Za detaljnije informacije o
    usluzi <Link to={Path.EPK}>EKI Poslovni klub (EPK)</Link>, <Link to={Path.TERMS}>pravilima i uslovima korištenja EPK portala</Link> i mogućnostima
    koje nude pogledajte na stranici <Link to={Path.FAQ}>Često postavljana pitanja (ČPP)</Link>.
       </>
                )
            } else if (this.state.typeOfButton === "buyAd") {
                typeOfButton = (
                    <>
                        Poštovani, opcija “Kupi artikal/Dogovori uslugu” je dostupna samo logovanim i
    registrovanim korisnicima EPK portala. Da biste ovom opcijom poslali zahtjev oglašivaču da
    želite da kupite artikal ili dogovorite uslugu iz oglasa koji je on objavio, to možete uraditi tako
    što ćete se logovati putem Facebook ili Gmail naloga na sljedećem linku:
<Link to={Path.LOGIN}>https://ekioglasi.ba/prijava</Link>. Za detaljnije informacije o
    usluzi <Link to={Path.EPK}>EKI Poslovni klub (EPK)</Link>, <Link to={Path.TERMS}>pravilima i uslovima korištenja EPK portala</Link> i mogućnostima
    koje nude pogledajte na stranici <Link to={Path.FAQ}>Često postavljana pitanja (ČPP)</Link>.
                </>
                )
            }

            visitorModal = (
                <Modal isOpen={this.state.visitorModal} toggle={() => this.visitorToggle('')}>
                    <ModalBody>
                        {typeOfButton}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => this.visitorToggle('')}>Zatvori</Button>
                    </ModalFooter>
                </Modal>
            )

            let nameOfBuyAd;

            if (ad.ad_type.id == AdType.SELL) {
                { Identity.id == ad.user.id ? nameOfBuyAd = "Završi prodaju" : nameOfBuyAd = "Kupi artikal" }
            } else if (ad.ad_type.id == AdType.RENT) {
                { Identity.id == ad.user.id ? nameOfBuyAd = "Završi iznajmljivanje" : nameOfBuyAd = "Iznajmi artikal" }
            } else if (ad.ad_type.id == AdType.BUY) {
                nameOfBuyAd = "Prodaj artikal"
            } else if (ad.ad_type.id == AdType.SERVICE) {
                nameOfBuyAd = "Dogovori uslugu"
            } else if (ad.ad_type.id == AdType.BUYOUT) {
                nameOfBuyAd = "Završi otkupljivanje"
            }

            let buyAd = null;
            if (Identity.id != ad.user.id && !Identity.isGuest && ad.user.verified && (ad.ad_status_id == 1 || ad.ad_status_id == 5)) {
                buyAd = <NavLink tag={Link} to="#" onClick={this.closeAd}><Icon name="kupi" /> {nameOfBuyAd}</NavLink>
            } else if (Identity.isGuest && ad.user.verified) {
                buyAd = <NavLink tag={Link} to="#" onClick={() => this.visitorToggle('buyAd')}><Icon name="kupi" /> {nameOfBuyAd}</NavLink>
            }

            let saveAd;
            if (!Identity.isGuest && !this.state.isFavorited && (Identity.id !== ad.user.id)) {
                saveAd = <NavLink onClick={this.saveItem}><Icon name="sacuvaj" /> Sačuvaj oglas</NavLink>
            } else if (Identity.isGuest) {
                saveAd = <NavLink onClick={() => this.visitorToggle('saveAd')}><Icon name="sacuvaj" /> Sačuvaj oglas</NavLink>
            }

            let editAd;
            if ((Identity.id == ad.user.id || Identity.id == ad.created_by) && !ad.closed_at) {
                editAd = <NavLink color="primary" tag={Link} to={"/azuriraj-oglas/" + ad.id}><Icon name="edit" /> Ažuriraj oglas</NavLink>
            }

            let sellAd;
            if (Identity.id == ad.user.id && !ad.closed_at) {
                sellAd = (
                    <>
                        <NavLink tag="a" color="primary" onClick={this.toggleSellAd}><Icon name="checked" /> {nameOfBuyAd}</NavLink>

                        <Modal isOpen={this.state.modalSellAd} toggle={this.toggleSellAd} className={"sell sell-" + ad.id}>
                            <ModalHeader toggle={this.toggleSellAd}>{nameOfBuyAd}: {ad.name}</ModalHeader>
                            <ModalBody>
                                <Form>
                                    <FormGroup>
                                        <Label for="userCheckbox" >Korisni koji su označili "{nameOfBuyAd} :"</Label>
                                        <div>
                                            {
                                                this.state.closeRequests.length > 0
                                                    ? this.state.closeRequests.map((req: any) => {
                                                        return <CustomInput onChange={this.onUserChange} key={req.user.id} type="radio" id={req.user.id} name="customRadio" label={req.user.username} />

                                                    })
                                                    : "Nema korisnika."
                                            }
                                        </div>
                                    </FormGroup>
                                    <Button color="primary" onClick={this.finishAdWUser}>{nameOfBuyAd}</Button>{' '}
                                    <Button color="secondary" onClick={this.finishAd}>{nameOfBuyAd} bez kupca</Button>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </>
                )
            }

            let deleteAd;
            if (Identity.id == ad.user.id) {
                deleteAd = <NavLink color="primary" tag={Link} to="#" onClick={this.deleteAd}><Icon name="trash" /> Izbriši oglas</NavLink>
            }

            let options;

            if (!this.props.isReadOnly) {
                options = (
                    <Nav className="flex-column">
                        {phoneNumber}
                        {saveAd}

                        {this.state.closingRequested ? null : buyAd}
                        {Identity.isGuest && (ad.ad_status_id == 1 || ad.ad_status_id == 5) ? buyAd : null}

                        {privateMessage}
                        {editAd}
                        {sellAd}
                        {deleteAd}
                    </Nav>
                )
            } else {
                options = (
                    <Nav className="flex-column">
                        {sellAd}
                        {deleteAd}
                    </Nav>
                )
            }

            if (!this.props.loaded) {
                profileAndOptions = (
                    <>
                        <div className="image-loader" />
                        <i className="content-loader w100" />
                        <i className="content-loader w50" />
                        <i className="content-loader w70" />
                        <i className="content-loader w50" />
                        <i className="content-loader w70" />
                    </>
                )
            } else {
                profileAndOptions = (
                    <>
                        {profile}
                        {options}
                    </>
                )
            }


        }

        return (
            <>
                <h4 className="epk-ad-info-title epk-info-first epk-add-margin">Objavio</h4>
                <div className="epk-ad-actions-section">
                    {profileAndOptions}
                </div>
                {visitorModal}
            </>
        );
    }
}

export default Profile;

