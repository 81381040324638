import * as dotenv from 'dotenv';
dotenv.config({path:".env.production"});

import React, { Component } from 'react';
import { Button, Container, Row, Col, Navbar, Collapse, Badge, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import HeaderNav from './header/HeaderNav';
import SponsorAndIssn from './header/SponsorAndIssn';
import SocialNav from './header/SocialNav';
import Logo from './header/Logo';
import DropdownCategoryButton from './header/DropdownCategoryButton';
import Icon from '../custom/Icon';
import Search from './header/Search';
import HeaderLogin from './header/HeaderLogin';
import { Link } from 'react-router-dom';
import { Path } from '../container/Path';
import Identity from '../user/Identity';
import { Role } from '../user/Role';
import Util from '../custom/Util';

interface IHeaderState {
  isOpen, modalAd, guest: boolean;
}

class Header extends Component<unknown, IHeaderState> {
  constructor(props: any) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      isOpen: false,
      modalAd: false,
      guest: false
    };

    Util.topFunction() //Svaka nova otvorena stranica počinje sa vrha
  }

  toggleModal() {
    this.setState(prevState => ({
      modalAd: !prevState.modalAd
    }));
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  componentDidMount() {
    this.setState({ guest: Identity.isGuest })
  }

  render() {

    let mobileOptions;

    if (Identity.role == Role.GUEST) {
      mobileOptions = (
        <>
          <Button tag={Link} to={Path.LOGIN} color="epk-header-mobile-button" className="epk-header-mobile-button singin">Prijava</Button>
          <Button tag={Link} to={Path.CATEGORIES} color="epk-header-mobile-button" className="epk-header-mobile-button register">Kategorije</Button>
        </>
      );
    }
    else if (Identity.role == Role.CLIENT || Identity.role == Role.LOAN_OFFICER) {
      mobileOptions = (
        <>
          <Button tag={Link} to={Path.USER_PROFILE + "/" + Identity.id + "/" + Identity.username} color="epk-header-mobile-button" className="epk-header-mobile-button singin">Profil {/* <Badge color="primary">99</Badge> */}</Button>
          <Button tag={Link} to={Path.AD_ADD} color="epk-header-mobile-button" className="epk-header-mobile-button register">Objavi</Button>
        </>
      );
    }
    else {
      mobileOptions = (
        <>
          <Button tag={Link} to={Path.USER_PROFILE + "/" + Identity.id + "/" + Identity.username} color="epk-header-mobile-button" className="epk-header-mobile-button singin">Profil {/* <Badge color="primary">99</Badge> */}</Button>
          <a onClick={this.toggleModal} className="epk-header-mobile-button register btn btn-epk-header-mobile-button">Objavi</a>
          <Modal isOpen={this.state.modalAd} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>Poštovani, Vi niste član EKI Poslovnog kluba</ModalHeader>
            <ModalBody>
              <p>EKI Poslovni klub (EPK) predstavlja dodatnu BESPLATNU uslugu za EKI-jeve klijente. EPK je usluga BESPLATNOG oglašavanja na EPK portalu za sve aktivne članove EPK. Članovi <Link to={Path.EPK}>EKI Poslovnog kluba</Link> mogu biti svi klijenti EKI-ja koji imaju aktivan kredit.</p>
              <p>Vi trenutno možete pregledati oglase, slati privatne i javne poruke, ali niste u mogućnosti da objavljujete svoje oglase, niti da pristupite izdvojenim sadržajima.
              Da bi pristupio svom profilu i mogao samostalno objavljivati oglase, član EPK mora izvršiti registraciju profila unosom korisničkih podataka dodijeljenih od strane EKI-ja.</p>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggleModal}>Zatvori</Button>
            </ModalFooter>
          </Modal>
        </>
      );
    }

    const loginSize = this.state.guest ? { size: 2, offset: 1 } : { size: 5, offset: 0 };

    let headerStyle

    if (process.env.REACT_APP_HEADER_TEST_IMAGE) {
      headerStyle = {
        backgroundImage: 'url(' + process.env.REACT_APP_HEADER_TEST_IMAGE + ')'
      }
    }

    const mdSearch = this.state.guest ? "8" : "6",
      xlSearch = this.state.guest ? "9" : "7",
      mdLogin = this.state.guest ? "4" : "6";


    return (
      <header className="epk-header sticky-top" id="epk-header" style={headerStyle}>
        <Container fluid>
          <Row>
            <Col lg="12" className="epk-header-menu">
              <Navbar expand="lg" className="epk-nav navbar-light">
                <Container fluid>
                  <Collapse isOpen={this.state.isOpen} navbar>
                    <HeaderNav /> {/* import HeaderNav to Header */}
                    <SponsorAndIssn /> {/* import SponsorAndIssn to Header */}
                    <SocialNav /> {/* import SocialNav to Header */}
                  </Collapse>
                </Container>
              </Navbar>
            </Col>
            <Col sm="12" md="5" xl="4" className="epk-header-logo-box">
              <Logo /> {/* import Logo to Header */}
              <div className="dropdown align-items-center epk-logo-nav">
                <Button color="header-logo-nav" className="epk-logo-nav-button" onClick={this.toggle}>
                  <Icon name="menu" />
                </Button>
                <DropdownCategoryButton />
              </div>
            </Col>
            <Col sm="12" md="7" xl="8" className="epk-header-option-box">
              <Row className="epk-header-options d-flex align-items-center">
                <Col sm="12" md={mdSearch} xl={xlSearch} className="epk-header-search-box">
                  <Row>
                    <Col xs="6" sm="4" md="0" className="epk-mobile-login-register" id="epk-mobile-login-register">
                      {mobileOptions}
                    </Col>
                    <Col xs="6" sm="8" md="12" className="epk-mobile-search" id="epk-mobile-search">
                      <Search /> {/* import Search to Header */}
                    </Col>
                  </Row>
                </Col>
                <Col md={mdLogin} xl={loginSize} className="d-none d-md-block">
                  <HeaderLogin /> {/* import HeaderLogin to Header */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </header>
    );
  }
}

export default Header;
