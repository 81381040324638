import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import { EmailValidator } from "../../common/components/validators/EmailValidator";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";

export interface IContact {
    name?: string,
    municipality?: string,
    phone?: string,
    email?: string,
    message?: string,
    captcha?: string,
}


export class Contact extends DataModel<IContact> {

    private resourceName = "user/contact";

    private _name: string;
    private _municipality: string;
    private _phone: string;
    private _email: string;
    private _message: string;
    private _captcha: string;

    protected getDefaultValues(): IContact {
        return {};
    }

    public createDataSource(resourceName: string = this.resourceName): RestDataSource<IContact> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, {
            "Content-Type": "application/json"
        });
    }

    protected createValidator(scenario: string): IDataModelValidator<IContact> {
        return new DataModelValidator<IContact>({
            name: [new RequiredValidator()],
            municipality: [new RequiredValidator()],
            phone: [new RequiredValidator()],
            email: [new RequiredValidator(), new EmailValidator()],
            message: [new RequiredValidator(), new LengthValidator({ min: 10 })],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IContact>, action: AnyAction): IDataModelState<IContact> {
        return state;
    }

    public send(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .plainPostOperation(this.toObj());
    }

    getStoreKey(): string {
        return "CONTACT";
    }

    protected setFromObj(data: IContact): void {
        this._name = DataModel.safeGet(data.name, this._name) as string;
        this._municipality = DataModel.safeGet(data.municipality, this._municipality) as string;
        this._phone = DataModel.safeGet(data.phone, this._phone) as string;
        this._email = DataModel.safeGet(data.email, this._email) as string;
        this._message = DataModel.safeGet(data.message, this._message) as string;
        this._captcha = DataModel.safeGet(data.captcha, this._captcha) as string;
    }


    protected toObj(): IContact {
        return {
            name: this._name,
            municipality: this._municipality,
            phone: this._phone,
            email: this._email,
            message: this._message,
            captcha: this._captcha,
        };
    }


    get name() {
        return this._name;
    }

    get municipality() {
        return this._municipality;
    }

    get phone() {
        return this._phone;
    }

    get email() {
        return this._email;
    }

    get message() {
        return this._message;
    }

    get captcha() {
        return this._captcha;
    }
}
