import React from "react";
import EpkPage from "./EpkPage";
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, } from 'reactstrap';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { IDestroy } from "../../common/components/pages/IDestroy";
import Identity from "../user/Identity";
import { withSecurity } from "../../common/security/Security";
import { Role } from "../user/Role";
import { User, IUser } from "../models/User";
import { PasswordInput } from "../../common/components/widgets/form/input/PasswordInput";
import { FormHandlingUtil } from "../../common/components/widgets/form/util/FormHandlingUtil";
import Noty from "noty";
import { AxiosResponse, AxiosError } from "axios";
import Util from "../custom/Util";

@withRouter(Path.FORGOTTEN_PASSWORD_CHANGE.toString(), PageContainer)
@withSecurity([Role.GUEST.toString()], Identity, Path.HOMEPAGE)
export default class ForgottenPasswordChangePage extends EpkPage {

    private user: User = new User(true, User.SCENARIO.CHANGE_PASSWORD)

    private formHandlingUtil: FormHandlingUtil;

    private inputs: { [attr: string]: React.RefObject<any> } = {
        password: React.createRef(),
        password_confirmed: React.createRef()
    };

    constructor(props: any) {
        super(props);

        const password_reset_token = this.props.match.params.token;

        this.formHandlingUtil = new FormHandlingUtil(
            this.inputs,
            this.handleSubmit.bind(this),
            [this.user]
        );

        this.state = {
            password_reset_token
        }

    }


    public componentDidMount() {
        super.componentDidMount();

        this.user.setFromPlainObject({
            password_reset_token: this.props.match.params.token
        });
    }

    pageTitle() {
        return "Nova lozinka" + super.pageTitle();
    }

    getDestroyableMembers(): IDestroy[] {
        return [
            this.user
        ];
    }

    handleSubmit(event: React.MouseEvent<HTMLElement>) {

        this.user.resetPassword(this.state.password_reset_token)
            .then((response: AxiosResponse) => {
                this.user.setFromPlainObject({ ...response.data, ...this.user });

                this.user.login(this.user).then((error: AxiosError | void) => {

                    if (!error) {
                        if (typeof window !== 'undefined') {
                            document.location.pathname = Path.HOMEPAGE.toString();
                        }
                    }
                })
            })
            .catch((error: AxiosError) => {

                Util.notification('error', 'Desila se greška, pokušajte ponovo.', 1500)
            });
    }

    renderContent() {

        return (
            <>
                <div className="epk-forgotten-password-content">
                    <Container>
                        <Row>
                            <Col xl={{ size: 6, offset: 3 }}>
                                <div className="epk-forgotten-password-content-section epk-section">
                                    <div>
                                        <h1 className="epk-container-h1">Zaboravljena lozinka</h1>
                                    </div>
                                    <div>
                                        <Form>
                                            <FormGroup>
                                                <Label for="newPassword">Nova lozinka</Label>
                                                <PasswordInput ref={this.inputs.password}
                                                    id="newPassword"
                                                    onBlur={this.formHandlingUtil.getValidateHandle()}
                                                    placeholder="Lozinka"
                                                //autoFocus={true}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="renewPassword">Ponovite novu lozinku</Label>
                                                <PasswordInput ref={this.inputs.password_confirmed}
                                                    id="renewPassword"
                                                    placeholder="Ponovite novu lozinku" />
                                            </FormGroup>
                                            <Button color="epk-primary" onClick={this.formHandlingUtil.getSubmitHandle()} type="submit">Spremi</Button>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}
