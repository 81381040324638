import React, { Component } from 'react';
import { Col, Collapse, ListGroup, ListGroupItem } from 'reactstrap';
import Select from 'react-select';
import { customStyles } from './selectstyles';
import { AdItemCondition } from '../../models/AdItemCondition';
import { AxiosResponse, AxiosError } from 'axios';
import { IQuery } from '../../models/Search';
import Util from '../../custom/Util';

interface ISearchConditionState {
    collapse: boolean;
    error: string;
    isLoaded: boolean;
    options: Option[];
    selectedOption: Option;
}

type Option = {
    value: string;
    label: string;
}

class SearchCondition extends Component<{ filterSearch: any, search: IQuery }, ISearchConditionState> {

    private adConditionModel: AdItemCondition = new AdItemCondition();

    constructor(props: any) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            collapse: true,
            error: "",
            isLoaded: false,
            options: [],
            selectedOption: props.search.opstina != undefined ? props.opstina : null
        };
    }


    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    handleChange = (selectedOption) => {

        this.setState({ selectedOption });
        this.props.filterSearch({ stanje: selectedOption != null ? selectedOption.value : null });

    }

    componentDidMount() {

        this.adConditionModel.createDataSource(this.adConditionModel.resourceNamePlural).getListPlain({})
            .then((response: AxiosResponse) => {

                const defaultValue =
                response.data.filter(condition => {
                    return condition.id == this.props.search.stanje;
                })[0];

                this.setState({
                    isLoaded: true,
                    options: response.data.map(condition => {
                        return { value: condition.id, label: condition.caption }
                    }),
                    selectedOption: defaultValue? { value: defaultValue.id, label: defaultValue.caption } : null
                });

            }).catch((error: AxiosError) => {

                Util.notification("error", "Stanja oglasa ne moggu biti učitana.", 1500)
            });

    }

    render() {
        const { error, isLoaded, options, selectedOption } = this.state;
        let content;
        if (error.length > 0) {
            content = <Col sm="12"> Error: {error}</Col>;
        } else if (!isLoaded) {
            content = <Col sm="12">Loading...</Col>;
        } else {
            content = (
                <Select
                    styles={customStyles}
                    onChange={this.handleChange}
                    options={options}
                    value={selectedOption}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Sve"
                    isClearable
                    isSearchable={false}
                />
            )
        }

        return (
            <>
                <ListGroupItem tag="a" className="epk-filter-mod dropdown-toggle" onClick={this.toggle}>Stanje</ListGroupItem>
                <Collapse isOpen={this.state.collapse}>
                    <div className="card card-body">
                        <ListGroup tag="ul" className="list-group-flush epk-filter-mod-category">
                            {content}
                        </ListGroup>
                    </div>
                </Collapse >
            </>
        );
    }
}

export default SearchCondition;
