import React, { Component, Fragment } from 'react';
import { Col, Collapse, ListGroup, ListGroupItem, Button, Spinner } from 'reactstrap';
import Icon from '../../custom/Icon';
import { Link } from 'react-router-dom';
import { AdCategory, IAdCategory } from '../../models/AdCategory';
import { AxiosResponse, AxiosError } from 'axios';
import { Path } from '../../container/Path';
import Util from '../../custom/Util';
import Helmet from 'react-helmet';
import Category from '../homepage/categories/Category';

interface ISearchCategoryState {
    collapse: boolean;
    categories: IAdCategory[];
    error: string;
    isLoaded: boolean;
    activeCategory: number;
}

class SearchCategory extends Component<{ filterSearch: any, search: any, data: any }, ISearchCategoryState> {

    private categoryModel: AdCategory = new AdCategory();

    constructor(props: any) {
        super(props);

        this.toggle = this.toggle.bind(this);

        const activeCategory: number = props.search.kategorija || -1 ;
        let data = this.props.data || null;

        if (typeof window !== 'undefined') {
            const hydrationMarker = document.getElementById("hydration-marker");

            if (hydrationMarker !== null && hydrationMarker.innerHTML.length > 0) {
                data = JSON.parse(hydrationMarker.innerHTML.replace(/(&quot\;)/g, "\""));
            }
        }

        this.state = {
            collapse: true,
            error: "",
            isLoaded: data !== null,
            categories: [],
            activeCategory: activeCategory,
            ...data
        };
    }


    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    public componentDidMount() {

        this.categoryModel.createDataSource(this.categoryModel.resourceNamePlural + "/subcategories").getListPlain({})
            .then((response: AxiosResponse) => {

                this.setState({
                    isLoaded: true,
                    categories: response.data
                });

            }).catch((error: AxiosError) => {

                Util.notification("error", "Kategorije ne mogu biti učitane.", 1500)
            });

    }


    getSubcategories(category: IAdCategory) {

        if (category.subcategories) {
            return category.subcategories.map((searchSubcategory) => {
                return (
                    <ListGroupItem tag="a" key={searchSubcategory.id} href={"/" + category.slug + "/" + category.id + "?stranica=1&podkategorija=" + searchSubcategory.id} >
                        <Icon name="podkategorija" /> {searchSubcategory.name}
                    </ListGroupItem>
                )
            })
        }
    }


    render() {
        const { error, isLoaded, categories, activeCategory } = this.state;
        let content, title = "", desc = "", slug = "";

        const search = Path.SEARCH;

        if (error.length > 0) {
            content = <Col sm="12"> Error: {error}</Col>;
        } else if (!isLoaded) {
            content = <Spinner color="primary" />;
        } else {
            if (activeCategory > -1) {
                let category;

                for (const i in categories) {
                    if (categories[i].id == activeCategory) {
                        category = categories[i];
                        break;
                    }
                }

                title = category.name;
                slug = category.slug;
                desc = "Pretraga za kategoriju " + category.name;

                content = (
                    <>
                        <ListGroupItem className="single" tag="a" href={search + "/" + category.slug + "/" + category.id}>
                            <Icon name={category.slug} /> {category.name}
                        </ListGroupItem>
                        {category.subcategories.map(singleCategory => (
                            <ListGroup key={singleCategory.id} tag="ul" className="list-group-flush epk-filter-mod-category">
                                <Fragment>
                                    <ListGroupItem className="sub" tag="a" href={search + "/" + category.slug + "/" + category.id + "?podkategorija=" + singleCategory.id}>
                                        <Icon name="podkategorija" /> {singleCategory.name}
                                    </ListGroupItem>
                                </Fragment>
                            </ListGroup>
                        ))}
                        <a className="btn btn-reload" href={Path.SEARCH + "?stranica=1"} >Sve kategorije</a>
                    </>
                )
            }

            else {
                content = (
                    categories.map(SearchCategorySingle => (
                        <Fragment key={SearchCategorySingle.id}>
                            <ListGroupItem tag="a" href={search + "/" + SearchCategorySingle.slug + "/" + SearchCategorySingle.id + "?stranica=1"}>
                                <Icon name={SearchCategorySingle.slug} /> {SearchCategorySingle.name}
                            </ListGroupItem>
                        </Fragment>
                    ))
                );
            }
        }

        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={desc} />
                    <meta name="keywords" content={slug.replace(new RegExp("-", 'g'), ",")} />
                </Helmet>
                <a className="epk-filter-mod epk-filter-category dropdown-toggle list-group-item" onClick={this.toggle}>Kategorije</a>
                <Collapse isOpen={this.state.collapse}>
                    <div className="card-body card-with-button">
                        <ListGroup tag="ul" className="list-group-flush epk-filter-mod-category">
                            {content}
                        </ListGroup>
                    </div>
                </Collapse >
            </>
        );
    }
}

export default SearchCategory;
