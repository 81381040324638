import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";

export interface ISearch {
    id?: number,
    query?: IQuery,
    user_id?: number,
    result?: any,
    s?: string
}


export interface IQuery {
    stanje?: string,
    kategorija?: number,
    podkategorija?: number,
    opstina?: any,
    tip?: string,
    cijena_od?: number,
    cijena_do?: number,
    sa_zamjenom?: boolean,
    sa_slikama?: boolean,
    sa_cijenom?: boolean,
    upit?: string,
    stranica?: number
}


export class Search extends DataModel<ISearch> {

    public resourceName = "search";

    private _id: number;
    private _query: IQuery;
    private _user_id: number;
    private _result: any;
    private _stranica: number;


    protected getDefaultValues(): ISearch {
        return { result: [] };
    }


    public createDataSource(resourceName: string = this.resourceName): RestDataSource<ISearch> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<ISearch> {
        return new DataModelValidator<ISearch>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<ISearch>, action: AnyAction): IDataModelState<ISearch> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<ISearch | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public static search(data: IQuery, params: IRestDataSourceParams): Promise<AxiosResponse> {

        const query: IQuery = {
        };

        if (data.stanje != null) { query.stanje = data.stanje }
        if (data.upit != null) { query.upit = data.upit.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "") }
        if (data.opstina != null) { query.opstina = data.opstina }
        if (data.cijena_do != null) { query.cijena_do = data.cijena_do }
        if (data.cijena_od != null) { query.cijena_od = data.cijena_od }
        if (data.tip != null) { query.tip = data.tip }
        if (data.sa_slikama != null) { query.sa_slikama = data.sa_slikama }
        if (data.sa_zamjenom != null) { query.sa_zamjenom = data.sa_zamjenom }
        if (data.sa_cijenom != null) { query.sa_cijenom = data.sa_cijenom }
        if (data.kategorija != null) { query.kategorija = data.kategorija }
        if (data.podkategorija != null) { query.podkategorija = data.podkategorija }

        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + 'search', UserIdentity.getRequestHeaders())
            .plainGetOperation({
                ...query, ...RestParamsQueryBuilder.buildRestParams(params)
            });

        //{pagination: params.paging.pageNumber+","+ params.paging.pageSize}});
    }

    public autocomplete(searchTerm: string): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName + '/autocomplete')
            .plainGetOperation({ s: searchTerm });
    }

    public static toURLParams(obj: unknown): string {
        return Object.keys(obj).map(function (key) {
            return key + '=' + obj[key];
        }).join('&');
    }


    getStoreKey(): string {
        return "SEARCH";
    }

    protected setFromObj(data: ISearch): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._user_id = DataModel.safeGet(data.user_id, this._user_id) as number;
        this._query = DataModel.safeGet(data.query, this._query);
        this._result = DataModel.safeGet(data.result, this._result);
    }


    protected toObj(): ISearch {
        return {
            id: this._id,
            user_id: this._user_id,
            query: this._query,
            result: this._result
        };
    }


    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get user_id() {
        return this._user_id;
    }


    get query() {
        return this._query;
    }


    set query(query: IQuery) {
        this._query = query;
    }


    get result() {
        return this._result;
    }


    set result(result: any) {
        this._result = result;
    }

}
