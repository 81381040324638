import {ValidatorError} from "./errors/ValidatorError";
export interface IDataModelValidator<T> {
    validate(data: T): boolean;

    getErrors(): { [attr: string]: string };

    hasErrors(): boolean;
}

export interface IValidator {
    validate(val: any, formData: any): ValidatorError | boolean;
    getAdditionalParams(): any;
}

export class DataModelValidator<T extends { [key: string]: any }> implements IDataModelValidator<T> {

    protected validationConfig: { [field: string]: IValidator[] };

    protected validatorMessages: { [key: number]: (field: string, additionalValidatorParams: any) => string };

    private errors: { [attr: string]: string } = {};

    public constructor(validationConfig: { [field: string]: IValidator[] },
                       validatorMessages: { [key: number]: (field: string, additionalValidatorParams: any) => string }) {
        this.validationConfig = validationConfig;
        this.validatorMessages = validatorMessages;
    }

    validate(data: T): boolean {
        let result: boolean = true;

        for (const key in this.validationConfig) {
            if (this.validationConfig.hasOwnProperty(key)) {
                const val: any = data[key];

                let fieldValidateResult: ValidatorError | boolean = true;
                let additionalValidationParams: any = {};

                this.validationConfig[key].forEach((validator: IValidator): void => {
                    if (fieldValidateResult === true) {
                        fieldValidateResult = validator.validate(val, data);
                        additionalValidationParams = validator.getAdditionalParams();
                    }
                });

                if (fieldValidateResult !== true) {
                    result = false;

                    if (typeof fieldValidateResult !== 'boolean') {
                        if (this.validatorMessages[fieldValidateResult]) {
                            this.errors[key] =
                                this.validatorMessages[fieldValidateResult](key, additionalValidationParams);
                        } else {
                            this.errors[key] = "Vrijednost nije validna";
                        }
                    }
                } else {
                    delete this.errors[key];
                }
            }
        }

        return result;
    }

    getErrors(): { [attr: string]: string } {
        return this.errors;
    }

    hasErrors(): boolean {
        return (Object.keys(this.errors).length) > 0;
    }
}
