import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IDropdownListItem } from "../../common/components/widgets/form/input/DropdownInput";
import { Municipality } from "./Municipality";
import { IUserIdentityParams } from "../../common/identity/UserIdentity";
import Identity from "../user/Identity";
import { EmailValidator } from "../../common/components/validators/EmailValidator";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";
import { MatchValidator } from "../../common/components/validators/MatchValidator";
import { Role } from "../user/Role";
import { NumberValidator } from "../../common/components/validators/NumberValidator";
import ReactGA from 'react-ga4';
import moment from "moment";

export interface IUser {
    id?: number,
    auth_key?: string,
    username?: string,
    avatar?: string,
    email?: string,
    phone_number?: string,
    municipality_id?: number,
    municipality?: Municipality,
    loan_officer?: unknown,
    loan_officer_id?: number,
    status_id?: number,
    standard_ad_duration?: number,
    standard_printed_ad_duration?: number,
    max_printed_ad_number?: number,
    last_login_at?: Date,
    profile_view_count?: number,
    member_since?: string,
    membership_expires_at?: string,
    verified?: boolean,
    email_verified?: boolean,
    phone_number_verified?: boolean,
    agreement_number?: string,
    visited_at?: string,
    ip_address?: string,
    user_agent?: string,
    user_id?: number
    password?: string
    password_confirmed?: string;
    password_reset_token?: string;
    remember_me?: boolean;
    role?: string;
}


export class User extends DataModel<IUser> {

    private resourceName = "user";
    private resourceNamePlural = "users";

    private _id: number;
    private _status_id: number;
    private _auth_key: string;
    private _username: string;
    private _avatar: string;
    private _email: string;
    private _phone_number: string;
    private _municipality_id: number;
    private _municipality: Municipality;
    private _loan_officer: unknown;
    private _loan_officer_id: number;
    private _standard_ad_duration: number;
    private _standard_printed_ad_duration: number;
    private _max_printed_ad_number: number;
    private _last_login_at: Date;
    private _profile_view_count: number;
    private _member_since: string;
    private _membership_expires_at: string;
    private _verified: boolean;
    private _email_verified: boolean;
    private _phone_number_verified: boolean;
    private _agreement_number: string;
    private _visited_at?: string;
    private _ip_address?: string;
    private _user_agent?: string;
    private _user_id?: number;
    private _password?: string;
    private _password_confirmed?: string;
    private _password_reset_token: string;
    private _remember_me: boolean = false;
    private _role: string;

    public static SCENARIO = {
        LOGIN: 'login',
        VERIFY_CLIENT: 'verify',
        FORGOTTEN_PASSWORD: 'forgottenPassword',
        CHANGE_PASSWORD_EMAIL: 'changePasswordEmail',
        CHANGE_PASSWORD_PHONE: 'changePasswordPhone',
        CHANGE_PASSWORD: 'changePassword',
        REGISTER: 'register',
        CLIENT_UPDATE: "clientUpdate"
    };
    protected getDefaultValues(): IUser {
        return {};
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IUser> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IUser> {
        switch (scenario) {
            case User.SCENARIO.LOGIN:
                return new DataModelValidator<IUser>({
                    username: [new RequiredValidator(), new LengthValidator({ min: 4 })],
                    password: [new RequiredValidator(), new LengthValidator({ min: 7 })]
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            case User.SCENARIO.VERIFY_CLIENT:
                return new DataModelValidator<IUser>({
                    agreement_number: [new RequiredValidator(), new LengthValidator({ min: 4 })],
                    password: [new RequiredValidator(), new LengthValidator({ min: 7 })]
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            case User.SCENARIO.FORGOTTEN_PASSWORD:
                return new DataModelValidator<IUser>({
                    email: [new RequiredValidator(), new EmailValidator()]
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            case User.SCENARIO.CHANGE_PASSWORD_EMAIL:
                return new DataModelValidator<IUser>({
                    email: [
                        new RequiredValidator(),
                        new EmailValidator()
                    ],
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            case User.SCENARIO.CHANGE_PASSWORD_PHONE:
                return new DataModelValidator<IUser>({
                    phone_number: [
                        new RequiredValidator(),
                        new LengthValidator({ min: 11 }),
                        new NumberValidator()
                    ],
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            case User.SCENARIO.CHANGE_PASSWORD:
                return new DataModelValidator<IUser>({
                    password: [
                        new RequiredValidator(),
                        new LengthValidator({
                            min: 7
                        }),
                        new MatchValidator(
                            {
                                toBeMatchedWithCallback: (data: IUser) => {
                                    return data.password_confirmed;
                                },
                                attrName: "Potvrda lozinke"
                            }
                        )
                    ],
                    password_confirmed: [
                        new RequiredValidator(),
                        new LengthValidator({
                            min: 7
                        }),
                        new MatchValidator(
                            {
                                toBeMatchedWithCallback: (data: IUser) => {
                                    return data.password;
                                },
                                attrName: "Lozinka"
                            }
                        )
                    ]
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            case User.SCENARIO.REGISTER:
                return new DataModelValidator<IUser>({
                    username: [new RequiredValidator(), new LengthValidator({ min: 4 })],
                    email: [new RequiredValidator(), new EmailValidator()],
                    password: [new RequiredValidator(), new LengthValidator({ min: 7 })]
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            case User.SCENARIO.CLIENT_UPDATE:
                return new DataModelValidator<IUser>({
                    username: [new RequiredValidator(), new LengthValidator({ min: 4 })],

                    password: [new RequiredValidator(), new LengthValidator({ min: 7 })]
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            default:
                return new DataModelValidator<IUser>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
        }
    }

    protected modelReducer(state: IDataModelState<IUser>, action: AnyAction): IDataModelState<IUser> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource('users').getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListItems(params: IRestDataSourceParams): Promise<IDropdownListItem[]> {
        return this.getListPlain(params)
            .then((response: AxiosResponse) => {
                const users: IUser[] = response.data as IUser[];

                const result: IDropdownListItem[] = [];

                users.forEach((user: IUser) => {
                    result.push({
                        id: user.id,
                        name: user.username
                    });
                });

                return result;
            });
    }

    public subscribe(email: string): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath(`/subscribe?email=${email}`)
            .plainGetOperation({});
    }

    public checkEmail(email: string): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath(`/check-email?email=${email}`)
            .plainGetOperation({});
    }

    public loadById(id: number): Promise<IUser | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public loadByUsername(username: string): Promise<IUser | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + username + "/username")
            .getOperation({});
    }

    public createNew(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .plainPostOperation({
                username: this._username,
                email: this._email,
                avatar: this._avatar ? this._avatar : null,
                phone_number: this._phone_number ? this._phone_number : null,
                municipality_id: this._municipality_id,
                loan_officer_id: this._loan_officer_id
            });
    }

    public newView(id: number, data: any): Promise<AxiosResponse> | void {

        if (Identity.id != id) {
            return this.createDataSource(this.resourceName)
                .addCustomPath('/' + id + "/view")
                .plainPostOperation({
                    visited_at: '' + new Date(),
                    ip_address: data.ip_address,
                    user_agent: data.user_agent,
                    user_id: data.user_id ? data.user_id : null,
                });
        }

    }

    public login(data: IUser): Promise<any> {
        this.setFromPlainObject(data);

        return this.createDataSource(this.resourceName)
            .addCustomPath("/login")
            .plainPostOperation({
                username: data.username,
                password: data.password

            })
            .then((userInfo: any) => {
                if (process.env.REACT_APP_ENV === "prod") {
                    // Slanje eventa Google Analytics-u
                    ReactGA.event({
                        category: 'Korisnik - Login',
                        action: 'Korisnik - Login'
                    });
                }

                if (userInfo.data.auth_key_expires_at) {
                    userInfo.data.auth_key_expires_at = new Date(userInfo.data.auth_key_expires_at);

                }

                if (userInfo.data.item_name) {
                    userInfo.data.role = userInfo.data.item_name;
                } else if (userInfo.data.agreement_number && userInfo.data.membership_expires_at) {

                    if (new Date(userInfo.data.membership_expires_at) > new Date()) {
                        userInfo.data.role = Role.CLIENT;
                    } else {
                        userInfo.data.role = Role.USER;
                    }
                } else {
                    userInfo.data.role = Role.USER;
                }

                return userInfo.data;
            })
            .then((userInfo: IUserIdentityParams | AxiosError) => {

                const userIdentity: IUserIdentityParams = <IUserIdentityParams>userInfo;

                userIdentity.remember_me = this.remember_me;

                if (userIdentity.auth_key) {
                    UserIdentity.setUserIdentity(userIdentity);
                    return userInfo;
                } else {
                    return <AxiosError>userInfo;
                }
            });
    }

    public register(data: IUser): Promise<AxiosError | void> {
        this.setFromPlainObject(data);

        return this.createDataSource(this.resourceName)
            .addCustomPath("/register")
            .plainPostOperation({
                username: data.username,
                password: data.password,
                email: data.email ? data.email : null

            })
            .then((userInfo: any) => {
                if (process.env.REACT_APP_ENV === "prod") {
                    // Slanje eventa Google Analytics-u
                    ReactGA.event({
                        category: 'Korisnik - Registracija',
                        action: 'Korisnik - Registracija'
                    });
                }

                if (userInfo.data.auth_key_expires_at) {
                    userInfo.data.auth_key_expires_at = new Date(userInfo.data.auth_key_expires_at);
                }

                if (userInfo.data.item_name) {
                    userInfo.data.role = userInfo.data.item_name;
                } else if (userInfo.data.agreement_number && userInfo.data.membership_expires_at) {

                    if (new Date(userInfo.data.membership_expires_at) > new Date()) {
                        userInfo.data.role = Role.CLIENT;
                    } else {
                        userInfo.data.role = Role.USER;
                    }
                } else {
                    userInfo.data.role = Role.USER;
                }

                return userInfo.data;
            })
            .then((userInfo: IUserIdentityParams | AxiosError) => {
                const userIdentity: IUserIdentityParams = <IUserIdentityParams>userInfo;

                if (userIdentity.auth_key) {
                    UserIdentity.setUserIdentity(userIdentity);
                } else {
                    return <AxiosError>userInfo;
                }
            });
    }


    public verify(data: IUser): Promise<AxiosError | AxiosResponse> {

        return this.createDataSource(this.resourceName)
            .addCustomPath("/verify")
            .plainPostOperation({
                username: data.agreement_number,
                password: data.password

            })
            .then((userInfo: any | AxiosError) => {
                if (!userInfo.data.auth_key) {
                    return <AxiosError>userInfo;
                } else {
                    return userInfo;
                }
            });
    }

    public verifyData(data: IUser): Promise<AxiosError | AxiosResponse> {
        this.setFromPlainObject(data);

        return this.createDataSource(this.resourceName)
            .addCustomPath("/verify-data/request")
            .plainPostOperation(data)
            .then((userInfo: any | AxiosError) => {
                if (!userInfo.data.auth_key) {
                    return <AxiosError>userInfo;
                } else {
                    return userInfo;
                }
            });
    }

    public verifyToken(token: string): Promise<AxiosError | AxiosResponse> {

        return this.createDataSource(this.resourceName)
            .addCustomPath("/verify-data/check-token/" + token)
            .plainGetOperation({});
    }

    public verifyAgreementNumber(number: string): Promise<AxiosError | AxiosResponse> {

        return this.createDataSource(this.resourceName)
            .addCustomPath("/check-agreement-number/" + number)
            .plainGetOperation({});
    }

    public logout(): Promise<AxiosError | IUser> {
        return this.createDataSource(this.resourceName)
            .addCustomPath("/logout")
            .postOperation({});
    }

    public addFavorite(userId: number | string): Promise<AxiosError | AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath("/favorite-user/" + userId)
            .plainPostOperation({});
    }

    public favorites(): Promise<AxiosError | AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath("/favorite-user")
            .plainGetOperation({});
    }


    public delFavorite(userId: number | string): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath("/favorite-user/" + userId)
            .plainDelOperation();
    }

    public getIpFromServer(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath("/ip")
            .plainGetOperation({});
    }
    public updatePassword(data: IUser): Promise<AxiosResponse> {

        return this.createDataSource(this.resourceName)
            .addCustomPath('/current/change-password')
            .plainPatchOperation({
                password: data.password
            });
    }

    public uploadImage(data: FormData): Promise<AxiosResponse> {

        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + this.resourceName,
            {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + Identity.auth_key
            })
            .addCustomPath('/upload-image')
            .plainPostOperation(data);
    }

    public saveImage(data: IUser): Promise<AxiosResponse> {

        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id + '/save-image')
            .plainPostOperation(data);
    }

    public forgottenPasswordRequest(data: IUser): Promise<AxiosResponse> {

        let body;

        if (data.email) {
            body = { email: data.email };
        } else if (data.phone_number) {
            body = { phone_number: data.phone_number.replace(/\D/g, "") };
        }

        return this.createDataSource(this.resourceName)
            .addCustomPath('/reset-password/request')
            .plainPostOperation(body);
    }

    public resetPassword(token: string): Promise<AxiosResponse> {

        return this.createDataSource(this.resourceName)
            .addCustomPath('/reset-password/set-password')
            .plainPostOperation({
                password: this.password,
                password_reset_token: token
            });
    }

    public forgottenPasswordCheckToken(token: string): Promise<AxiosResponse> {

        return this.createDataSource(this.resourceName)
            .addCustomPath('/reset-password/check-token/' + token)
            .plainGetOperation({});
    }

    public updateClient(data: IUser): Promise<AxiosResponse> {

        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + this.resourceName, {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + data.auth_key
        })
            .addCustomPath('/' + this.id)
            .plainPatchOperation({
                username: data.username,
                password: data.password,
                verified: true,
                verified_at: moment().toISOString()
            });
    }

    public update(data: IUser): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation(data);
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    getStoreKey(): string {
        return "USER";
    }

    protected setFromObj(data: IUser): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._username = DataModel.safeGet(data.username, this._username);
        this._password_confirmed = DataModel.safeGet(data.password_confirmed, this._password_confirmed);
        this._password = DataModel.safeGet(data.password, this._password);
        this._email = DataModel.safeGet(data.email, this._email);
        this._avatar = DataModel.safeGet(data.avatar, this._avatar);
        this._phone_number = DataModel.safeGet(data.phone_number, this._phone_number);
        this._municipality_id = DataModel.safeGet(data.municipality_id, this._municipality_id);
        this._municipality = DataModel.safeGet(data.municipality, this._municipality);
        this._loan_officer_id = DataModel.safeGet(data.loan_officer_id, this._loan_officer_id);
        this._standard_ad_duration = DataModel.safeGet(data.standard_ad_duration, this._standard_ad_duration);
        this._standard_printed_ad_duration = DataModel.safeGet(data.standard_printed_ad_duration, this._standard_printed_ad_duration);
        this._max_printed_ad_number = DataModel.safeGet(data.max_printed_ad_number, this._max_printed_ad_number);
        this._last_login_at = DataModel.safeGet(data.last_login_at, this._last_login_at);
        this._member_since = DataModel.safeGet(data.member_since, this._member_since);
        this._membership_expires_at = DataModel.safeGet(data.membership_expires_at, this._membership_expires_at);
        this._verified = DataModel.safeGet(data.verified, this._verified);
        this._email_verified = DataModel.safeGet(data.email_verified, this._email_verified);
        this._phone_number_verified = DataModel.safeGet(data.phone_number_verified, this._phone_number_verified);
        this._agreement_number = DataModel.safeGet(data.agreement_number, this._agreement_number);
        this._remember_me = DataModel.safeGet(data.remember_me, this._remember_me);
        this._role = DataModel.safeGet(data.role, this._role);
    }


    protected toObj(): IUser {
        return {
            id: this._id,
            username: this._username,
            password: this._password,
            password_confirmed: this._password_confirmed,
            email: this._email,
            avatar: this._avatar,
            phone_number: this._phone_number,
            municipality_id: this._municipality_id,
            municipality: this._municipality,
            loan_officer_id: this._loan_officer_id,
            standard_ad_duration: this._standard_ad_duration,
            standard_printed_ad_duration: this._standard_printed_ad_duration,
            max_printed_ad_number: this._max_printed_ad_number,
            last_login_at: this._last_login_at,
            member_since: this._member_since,
            membership_expires_at: this._membership_expires_at,
            verified: this._verified,
            email_verified: this._email_verified,
            phone_number_verified: this._phone_number_verified,
            agreement_number: this._agreement_number,
            remember_me: this._remember_me,
            role: this._role,
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get username() {
        return this._username;
    }

    get password() {
        return this._password;
    }

    get password_confirmed() {
        return this._password_confirmed;
    }

    get auth_key() {
        return this._auth_key;
    }

    get email() {
        return this._email;
    }


    get avatar() {
        return this._avatar;
    }

    get phone_number() {
        return this._phone_number;
    }

    get municipality_id() {
        return this._municipality_id;
    }

    get municipality() {
        return this._municipality;
    }

    get loan_officer_id() {
        return this._loan_officer_id;
    }

    get standard_ad_duration() {
        return this._standard_ad_duration;
    }

    get standard_printed_ad_duration() {
        return this._standard_printed_ad_duration;
    }

    get last_login_at() {
        return this._last_login_at;
    }

    get max_printed_ad_number() {
        return this._max_printed_ad_number;
    }

    get profile_view_count() {
        return this._profile_view_count;
    }


    get status_id() {
        return this._status_id;
    }

    set status_id(status_id: number) {
        this._status_id = status_id;
    }

    get member_since() {
        return this._member_since;
    }

    get membership_expires_at() {
        return this._membership_expires_at;
    }

    get verified() {
        return this._verified;
    }

    get email_verified() {
        return this._email_verified;
    }

    get phone_number_verified() {
        return this._phone_number_verified;
    }

    get agreement_number() {
        return this._agreement_number;
    }

    get remember_me() {
        return this._remember_me;
    }

    get role() {
        return this._role;
    }

}
