import React, { Component } from 'react';
import Router from './common/components/routing/Router';
import { Container } from "./index";
import { StoreProvider } from './common/model/StoreProvider';
import Identity from './components/user/Identity';


class App extends Component {
  componentDidMount() {

    Identity.setGuestUserIdentity();

    if (typeof window !== 'undefined') {
      // SSE za blokirane korisnike
      if (typeof EventSource !== "undefined" && !Identity.isGuest) {

        const eventSource = new EventSource(process.env.REACT_APP_ADMIN_URL);

        eventSource.addEventListener("blockUser", (e: MessageEvent) => {
          const userId = e.data as number;

          if (userId == Identity.id) {
            Identity.eraseUserIdentity();
            window.location.reload();
          }
        });
      }
    }
  }


  render() {


    return (
        <StoreProvider container={Container}>
          <Router container={Container} />
        </StoreProvider>
    );
  }
}

export default App;
