import React, { Component, Fragment } from 'react';
import { Form, Input, Button, ListGroup, ListGroupItem } from 'reactstrap';
import Icon from '../../custom/Icon';
import { Search as SearchModel } from '../../models/Search';
import { AxiosError, AxiosResponse } from 'axios';
import { Path } from '../../container/Path';
import { Link } from 'react-router-dom';
import * as qs from 'query-string';

interface ISearchState {
    autocompleteResultSet: any;
    searchString: string;
    redirectToSearchPage: boolean;
    focused: boolean
}

class Search extends Component<unknown, ISearchState> {
    private searchModel: SearchModel = new SearchModel();

    constructor(props: any) {
        super(props);

        let searchString: string = "";

        this.debounced = this.debounced.bind(this);
        this.autocomplete = this.debounced(300, this.autocomplete.bind(this));
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.isStartSearchPage = this.isStartSearchPage.bind(this);

        if (typeof window !== 'undefined') {
            if (window.location.search != undefined) {

                const search = qs.parse(window.location.search);
                if (search.upit) {
                    searchString = search.upit as string;
                }
            }
        }

        this.state = {
            autocompleteResultSet: [],
            searchString: searchString,
            redirectToSearchPage: false,
            focused: false
        };

    }

    isStartSearchPage() {
        if (typeof window !== 'undefined') {
            if (window.location.pathname === Path.SEARCH && window.location.search != undefined) {

                const search = qs.parse(window.location.search);
                return search.stranica === "1" && search.upit === this.state.searchString;

            }
        }
        return false;
    }

    debounced(delay, fn) {
        let timerId;
        return function (...args) {
            if (timerId) {
                clearTimeout(timerId);
            }
            timerId = setTimeout(() => {
                fn(...args);
                timerId = null;
            }, delay);
        }
    }

    autocomplete(target: HTMLInputElement) {

        if (target.value.length > 2) {
            this.searchModel.autocomplete(target.value.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")).then((response: AxiosResponse | AxiosError) => {
                const aError: AxiosError = response as AxiosError;
                const resultSet: AxiosResponse = response as AxiosResponse;

                if (!aError.response) {
                    this.setState({ autocompleteResultSet: resultSet.data });
                }
            });
        }
    }

    onSearchChange(e: React.FormEvent<HTMLInputElement>) {

        const target = e.target as HTMLInputElement;

        this.setState({ searchString: target.value });

        this.autocomplete(target);
    }

    onFocus(e: React.FormEvent<HTMLInputElement>) {
        this.setState({
            focused: true
        })

        if (typeof window !== 'undefined') {
            document.getElementById("epk-mobile-login-register").classList.add("epk-mobile-login-register-hide");
            document.getElementById("epk-mobile-search").classList.add("epk-mobile-search-resize");
        }
    }

    onBlur(e: React.FormEvent<HTMLInputElement>) {
        setTimeout(() => {
            this.setState({
                focused: false
            })
            if (typeof window !== 'undefined') {

                document.getElementById("epk-mobile-login-register").classList.remove("epk-mobile-login-register-hide");
                document.getElementById("epk-mobile-search").classList.remove("epk-mobile-search-resize");
            }
        }, 200); // timeout jer jer jer nije moguće otvoriti link
    }

    render() {
        const { focused } = this.state;
        const pathurl = Path.USER_PROFILE;
        const search = Path.SEARCH;

        let content;

        const searchResultCategory = this.state.autocompleteResultSet.category;
        const searchResultSubcategory = this.state.autocompleteResultSet.subcategory;
        const searchResultUser = this.state.autocompleteResultSet.user;

        let searchFindCategory;
        let searchFindSubcategory;
        let searchFindUser;

        if (searchResultCategory === undefined || searchResultCategory.length === 0) {
            searchFindCategory = null;
        } else {
            searchFindCategory = (
                <>
                    <ListGroupItem disabled><Icon name="menu" /> Kategorija</ListGroupItem>
                    {searchResultCategory.map(singleResult => (
                        <ListGroupItem key={singleResult.id} tag="a" href={search + "/" + singleResult.slug + "/" + singleResult.id}>{singleResult.name}</ListGroupItem>
                    ))}
                </>
            )
        }

        if (searchResultSubcategory === undefined || searchResultSubcategory.length === 0) {
            searchFindSubcategory = null;
        } else {
            searchFindSubcategory = (
                <>
                    <ListGroupItem disabled><Icon name="menu" /> Podkategorija</ListGroupItem>
                    {searchResultSubcategory.map((singleResult, index) => (
                        <div key={index + 'single'}>
                            <ListGroupItem style={{ display: "inline" }} key={singleResult.cat_id + 'cat'} tag="a" href={search + "/" + singleResult.slug + "/" + singleResult.cat_id}>{singleResult.cat_name}</ListGroupItem>
                            {">"}
                            <ListGroupItem style={{ display: "inline" }} key={singleResult.id + 'subcat'} tag="a" href={search + "/" + singleResult.slug + "/" + singleResult.cat_id + "?podkategorija=" + singleResult.id}>{singleResult.name}</ListGroupItem>
                        </div>
                    ))}
                </>
            )
        }

        if (searchResultUser === undefined || searchResultUser.length === 0) {
            searchFindUser = null;
        } else {
            searchFindUser = (
                <>
                    <ListGroupItem disabled><Icon name="user" /> Profil</ListGroupItem>
                    {searchResultUser.map(singleResult => (
                        <ListGroupItem key={singleResult.id} tag="a" href={pathurl + "/" + singleResult.id + "/" + singleResult.username}>{singleResult.username}</ListGroupItem>
                    ))}
                </>
            )
        }

        if (searchFindUser !== null || searchFindSubcategory !== null || searchFindCategory !== null) {
            content = (
                <div className="epk-search-find" style={{ display: focused ? 'block' : 'none' }}>
                    <ListGroup>
                        {searchFindCategory}
                        {searchFindSubcategory}
                        {searchFindUser}
                    </ListGroup>
                </div>
            )
        }

        return (
            <Form className="form-inline justify-content-center align-items-center epk-header-search">
                <Input className="epk-search-input" onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.onSearchChange} type="search" placeholder="Pretraga" value={this.state.searchString} ></Input>
                <Link
                    to={{
                        pathname: Path.SEARCH,
                        search: '?stranica=1&upit=' + this.state.searchString

                    }}
                    onClick={() => {
                        if (typeof window !== 'undefined') {
                            this.isStartSearchPage() && typeof window !== 'undefined'
                                ? window.location.reload()
                                : window.location.href = Path.SEARCH + '?stranica=1&upit=' + this.state.searchString
                        }
                    }}
                >
                    <Button color="header-find" type="submit">
                        <Icon name="search" />
                    </Button>
                </Link>
                {content}
            </Form>
        )
    }
}

export default Search;
