import { IValidator } from "./DataModelValidator";
import { ValidatorError } from "./errors/ValidatorError";
import { RequiredValidator } from "./RequiredValidator";

export interface INumberBetweenValidator {
    min?: number | null,
    max?: number | null
}

export class NumberBetweenValidator implements IValidator {

    private min: number | null;
    private max: number | null;

    public constructor(params: INumberBetweenValidator) {
        this.min = params.min ? params.min : null;
        this.max = params.max ? params.max : null;
    }

    public validate(val: string | number): ValidatorError | boolean {
        let result: ValidatorError | boolean = true;

        if (typeof val == "string") {
            val = parseInt(val);
        }

        const requiredValidator: RequiredValidator = new RequiredValidator();
        const valExists: boolean = (requiredValidator.validate(val) === true);

        if (!valExists || !val) {
            return true;
        }

        if (this.min && this.max) {
            result = (this.min <= val && val <= this.max) ? true : ValidatorError.LENGTH_NOT_IN_BETWEEN;
        } else if (this.min) {
            result = (this.min <= val) ? true : ValidatorError.VALUE_TOO_LOW;
        } else if (this.max) {
            result = (val <= this.max) ? true : ValidatorError.VALUE_TOO_LARGE;
        }

        return result;
    }

    public getAdditionalParams(): any {
        if (this.min && this.max) {
            return {
                min: this.min,
                max: this.max
            }
        } else if (this.min) {
            return {
                min: this.min
            }
        } else if (this.max) {
            return {
                max: this.max
            }
        } else {
            return {};
        }
    }
}
