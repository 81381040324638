import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import NewsletterForm from './newsletter/NewsletterForm';

function Newsletter() {

    return (
        <div className="epk-newsletter">
            <Container>
                <div className="epk-newsletter-section">
                    <Row>
                        <Col md="6">
                            <h4 className="newsletter-title">Pretplati se na naš besplatni newsletter</h4>
                            <p>Ako želite BESPLATNO da dobijate elektronsko izdanje publikacije EKI Oglasi prijavite se na naš Newsletter.</p>
                        </Col>
                        <Col md="6" className="align-self-center">
                            <div className="newsletter-form float-md-right">
                                <NewsletterForm />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default Newsletter;