import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import {IAd} from '../../models/Ad';
import Util from '../../custom/Util';
import Icon from '../../custom/Icon';

export default class FavoriteAd extends Component<{ ad: IAd, deleteFromFavorites: any }, unknown> {
    render() {
        let image = "/ad/no-img.jpg";

        if (this.props.ad.ad_images && this.props.ad.ad_images.length != 0) {
            image = (this.props.ad.ad_images[0].image_path ? this.props.ad.ad_images[0].image_path : this.props.ad.ad_images[0] as string);
        }

        return (
            <Col xs="12" md="6" xl="4">
                <div className="epk-ad"   data-id={this.props.ad.id}>
                    <Link  onClick={this.props.deleteFromFavorites} to={"#"} className="remove"><Icon name="trash"/></Link>
                    <Link to={'/oglas/' + this.props.ad.id} className="epk-ad-img">
                        <img src={image} alt={this.props.ad.name}/>
                        <div
                            className="ad-status">{this.props.ad.ad_item_condition ? this.props.ad.ad_item_condition.caption : ''}</div>
                    </Link>
                    <div>
                        <Link to={'/oglas/' + this.props.ad.id + "/" + Util.slug(this.props.ad.name)}>
                            <h5 className="ad-title">{this.props.ad.name}</h5>
                        </Link>
                        <div className="epk-ad-description">
                            <Row>
                                <Col xs="7">
                                    <div
                                        className="ad-price">{this.props.ad.price ? Util.formatMoney(this.props.ad.price) : "Po dogovoru"}</div>
                                </Col>
                                <Col xs="5">
                                    <div
                                        className="ad-date">{this.props.ad.published_at ? Util.formatDatetime(this.props.ad.published_at) : 'Čeka na objavu'}</div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Col>
        )
    }
}

