import React, { Component, ReactEventHandler } from 'react';
import { Form, Alert } from 'reactstrap';
import { User } from "../../../models/User";
import Axios, { AxiosError, AxiosResponse } from "axios";
interface INewsletterFormState {
    value: string;
    message: string;
}

class NewsletterForm extends Component<unknown, INewsletterFormState> {

    private user: User = new User();

    constructor(props: any) {
        super(props);

        this.state = {
            value: '',
            message: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        this.setState({ value: target.value });
        // TODO: možda validacija?
    }

    handleSubmit(event: React.FormEvent<HTMLInputElement>) {
        // ne radi submit
        event.preventDefault();

        // pozovi newsletter api i pošalji email
       
        this.user.subscribe(this.state.value).then(
            (response: AxiosResponse | AxiosError) => {

                const aError: AxiosError = response as AxiosError;
                const result: AxiosResponse = response as AxiosResponse;

                if (!aError.response) {
                    let message = '';
                    if (result['data'].status == 400) {
                        switch(result['data'].title) {
                            case "Member Exists":
                                message = 'Već ste u našoj mailing listi. ';
                                break;
                            case "Invalid Resource":
                                message = 'Molimo unesite ispravnu mail adresu.';
                                break;
                            default:
                                message = 'Desila se greška. Ne možemo vas trenutno dodati na našu mailing listu. ';
                                break;
                        }
                        this.setState({ message: message });
                    } else if (result['data'].status == 'subscribed'){
                        message = 'Uspješno ste prijavljeni na našu mailing listu.'
                        this.setState({ value: '', message: message });
                    }
                }
        });
    
    }


    render() {
        return (
            <Form >
                <div className="input-prepend">
                    <input type="text" className="newsletter-input" value={this.state.value} onChange={this.handleChange} placeholder="vaš@email.com" />
                    <input type="submit" value="Pretplati me" className="btn btn-epk-newsletter" onClick={this.handleSubmit} />
                    <p>{this.state.message}</p>
                </div>
            </Form>
        );
    }
}

export default NewsletterForm;