import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Description from './footer/Description';
import FooterNav from './footer/FooterNav';
import Copyright from './footer/Copyright';
import BackToTop from './footer/BackToTop';
import {Path} from "../container/Path";

export interface IFooterNavLink {
  id: number;
  name: string;
  link: string;
  newTab: boolean;
}

class Footer extends Component {

  navOne: IFooterNavLink[] = [
    {id: 1, name: "EKI Poslovni klub", link: Path.EPK.toString(), newTab: false },
    {id: 2, name: "Registracija članova i korisnika", link: Path.USER_REGISTRATION.toString(), newTab: false },
    {id: 3, name: "Objava oglasa", link: Path.OBJAVA_OGLASA.toString(), newTab: false },
    {id: 4, name: "Uslovi korištenja", link: Path.TERMS.toString(), newTab: false },
  ];

  navTwo: IFooterNavLink[] = [
    {id: 5, name: "RSS", link: "#", newTab: false },
    {id: 6, name: "Sitemap", link: "#", newTab: false },
  ];

  navThree: IFooterNavLink[] = [
    {id: 7, name: "Često postavljana pitanja", link: Path.FAQ.toString(), newTab: false },
    {id: 8, name: "Kontakt", link: Path.CONTACT.toString(), newTab: false },
    {id: 15, name: "Politika privatnosti", link: Path.PRIVACY.toString(), newTab: false }
  ];

  navFour: IFooterNavLink[] = [
    {id: 9, name: "EKI.ba", link: "http://www.eki.ba", newTab: true },
    {id: 10, name: "Facebook", link: "http://www.facebook.com/MKOEKI", newTab: true },
    {id: 11, name: "Viber", link: "https://viber.com/ekimkd", newTab: true },
    {id: 12, name: "Instagram", link: "http://www.instagram.com/mkoeki", newTab: true },
    {id: 13, name: "Twitter", link: "https://twitter.com/MKO_EKI", newTab: true },
    {id: 14, name: "Youtube", link: "https://www.youtube.com/channel/UCjfng88DHlpbEdC-9Q1piqw", newTab: true },
  ];

  render() {
    return (
      <>
        <div className="epk-footer" id="epk-footer">
          <div className="epk-footer-section">
            <Container>
              <Row>
                <Col md="4">
                  <Description /> {/* import Description to Footer */}
                </Col>
                <Col md="3">
                  <FooterNav title="Usluge" links={this.navOne} /> {/* import FooterNav to Footer */}
                </Col>

                <Col md="3">
                  <FooterNav title="Pomoć" links={this.navThree} /> {/* import FooterNav to Footer */}
                </Col>
                <Col md="2">
                  <FooterNav title="Posjetite nas" links={this.navFour} /> {/* import FooterNav to Footer */}
                </Col>
              </Row>
            </Container>
          </div>
          <div className="copy">
            <Container>
              <Row>
                <Col md="12">
                  <Copyright /> {/* import Copyright to Footer */}
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <BackToTop /> {/* import BackToTop to Footer */}
      </>
    );
  }
}

export default Footer;
