import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IDropdownListItem } from "../../common/components/widgets/form/input/DropdownInput";
import { IAdCategory } from "./AdCategory";

export interface IAdSubcategory {
    id?: number,
    name?: string,
    image_path?: string,
    sort_key?: number,
    ad_category_id?: number,
    status_id?: number,
    ad_category?: IAdCategory
}


export class AdSubcategory extends DataModel<IAdSubcategory> {

    public resourceName = "ad-subcategory";
    public resourceNamePlural = "ad-subcategories";

    private _id: number;
    private _name: string;
    private _image_path: string;
    private _sort_key: number;
    private _ad_category_id: number;
    private _status_id: number;
    private _ad_category: IAdCategory;


    protected getDefaultValues(): IAdSubcategory {
        return {
            ad_category: {}
        };
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IAdSubcategory> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IAdSubcategory> {
        return new DataModelValidator<IAdSubcategory>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IAdSubcategory>, action: AnyAction): IDataModelState<IAdSubcategory> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource('ad-subcategories').getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListItems(params: IRestDataSourceParams): Promise<IDropdownListItem[]> {
        return this.getListPlain(params)
            .then((response: AxiosResponse) => {
                const adSCategories: IAdSubcategory[] = response.data as IAdSubcategory[];

                const result: IDropdownListItem[] = [];

                adSCategories.forEach((adSCategory: IAdSubcategory) => {
                    result.push({
                        id: adSCategory.id,
                        name: adSCategory.name
                    });
                });

                return result;
            });
    }

    public loadById(id: number): Promise<IAdSubcategory | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public createNew(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .plainPostOperation({
                name: this._name,
                sort_key: this._sort_key,
                image_path: this._image_path

            });
    }

    public update(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({

            });
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    getStoreKey(): string {
        return "AD_SUBCATEGORY";
    }

    protected setFromObj(data: IAdSubcategory): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._sort_key = DataModel.safeGet(data.sort_key, this._sort_key);
        this._ad_category_id = DataModel.safeGet(data.ad_category_id, this._ad_category_id);
        this._image_path = DataModel.safeGet(data.image_path, this._image_path);
        this._ad_category = DataModel.safeGet(data.ad_category, this._ad_category);
    }


    protected toObj(): IAdSubcategory {
        return {
            id: this._id,
            name: this._name,
            sort_key: this._sort_key,
            ad_category_id: this._ad_category_id,
            image_path: this._image_path,
            ad_category: this._ad_category
        };
    }


    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }


    get sort_key() {
        return this._sort_key;
    }


    get ad_category_id() {
        return this._ad_category_id;
    }

    get ad_category() {
        return this._ad_category;
    }

    get image_path() {
        return this._image_path;
    }


    get status_id() {
        return this._status_id;
    }

    set status_id(status_id: number) {
        this._status_id = status_id;
    }
}
