import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { AxiosError, AxiosResponse } from "axios";
import { IAd } from '../../models/Ad';
import { Search as SearchModel } from "../../models/Search";
import Ad from '../../custom/Ad';
import Icon from '../../custom/Icon';
import { Path } from '../../container/Path';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

export interface ILatestAds {
    searchResults?: IAd[];
    error: string;
    isLoaded: boolean;
    adsNumber: number;
}

class LatestAds extends Component<{ data: any }, ILatestAds> {

    constructor(props) {
        super(props);

        const data = this.props.data || null;

        this.state = {
            error: "",
            isLoaded: false,
            searchResults: [],
            adsNumber: 36,
            ...data
        }
    }

    componentDidMount() {
        SearchModel.search({}, {
            paging: { pageNumber: 1, pageSize: this.state.adsNumber },
            sort: { attr: "id", ascending: false }
        })
            .then((response: AxiosResponse) => {
                this.setState({
                    searchResults: response.data,
                    isLoaded: true
                });
            }).catch((e: AxiosError) => {
                this.setState({
                    error: e.message
                })
            });
    }

    render() {
        const listAdsLoader = []

        for (let i = 1; i <= this.state.adsNumber; i++) {
            listAdsLoader.push(
                <Col xs="12" md="4" xl="3" key={i}>
                    <div className="epk-ad">
                        <div className="epk-ad-img">
                            <div className="category-loader" />
                        </div>
                        <div>
                            <h5 className="epkad-title"><i className="title-loader w100" /><i className="title-loader w100 mt-1" /></h5>
                            <div className="epk-ad-description">
                                <Row>
                                    <Col xs="7">
                                        <div className="epkad-price"><i className="title-loader-small w100" /></div>
                                    </Col>
                                    <Col xs="5">
                                        <div className="epkad-date"><i className="title-loader-small w100" /> </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>
            )
        }

        const { error, isLoaded, searchResults } = this.state;
        let content;

        if (error.length > 0) {
            content = <Col sm="12"> Error: {error}</Col>;
        } else if (!isLoaded) {
            content = <>{listAdsLoader}</>
        } else {
            content = searchResults.map(AdsSingle => (

                <LazyLoad key={AdsSingle.id} height={250} >
                    <Ad {...AdsSingle} col={{ xs: "12", md: "4", xl: "3" }} key={AdsSingle.id} />
                </LazyLoad>
            ))
        }

        return (
            <div className="epk-homeads-list">
                <Container>
                    <div className="epk-homeads-list-section">
                        <Row>
                            {content}
                        </Row>
                        <div className="epk-load-more text-center">
                            {isLoaded ? <Button className="d-inline-block" tag={Link} color="epk-primary" to={Path.SEARCH + '?stranica=3'} ><Icon name="vise" /> Učitaj više</Button> : null}
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default LatestAds;
