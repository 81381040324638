import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IDropdownListItem } from "../../common/components/widgets/form/input/DropdownInput";

export interface IPage {
    id?: number,
    title?: string,
    content?: string
    slug?: string
}


export class Page extends DataModel<IPage> {

    private resourceName = "page";
    private resourceNamePlural = "pages";

    private _id: number;
    private _title: string;
    private _content: string;
    private _slug: string;


    protected getDefaultValues(): IPage {
        return {};
    }


    public createDataSource(resourcetitle: string = this.resourceNamePlural): RestDataSource<IPage> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourcetitle, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IPage> {
        return new DataModelValidator<IPage>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IPage>, action: AnyAction): IDataModelState<IPage> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource('pages').getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListItems(params: IRestDataSourceParams): Promise<IDropdownListItem[]> {
        return this.getListPlain(params)
            .then((response: AxiosResponse) => {
                const pages: IPage[] = response.data as IPage[];

                const result: IDropdownListItem[] = [];

                pages.forEach((page: IPage) => {
                    result.push({
                        id: page.id,
                        name: page.title
                    });
                });

                return result;
            });
    }

    public loadById(id: number): Promise<IPage | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }


    public loadBySlug(slug: string): Promise<IPage | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + slug)
            .getOperation({});
    }


    getStoreKey(): string {
        return "PAGE";
    }

    protected setFromObj(data: IPage): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._title = DataModel.safeGet(data.title, this._title);
        this._content = DataModel.safeGet(data.content, this._content);
        this._slug = DataModel.safeGet(data.slug, this._slug);
    }


    protected toObj(): IPage {
        return {
            id: this._id,
            title: this._title,
            content: this._content,
            slug: this._slug,
        };
    }


    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get title():string {
        return this._title;
    }

    get content():string {
        return this._content;
    }

    get slug():string {
        return this._slug;
    }
}
