import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { IAd } from '../../models/Ad';
import { AdImage } from '../../models/AdImage';
import Helmet from 'react-helmet';

interface IIimages {
    photoIndex: number,
    isOpen: boolean
}

class Images extends Component<{ ad: IAd }, IIimages> {
    constructor(props: any) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };

        this.contentMenu = this.contentMenu.bind(this);

    }

    contentMenu(e) {
        // e.preventDefault();
        e.stopPropagation();
    }

    render() {

        let imagePrefix = "";

        if (typeof window !== 'undefined') {

            imagePrefix = window.location.hostname;
            // disable right click when lightbox state is true
            if (!this.state.isOpen) {
                document.oncontextmenu = function () {
                    return true;
                }
            } else {
                document.oncontextmenu = function () {
                    return false;
                }
            }
        }

        const { photoIndex, isOpen } = this.state;
        const ad = this.props.ad;

        const defaultImage = "/ad/" + ad.ad_category.slug + ".jpg";

        const imagesPath = []

        ad.ad_images.sort(function (a, b) {
            return a.sort_key - b.sort_key;
        }); // poredaj array po sort_key

        ad.ad_images.forEach((image: AdImage, index: number) => {
            if (image.status_id == 1) {
                imagesPath.push(
                    image.image_path
                )
            }
        })

        let images;

        if (imagesPath.length > 0) {
            images = imagesPath
        } else {
            images = [defaultImage]
        }

        return (
            <>
                <Helmet>
                    <meta property="og:image" content={(imagesPath.length > 0 ? imagePrefix + '/med' : '') + images[0]} />
                </Helmet>
                <div className="epk-ad-single-image" onContextMenu={isOpen ? this.contentMenu : null}>
                    <a className="ad-image-link" onClick={() => this.setState({ isOpen: true })}>
                        <img src={(imagesPath.length > 0 ? '/med' : '') + images[0]} className="img-thumbnail epk-image ad-image" alt="Otvori galeriju" onContextMenu={this.contentMenu} />
                        <div className="ad-gallery">Galerija fotografija ({images.length > 0 ? images.length : 1})</div>
                    </a>
                </div>

                {isOpen && (
                    <div id="epk-lightbox">
                        <Lightbox
                            reactModalStyle={{ overlay: { zIndex: '1030' } }}
                            mainSrc={'/med' + images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + images.length - 1) % images.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + 1) % images.length,
                                })
                            }
                        />
                    </div>
                )}
            </>
        );
    }
}

export default Images;

