import React, { Component } from 'react';
import { NavItem } from 'reactstrap';

interface IFontSize {
    handleChangeFont: any
}

class FontSize extends Component<IFontSize, unknown> {
    render() {
        const handleChangeFont = this.props.handleChangeFont;
        return (
            <>
                <NavItem className="small-font" onClick={() => handleChangeFont('0.95rem')}>A</NavItem>
                <NavItem className="medium-font" onClick={() => handleChangeFont('1rem')}>A</NavItem>
                <NavItem className="large-font" onClick={() => handleChangeFont('1.05rem')}>A</NavItem>
            </>
        );
    }
}

export default FontSize;

