import React from "react";
import EpkPage from "./EpkPage";
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, FormText, CustomInput, FormFeedback, Alert } from 'reactstrap';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { IDestroy } from "../../common/components/pages/IDestroy";
import Info from "./register/Info";
import Icon from "../custom/Icon";
import { User, IUser } from "../models/User";
import { TextInput } from "../../common/components/widgets/form/input/TextInput";
import { PasswordInput } from "../../common/components/widgets/form/input/PasswordInput";
import { AxiosError, AxiosResponse } from "axios";
import { EmailInput } from "../../common/components/widgets/form/input/EmailInput";
import Identity from "../user/Identity";
import { withSecurity } from "../../common/security/Security";
import { Role } from "../user/Role";
import { EpkCheckboxInput } from "../custom/EpkCheckboxInput";
import Util from "../custom/Util";
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import { ISocialLogin, SocialLogin } from '../models/SocialLogin';
import ReactGA from 'react-ga4';

@withRouter(Path.REGISTER.toString(), PageContainer)
@withSecurity([Role.GUEST.toString()], Identity, Path.HOMEPAGE)
export default class RegisterPage extends EpkPage {

    private user: User = new User();
    private socialLoginModel: SocialLogin = new SocialLogin();

    private submitted: boolean = false;

    private clientInputs: { [attr: string]: React.RefObject<any> } = {
        agreement_number: React.createRef(),
        password: React.createRef()
    };

    private registerInputs: { [attr: string]: React.RefObject<any> } = {
        username: React.createRef(),
        password: React.createRef(),
        email: React.createRef(),
        agree: React.createRef()
    };

    private updateInputs: { [attr: string]: React.RefObject<any> } = {
        username: React.createRef(),
        password: React.createRef()
    };

    constructor(props: any) {
        super(props);

        this.formDisplay = this.formDisplay.bind(this);
        this.formEpkMember = this.formEpkMember.bind(this);
        this.formRegister = this.formRegister.bind(this);
        this.handleValidateClient = this.handleValidateClient.bind(this);
        this.handleValidateUser = this.handleValidateUser.bind(this);
        this.handleLogIn = this.handleLogIn.bind(this);
        this.updateUserData = this.updateUserData.bind(this);
        this.setDefaultInputs = this.setDefaultInputs.bind(this);

        this.state = {
            isFormEpk: false,
            whichFrom: '',
            user: this.user,
            userDataVerified: false
        }
    }

    pageTitle() {
        return "Registracija" + super.pageTitle();
    }

    getDestroyableMembers(): IDestroy[] {
        return [
            this.user
        ];
    }

    setDefaultInputs(): void {

        this.registerInputs = {
            username: React.createRef(),
            password: React.createRef(),
            email: React.createRef(),
            agree: React.createRef()
        }

        this.clientInputs = {
            agreement_number: React.createRef(),
            password: React.createRef(),
            agree: React.createRef()
        }
    }

    formDisplay(event: React.MouseEvent<HTMLInputElement>) {
        const radio = event.target as HTMLInputElement;
        this.user = new User();

        //ciscenje errora
        if (this.registerInputs.username.current) {
            this.registerInputs.username.current.removeError();
        }
        if (this.clientInputs.agreement_number.current) {
            this.clientInputs.agreement_number.current.removeError();
        }

        this.setDefaultInputs();
        this.submitted = false;

        this.setState({
            whichFrom: radio.id,
            user: this.user
        })
    }

    formEpkMember(event: React.FormEvent<HTMLElement>) {
        if (this.clientInputs.agree.current.getValue()) {
            this.handleLogIn(event, true);
        } else {
            Util.notification("error", "Morate se složiti sa uslovima korištenja", 10000);
            event.preventDefault();
        }
    }

    formRegister(event: React.FormEvent<HTMLElement>) {
        if (this.registerInputs.agree.current.getValue()) {

            this.handleLogIn(event);
        } else {
            Util.notification("error", "Morate se složiti sa uslovima korištenja", 10000);
            event.preventDefault();
        }
    }

    protected handleLogIn(event: React.FormEvent<HTMLElement>, isFormEpk = false): void {
        this.submitted = true;
        let plainObject: IUser;
        let valid: boolean;

        this.user.changeScenario(User.SCENARIO.LOGIN);

        if (isFormEpk) {

            valid = this.handleValidateClient(event);

            if (valid) {

                this.user.verifyAgreementNumber(this.user.agreement_number)
                    .then((response: any) => {

                        if (response.data && response.data.exists) {
                            Util.notification("error", "Broj sporazuma je već iskorišten za registrovanje.", 10000, "bootstrap-v4", "center");
                            return;
                        } else {

                            this.user.verify(this.user)
                                .then((response: AxiosResponse) => {
                                    plainObject = response.data;

                                    this.setState({
                                        isFormEpk: true,
                                        user: plainObject,
                                        userDataVerified: true
                                    })

                                }).catch((error: AxiosError) => {
                                    const aError: AxiosError = error;

                                    if (aError.response.data.errorCode == 1050) {
                                        Util.notification("error", "Vaše članstvo je isteklo. Registrujte se kao korisnik koji nije član EKI poslovnog kluba ili reaktivirajte svoje članstvo.", 10000, "bootstrap-v4", "center");
                                    } else if (aError.response.data.errorCode == 1066) {
                                        Util.notification("error", "Već ste registrovani. <a href=\"" + Path.LOGIN + "\" >Prijavite se </a>", false, "bootstrap-v4", "center");
                                    } else {
                                        switch (aError.response.status) {
                                            case 404:
                                            case 403:
                                                Util.notification("error", "Lozinka i broj sporazuma se ne poklapaju!", 1500);
                                                this.fillInputsWithErrors({
                                                    agreement_number: "Lozinka i broj sporazuma se ne poklapaju!",
                                                    password: "Lozinka i broj sporazuma se ne poklapaju!"
                                                }, this.clientInputs);
                                                break;
                                            case 422:

                                                if (aError.response && aError.response.data && aError.response.data.errors) {
                                                    const respErrors: any[] = aError.response.data.errors;
                                                    const errors: { [attr: string]: string } = {};

                                                    respErrors.forEach((error: any) => {
                                                        if (error.fieldName == "username") {
                                                            errors["agreement_number"] = "Vrijednost nije tačna";
                                                        } else {
                                                            errors[error.fieldName] = error.errorMessage;
                                                        }

                                                    });
                                                    this.fillInputsWithErrors(errors, this.clientInputs);
                                                }

                                                break;
                                            default:
                                        }
                                    }
                                });
                        }
                    })



            }


        } else {
            plainObject = this.readInfoRegister();
            plainObject.verified = true;
            valid = this.handleValidateUser(event);

            if (valid) {

                this.user.checkEmail(this.user.email).then((response) => {

                    if (response.data.exists === false) {
                        //register user

                        this.user.register(plainObject)
                            .then((error: AxiosError | void) => {

                                if (!error) {
                                    if (typeof window !== 'undefined') {
                                        document.location.pathname = Path.HOMEPAGE.toString();
                                    }
                                }

                            }).catch((error: AxiosError) => {

                                const aError: AxiosError = error;

                                if (aError.response) {
                                    switch (aError.response.status) {
                                        case 500:
                                        case 404:
                                            Util.notification("error", "Došlo je do greške na serveru!", 1500);
                                            break;
                                        case 422:

                                            if (aError.response && aError.response.data && aError.response.data.errors) {
                                                const respErrors: any[] = aError.response.data.errors;
                                                const errors: { [attr: string]: string } = {};

                                                respErrors.forEach((error: any) => {

                                                    if (error.fieldName == "username") {
                                                        errors["username"] = "Korisničko ime se već koristi, izaberite drugo.";
                                                    } else {
                                                        errors[error.fieldName] = error.errorMessage;
                                                    }
                                                });

                                                this.fillInputsWithErrors(errors, this.registerInputs);
                                            }

                                            break;
                                        default:
                                    }
                                }
                            });

                    } else {

                        // write error message
                        const errors: { [attr: string]: string } = {};

                        errors["email"] = "Email se već koristi.";


                        this.fillInputsWithErrors(errors, this.registerInputs);
                    }
                })

            }
        }

        event.preventDefault();
    }


    private readInfoClient(): IUser {
        const plainObject: IUser = {};

        for (const key in this.clientInputs) {

            if (this.clientInputs.hasOwnProperty(key) && this.clientInputs[key].current) {
                plainObject[key] = this.clientInputs[key].current.getValue();
                this.clientInputs[key].current.removeError();
            }
        }

        this.user.setFromPlainObject(plainObject);

        return plainObject;
    }


    private readInfoRegister(): IUser {

        const plainObject: IUser = {};

        for (const key in this.registerInputs) {

            if (this.registerInputs.hasOwnProperty(key) && this.registerInputs[key].current) {
                plainObject[key] = this.registerInputs[key].current.getValue();
                this.registerInputs[key].current.removeError();
            }
        }

        this.user.setFromPlainObject(plainObject);

        return plainObject;
    }

    private readInfoClientUpdate(): IUser {

        const plainObject: IUser = {};

        for (const key in this.registerInputs) {

            if (this.updateInputs.hasOwnProperty(key) && this.updateInputs[key].current) {
                plainObject[key] = this.updateInputs[key].current.getValue();
                this.updateInputs[key].current.removeError();
            }
        }

        this.user.setFromPlainObject(plainObject);

        return plainObject;
    }

    private handleValidateClient(event: any): boolean {
        this.readInfoClient();

        //promjena scenarija za verifikaciju klijenta
        this.user.changeScenario(User.SCENARIO.VERIFY_CLIENT);

        const valid = this.user.validate();

        if (this.submitted && !valid) {
            const errors: { [attr: string]: string } = this.user.getErrors();
            this.fillInputsWithErrors(errors, this.clientInputs);
        }

        event.preventDefault();

        return valid;

    }

    private handleValidateClientUpdate(event: any): boolean {
        this.readInfoClientUpdate();

        //promjena scenarija za verifikaciju klijenta
        this.user.changeScenario(User.SCENARIO.CLIENT_UPDATE);

        const valid = this.user.validate();

        if (this.submitted && !valid) {
            const errors: { [attr: string]: string } = this.user.getErrors();
            this.fillInputsWithErrors(errors, this.updateInputs);
        }

        event.preventDefault();

        return valid;

    }

    private handleValidateUser(event: any): boolean {
        this.readInfoRegister();

        //promjena scenarija
        this.user.changeScenario(User.SCENARIO.REGISTER);

        const valid = this.user.validate();

        if (this.submitted) {
            if (!valid) {
                const errors: { [attr: string]: string } = this.user.getErrors();
                this.fillInputsWithErrors(errors, this.registerInputs);

            }
        }

        event.preventDefault();

        return valid;
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

        for (const key in errors) {
            if (errors.hasOwnProperty(key) && inputs[key].current) {
                inputs[key].current.setError(errors[key]);
            }
        }
    }

    protected updateUserData(event: React.FormEvent<HTMLElement>): void {

        const oldPwd = this.user.password;
        this.submitted = true;

        const plainObject: IUser = this.readInfoClientUpdate();
        const valid: boolean = this.handleValidateClientUpdate(event);

        const errors: { [attr: string]: string } = {};

        if (this.user.agreement_number === plainObject.username) {
            errors["username"] = "'Upišite novo korisničko ime različito od pristupnog koda sa Sporazuma o razumijevanju.";
        }
        if (oldPwd === plainObject.password) {
            errors["password"] = "Upišite novu lozinku različitu od lozinike različitu od lozinke sa Sporazuma o razumijevanju.";
        }

        if (errors["password"] || errors["username"]) {
            this.fillInputsWithErrors(errors, this.updateInputs);
        } else {
            const userObject: IUser = { ...this.state.user, ...plainObject };
            this.user.setFromPlainObject(userObject);

            if (valid) {

                this.user.updateClient(userObject)
                    .then((response: AxiosResponse) => {
                        if (process.env.REACT_APP_ENV === "prod") {
                            // Slanje eventa Google Analytics-u
                            ReactGA.event({
                                category: 'Korisnik - Registracija (klijent)',
                                action: 'Korisnik - Registracija (klijent)'
                            });
                        }

                        return response;
                    })
                    .then((response: AxiosResponse) => {

                        this.user.setFromPlainObject(response as IUser);
                        this.user.login(userObject)
                            .then((error: AxiosError | void) => {

                                if (typeof window !== 'undefined') {
                                    document.location.pathname = Path.USER_PROFILE.toString()
                                        + "/" + this.user.id
                                        + "/" + this.user.username
                                        + "/postavke";
                                }

                            })
                    })
                    .catch((error: AxiosError) => {
                        const aError: AxiosError = error;

                        if (aError.response.data.errorCode == 1050) {
                            Util.notification("error", "Vaše članstvo je isteklo. Registrujte se kao korisnik koji nije član EKI poslovnog kluba ili reaktivirajte svoje članstvo.", 10000, "bootstrap-v4", "center");
                        } else if (aError.response.data.errorCode == 1066) {
                            Util.notification("error", "Već ste registrovani. <a href=\"" + Path.LOGIN + "\" >Prijavite se </a>", false, "bootstrap-v4", "center");
                        } else {
                            switch (aError.response.status) {
                                case 404:
                                case 403:
                                    Util.notification("error", "Podaci su pogrešni.", 1500);
                                    break;
                                case 422:

                                    if (aError.response && aError.response.data && aError.response.data.errors) {
                                        const respErrors: any[] = aError.response.data.errors;
                                        const formErrors: { [attr: string]: string } = {};

                                        respErrors.forEach((error: any) => {
                                            if (error.fieldName == "username") {
                                                formErrors["username"] = "Korisničko ime se već koristi, izaberite drugo.";
                                            } else {
                                                formErrors[error.fieldName] = error.errorMessage;
                                            }
                                        });

                                        this.fillInputsWithErrors(formErrors, this.updateInputs);
                                    }

                                    break;
                                default:
                            }
                        }
                    });
            }
        }

    }

    responseFacebook = (response) => {

        if (response) {
            if (process.env.REACT_APP_ENV === "prod") {
                // Slanje eventa Google Analytics-u
                ReactGA.event({
                    category: 'Korisnik - Registracija (FB)',
                    action: 'Korisnik - Registracija (FB)'
                });
            }

            const profile_cache = {
                email: response.email ? response.email : null,
                avatar: response.picture ? response.picture.data.url : null
            }

            const socialLoginParams: ISocialLogin = {
                provider: "facebook",
                username: response.name,
                identifier: response.userID,
                profile_cache: profile_cache
            }

            this.socialLoginModel.setFromPlainObject(socialLoginParams);
            this.socialLoginModel.createNew();

            setTimeout(() => {
                //redirect after login
                if (typeof window !== 'undefined') {
                    document.location.pathname = Path.HOMEPAGE.toString();
                }
            }, 1000)
        } else {
            Util.notification("error", "Desila se greška, prijava nije uspjela.", 1500);
        }
    }

    responseGoogle = (response) => {

        if (!response.error) {
            if (process.env.REACT_APP_ENV === "prod") {
                // Slanje eventa Google Analytics-u
                ReactGA.event({
                    category: 'Korisnik - Registracija (Google)',
                    action: 'Korisnik - Registracija (Google)'
                });
            }

            const profileObj = response.profileObj;

            const profile_cache = {
                email: profileObj.email ? profileObj.email : null,
                avatar: profileObj.imageUrl ? profileObj.imageUrl : null
            }

            const socialLoginParams: ISocialLogin = {
                provider: "google",
                username: response.profileObj.name,
                identifier: response.googleId,
                profile_cache: profile_cache
            }

            this.socialLoginModel.setFromPlainObject(socialLoginParams);
            this.socialLoginModel.createNew();

            setTimeout(() => {
                //redirect after login
                if (typeof window !== 'undefined') {
                    document.location.pathname = Path.HOMEPAGE.toString();
                }
            }, 1000)
        }/* else {
            Util.notification("error", "Desila se greška, prijava nije uspjela.", 1500);
        }*/
    }

    renderContent() {

        let displayFrom;
        if (this.state.whichFrom == 'epkMember' && !this.user.verified) { // Forma za registraciju korisnika koji je član EPK-a
            displayFrom = (
                <>
                    <Alert color="empty" className="bg-primary text-light">
                        Na dokumentu 'Sporazum o razumijevanju', koji ste dobili potpisivanjem ugovora o kreditu u EKI kancelariji, navedeni su vaši pristupni podaci
                    </Alert>
                    <Form onSubmit={this.formEpkMember}>
                        <FormGroup>
                            <Label for="username">Pristupni kod</Label>
                            <TextInput ref={this.clientInputs.agreement_number}
                                onBlur={this.handleValidateClient.bind(this)}

                            />
                            <FormText>Upišite broj pristupnog koda</FormText>
                        </FormGroup>
                        <FormGroup>
                            <Label for="Password">Lozinka</Label>
                            <PasswordInput ref={this.clientInputs.password}
                            />
                            <FormText>Upišite lozinku sa koda</FormText>
                        </FormGroup>
                        <FormGroup>
                            <EpkCheckboxInput ref={this.clientInputs.agree} />{' '}
                        </FormGroup>
                        <div className="submitbox">
                            <Button color="primary" type="submit"  >Potvrdi članstvo</Button>
                        </div>
                    </Form>
                </>
            )
        } else if (this.state.whichFrom == 'Member') { // Forma za registraciju korisnika koji nije član EPK-a
            displayFrom = (
                <>
                    <Form onSubmit={this.formRegister}>
                        <FormGroup>
                            <Label for="User">Korisničko ime</Label>
                            <TextInput
                                ref={this.registerInputs.username}
                                onBlur={this.handleValidateUser.bind(this)}
                                autoComplete="username"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="Email">Email adresa</Label>
                            <EmailInput ref={this.registerInputs.email}
                                onBlur={this.handleValidateUser.bind(this)} />
                            <FormFeedback>Nešto nije uredu.</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="Password">Lozinka</Label>
                            <PasswordInput ref={this.registerInputs.password} autoComplete="new-password" />
                        </FormGroup>
                        <FormGroup>
                            <EpkCheckboxInput ref={this.registerInputs.agree} />{' '}
                        </FormGroup>
                        <div className="submitbox">
                            <Button color="primary" type="submit">Registruj se</Button>

                            <FacebookLogin
                                appId={process.env.REACT_APP_FB_APP_ID}
                                autoLoad={true}
                                size="medium"
                                fields="name,email,picture"
                                textButton=""
                                version="3.2"
                                callback={this.responseFacebook}
                                isMobile={true}
                                disableMobileRedirect={true}
                                redirectUri={process.env.REACT_APP_URL}
                                cssClass="social-button facebook btn btn-social-button"
                                icon={<Icon name="facebook" tabIndex={12} />}
                            />

                            <GoogleLogin
                                autoLoad={false}
                                onAutoLoadFinished={true}
                                clientId={process.env.REACT_APP_GOOGLE_LOGIN_APP_ID}
                                onSuccess={this.responseGoogle}
                                onFailure={this.responseGoogle}
                                buttonText=""
                                style={{}}
                                className="social-button google btn btn-social-button"
                            />

                        </div>
                    </Form>
                </>
            )
        }

        let registerFrom;
        if (!this.state.isFormEpk) { //Početna forma
            registerFrom = (
                <>
                    <div className="epk-member">
                        <h2>Da li ste član EKI Poslovnog kluba?</h2>
                        <FormGroup>
                            <div>
                                <CustomInput onClick={this.formDisplay} type="radio" id="epkMember" name="whichFrom" label="Da, član sam" />
                                <CustomInput onClick={this.formDisplay} type="radio" id="Member" name="whichFrom" label="Ne, nisam član" />
                            </div>
                        </FormGroup>
                    </div>
                    <div className="register-form">
                        {displayFrom}
                    </div>
                </>
            )
        } else if (this.state.isFormEpk || this.state.userDataVerified) { // Forma postavljanje novih podataka

            registerFrom = (
                <>
                    <div className="epk-member">
                        <h2>Uspješno ste verifikovali račun</h2>
                        <p>Podesite Vaše novo Korisničko ime i Lozinku</p>
                    </div>
                    <div className="register-form">
                        <Alert color="empty" className="bg-primary text-light">
                        Na ovom koraku je potrebno da unesete vama pamtljivo korisničko ime i lozinku. To su podaci na osnovu kojih ćete se u budućnosti prijavljivati na stranicu. Samo vas ovaj korak dijeli od postavljanja prvog oglasa.
                        </Alert>
                        <Form onSubmit={this.updateUserData}>
                            <FormGroup>
                                <Label for="username">Korisničko ime</Label>
                                <TextInput placeholder="Korisničko ime" ref={this.updateInputs.username}
                                    onBlur={this.handleValidateClient.bind(this)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="Password">Lozinka</Label>
                                <PasswordInput placeholder="Lozinka" ref={this.updateInputs.password} />
                            </FormGroup>
                            <div className="submitbox">
                                <Button color="primary" >Registruj se</Button>
                            </div>
                        </Form>
                    </div>
                </>
            )
        }

        return (
            <>
                <div className="epk-register-content">
                    <Container>
                        <div className="epk-register-content-section epk-section">
                            <Row>
                                <Col md="12">
                                    <h1 className="epk-container-h1">Registracija</h1>
                                </Col>
                                <Col md="6">
                                    {registerFrom}
                                </Col>
                                <Col md="6">
                                    <Info whichFrom={this.state.whichFrom} />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }
}
