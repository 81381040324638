import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IDropdownListItem } from "../../common/components/widgets/form/input/DropdownInput";
import { IAdCategory } from "./AdCategory";
import { IAdSubcategory } from "./AdSubcategory";
import { IAdItemCondition } from "./AdItemCondition";
import { IAdType } from "./AdType";
import { IMunicipality } from "./Municipality";
import { IUser } from "./User";
import { IAdImage } from "./AdImage";
import Identity from "../user/Identity";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";
import { LengthValidator } from "../../common/components/validators/LengthValidator";
import moment from "moment";
import { NumberBetweenValidator } from "../../common/components/validators/NumberBetweenValidator";
import { Config, IConfig } from "./Config";
import { NumberValidator } from "../../common/components/validators/NumberValidator";
import Util from "../custom/Util";

export interface IAdViewLog {
    ip_address?: string,
    user_agent?: string
}

export interface IAd {
    id?: number,
    name?: string,
    short_desc?: string,
    description?: string,
    status_id?: number,
    exchange_enabled?: boolean,
    exchange_description?: string,
    price?: number,
    municipality?: IMunicipality,
    municipality_id?: number,
    municipality_code?: number,
    municipality_name?: string,
    ad_item_condition?: IAdItemCondition,
    ad_item_condition_id?: number,
    ad_subcategory?: IAdSubcategory,
    ad_subcategory_id?: number,
    ad_category_slug?: string,
    ad_category?: IAdCategory,
    ad_category_id?: number,
    ad_status_id?: number,
    published_at?: string,
    created_at?: string,
    expires_at?: string | number,
    printed_expires_at?: string | number,
    ad_type?: IAdType, //prodaja, usluga ...
    ad_type_id?: number,
    view_count?: number,
    printed?: boolean,
    printed_phone_num?: string,
    user?: IUser,
    user_id?: number,
    ad_duration?: number,// za koliko vremena ističe oglas npr. 1 mjesec
    printed_ad_duration?: number,// za koliko vremena ističe štampani oglas npr. 1 mjesec
    ad_images?: IAdImage[],
    ad_view_log?: IAdViewLog,
    closed_at?: string,
    updated_at?: string,
    closed_by?: string | number,
    created_by?: string | number,
    agreement_number?: string | number,
}

export class Ad extends DataModel<IAd> {


    public resourceName = "oglas";
    public resourceNamePlural = "oglasi";

    private _id: number;
    private _name: string;
    private _short_desc: string;
    private _description: string;
    private _status_id: number;
    private _exchange_enabled: boolean = false;
    private _exchange_description: string;
    private _price: number;
    private _municipality: IMunicipality;
    private _municipality_id: number;
    private _municipality_name: string;
    private _ad_item_condition: IAdItemCondition;
    private _ad_item_condition_id: number;
    private _ad_subcategory: IAdSubcategory;
    private _ad_subcategory_id: number;
    private _ad_category_slug: string;
    private _ad_category: IAdCategory;
    private _ad_category_id: number;
    private _ad_status_id: number;
    private _published_at: string;
    private _created_at: string;
    private _updated_at: string;
    private _closed_at: string;
    private _closed_by: string | number;
    private _created_by: string | number;
    private _expires_at: string | number; // za koliko vremena ističe oglas npr. 1 mjesec
    private _printed_expires_at: string | number;
    private _ad_type: IAdType; //prodaja, usluga ...
    private _ad_type_id: number;
    private _view_count: number;
    private _printed: boolean = false;
    private _printed_phone_num: string;
    private _user: IUser;
    private _user_id: number;
    private _ad_images: IAdImage[];
    private _ad_view_log: IAdViewLog;
    private _agreement_number: string | number;

    private _ad_duration: number;
    private _printed_ad_duration: number;

    public static SCENARIO = {
        CREATE1_LOAN_OFFICER: 'create1_loan_officer',
        CREATE1: 'create1',
        CREATE2: 'create2',
        CREATE3: 'create3',
        CREATE3_PHONE_REQUIRED: 'create3_phone_req',
        //Ista pravila vrijede i za update oglasa, pa se koriste isti scenariji
    };

    protected getDefaultValues(): IAd {
        return {
            municipality: {},
            ad_item_condition: {},
            ad_subcategory: {
                ad_category: {}
            },
            ad_category: {},
            ad_type: {},
            user: {},
            ad_images: []
        };
    }

    public createDataSource(resourceName: string = this.resourceName): RestDataSource<IAd> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }


    protected createValidator(scenario: string): IDataModelValidator<IAd> {

        switch (scenario) {
            case Ad.SCENARIO.CREATE1:
                return new DataModelValidator<IAd>({
                    name: [new RequiredValidator(), new LengthValidator({ min: 2 })],
                    ad_type_id: [new RequiredValidator()],
                    ad_subcategory_id: [new RequiredValidator()],
                    municipality_id: [new RequiredValidator()],
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            case Ad.SCENARIO.CREATE1_LOAN_OFFICER:
                return new DataModelValidator<IAd>({
                    user_id: [new RequiredValidator()],
                    name: [new RequiredValidator(), new LengthValidator({ min: 2 })],
                    ad_type_id: [new RequiredValidator()],
                    ad_subcategory_id: [new RequiredValidator()],
                    municipality_id: [new RequiredValidator()],
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            case Ad.SCENARIO.CREATE2:
                return new DataModelValidator<IAd>({
                    short_desc: [new RequiredValidator(), new LengthValidator({ min: 20, max: 170 })],
                    description: [new LengthValidator({ max: 1000 })],
                    exchange_enabled: [new RequiredValidator()],
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            case Ad.SCENARIO.CREATE3:
                return new DataModelValidator<IAd>({
                    ad_duration: [new NumberBetweenValidator({ min: 0, max: 12 })],
                    printed_ad_duration: [new NumberBetweenValidator({ min: 0, max: 3 })],
                    printed_phone_num: [
                        new LengthValidator({ min: 11 }),
                        new NumberValidator()
                    ],
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            case Ad.SCENARIO.CREATE3_PHONE_REQUIRED:
                return new DataModelValidator<IAd>({
                    ad_duration: [new NumberBetweenValidator({ min: 0, max: 12 })],
                    printed_ad_duration: [new NumberBetweenValidator({ min: 0, max: 3 })],
                    printed_phone_num: [
                        new RequiredValidator(),
                        new LengthValidator({ min: 11 }),
                        new NumberValidator()
                    ],
                }, ErrorMessages.getDefaultErrorMessageCallbacks());
            default:
                return new DataModelValidator<IAd>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
        }
    }

    protected modelReducer(state: IDataModelState<IAd>, action: AnyAction): IDataModelState<IAd> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListItems(params: IRestDataSourceParams): Promise<IDropdownListItem[]> {
        return this.getListPlain(params)
            .then((response: AxiosResponse) => {
                const ads: IAd[] = response.data as IAd[];

                const result: IDropdownListItem[] = [];

                ads.forEach((ad: IAd) => {
                    result.push({
                        id: ad.id,
                        name: ad.name
                    });
                });

                return result;
            });
    }

    public loadById(id: number): Promise<IAd | AxiosError> {
        return this.createDataSource()
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public closeAdRequest(id: number): Promise<any> {
        return this.createDataSource()
            .addCustomPath('/close-request/' + id)
            .plainGetOperation({});
    }

    public getCloseAdRequest(id: number): Promise<any> {
        return this.createDataSource()
            .addCustomPath('/close-requests/' + id)
            .getListPlain({});
    }


    public increaseViewCount(id: number): Promise<IAd | AxiosError> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + this.resourceName, UserIdentity.getRequestHeaders())
            .addCustomPath('/' + id + '/view')
            .postOperation({
                user_agent: Identity.user_agent,
                ip_address: Identity.ip_address
            });
    }

    public createNew(): Promise<AxiosResponse> {

        const shortDesc = Util.endSentence(this._short_desc);
        const data: IAd = {
            name: this._name,
            short_desc: shortDesc,
            description: this._description ? this._description : null,
            ad_item_condition_id: this._ad_item_condition_id ? Number(this._ad_item_condition_id) : null,
            ad_type_id: Number(this._ad_type_id),
            ad_subcategory_id: Number(this._ad_subcategory_id),
            ad_category_slug: this._ad_category_slug ? this._ad_category_slug : null,
            exchange_enabled: this._exchange_enabled,
            exchange_description: this._exchange_description ? this._exchange_description : null,
            expires_at: this._ad_duration ? new Date((new Date()).setMonth((new Date()).getMonth() + parseInt(this._ad_duration.toString()))).toISOString() : null,
            price: this._price ? Number(this._price) : null,
            municipality_id: this._municipality_id ? Number(this._municipality_id) : null,
            printed: this._printed
        };

        if (this._agreement_number !== undefined && this._agreement_number !== null && this._agreement_number.length > 0) {
            data.agreement_number = this._agreement_number;
        }

        if (this._user_id) {
            data.user_id = this._user_id;
        }

        if (this._printed == true) {
            data.short_desc += " " + this._municipality_name + ";" + Util.formatPhoneForShortDesc(this._printed_phone_num);
            data.printed_expires_at = this._printed_ad_duration ? new Date((new Date()).setMonth((new Date()).getMonth() + parseInt(this._printed_ad_duration.toString()))).toISOString() : null;
        }

        return this.createDataSource(this.resourceName)
            .plainPostOperation(data);
    }

    public update(data: IAd): Promise<AxiosResponse> {

        const dataToSend: IAd = {
            name: this._name,
            short_desc: this._short_desc,
            description: this._description ? this._description : null,
            ad_item_condition_id: this._ad_item_condition_id ? Number(this._ad_item_condition_id) : null,
            ad_type_id: Number(this._ad_type_id),
            ad_subcategory_id: Number(this._ad_subcategory_id),
            ad_category_slug: this._ad_category_slug ? this._ad_category_slug : null,
            exchange_enabled: this._exchange_enabled,
            exchange_description: this._exchange_description ? this._exchange_description : null,
            expires_at: this._ad_duration ? new Date((new Date()).setMonth((new Date()).getMonth() + parseInt(this._ad_duration.toString()))).toISOString() : null,
            printed_expires_at: this._printed_ad_duration ? new Date((new Date()).setMonth((new Date()).getMonth() + parseInt(this._printed_ad_duration.toString()))).toISOString() : null,
            price: this._price ? Number(this._price) : null,
            municipality_id: this._municipality_id ? Number(this._municipality_id) : null,
            printed: this._printed
        }

        if (this._agreement_number !== undefined && this._agreement_number !== null && this._agreement_number.length > 0) {
            data.agreement_number = this._agreement_number;
        }

        if (this._user_id) {
            dataToSend.user_id = this._user_id;
        }


        if (this._printed) {
            dataToSend.printed_expires_at = this._printed_ad_duration ? new Date((new Date()).setMonth((new Date()).getMonth() + parseInt(this._printed_ad_duration.toString()))).toISOString() : null;

            if (this._ad_status_id == 3) {

                if (!dataToSend.short_desc.includes(this._municipality_name)) {
                    dataToSend.short_desc += " " + this._municipality_name
                }

                if (!dataToSend.short_desc.includes(";" + Util.formatPhoneForShortDesc(this._printed_phone_num))) {
                    dataToSend.short_desc += ";" + Util.formatPhoneForShortDesc(this._printed_phone_num);
                }
            }
        }

        return this.createDataSource()
            .addCustomPath('/' + this.id)
            .plainPatchOperation(dataToSend);
    }


    public updateClosed(data: IAd): Promise<AxiosResponse> {

        return this.createDataSource()
            .addCustomPath('/' + data.id)
            .plainPatchOperation(data);
    }

    public addFavorite(adId: number | string): Promise<AxiosError | AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath("/favorite-ad/" + adId)
            .plainPostOperation({});
    }

    public favorites(): Promise<AxiosError | AxiosResponse> {
        return this.createDataSource("favorite-ads")
            .plainGetOperation({});
    }

    public delFavorite(adId: number | string): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath("/favorite-ad/" + adId)
            .plainDelOperation();
    }

    public del(id: number): Promise<void> {
        return this.createDataSource()
            .addCustomPath('/' + id)
            .plainDelOperation();
    }

    public uploadImages(data: FormData): Promise<AxiosResponse> {

        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + this.resourceName,
            {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + Identity.auth_key
            })
            .addCustomPath('/upload-images')
            .plainPostOperation(data);
    }

    public deleteImage(image_id: string): Promise<void> {

        return this.createDataSource(this.resourceName)
            .addCustomPath("/" + this.id + '/ad-image/' + image_id)
            .plainDelOperation();
    }


    getStoreKey(): string {
        return "AD";
    }

    protected setFromObj(data: IAd): void {

        const subcategory = data.ad_subcategory || this.ad_subcategory;
        const category = subcategory ? subcategory.ad_category : (data.ad_category || this.ad_category)


        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._name = DataModel.safeGet(data.name, this._name);
        this._short_desc = DataModel.safeGet(data.short_desc, this._short_desc);
        this._description = DataModel.safeGet(data.description, this._description);
        this._ad_item_condition = DataModel.safeGet(data.ad_item_condition, this._ad_item_condition);
        this._ad_item_condition_id = DataModel.safeGet(data.ad_item_condition_id, this._ad_item_condition_id);
        this._ad_type = DataModel.safeGet(data.ad_type, this._ad_type);
        this._ad_type_id = DataModel.safeGet(data.ad_type_id, this._ad_type_id);
        this._ad_subcategory = DataModel.safeGet(data.ad_subcategory, this._ad_subcategory);
        this._ad_subcategory_id = DataModel.safeGet(data.ad_subcategory_id, this._ad_subcategory_id);
        this._ad_category_slug = DataModel.safeGet(data.ad_category_slug, this._ad_category_slug);
        this._ad_images = DataModel.safeGet(data.ad_images, this._ad_images);
        this._ad_category = category;
        this._ad_category_id = category ? category.id : null;
        this._ad_status_id = DataModel.safeGet(data.ad_status_id, this._ad_status_id);
        this._ad_images = DataModel.safeGet(data.ad_images, this._ad_images);
        this._exchange_enabled = DataModel.safeGet(data.exchange_enabled, this._exchange_enabled);
        this._exchange_description = DataModel.safeGet(data.exchange_description, this._exchange_description);
        this._published_at = DataModel.safeGet(data.published_at, this._published_at);
        this._price = DataModel.safeGet(data.price, this._price);
        this._printed = DataModel.safeGet(data.printed, this._printed);
        this._created_at = DataModel.safeGet(data.created_at, this._created_at);
        this._updated_at = DataModel.safeGet(data.updated_at, this._updated_at);
        this._printed_expires_at = DataModel.safeGet(data.printed_expires_at, this._printed_expires_at);
        this._expires_at = DataModel.safeGet(data.expires_at, this._expires_at);
        this._municipality_id = DataModel.safeGet(data.municipality_id, this._municipality_id);
        this._municipality_name = DataModel.safeGet(data.municipality_name, this._municipality_name);
        this._municipality = DataModel.safeGet(data.municipality, this._municipality);
        this._view_count = DataModel.safeGet(data.view_count, this._view_count);
        this._user = DataModel.safeGet(data.user, this._user);
        this._user_id = DataModel.safeGet(data.user_id, this._user_id);
        this._closed_at = DataModel.safeGet(data.closed_at, this._closed_at);
        this._closed_by = DataModel.safeGet(data.closed_by, this._closed_by);
        this._created_by = DataModel.safeGet(data.created_by, this._created_by);
        this._printed_phone_num = DataModel.safeGet(data.printed_phone_num, this._printed_phone_num);
        this._status_id = DataModel.safeGet(data.status_id, this._status_id);
        this._ad_duration = DataModel.safeGet(data.ad_duration, this._ad_duration);
        this._printed_ad_duration = DataModel.safeGet(data.printed_ad_duration, this._printed_ad_duration);
        this._agreement_number = DataModel.safeGet(data.agreement_number, this._agreement_number);
    }


    protected toObj(): IAd {
        return {
            id: this._id,
            name: this._name,
            short_desc: this._short_desc,
            description: this._description,
            ad_item_condition: this._ad_item_condition,
            ad_item_condition_id: this._ad_item_condition_id,
            ad_type: this._ad_type,
            ad_type_id: this._ad_type_id,
            ad_subcategory: this._ad_subcategory,
            ad_subcategory_id: this._ad_subcategory_id,
            ad_category_slug: this._ad_category_slug,
            ad_category: this._ad_subcategory ? this._ad_subcategory.ad_category : null,
            ad_category_id: this._ad_subcategory ? this._ad_subcategory.ad_category.id : this._ad_category_id,
            ad_status_id: this._ad_status_id,
            ad_images: this._ad_images,
            exchange_enabled: this._exchange_enabled,
            exchange_description: this._exchange_description,
            published_at: this._published_at,
            price: this._price,
            printed: this._printed,
            created_at: this._created_at,
            updated_at: this._updated_at,
            printed_expires_at: this._printed_expires_at,
            expires_at: this._expires_at,
            municipality: this._municipality,
            municipality_id: this._municipality_id,
            municipality_name: this._municipality_name,
            view_count: this._view_count,
            user: this._user,
            user_id: this._user_id,
            status_id: this._status_id,
            closed_at: this._closed_at,
            closed_by: this._closed_by,
            created_by: this._created_by,
            printed_phone_num: this._printed_phone_num,
            ad_duration: Number(this._expires_at),
            printed_ad_duration: Number(this._printed_expires_at),
            agreement_number: this._agreement_number,
        };
    }


    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get name() {
        return this._name;
    }

    get ad_duration() {
        return this._ad_duration;
    }

    set ad_duration(duration: any) {
        this._ad_duration = duration;
    }

    get printed_ad_duration() {
        return this._printed_ad_duration;
    }

    set printed_ad_duration(duration: any) {
        this._printed_ad_duration = duration;
    }

    get short_desc() {
        return this._short_desc;
    }

    set short_desc(desc: any) {
        this._short_desc = desc;
    }


    get description() {
        return this._description;
    }

    get agreement_number() {
        return this._agreement_number;
    }

    set agreement_number(agreement_number: string | number) {
        this._agreement_number = agreement_number;
    }


    get status_id() {
        return this._status_id;
    }

    set status_id(status_id: number) {
        this._status_id = status_id;
    }


    get exchange_enabled() {
        return this._exchange_enabled;
    }


    get exchange_description() {
        return this._exchange_description;
    }

    get price() {
        return this._price;
    }

    get municipality() {
        return this._municipality;
    }

    get ad_item_condition() {
        return this._ad_item_condition;
    }

    get ad_subcategory() {
        return this._ad_subcategory;
    }

    get ad_category() {
        return this._ad_subcategory ? this._ad_subcategory.ad_category : this._ad_category;
    }

    get municipality_id() {
        return this._municipality_id;
    }

    get ad_item_condition_id() {

        if (this._ad_item_condition) {
            return this._ad_item_condition.id
        }
        else {
            return this._ad_item_condition_id
        }
    }

    get ad_subcategory_id() {
        return this._ad_subcategory ? this._ad_subcategory.id : this._ad_subcategory_id;
    }

    get ad_category_slug() {
        return this._ad_category ? this._ad_category.slug : this._ad_category_slug;
    }

    get ad_category_id() {
        return this._ad_category ? this._ad_category.id : (this.ad_subcategory ? this.ad_subcategory.ad_category.id : this._ad_category_id);
    }

    get published_at() {
        return this._published_at;
    }

    get created_at() {
        return this._created_at;
    }

    get updated_at() {
        return this._updated_at;
    }

    get expires_at() {
        return this._expires_at;
    }

    set expires_at(expires_at: any) {
        this._expires_at = expires_at;
    }

    get printed() {
        return this._printed;
    }

    get printed_expires_at() {
        return this._printed_expires_at;
    }


    set printed_expires_at(printed_expires_at: any) {
        this._printed_expires_at = printed_expires_at;
    }

    get view_count() {
        return this._view_count;
    }

    get ad_type() {
        return this._ad_type;
    }

    get ad_type_id() {
        return this._ad_type_id;
    }

    get user() {
        return this._user;
    }

    get user_id() {
        return this._user_id;
    }

    set user_id(user_id: number) {
        this._user_id = user_id;
    }

    get ad_images() {
        return this._ad_images;
    }

    get ad_status_id() {
        return this._ad_status_id;
    }

    get closed_at() {
        return this._closed_at;
    }

    get closed_by() {
        return this._closed_by;
    }

    get created_by() {
        return this._created_by;
    }

    set municipality_name(municipality_name: any) {
        this._municipality_name = municipality_name;
    }
}
