import React, { Component, Fragment } from 'react';
import { Row, Col, Pagination, PaginationItem, PaginationLink, Alert, Spinner } from 'reactstrap';
import Ad from "../../custom/Ad";
import { IAd } from "../../models/Ad";
import { Search as SearchModel, IQuery } from "../../models/Search";
import { AxiosError, AxiosResponse } from 'axios';
import * as qs from 'query-string';
import CatBanner from '../../pages/categories/CatBanner';
import { IBanner, Banner } from '../../models/Banner';
import { IRestDataSourceParams } from '../../../common/dataSource/IRestDataSourceParams';
import Util from '../../custom/Util';

interface ISearchResultState {
    collapse: boolean;
    search?: IQuery,
    searchResults?: IAd[];
    error: string;
    isLoaded: boolean;
    currentPage: number;
    pageSize: number;
    maxPage: number;
    banners: IBanner[];
}


class SearchResult extends Component<{ search: IQuery, bannerBreakpoint: number }, ISearchResultState> {

    private bannerModel: Banner = new Banner();

    constructor(props: any) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.handlePages = this.handlePages.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.getSearchResults = this.getSearchResults.bind(this);

        this.state = {
            collapse: true,
            error: "",
            isLoaded: false,
            search: props.search,
            searchResults: [],
            currentPage: 1,
            pageSize: 18, // broj prikazanih oglasa na jednoj stranici
            maxPage: 1,
            banners: []
        };

        if (typeof window !== 'undefined') {
            const search = qs.parse(location.search) as any;
            this.getSearchResults(this.state.search, search.stranica);
        }
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    getSearchResults(query: IQuery, pageNumber: number = 1) {

        if (this.props.search.kategorija !== null) {
            query.kategorija = this.props.search.kategorija
        }


        if (this.props.search.upit) {
            if (typeof window !== 'undefined') {
                query.upit = qs.parse(document.location.search).upit as string;
            }
        }

        //paginacija u url-u - mijenja se broj stranice bez reloada page-a
        if (pageNumber != undefined) {
            if (location.search != undefined) {
                const search = qs.parse(location.search) as any;

                if (search.stranica != undefined) {
                    search.stranica = pageNumber;
                    history.replaceState({}, "", location.pathname + "?" + qs.stringify(search));
                }
            }
        }


        const params: IRestDataSourceParams = { paging: { pageNumber: pageNumber, pageSize: this.state.pageSize } }

        SearchModel.search(query, params).then((response: AxiosResponse | AxiosError) => {
            const aError: AxiosError = response as AxiosError;
            const resultSet: AxiosResponse = response as AxiosResponse;

            if (!aError.response && resultSet.data != undefined) {
                const resultCount = resultSet.headers["x-pagination-itemscount"] != undefined
                    ? Math.ceil(resultSet.headers["x-pagination-itemscount"] / this.state.pageSize)
                    : (Math.ceil(resultSet.data.length / this.state.pageSize) || 0);

                this.setState({
                    search: query,
                    isLoaded: true,
                    searchResults: resultSet.data,
                    currentPage: Number(pageNumber),
                    maxPage: resultCount
                });

                const queryParams = { ...query };
                //Ako je odabrana kategorija, poništava se trenutni filter, dodaje se broj stranice
                if (query.kategorija) {

                    delete queryParams.kategorija;
                    queryParams.stranica = pageNumber;

                    if (typeof window !== 'undefined') {
                        if (Object.keys(queryParams).length !== 0 && document.location.search !== '?' + qs.stringify(queryParams)) {
                            history.replaceState({}, "", location.pathname + "?" + qs.stringify(queryParams));
                        }
                    }
                } else {

                    // Upoređuje se prethodnop stanje i stanje sa novim filterom
                    // reload po potrebi
                    if (typeof window !== 'undefined') {
                        if (document.location.search) {
                            const search = qs.parse(document.location.search) as any;
                            delete search.stranica;
                            delete query.stranica;

                            if (qs.stringify(search) !== qs.stringify(query)) {
                                query.stranica = pageNumber;
                                history.replaceState({}, "", location.pathname + "?" + qs.stringify(queryParams));
                            }
                        }
                    }
                }

                Util.topFunction();
            }
        });


    }

    componentDidMount() {

        if (this.props.search.kategorija) {

            this.bannerModel.loadByCategoryId(this.props.search.kategorija)
                .then((response: IBanner[] | AxiosError) => {

                    const aError: AxiosError = response as AxiosError;
                    const banners: IBanner[] = response as IBanner[];

                    if (!aError.response) {

                        this.setState({
                            banners
                        });
                    }

                }).catch((error: AxiosError) => {
                    console.log(error);
                });

        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.search !== this.state.search) {
            this.getSearchResults(nextProps.search);
        }
    }

    handlePages(event) {
        const el = event.target as HTMLElement;
        const newPage = el.getAttribute('data-page') || '1';

        this.getSearchResults(this.state.search, parseInt(newPage, 10));
    }

    handleNextPage() {
        if (this.state.currentPage < this.state.maxPage) {
            const nextPage = this.state.currentPage + 1;

            this.getSearchResults(this.state.search, nextPage);
        }
    }

    handlePreviousPage() {
        if (this.state.currentPage <= this.state.maxPage) {
            const previousPage = this.state.currentPage - 1;

            this.getSearchResults(this.state.search, previousPage);
        }
    }

    range(start, count) { return Array.apply(0, Array(count)).map(function (element, index) { return index + start; }); }

    render() {
        const { error, isLoaded, searchResults, banners } = this.state;
        const pageNumbers = this.range(1, this.state.maxPage);

        const banner = (banners.length >= this.state.currentPage) ? banners[this.state.currentPage - 1] : null;

        let content;
        if (error.length > 0) {
            content = <Col sm="12"> Error: {error}</Col>;
        } else if (!isLoaded) {
            content =
                <Col sm="12">
                    <Alert color="empty">
                        <Spinner style={{ width: '1rem', height: '1rem' }} color="primary" type="grow" /> Učitavanje oglasa
                    </Alert>
                </Col>;
        } else if (searchResults.length === 0) {
            content =
                <Col sm="12">
                    <Alert color="empty">
                        Nije pronađeno ništa za vašu pretragu
                    </Alert>
                </Col>;
        } else {
            content = (
                <>

                    {searchResults.map((ad, index) => (
                        <Fragment key={ad.id}>
                            {(index == this.props.bannerBreakpoint && banner != null && this.props.search.kategorija != undefined) ?

                                <Col xs="12">
                                    <CatBanner banner={banner} />
                                </Col>

                                : null}
                            <Ad {...ad} />
                        </Fragment>
                    ))}

                    <Pagination className="col-12 epk-pagination" aria-label="Navigacija pretrage">
                        <PaginationItem disabled={this.state.currentPage === 1}>
                            <PaginationLink previous onClick={this.handlePreviousPage} />
                        </PaginationItem>

                        {this.state.currentPage > 3 ? (
                            <PaginationItem active={1 === this.state.currentPage}>
                                <PaginationLink data-page="1" onClick={this.handlePages}>
                                    Prva
                                </PaginationLink>
                            </PaginationItem>
                        ) : (null)}

                        {pageNumbers.slice(Math.max(0, this.state.currentPage - 3), Math.min(this.state.maxPage, this.state.currentPage + 2)).map(pageNumber => (
                            <PaginationItem active={pageNumber === this.state.currentPage} key={pageNumber} >
                                <PaginationLink data-page={pageNumber} onClick={this.handlePages}>
                                    {pageNumber}
                                </PaginationLink>
                            </PaginationItem>
                        ))}

                        {this.state.currentPage < this.state.maxPage - 2 ? (
                            <PaginationItem active={this.state.maxPage === this.state.currentPage}>
                                <PaginationLink data-page={this.state.maxPage} onClick={this.handlePages}>
                                    Zadnja
                                </PaginationLink>
                            </PaginationItem>
                        ) : (null)}

                        <PaginationItem disabled={this.state.currentPage === this.state.maxPage}>
                            <PaginationLink next onClick={this.handleNextPage} />
                        </PaginationItem>
                    </Pagination>

                </>
            )
        }

        return (

            <Row>
                {content}
            </Row>
        );
    }
}

export default SearchResult;
