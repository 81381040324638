import React from "react";
import EpkPage from "./EpkPage";
import { Container, Row, Col, Button, Form, FormGroup, Label, FormText, InputGroup, Progress, ListGroup, ListGroupItem, Collapse, Card, CardBody } from 'reactstrap';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { IDestroy } from "../../common/components/pages/IDestroy";
import InputGroupText from "reactstrap/lib/InputGroupText";
import Icon from "../custom/Icon";
import { Role } from "../user/Role";
import { withSecurity } from "../../common/security/Security";
import Identity from "../user/Identity";
import { Municipality } from "../models/Municipality";
import { AdCategory } from "../models/AdCategory";
import { AdType } from "../models/AdType";
import { AxiosResponse, AxiosError } from "axios";
import { AdItemCondition } from "../models/AdItemCondition";
import { TextInput } from "../../common/components/widgets/form/input/TextInput";
import { TextAreaInput } from "../../common/components/widgets/form/input/TextAreaInput";
import { IAd, Ad } from "../models/Ad";
import { CheckboxInput } from "../../common/components/widgets/form/input/CheckboxInput";
import { DropdownInput } from "../../common/components/widgets/form/input/DropdownInput";
import Util from "../custom/Util";
import { AdImage } from "../models/AdImage";
import { Config, IConfig } from "../models/Config";
import Noty from "noty";
import { NumberInput } from "../../common/components/widgets/form/input/NumberInput";
import moment, { max } from "moment";
import { AdStatus } from "../custom/enum/AdStatus";
import Dropzone from 'react-dropzone';
import { User, IUser } from "../models/User";
import ReactGA from 'react-ga4';

@withRouter(Path.AD_ADD.toString(), PageContainer)
@withRouter(Path.UPDATE_AD.toString(), PageContainer)
@withSecurity([Role.CLIENT.toString(), Role.LOAN_OFFICER.toString()], Identity, Path.LOGIN)
export default class AddAdPage extends EpkPage {

    private ad: Ad = new Ad(true, Ad.SCENARIO.CREATE1);
    private adImage: AdImage = new AdImage();
    private municipalityModel: Municipality = new Municipality();
    private categoryModel: AdCategory = new AdCategory();
    private typeModel: AdType = new AdType();
    private adConditionModel: AdItemCondition = new AdItemCondition();
    private config: Config = new Config();
    private user: User = new User();

    private submitted = false;
    private subtitle: string;
    private printDisabled: boolean = false;
    private printAlreadySet: boolean = false;

    private printed_ad_duration = 3;
    private ad_duration = 12;


    private inputs1: { [attr: string]: React.RefObject<any> } = {
        name: React.createRef(),
        ad_type_id: React.createRef(),
        ad_item_condition_id: React.createRef(),
        ad_category_id: React.createRef(),
        ad_subcategory_id: React.createRef(),
        municipality_id: React.createRef(),
    };

    private inputs2: { [attr: string]: React.RefObject<any> } = {

        price: React.createRef(),
        exchange_enabled: React.createRef(),
        exchange_description: React.createRef(),
        description: React.createRef(),
        short_desc: React.createRef()
    };

    private inputs3: { [attr: string]: React.RefObject<any> } = {
        ad_duration: React.createRef(),
        is_for_another_user: React.createRef(),
        agreement_number: React.createRef(),
        printed: React.createRef(),
        printed_phone_num: React.createRef(),
        printed_ad_duration: React.createRef(),
    };

    constructor(props: any) {
        super(props);

        this.nextStep = this.nextStep.bind(this);
        this.backStep = this.backStep.bind(this);
        this.onCategoryChange = this.onCategoryChange.bind(this);
        this.onSubcategoryChange = this.onSubcategoryChange.bind(this);
        this.readInputs = this.readInputs.bind(this);
        this.fillInputsWithErrors = this.fillInputsWithErrors.bind(this);
        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadFilesToServer = this.uploadFilesToServer.bind(this);
        this.setInputValues = this.setInputValues.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.getAdPlainObject = this.getAdPlainObject.bind(this);
        this.toggleHelp = this.toggleHelp.bind(this);
        this.removeFromUploadList = this.removeFromUploadList.bind(this);
        this.featuredImage = this.featuredImage.bind(this);
        this.editFeaturedImage = this.editFeaturedImage.bind(this);
        this.getUserConfig = this.getUserConfig.bind(this);
        this.onPrintedExpiresChange = this.onPrintedExpiresChange.bind(this);
        this.onExpiresChange = this.onExpiresChange.bind(this);

        this.state = {
            isLoading: true,
            categoriesLoaded: false,
            municipalitiesLoaded: false,
            typesLoaded: false,
            conditionsLoaded: false,
            formStep: 1,
            printedChecked: false,
            files: [],
            deletedFilesNum: 0,
            municipalities: [],
            categories: [],
            categoriesOptions: [],
            subcategories: [],
            types: [],
            conditions: [],
            formError: [],
            reload: false,
            exchange_enabled: this.ad.exchange_enabled,
            is_for_another_user: false,
            printed: this.ad.printed,
            ad_type_id: this.ad.ad_type_id,
            ad_item_condition_id: this.ad.ad_item_condition_id,
            ad_category_id: null,
            ad_subcategory_id: null,
            municipality_id: this.ad.municipality_id,
            municipality_name: "",
            ad_duration: null,
            max_printed_ad_duration: 3,
            max_printed_num: 3,
            printed_num: 0,
            configList: [],
            adname: this.ad.name,
            collapseHelp: typeof window !== 'undefined' && window.innerWidth < 1199 ? false : true,
            selectedFeatureImage: null,
            ad_images: this.isCreate() ? null : this.ad.ad_images,
        }
    }

    toggleHelp() {
        this.setState(state => ({ collapseHelp: !state.collapseHelp }));
    }

    removeFromUploadList(id) {

        delete this.state.files[id]

        this.setState({
            files: this.state.files.filter(Boolean) //filtirira empty slot iz array kojeg ostavi delete
        })

    }

    protected handleSubmit(event: React.MouseEvent<HTMLElement>): void {
        let promise: Promise<AxiosResponse>;

        if (this.submitted) {
            (document.getElementsByClassName('submitButton')[0] as HTMLButtonElement).disabled = true;
            return
        }

        this.submitted = true;

        if (this.isCreate()) {
            if (!this.ad.expires_at) {
                this.ad.expires_at = parseInt(this.state.configList.ad_duration.value);
            }

            if (!this.ad.printed_expires_at) {
                this.ad.printed_expires_at = parseInt(this.state.configList.printed_ad_duration.value);
            }

            this.ad.municipality_name = this.state.municipality_name;

            promise = this.ad.createNew();
        } else {
            this.ad.municipality_name = this.ad.municipality.name;

            promise = this.ad.update(this.ad);
        }

        if (this.validate(event)) {

            promise.then((response: AxiosResponse) => {

                if (this.state.selectedFeatureImage && this.state.ad_images) { //ako selectedFeatureImage nije null uradi sortkey
                    for (let i = 0; i < this.state.ad_images.length; i++) {
                        this.adImage.update({ id: this.state.ad_images[i].id, sort_key: i });
                    }
                }

                this.ad.setFromPlainObject(response.data as IAd);
                this.uploadFilesToServer();

                if (process.env.REACT_APP_ENV === "prod") {
                    // Slanje eventa Google Analytics-u
                    ReactGA.event({
                        category: 'Oglas - Dodat',
                        action: 'Oglas - Dodat'
                    });
                }
            }).then(() => {
                if (this.state.files.length > 0) {
                    //Prikazuje ovo dok fotografije ne budu dodane
                    Util.notification("success", "Vaš oglas se sprema, ovo može potrajati.", false);
                } else {
                    // Nema fotografija za upload, idi odmah na link oglasa
                    Util.notification("success", "Vaš oglas je uspješno spremljen.", 2000);
                    setTimeout(() => {
                        if (typeof window !== 'undefined') {
                            window.location.href = "/oglas/" + this.ad.id + '/' + Util.slug(this.ad.name) + '?uspjesno=true';
                        }
                    }, 2000)
                }

            }).catch((error: AxiosError) => {
                const aError: AxiosError = error;

                switch (aError.response.status) {
                    case 422:

                        if (aError.response && aError.response.data && aError.response.data.errors) {
                            const respErrors: any[] = aError.response.data.errors;
                            const errors: { [attr: string]: string } = {};

                            respErrors.forEach((error: any) => {
                                errors[error.fieldName] = error.errorMessage;
                            });

                            this.fillInputsWithErrors(errors, this.inputs1);
                            this.fillInputsWithErrors(errors, this.inputs2);
                            this.fillInputsWithErrors(errors, this.inputs3);
                        }
                        break;
                    default:

                        Util.notification("error", "Desila se greška, podaci nisu spremljeni.", 2000);
                        break;
                }

                this.setState({ reload: true })
            });
        }
    }

    private isCreate(): boolean {
        return !this.props.match.params.id;
    }

    pageTitle() {
        if (this.isCreate()) {
            this.subtitle = 'Dodaj oglas ';
        } else {
            this.subtitle = 'Ažuriraj oglas: ' + this.props.match.params.id;
        }
        return this.subtitle + super.pageTitle();
    }

    getDestroyableMembers(): IDestroy[] {
        return [
            this.typeModel,
            this.categoryModel,
            this.adConditionModel,
            this.typeModel,
            this.config
        ];
    }

    setInputValues(values: any) {

        for (const key in values) {
            if (this.inputs1[key] && this.inputs1[key].current) {
                this.inputs1[key].current.setValue(values[key]);
            }
            if (this.inputs2[key] && this.inputs2[key].current) {
                this.inputs2[key].current.setValue(values[key]);
            }
            if (this.inputs3[key] && this.inputs3[key].current) {
                this.inputs3[key].current.setValue(values[key]);
            }
        }
    }

    componentDidMount() {
        super.componentDidMount();

        if (!this.isCreate()) {

            this.ad.loadById(this.props.match.params.id)
                .then((response: IAd) => {

                    const responseObject = this.getAdPlainObject(response);
                    this.ad.setFromPlainObject(responseObject);

                    // Popunjavanje vrijednosti inputa (za dropdown i checkbox ne vrijedi)
                    this.setInputValues(responseObject);

                    this.setState({
                        printedChecked: responseObject.printed,
                        selectedUserOption: {
                            key: this.ad.user.id,
                            label: this.ad.user.username + " - " + this.ad.user.agreement_number,
                            value: this.ad.user.id
                        },
                        isLoading: false,
                        adname: responseObject.name,
                        ad_category_id: responseObject.ad_subcategory.ad_category_id,
                        ad_subcategory_id: responseObject.ad_subcategory_id,
                        ad_duration: responseObject.expires_at,
                        printed_ad_duration: responseObject.printed_expires_at,
                        ...responseObject
                    })

                    this.printAlreadySet = true;


                })
                .then(() => {

                    //redirektovanje korisnika ako nije njegov/njen  oglas ili nj kreditnog službenika
                    if (Identity.id != this.ad.user_id && Identity.id != this.ad.created_by) {
                        document.location.pathname = Path.NOT_FOUND.toString();
                    }
                })
                .catch((error: AxiosError) => {
                    Util.notification("error", "Oglas ne može biti učitan", 1500);
                });
        }

        //load user every time because of eventual changes in ad number
        this.user.loadById(Identity.id).then((response: IUser | AxiosError) => {
            const aError: AxiosError = response as AxiosError;
            const users: IUser = response as IUser;

            if (!aError.response) {
                this.user.setFromPlainObject(users);
                if (this.user.role !== 'lo') delete this.inputs3.agreement_number;
            }
        });

        this.ad.getListPlain(
            {
                filters:
                    [
                        { attr: "user_id", val: Identity.id, operator: "eq" },
                        { attr: "status_id", val: 1, operator: "eq" },
                        { attr: "ad_status_id", val: AdStatus.APPROVED, operator: "eq" },
                        { attr: "printed", val: true, operator: "eq" },
                        { attr: "printed_expires_at", val: moment().format('YYYY-MM-DD'), operator: "gt" },
                    ]
            })
            .then((res: any) => {

                if (res.data) {
                    this.setState({ printed_num: res.data.length });
                }
            });

        const httpReqs = [];

        //inicijalizacija vrijednosti u dropdownima
        httpReqs.push(this.categoryModel.createDataSource(this.categoryModel.resourceNamePlural + "/subcategories?sort=name").getListPlain({})
            .then((response: AxiosResponse) => {

                const categories = response.data.map(category => {
                    return { id: category.id, name: category.name };
                })

                this.setState({
                    categories: response.data,
                    categoriesOptions: categories,
                    categoriesLoaded: true,
                    isLoading: false
                });

                return response;

            }).then((responseCat: AxiosResponse) => {
                //inicijalizacija vrijednosti modela u state-u ako je update
                if (!this.isCreate()) {

                    this.ad.loadById(this.props.match.params.id)
                        .then((response: IAd) => {

                            const responseObject = this.getAdPlainObject(response);
                            let subcategories = [];
                            this.ad.setFromPlainObject(responseObject);

                            // Popunjavanje vrijednosti inputa (za dropdown i checkbox ne vrijedi)
                            this.setInputValues(responseObject);


                            responseCat.data.map(category => {

                                if (category.id == responseObject.ad_subcategory.ad_category.id)
                                    subcategories = category.subcategories.map(subcategory => {
                                        return { id: subcategory.id, name: subcategory.name };
                                    })

                                subcategories.sort(function (a, b) {
                                    if (a.name < b.name) { return -1; }
                                    if (a.name > b.name) { return 1; }
                                    return 0;
                                })
                                this.setState({ subcategories });

                            })

                            this.setState({
                                printedChecked: responseObject.printed,
                                selectedUserOption: {
                                    key: this.ad.user.id,
                                    label: this.ad.user.username + " - " + this.ad.user.agreement_number,
                                    value: this.ad.user.id
                                },
                                isLoading: false,
                                adname: responseObject.name,
                                ad_category_id: responseObject.ad_subcategory.ad_category_id,
                                ad_subcategory_id: responseObject.ad_subcategory_id,
                                ...responseObject
                            })

                            this.printAlreadySet = true;


                        })
                        .then(() => {

                            //redirektovanje korisnika ako nije njegov/njen  oglas ili nj kreditnog službenika
                            if (Identity.id != this.ad.user_id && Identity.id != this.ad.created_by) {
                                document.location.pathname = Path.NOT_FOUND.toString();
                            }
                        })
                        .catch((error: AxiosError) => {
                            Util.notification("error", "Oglas ne može biti učitan", 1500);
                        });

                }
            })

            .catch((error: AxiosError) => {

                Util.notification("error", "Kategorije ne mogu biti učitane.", 1500);
            }));

        httpReqs.push(this.municipalityModel.createDataSource(this.municipalityModel.resourceNamePlural + "?sort=name").getListPlain({})
            .then((response: AxiosResponse) => {
                const municipalities = [];

                response.data.map(municipality => {
                    municipalities.push({ id: municipality.id, name: municipality.name });
                })

                this.setState({
                    municipalities,
                    municipalitiesLoaded: true
                });

            }).catch((error: AxiosError) => {
                Util.notification("error", "Opštine ne mogu biti učitane.", 1500);
            }));

        httpReqs.push(this.typeModel.createDataSource(this.typeModel.resourceNamePlural).getListPlain({})
            .then((response: AxiosResponse) => {
                const types = [];

                response.data.map(type => {
                    types.push({ id: type.id, name: type.caption });
                })

                this.setState({
                    types,
                    typesLoaded: true
                });

            }).catch((error: AxiosError) => {
                Util.notification("error", "Vrste oglasa ne mogu biti učitane.", 1500);
            }));

        httpReqs.push(this.adConditionModel.createDataSource(this.adConditionModel.resourceNamePlural).getListPlain({})
            .then((response: AxiosResponse) => {
                const conditions = [];

                response.data.map(c => {
                    conditions.push({ id: c.id, name: c.caption });
                })

                this.setState({
                    conditions,
                    conditionsLoaded: true,
                });

            }).catch((error: AxiosError) => {

                Util.notification("error", "Stanja oglasa ne mogu biti učitana.", 1500);
            }));

    }

    addFile = (files, text) => {
        let maxFileNumber = (this.state.configList.ad_image_number ? this.state.configList.ad_image_number.value : 10);
        
        if (this.ad.ad_images) {
            const prevImages = this.ad.ad_images.filter(function (item) {
                return item.status_id == 1;
            });
            maxFileNumber -= prevImages.length
        }

        const newFilesList = [...this.state.files];

        files.forEach((file: File) => {
            let fileGood = true;

            if (newFilesList.length >= maxFileNumber) {
                Util.notification("warning", "Unijeli ste maksimalan broj fotografija za oglas", 1500);
                fileGood = false;
                return;
            }

            if (file.size >= (this.state.configList.image_max_size * 1000000 || 5000000)) {
                Util.notification("error", "Slika je prevelika, najveća dozvoljena veličina je " + this.state.configList.image_max_size.value + " MB.", 10000);
                fileGood = false;
            }

            const pattern = /image-*/;

            if (!file.type.match(pattern) || file.type == "image/webp") {
                Util.notification("error", "Format slike nije validan.", 10000);
                fileGood = false;
            }

            if (fileGood) {
                newFilesList.push(file);
            }
        })

        this.setState({ files: newFilesList })

    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.formStep != this.state.formStep && this.state.formStep != 1) {
            this.setInputValues(this.getAdPlainObject(this.ad.asPlainObject()));

            if (document.getElementById('Stampan') && this.state.formStep == 3 && prevState.formStep != 3) {
                if (prevState.formStep != 3 && !this.printAlreadySet) {
                    document.getElementById('Stampan').click();
                } else {
                    this.changePhoneInputVisibility(this.state.printedChecked);
                }

                if (this.state.is_for_another_user) {
                    document.getElementById('checkbox_is_for_another_user').click();
                    this.setState({is_for_another_user: true});
                }
            }

            if (document.getElementById('Datum-isteka-stampe') && this.state.formStep == 3 && !this.printDisabled && this.isCreate()) {
                const selectDatumIstekaStampe = document.getElementById('Datum-isteka-stampe') as HTMLSelectElement;
                selectDatumIstekaStampe.value = "3";
            }

            if (document.getElementById('expiration-date') && this.state.formStep == 3) {
                const selectDatumIstekaStampe = document.getElementById('expiration-date') as HTMLSelectElement;
                selectDatumIstekaStampe.value = "12";
            }

        }

        if (this.ad.ad_images && this.ad.ad_images.length >= 2) {
            this.ad.ad_images.sort(function (a, b) {
                return a.sort_key - b.sort_key;
            }); // poredaj array po sort_key
        }
    }


    private async uploadFilesToServer() {

        //Append files to form data
        const files = this.state.files;

        if (files.length > 0) {

            for (let i = 0; i < files.length; i++) {
                const data = new FormData();

                data.append('files', files[i], files[i].name);

                const response = await this.ad.uploadImages(data);

                const images: string[] = response.data;

                images.map(async (image, index) => {
                    const imageObject: AdImage = new AdImage();
                    imageObject.image_path = image;
                    imageObject.sort_key = i + this.ad.ad_images.length; // ako slike već postoje sort_key kreće od broja postojećih slika

                    try {
                        const response = await imageObject.createNew(this.ad.id);
                    } catch (error) {
                        console.log(error)
                    }
                }

                );
            }

            // Izvrši nakon što fotografije budu dodane
            Util.notification("success", "Vaš oglas je uspješno spremljen.", 2000);
            setTimeout(() => {
                if (typeof window !== 'undefined') {
                    window.location.href = "/oglas/" + this.ad.id + '/' + Util.slug(this.ad.name) + '?uspjesno=true';

                }
            }, 2000);
        }
    }

    visualDelete(imageId: string) {
        document.getElementById('image-' + imageId).style.display = "none";
    }

    togglePhoneInput(event) {
        const checked = !this.state.printedChecked;

        if (this.state.printed_num < this.state.max_printed_num) {
            this.changePhoneInputVisibility(checked);
            this.printAlreadySet = true;
        }

        this.setState({ printedChecked: checked });
    }

    changePhoneInputVisibility(visible: boolean) {
        document.getElementById('printed_phone_num').style.display = visible ? "block" : "none";
    }

    swapArrayElements(arr, indexA, indexB) {

        const temp = arr[indexA];
        arr[indexA] = arr[indexB];
        arr[indexB] = temp;

        this.setState({
            selectedFeatureImage: indexB
        })
    }

    featuredImage(id: number) {

        this.swapArrayElements(this.state.files, 0, id)
    }

    editFeaturedImage(id: number) {

        let selectedId;
        if (this.state.selectedFeatureImage === id) {
            selectedId = 0
        } else {
            selectedId = id
        }

        this.swapArrayElements(this.ad.ad_images, 0, selectedId)

        const imagesList = []

        for (let i = 0; i < this.ad.ad_images.length; i++) {
            imagesList.push(this.ad.ad_images[i]);
        }

        this.setState({
            ad_images: imagesList
        })

        Util.notification("success", "Označena fotografija je postavljena kao istaknuta", 1000);

        this.forceUpdate();
    }

    deleteImage(event: React.MouseEvent<HTMLAnchorElement>) {

        const target = event.target as HTMLInputElement;

        const n = new Noty({
            text: "Jeste li sigurni da želite izbrisati?",
            buttons: [
                Noty.button('DA', 'btn btn-primary', () => {

                    this.ad.deleteImage(target.id).then(() => {
                        Util.notification("success", "Slika je uspješno izbrisana", 1000);
                        //ako je proces brisanja uspješan na colonu će se dodati direktan style dispay:none
                        this.visualDelete(target.id);

                        this.setState({
                            deletedFilesNum: this.state.deletedFilesNum + 1
                        })
                    }).catch(() => {
                        Util.notification("success", "Desila se greška, slika je nije izbrisana", 1000);
                    })
                    n.close();

                }, { id: 'button1', 'data-status': 'ok' }),

                Noty.button('NE', 'btn btn-secondary', function () {
                    n.close();
                })
            ],
            theme: 'bootstrap-v4',
            layout: 'center',
            progressBar: true
        });

        n.show();
    }

    getUserConfig() {

        //load user every time because of eventual changes in ad number
        this.user.loadById(Identity.id).then((response: IUser | AxiosError) => {
            const aError: AxiosError = response as AxiosError;
            const users: IUser = response as IUser;

            if (!aError.response) {
                this.user.setFromPlainObject(users);
            }

        });

        if (!this.isCreate()) {
            this.setState({
                printedChecked: this.ad.printed

            });
            this.printAlreadySet = true;
        }


        this.config.createDataSource(this.config.resourceName).getListPlain({})
            .then((response: AxiosResponse) => {

                const configList: Config[] = [];

                response.data.map((setting: IConfig) => {
                    configList[setting.key] = { caption: setting.caption, value: setting.value };
                });

                const userMaxPrintedAdNumber = this.user.max_printed_ad_number;

                this.setState({
                    configList,
                    max_printed_num: userMaxPrintedAdNumber !== null ? userMaxPrintedAdNumber : configList["max_printed_ad_number"].value
                });
            }).catch((error: AxiosError) => {
                console.log(error);
            });
    }

    nextStep(event: React.MouseEvent<HTMLInputElement>) {
        //za svaku grupu forme treba ponovo postaviti vrijednosti jer se gube iz koraka u korak

        this.submitted = true;

        if (this.validate(event)) {
            this.submitted = false;

            if (this.state.formStep == 2) {
                this.getUserConfig()
            }

            this.setState({
                formStep: this.state.formStep + 1
            });

            const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                if (typeof window !== 'undefined') {
                    window.scrollTo({ top: 0 });
                }
            }

            this.setInputValues(this.getAdPlainObject(this.ad.asPlainObject()));
            this.setState({ reload: !this.state.reload })
        }

    }

    getAdPlainObject(data: IAd) {
        const object = {
            ...data
        }

        if (typeof data.expires_at == "string") {
            object.expires_at = moment(data.expires_at).diff(moment(data.created_at), 'months');

            if (object.expires_at > 12) {
                object.expires_at = 12;
            }
        }

        if (typeof data.printed_expires_at == "string") {
            object.printed_expires_at = moment(data.printed_expires_at).diff(moment(data.created_at), 'months');
        }

        return object;
    }

    backStep() {

        this.setInputValues(this.getAdPlainObject(this.ad.asPlainObject()));
        this.setState({
            formStep: this.state.formStep - 1
        });
    }

    onSubcategoryChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        this.setState({ ad_subcategory_id: target.value });
    }

    onCategoryChange(event: React.ChangeEvent<HTMLInputElement>) {

        const target = event.target as HTMLInputElement;

        const filteredCategories = this.state.categories.filter(category => category.id == target.value);
        const subcategoriesOptions = [];

        if (filteredCategories.length > 0) {
            filteredCategories[0].subcategories.map(subcategory => {
                subcategoriesOptions.push({ id: subcategory.id, name: subcategory.name });
            })
        }

        subcategoriesOptions.sort(function (a, b) {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        })

        this.setState({
            ad_category_id: target.value,
            subcategories: subcategoriesOptions,
            ad_subcategory_id: subcategoriesOptions[0].id
        });
    }

    onExpiresChange(event: React.ChangeEvent<HTMLInputElement>) {

        const target = event.target as HTMLInputElement;
        this.setState({
            ad_duration: target.value,
        });
    }

    onPrintedExpiresChange(event: React.ChangeEvent<HTMLInputElement>) {

        const target = event.target as HTMLInputElement;

        this.setState({
            printed_ad_duration: target.value,
        });
    }


    private validate(event: any): boolean {
        this.readInputs();

        let inputs: any;

        switch (this.state.formStep) {
            case 1:
                inputs = this.inputs1;
                this.ad.changeScenario(Ad.SCENARIO.CREATE1);
                break;
            case 2:
                inputs = this.inputs2;
                this.ad.changeScenario(Ad.SCENARIO.CREATE2);
                break;
            case 3:
                inputs = this.inputs3;

                if (this.state.printedChecked) {
                    this.ad.changeScenario(Ad.SCENARIO.CREATE3_PHONE_REQUIRED);
                } else {
                    this.ad.changeScenario(Ad.SCENARIO.CREATE3);
                }

                break;
            default:
                inputs = this.inputs1;
                this.ad.changeScenario(Ad.SCENARIO.CREATE1);
                break;
        }


        const valid = this.ad.validate();

        if (this.submitted) {
            if (!valid) {
                this.setState({ formError: [this.state.formStep] })
                const errors: { [attr: string]: string } = this.ad.getErrors();
                this.fillInputsWithErrors(errors, inputs);
            } else {
                const form = this.state.formError;
                const index = form.indexOf(this.state.formStep);
                if (index > -1) {
                    form.splice(index, 1);
                }
                this.setState({ formError: form })
            }
        }

        event.preventDefault();

        return valid;
    }

    private readInputs(): IAd {

        const plainObject: IAd = {};
        let inputs: any;

        switch (this.state.formStep) {
            case 1:
                inputs = this.inputs1;
                break;
            case 2:
                inputs = this.inputs2;
                break;
            case 3:
                inputs = this.inputs3;
                break;
            default:
        }

        for (const key in inputs) {
            if (inputs.hasOwnProperty(key) && inputs[key].current) {
                plainObject[key] = inputs[key].current.getValue();
                inputs[key].current.removeError();
            }
        }

        switch (this.state.formStep) {
            case 3:
                if (!this.state.is_for_another_user) delete plainObject.agreement_number;
                break;
        }

        this.ad.setFromPlainObject({ ...this.ad.asPlainObject(), ...plainObject });


        return plainObject;
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

        for (const key in errors) {
            if (errors.hasOwnProperty(key) && inputs[key] != undefined && inputs[key].current) {
                inputs[key].current.setError(errors[key]);
            }
        }
    }

    renderContent() {

        const FormLoader = []

        for (let i = 1; i <= 10; i++) {
            FormLoader.push(
                <div key={i}>
                    <i className="content-loader w30" />
                    <i className="title-loader w100" />
                    <i className="content-loader w100" />
                </div>
            )
        }

        let fromDisplay;

        // trajanje oglasa - uzim se korisnički limit ako ima, ako nema onda iz sys config tabele, a ako slučajno 
        // ni tu ne postoji uzimaju se 3 i 12 kao vrijednosti za max trajanje oglasa u štampi i na webu
        if (this.user.standard_ad_duration) {
            this.ad_duration = this.user.standard_ad_duration
        } else if (this.state.configList.ad_duration) {
            this.ad_duration = parseInt(this.state.configList.ad_duration.value)
        }

        if (this.user.standard_printed_ad_duration) {
            this.printed_ad_duration = this.user.standard_printed_ad_duration
        } else if (this.state.configList.printed_ad_duration) {
            this.printed_ad_duration = parseInt(this.state.configList.printed_ad_duration.value)
        }
        const printedDurationDropdownValues = Array.apply(null, Array(this.printed_ad_duration)).map(function (_, i) { return { id: i + 1, name: i + 1 }; });
        const durationDropdownValues = Array.apply(null, Array(this.ad_duration)).map(function (_, i) { return { id: i + 1, name: i + 1 }; });

        if (this.state.formStep == 1) {

            fromDisplay = (
                <>
                    <div className="from-part from-part-1">
                        <h2>Osnovne informacije</h2>
                        <Form>
                            <FormGroup>
                                <Label for="Naziv">Naziv</Label>
                                <TextInput
                                    name="naziv"
                                    id="name"
                                    placeholder="npr. Golf 4"
                                    ref={this.inputs1.name}
                                    initialValue={this.state.adname} // Ovo ne bi trebalo ovako raditi, međutim input se ne popuni za name
                                    onChange={(event?: any) => {
                                        this.setState({ adname: event.target.value })
                                    }}
                                    onBlur={this.validate.bind(this)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <DropdownInput
                                    label="Vrsta"
                                    listItems={this.state.types}
                                    id="ad_type_id"
                                    placeholder="Izaberite"
                                    initialValue={this.state.ad_type_id}
                                    ref={this.inputs1.ad_type_id}
                                    onBlur={this.validate.bind(this)}
                                    onChange={(event?: any) => {
                                        this.setState({ ad_type_id: event.target.value })
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <DropdownInput
                                    label="Stanje"
                                    listItems={this.state.conditions}
                                    initialValue={this.state.ad_item_condition_id}
                                    id="ad_item_condition_id"
                                    placeholder="Izaberite"
                                    ref={this.inputs1.ad_item_condition_id}
                                    onBlur={this.validate.bind(this)}
                                    onChange={(event?: any) => {
                                        this.setState({ ad_item_condition_id: event.target.value })
                                    }}

                                />
                            </FormGroup>
                            <FormGroup>
                                <DropdownInput
                                    label="Kategorija"
                                    listItems={this.state.categoriesOptions}
                                    initialValue={this.state.ad_category_id}
                                    id="ad_category_id"
                                    placeholder="Izaberite"
                                    ref={this.inputs1.ad_category_id}
                                    onBlur={this.validate.bind(this)}
                                    onChange={this.onCategoryChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <DropdownInput
                                    label="Podkategorija"
                                    listItems={this.state.subcategories}
                                    initialValue={this.state.ad_subcategory_id}
                                    id="ad_subcategory_id"
                                    placeholder="Izaberite"
                                    ref={this.inputs1.ad_subcategory_id}
                                    onBlur={this.validate.bind(this)}
                                    onChange={this.onSubcategoryChange}

                                />
                            </FormGroup>
                            <FormGroup>
                                <DropdownInput
                                    label="Opština"
                                    listItems={this.state.municipalities}
                                    initialValue={this.state.municipality_id}
                                    id="municipality_id"
                                    placeholder="Izaberite"
                                    ref={this.inputs1.municipality_id}
                                    onChange={(event?: any) => {
                                        this.setState({
                                            municipality_id: event.target.value,
                                            municipality_name: this.state.municipalities.filter(mun => mun.id == event.target.value)[0].name
                                        })
                                    }}

                                />
                            </FormGroup>
                            <Row>
                                <Col xs={{ size: 5, offset: 7, }} md={{ size: 3, offset: 9, }}><Button color="primary"
                                    id="1"
                                    onClick={this.nextStep}>Dalje</Button></Col>
                            </Row>
                        </Form>
                    </div>
                </>
            )
        } else if (this.state.formStep == 2) {

            fromDisplay = (
                <div className="from-part from-part-2">
                    <h2>Cijena, zamjena, opis</h2>
                    <Form>
                        <FormGroup>
                            <Label for="Cijena">Cijena</Label>
                            <InputGroup>
                                <NumberInput
                                    id="Cijena"
                                    ref={this.inputs2.price}
                                    onBlur={this.validate.bind(this)}
                                    step="0.01"
                                />
                                <InputGroupText>KM</InputGroupText>
                            </InputGroup>
                            <FormText>
                                Ukoliko ne navedete cijenu automatski će pisati<b>Po dogovoru</b>
                            </FormText>
                        </FormGroup>

                        <FormGroup>
                            <Label for="Zamjena">Zamjena</Label>
                            <div>
                                <CheckboxInput
                                    id="Zamjena"
                                    label="Da, želim zamjenu"
                                    ref={this.inputs2.exchange_enabled}
                                    onBlur={this.validate.bind(this)}
                                    onChange={(event?: any) => {
                                        this.inputs2.exchange_description.current.setValue("");
                                        this.setState({ exchange_enabled: !this.state.exchange_enabled })
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <TextAreaInput
                                rows={2}
                                id="Zamjenatext"
                                readOnly={!this.state.exchange_enabled}
                                ref={this.inputs2.exchange_description}
                                onBlur={this.validate.bind(this)}
                                placeholder="Upišite šta želite u zamjeni"
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="Kratki-opis">Kratki opis</Label>
                            <TextInput
                                name="kratkiopis"
                                id="Kratki-opis"
                                ref={this.inputs2.short_desc}
                                onBlur={this.validate.bind(this)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="Detaljan-opis">Detaljan opis</Label>
                            <TextAreaInput
                                rows={3}
                                id="Detaljan-opis"
                                ref={this.inputs2.description}
                            />
                        </FormGroup>

                        <Row>
                            <Col xs="5" md="3"><Button color="primary" onClick={this.backStep}>Nazad</Button></Col>
                            <Col xs={{ size: 5, offset: 2, }} md={{ size: 3, offset: 6, }}><Button color="primary" id="2"
                                onClick={this.nextStep}>Dalje</Button></Col>
                        </Row>
                    </Form>
                </div>
            )
        } else if (this.state.formStep == 3) {

            this.printDisabled = this.state.printed_num >= this.state.max_printed_num && this.isCreate();

            let notification = (<></>);

            if (this.printDisabled) {
                notification = (<Col xs={{ size: 12 }} className="alert-warning"> Dostignuli ste limit od {this.state.max_printed_num} oglasa u štampanim oglasima i ne možete
                     dodavati nove dok ne isteknu postojeći.</Col>);
            }

            fromDisplay = (
                <div className="from-part from-part-3">
                    <h2>EPK detalji</h2>
                    <Form>
                        <FormGroup>
                            <Label for="Datum-isteka">Broj mjeseci trajanja oglasa na portalu EKI Oglasi</Label>

                            <DropdownInput
                                id="expiration-date"
                                label=""
                                listItems={durationDropdownValues}
                                ref={this.inputs3.ad_duration}
                                onBlur={this.validate.bind(this)}
                                initialValue={this.state.ad_duration}
                                onChange={this.onExpiresChange}
                            />
                        </FormGroup>
                        {this.user.role === 'lo' ?
                        <>
                            <CheckboxInput
                                id="checkbox_is_for_another_user"
                                label="Objavi za drugog korisnika"
                                onBlur={this.validate.bind(this)}
                                onChange={(event?: any) => {
                                    this.setState({ is_for_another_user: !this.state.is_for_another_user })
                                }}
                            />
                            <FormGroup>
                                <Label for="expiration-date">Broj EPK sporazuma korisnika</Label>
                                <TextInput
                                    id="agreement_number"
                                    placeholder="Broj EPK sporazuma"
                                    ref={this.inputs3.agreement_number}
                                    readOnly={!this.state.is_for_another_user}
                                    onBlur={this.validate.bind(this)}
                                />
                            </FormGroup>
                        </>
                        : ""}
                        <FormGroup>
                            <Row>
                                <Col xs={{ size: 6 }}>
                                    <Label for="Stampan">Štampano izdanje</Label>
                                    <div>
                                        <CheckboxInput
                                            id="Stampan"
                                            label="Da, želim objavu oglasa u štampanom izdanju EKI Oglasa"
                                            ref={this.inputs3.printed}
                                            onBlur={this.validate.bind(this)}
                                            onChange={this.togglePhoneInput.bind(this)}
                                            disabled={this.printDisabled}
                                            value={this.state.printedChecked}
                                        />
                                    </div>
                                </Col>

                                <Col xs={{ size: 4 }} id="printed_phone_num"
                                    style={{ display: "none" }}>
                                    <Label for="printed_phone_num">Broj telefona za štampani oglas</Label>
                                    <div>
                                        <TextInput
                                            placeholder="npr. 38760000000"
                                            ref={this.inputs3.printed_phone_num}
                                            onBlur={this.validate.bind(this)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {notification}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormText>
                                        {this.printDisabled ? "" : "Ukoliko ne želite objavu oglasa u štampanom izdanju EKI Oglasa isključite ovu opciju. U suprotnom vaš oglas će biti objavljen u naredna tri broja EKI Oglasa."}
                                    </FormText>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Label for="Datum-isteka-stampe">Broj mjeseci trajanja štampanog oglasa</Label>
                            <FormText>
                                Član EKI Poslovnog kluba može imati 3 aktivna oglasa u jednom momentu za štampani primjerak EKI Oglasa. Trajanje oglasa je 3 mjeseca ili 3 broja EKI Oglasa, sa mogućnošću produžavanja putem besplatne info linije 080 051 052. <b>Oglasi u štampanom izdanju ne mogu sadržavati fotografije zbog tehničkih ograničenja</b>.
                            </FormText>
                            <DropdownInput
                                label=""
                                id="Datum-isteka-stampe"
                                listItems={printedDurationDropdownValues}
                                ref={this.inputs3.printed_ad_duration}
                                initialValue={this.state.printed_ad_duration}
                                onChange={this.onPrintedExpiresChange}
                            />
                        </FormGroup>

                        <Row>
                            <Col xs="5" md="3"><Button color="primary" onClick={this.backStep}>Nazad</Button></Col>
                            <Col xs={{ size: 5, offset: 2, }} md={{ size: 3, offset: 6, }}><Button color="primary" id="3"
                                onClick={this.nextStep}>Dalje</Button></Col>
                        </Row>
                    </Form>
                </div>
            )
        } else if (this.state.formStep == 4) {
            const imagesContent = [];

            if (!this.isCreate()) {

                if (this.state.ad_images.length > 0) {
                    this.state.ad_images.forEach((image: AdImage, index: number) => {
                        if (image.status_id == 1) {
                            imagesContent.push(
                                <Col xs="12" md="6" xl="4" key={image.image_path} id={'image-' + image.id}>
                                    <div className="epk-ad">
                                        <div className="piceditor">
                                            <a onClick={() => this.editFeaturedImage(index)} >
                                                {index === 0 ? <Icon name="star" /> : <Icon name="feature" />}
                                            </a>
                                            <a onClick={this.deleteImage}>
                                                <Icon id={image.id} name="trash" />
                                            </a>
                                        </div>
                                        <a target='_blank' href={image.image_path} className="epk-ad-img">
                                            <img src={image.image_path} alt={image.image_path} />
                                        </a>
                                    </div>
                                </Col>)
                        }
                    })
                }
            }

            fromDisplay = (
                <div className="from-part from-part-4">
                    <h2>Slike oglasa - maksimalno 10 fotografija</h2>
                    <Row>
                        {imagesContent}
                    </Row>
                    <div>
                        <Form>
                            <Dropzone
                                className="DropZone"
                                onDrop={this.addFile} >
                                <div>Prebacite slike ili kliknite ovdje</div>
                            </Dropzone>
                            <Row className="list-of-images mb-4">
                                {
                                    this.state.files.map((file, index) =>
                                        <Col xs="12" md="6" xl="4" key={file.name + '-' + index} id={index}>
                                            <div className="epk-ad">
                                                <div className="piceditor">
                                                    {this.isCreate() ? <a onClick={() => this.featuredImage(index)}>
                                                        {index === 0 ? <Icon name="star" /> : <Icon name="feature" />}
                                                    </a> : null}
                                                    <a onClick={() => this.removeFromUploadList(index)}>
                                                        <Icon name="trash" />
                                                    </a>
                                                </div>
                                                <a className="epk-ad-img">
                                                    <img src={file.preview} />
                                                </a>
                                            </div>
                                        </Col>
                                    )
                                }
                            </Row>
                            <Row>
                                <Col xs="4" md="3"><Button color="primary" onClick={this.backStep}>Nazad</Button></Col>
                                <Col xs={{ size: 7, offset: 1, }} md={{ size: 4, offset: 5, }}><Button color="primary"
                                    id="4"
                                    className="submitButton"
                                    onClick={this.handleSubmit}>Završi
                                    objavu</Button></Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            )
        }

        return (this.state.isLoading || !this.state.categoriesLoaded || !this.state.municipalitiesLoaded || !this.state.conditionsLoaded || !this.state.typesLoaded)
            ? <>
                <div className="epk-oglas-content">
                    <Container>
                        <div className="epk-oglas-content-section epk-section">
                            <Row>
                                <Col md="12">
                                    <h1 className="epk-container-h1"><i className="title-loader w50" /></h1>
                                    <h2><i className="content-loader w70" /></h2>
                                    <p><i className="content-loader w100" />
                                        <i className="content-loader w50" />
                                        <i className="content-loader w70" />
                                    </p>
                                </Col>

                                <Col md="12">
                                    <i className="content-loader w100" />
                                </Col>

                                <Col md="12">
                                    <div className="from-part from-part-1">
                                        <h2><i className="content-loader w30" /></h2>
                                        {FormLoader}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>

            : (
                <>
                    <div className="epk-oglas-content">
                        <Container>
                            <div className="epk-oglas-content-section epk-section">
                                <Row>
                                    <Col md="12">
                                        <h1 className="epk-container-h1">{this.subtitle}</h1>

                                        <a onClick={this.toggleHelp}>Kako dodati oglas?</a>

                                        <Collapse isOpen={this.state.collapseHelp}>
                                            <p>Molimo vas da se prilikom objave oglasa vodite smjernicama za objavu oglasa koje su navedene u uslovima korištenja EPK portala (Pravila i uslovi korištenja EPK portala). Oglasi sa nedozvoljenim sadržajem i neprimjerenim fotografijama neće biti objavljeni (stavka 6. Pravila i uslovi korištenja EPK portala). Oglas treba da sadrži isključivo podatke o predmetu koji se prodaje ili usluzi koja se pruža. Naslov oglasa i opis treba da se odnose samo na predmet prodaje ili uslugu. U oglasima nije dopuštena upotreba fotografija sa drugih oglasnih sajtova ili iz oglasa drugih oglašivača na EPK portalu. Oglas mora svojim sadržajem da pripada kategoriji u kojoj je postavljen.
                                            <br></br>
                                                <b>Nakon unosa vaš oglas će biti pregledan i odobren za objavu. Oglasi će se odobravati za prikazivanje u što kraćem roku, a najkasnije od narednog radnog dana</b>.</p>
                                        </Collapse>
                                    </Col>

                                    <Col md="12">
                                        <Progress multi className="progress-steps">
                                            <Progress animated bar
                                                color={this.state.formStep >= 1 ? (this.state.formError.indexOf(1) == -1 ? "success" : "danger") : "info"}
                                                value="30">Osnovne informacije</Progress>
                                            <Progress animated bar
                                                color={this.state.formStep >= 2 ? (this.state.formError.indexOf(2) == -1 ? "success" : "danger") : "info"}
                                                value="30">Cijena, zamjena, opis</Progress>
                                            <Progress animated bar
                                                color={this.state.formStep >= 3 ? (this.state.formError.indexOf(3) == -1 ? "success" : "danger") : "info"}
                                                value="25">EPK detalji</Progress>
                                            <Progress animated bar
                                                color={this.state.formStep >= 4 ? (this.state.formError.indexOf(4) == -1 ? "success" : "danger") : "info"}
                                                value="25">Slike</Progress>
                                        </Progress>
                                    </Col>

                                    <Col md="12">
                                        {fromDisplay}
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                </>
            );
    }
}
