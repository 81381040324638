import React from "react";
import { Container, Row, Col } from 'reactstrap';
import EpkPage from "./EpkPage";
import MainLayout from "../layout/MainLayout";
import { IPage, Page } from "../models/Page";
import { Path } from "../container/Path";
import { withRouter } from "../../common/components/routing/Router";
import { Container as PageContainer } from "../../index";
import { AxiosError } from "axios";
import Util from "../custom/Util";


@withRouter(Path.IMPRESUM.toString(), PageContainer)
@withRouter(Path.FAQ.toString(), PageContainer)
@withRouter(Path.EPK.toString(), PageContainer)
@withRouter(Path.USER_REGISTRATION.toString(), PageContainer)
@withRouter(Path.TERMS.toString(), PageContainer)
@withRouter(Path.OBJAVA_OGLASA.toString(), PageContainer)
@withRouter(Path.PTS.toString(), PageContainer)
@withRouter(Path.PRIVACY.toString(), PageContainer)
@withRouter(Path.PTS0.toString(), PageContainer)
@withRouter(Path.PTS1.toString(), PageContainer)
@withRouter(Path.PTS2.toString(), PageContainer)
@withRouter(Path.PTS3.toString(), PageContainer)
@withRouter(Path.PTS4.toString(), PageContainer)
@withRouter(Path.PTS5.toString(), PageContainer)
@withRouter(Path.PTS6.toString(), PageContainer)
@withRouter(Path.PTS7.toString(), PageContainer)
@withRouter(Path.PTS8.toString(), PageContainer)
@withRouter(Path.PTS9.toString(), PageContainer)
export default class StaticContentPage extends EpkPage {

    private pageModel: Page = new Page();

    constructor(props) {
        super(props);

        this.state = {
            page: this.pageModel,
            isLoaded: false,
        }

    }

    pageTitle() {
        return this.pageModel.title + " - " + process.env.REACT_APP_TITLE_PREFIX;
    }

    pageLayout() {
        return new MainLayout();
    }

    getDestroyableMembers() {
        return [this.pageModel];
    }

    public componentDidMount() {

        Util.topFunction() //Svaka nova otvorena stranica počinje sa vrha

        this.pageModel.loadBySlug(this.props.location.pathname.replace("/", "")).then((response: IPage | AxiosError) => {
            const aError: AxiosError = response as AxiosError;
            const pageModel: IPage = response as IPage;

            if (!aError.response) {
                this.pageModel.setFromPlainObject(pageModel);
                this.setState({
                    page: this.pageModel,
                    isLoaded: true,
                });
            }

            super.componentDidMount();
        });


    }

    renderContent() {
        const { isLoaded } = this.state;
        const __html = this.state.page.content;

        let title;
        let content;

        if (!isLoaded) {
            title = <i className="title-loader w50" />;
            content = (
                <div className="content">
                    <p>
                        <i className="content-loader w100" />
                        <i className="content-loader w50" />
                        <i className="content-loader w70" />
                        <i className="content-loader w100" />
                        <i className="content-loader w70" />
                        <i className="content-loader w70" />
                        <i className="content-loader w50" />
                    </p>
                </div>
            );
        } else {
            title = this.pageModel.title;
            content = <div className="content" dangerouslySetInnerHTML={{ __html }}></div>
        }

        return (
            <>
                <div className="epk-static-content">
                    <Container>
                        <div className="epk-static-content-section epk-section">
                            <Row>
                                <Col md="12">
                                    <h1 className="epk-container-h1">{title}</h1>
                                </Col>
                                <Col md="12">{content}</Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }
}
