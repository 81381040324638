import React from 'react';
import { Link } from 'react-router-dom';
import { Path } from '../../container/Path';

function Logo() {

    return (
        <Link className="navbar-brand" to={Path.HOMEPAGE}
            onClick={() => {
                if (typeof window !== 'undefined') {
                    window.location.pathname === Path.HOMEPAGE
                        ? window.location.reload()
                        : null
                }
            }}
        >
            <img alt="logo" src="/img/epk-logo.png"></img>
        </Link>
    );
}

export default Logo;