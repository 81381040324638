import React, { Component } from 'react';
import Icon from './Icon';
import Identity from '../user/Identity';
import Button from 'reactstrap/lib/Button';
import { ErrorLog, IErrorLog } from '../models/ErrorLog';
import { AxiosResponse, AxiosError } from 'axios';

interface IErrorBoundary {
  error: any,
  errorInfo: any,
  hasError: boolean,
}

class ErrorBoundary extends Component<unknown, IErrorBoundary> {
  state = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  private errorlog: ErrorLog = new ErrorLog(false);

  componentDidCatch(error, errorInfo) {

    const data: IErrorLog = typeof window !== 'undefined' ?{
      ip_address: Identity.ip_address,
      user_agent: Identity.user_agent,
      location: window.location.href,
      display: window.innerWidth + 'X' + window.innerHeight,
      error: JSON.stringify(error.toString()),
      stack_trace: errorInfo.componentStack,
    } : {}

    this.errorlog.createNew(data).then((response: AxiosResponse) => {
      //console.log(response)
    }).catch((error: AxiosError) => {
      //console.log(error)
    })

    this.setState({
      error: error,
      errorInfo: errorInfo,
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="epk-ups-page">
          <div className="epk-ups-section">
            <Icon name="404" />
            <h2>Ups, nešto je pošlo krivo</h2>
            <p>Problem je zabilježen i bit će riješen čim prije.</p>
            <Button color="primary" onClick={() => {
              if (typeof window !== 'undefined') {
                window.location.reload()
              }
            }}>Osvježi</Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;