import * as React from "react";
import {IUserIdentity} from "../identity/UserIdentity";

/**
 * Evaluate security check related to allowed roles.
 * Role "guest" represents ALL roles.
 * Redirect non-authorized users to redirect path provided.
 */
export function withSecurity(roles: string[], userIdentity: IUserIdentity, redirectPath: string) {
    return (Comp: React.ComponentClass | React.StatelessComponent): any => {

        class WrappedComponent extends React.Component<any> {
            private redirectionPerformed: boolean = false;

            public componentDidMount() {
                const role: string = userIdentity.role;
                const isGuest: boolean = userIdentity.isGuest;

                let allowed: boolean = true;
                if (isGuest && roles.indexOf(role) === -1) {
                    allowed = false;
                } else if (!isGuest && roles.indexOf(role) === -1) {
                    allowed = false;
                }

                if (!allowed && this.props.history) {
                    this.redirectionPerformed = true;
                    this.props.history.push(redirectPath);
                }
            }

            public render() {
                return (this.redirectionPerformed)?<div />:<Comp {...this.props} />
            }
        }

        return WrappedComponent;
    };
}
