import {ValidatorError} from "../../common/components/validators/errors/ValidatorError";

export class ErrorMessages {
    public static getDefaultErrorMessageCallbacks(): {[key: number]: (field: string, additionalParams: any) => string} {
        return {
            [ValidatorError.REQUIRED]: (field: string, additionalParams: any):string => {
                return "Vrijednost je obavezna."
            },

            [ValidatorError.FORMAT_IS_NOT_VALID]: (field: string, additionalParams: any):string => {
                return "Format vrijednosti nije validan."
            },

            [ValidatorError.ADEQUATE_VALUE_IS_NOT_SELECTED]: (field: string, additionalParams: any):string => {
                return "Ova vrijednost mora biti označena."
            },

            [ValidatorError.VALUE_DOES_NOT_MATCH]: (field: string, additionalParams: any):string => {
                return "Vrijednost se mora podudarati sa " + additionalParams.attrName + ".";
            },

            [ValidatorError.LENGTH_NOT_IN_BETWEEN]: (field: string, additionalParams: any):string => {
                return "Dužina mora biti između " + additionalParams.min + " i " + additionalParams.max + ".";
            },

            [ValidatorError.LENGTH_IS_BELLOW_ALLOWED]: (field: string, additionalParams: any):string => {
                return "Vrijednost mora imati najmanje " + additionalParams.min + " karaktera.";
            },

            [ValidatorError.LENGTH_IS_ABOVE_ALLOWED]: (field: string, additionalParams: any):string => {
                return "Vrijednost može imati najviše " + additionalParams.max + " karaktera.";
            }
        }
    }
}
