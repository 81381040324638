import * as dotenv from 'dotenv';
dotenv.config({path:".env.production"});

import React from "react";
import EpkPage from "./EpkPage";
import { Container, Row, Col, Nav, Popover, PopoverHeader, PopoverBody, UncontrolledPopover, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { Ad, IAd, IAdViewLog } from "../models/Ad";
import { IPublicMessage, PublicMessage } from "../models/PublicMessage";
import { IDestroy } from "../../common/components/pages/IDestroy";
import { AxiosError, AxiosResponse } from "axios";
import FontSize from "./ad/FontSize";
import Share from "../custom/Share";
import InfoDetails from "./ad/InfoDetails";
import BreadcrumbNav from "./ad/Breadcrumb";
import InfoBox from "./ad/InfoBox";
import Info from "./ad/Info";
import Profile from "./ad/Profile"
import Images from "./ad/Images"
import PublicMessages from "./ad/PublicMessages"
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import Button from "reactstrap/lib/Button";
import Identity from "../user/Identity";
import Util from "../custom/Util";
import { TextAreaInput } from "../../common/components/widgets/form/input/TextAreaInput";
import moment from 'moment';
import Icon from "../custom/Icon";
import { AdStatus } from "../custom/enum/AdStatus";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { IBanner, Banner } from '../models/Banner';

@withRouter(Path.AD_SLUG.toString(), PageContainer)
@withRouter(Path.AD.toString(), PageContainer)
export default class AdPage extends EpkPage {
    private increasedViewCount: boolean = false;
    private ad: Ad = new Ad();
    private publicMessage: PublicMessage = new PublicMessage();
    private bannerModel: Banner = new Banner();

    private messageInputs: { [attr: string]: React.RefObject<any> } = {
        content: React.createRef()
    };

    constructor(props: any) {
        super(props);

        this.sendMessage = this.sendMessage.bind(this);
        this.handleContentChange = this.handleContentChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.messageToggle = this.messageToggle.bind(this);
        let data = this.props.data || null;
        let loaded = false;
        let isReadOnly = false;


        if (typeof window !== 'undefined') {
            const hydrationMarker = document.getElementById("hydration-marker");

            if (hydrationMarker !== null && hydrationMarker.innerHTML.length > 0) {
                data = JSON.parse(hydrationMarker.innerHTML.replace(/(&quot\;)/g, "\""));
            }
        }

        if (data && data.ad) {

            this.ad.setFromPlainObject(data.ad);

            if (this.ad.status_id == 1) {

                if (!(this.ad.ad_status_id == AdStatus.APPROVED || this.ad.ad_status_id == AdStatus.DUPLICATE_APPROVED || this.ad.ad_status_id == AdStatus.FINISHED || (this.ad.ad_status_id == AdStatus.REJECTED && this.ad.user.id == Identity.id)) && (this.ad.user.id != Identity.id && Identity.id != this.ad.created_by)) {
                    if (typeof window !== 'undefined') {
                        document.location.pathname = Path.FORBIDDEN;
                    }
                }

                loaded = true;

                if (this.ad.user.id == Identity.id && (this.ad.ad_status_id == AdStatus.PENDING || this.ad.ad_status_id == AdStatus.PENDING_REPEATED)) {

                    if (typeof window !== 'undefined') {
                        const url = new URL(window.location.href);
                        if (url.searchParams.get('uspjesno')) {
                            Util.notification("success", "Nakon unosa vaš oglas će biti pregledan i odobren za objavu. Oglasi će se odobravati za prikazivanje u što kraćem roku, a najkasnije od narednog radnog dana.", 10000);
                        }
                    }
                }

                if (this.ad.id && this.ad.expires_at && moment().isSameOrAfter(this.ad.expires_at.toString())) {

                    isReadOnly = true
                }
            } else {
                if (typeof window !== 'undefined') {
                    window.location.replace(Path.NOT_FOUND);
                }
            }
        }

        this.state = {
            size: '1rem',
            content: "",
            loaded,
            isReadOnly,
            popoverOpen: false,
            messageModal: false,
            messages: [],
            banners: null,
            bannerToShowIndex: 0,
            ...data,
        };

    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    messageToggle() {
        this.setState(prevState => ({
            messageModal: !prevState.messageModal
        }));
    }

    pageTitle() {
        return this.ad.name;
    }

    getDestroyableMembers(): IDestroy[] {
        return [
            this.ad,
            this.publicMessage
        ];
    }

    static fetchData(match) {

        const ad = new Ad(false);
        const publicMessage = new PublicMessage(false);

        return {
            ad: new Promise((resolve, reject) => {
                ad.loadById(match.params.id).then((response: IAd | AxiosError) => {
                    const aError: AxiosError = response as AxiosError;
                    const ads: IAd = response as IAd;

                    if (!aError.response) {
                        return resolve(ads)
                    } else {
                        reject(ads)
                    }
                }).catch((error: AxiosError) => {
                    reject(error)
                });
            }),
            messages: new Promise((resolve, reject) => {

                publicMessage.loadByAdId(match.params.id).then((response: AxiosResponse) => {
                    return resolve(response.data)
                }).catch((error: AxiosError) => {
                    reject(error)
                })
            })
        }

    }


    componentDidMount() {
        this.ad.loadById(this.props.match.params.id).then((response: IAd | AxiosError) => {
            const aError: AxiosError = response as AxiosError;
            const ads: IAd = response as IAd;

            if (!aError.response) {
                this.ad.setFromPlainObject(ads)

                if (this.ad.status_id == 1) {

                    if (!(this.ad.ad_status_id == AdStatus.APPROVED || this.ad.ad_status_id == AdStatus.DUPLICATE_APPROVED || this.ad.ad_status_id == AdStatus.FINISHED || (this.ad.ad_status_id == AdStatus.REJECTED && this.ad.user.id == Identity.id)) && (this.ad.user.id != Identity.id && Identity.id != this.ad.created_by)) {
                        document.location.pathname = Path.FORBIDDEN;
                    }
                    else if ((this.ad.ad_status_id == AdStatus.APPROVED || this.ad.ad_status_id == AdStatus.DUPLICATE_APPROVED) && this.ad.user.id != Identity.id && !this.increasedViewCount) {
                        this.ad.increaseViewCount(this.ad.id);
                        this.increasedViewCount = true;
                    }

                    this.setState({ loaded: true })

                    if (this.ad.id && this.ad.expires_at && moment().isSameOrAfter(this.ad.expires_at.toString())) {
                        this.setState({
                            isReadOnly: true
                        })
                    }

                    this.publicMessage.loadByAdId(this.ad.id).then((response: AxiosResponse) => {
                        this.setState({ messages: response.data })
                    }).catch((error: AxiosError) => {
                        console.log(error)
                    })
                } else {
                    window.location.replace(Path.NOT_FOUND);
                }
            } else {
                window.location.replace(Path.NOT_FOUND);
            }

            super.componentDidMount();
        });

    }


    readInput() {
        const plainObject: IPublicMessage = {};

        for (const key in this.messageInputs) {

            if (this.messageInputs.hasOwnProperty(key) && this.messageInputs[key].current) {
                plainObject[key] = this.messageInputs[key].current.getValue();
                this.messageInputs[key].current.removeError();
            }
        }

        this.publicMessage.setFromPlainObject(plainObject);

        return plainObject;
    }

    validate(event: any): boolean {

        this.readInput();

        const valid = this.publicMessage.validate();

        if (!valid) {
            const errors: { [attr: string]: string } = this.publicMessage.getErrors();
            this.fillInputsWithErrors(errors, this.messageInputs);

        }

        event.preventDefault();

        return valid;
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                inputs[key].current.setError(errors[key]);
            }
        }
    }

    handleContentChange(event: React.ChangeEvent<HTMLInputElement>) {

        this.setState({
            content: event.target.value
        });
    }


    sendMessage(event: React.MouseEvent<HTMLInputElement>) {

        let plainObject = this.readInput();
        plainObject.ad_id = this.ad.id;
        plainObject.to_user = this.ad.user.id;
        plainObject.subject = "Poruka za oglas: " + this.ad.name;

        this.publicMessage.setFromPlainObject(plainObject);

        const valid = this.validate(event);

        if (valid) {
            this.publicMessage.createNew(plainObject).then((response: AxiosResponse) => {
                plainObject = response.data;

                this.publicMessage.loadByAdId(this.ad.id).then((response: AxiosResponse) => {
                    this.messageInputs.content.current.setValue(null);
                    this.setState({ messages: response.data, content: "" })
                }).catch((error: AxiosError) => {
                    console.log(error)
                })

            }).catch((error: AxiosError) => {
                Util.notification("error", "Desila se greška, poruka nije poslata.", 1500)
            });
        }
    }


    handleChangeFont(arg) {
        this.setState({ size: arg });
    }

    renderContent() {

        const fontSize = this.state.size; //kupi trenutnu veličinu fonta
        const adlink = typeof window !== 'undefined' ? window.location.href : (process.env.REACT_APP_URL + "/oglas/" + this.ad.id) //generiše link za share
        const price = this.ad.price ? Util.formatMoney(this.ad.price) : 'Po dogovoru'; //ispisuje cijenu ili ako je prazno "Po dogovoru"
        const exchange = this.ad.exchange_enabled ? this.ad.exchange_description : 'Korisnik ne želi zamjenu';
        let publicMessagesForm = null;
        const cat = this.state.loaded ? this.ad.ad_subcategory : null;
        if (this.state.loaded && !this.ad.user) {
            if (typeof window !== 'undefined') {
                document.location.pathname = Path.NOT_FOUND.toString();
            }
        }


        if (this.state.loaded) {
            if (this.state.banners == null) {
                this.state.banners = [];
                this.bannerModel.loadByCategoryId(this.ad.ad_category_id)
                    .then((response: IBanner[] | AxiosError) => {
        
                    const aError: AxiosError = response as AxiosError;
                    const banners: IBanner[] = response as IBanner[];
        
                    if (!aError.response) {
                        const indexToShow = Math.floor(Math.random() * banners.length);
                        this.setState({
                            banners: banners,
                            bannerToShowIndex: indexToShow
                        });
                    }
        
                }).catch((error: AxiosError) => {
                    console.log(error);
                });
            }

            if (this.ad.user.verified && !Identity.isGuest && (AdStatus.APPROVED == this.ad.ad_status_id || AdStatus.DUPLICATE_APPROVED == this.ad.ad_status_id)) {
                publicMessagesForm = (
                    <div className="epk-message">
                        <Form>
                            <FormGroup>
                                <TextAreaInput
                                    rows={2}
                                    ref={this.messageInputs.content}
                                    onBlur={this.validate.bind(this)}
                                    initialValue={this.state.content}
                                    onChange={this.handleContentChange}
                                />
                                <Button color="epk-primary" onClick={this.sendMessage}>Pošalji javno pitanje</Button>
                            </FormGroup>
                        </Form>
                    </div>);
            } else if (Identity.isGuest && (AdStatus.APPROVED == this.ad.ad_status_id || AdStatus.DUPLICATE_APPROVED == this.ad.ad_status_id)) {
                publicMessagesForm = (
                    <div className="epk-message">
                        <Form>
                            <FormGroup>
                                <TextAreaInput rows={2} />
                                <Button color="epk-primary" onClick={this.messageToggle}>Pošalji javno pitanje</Button>
                            </FormGroup>
                        </Form>
                    </div>);
            }

        }

        const messageModalText = (
            <Modal isOpen={this.state.messageModal} toggle={this.messageToggle}>
                <ModalHeader toggle={this.messageToggle}>Javno pitanje</ModalHeader>
                <ModalBody>
                    Poštovani, slanje javnih i privatnih poruka je dostupno samo logovanim i registrovanim
                    korisnicima EPK portala. Da biste komunicirali sa oglašivačima možete se logovati putem
                    Facebook ili Gmail naloga na sljedećem linku: <Link to={Path.LOGIN}>https://ekioglasi.ba/prijava</Link>. Za detaljnije informacije o
                    usluzi <Link to={Path.EPK}>EKI Poslovni klub (EPK)</Link>, <Link to={Path.TERMS}>pravilima i uslovima korištenja EPK portala</Link> i mogućnostima
                    koje nude pogledajte na stranici <Link to={Path.FAQ}>Često postavljana pitanja (ČPP)</Link>.
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.messageToggle}>Zatvori</Button>
                </ModalFooter>
            </Modal>
        )

        let adStatus = null;

        if (this.state.loaded) {
            if (this.ad.ad_status_id == AdStatus.PENDING) {
                adStatus = (
                    <>
                        <div id={"na-cekanju-" + this.ad.id} className="after-title-info">
                            <Icon name="cekanje" /> <div className="status-title-name">(Na čekanju)</div>
                        </div>
                        <UncontrolledPopover trigger="legacy" target={"na-cekanju-" + this.ad.id}>
                            <PopoverHeader>Na čekanju</PopoverHeader>
                            <PopoverBody>Nakon unosa vaš oglas će biti pregledan i odobren za objavu. Oglasi će se odobravati za prikazivanje u što kraćem roku, a najkasnije od narednog radnog dana.</PopoverBody>
                        </UncontrolledPopover>
                    </>
                )
            } else if (this.ad.ad_status_id == AdStatus.REJECTED) {
                adStatus = (
                    <>
                        <div id={"odbijeno-" + this.ad.id} className="after-title-info">
                            <Icon name="odbijen" /> <div className="status-title-name">(Odbijeno)</div>
                        </div>
                        <UncontrolledPopover trigger="legacy" target={"odbijeno-" + this.ad.id}>
                            <PopoverHeader>Odbijeno</PopoverHeader>
                            <PopoverBody>Molimo vas da se prilikom objave oglasa vodite smjernicama za objavu oglasa koje su navedene u <a href="/uslovi-koristenja" target="_blank">uslovima korištenja</a> EPK portala. Oglasi sa nedozvoljenim sadržajem i neprimjerenim fotografijama neće biti objavljeni (stavka 6. Pravila i <a href="/uslovi-koristenja" target="_blank">uslovi korištenja</a> EPK portala. Oglas treba da sadrži isključivo podatke o predmetu koji se prodaje ili usluzi koja se pruža. Naslov oglasa i opis treba da se odnose samo na predmet prodaje ili uslugu. U oglasima nije dopuštena upotreba fotografija sa drugih oglasnih sajtova ili iz oglasa drugih oglašivača na EPK portalu. Oglas mora svojim sadržajem da pripada kategoriji u kojoj je postavljen.</PopoverBody>
                        </UncontrolledPopover>
                    </>
                )
            }
        }


        return (
            <>
                <Helmet>
                    <title>{this.ad.name + " - EKI OGLASI"}</title>
                    <meta name="description" content={this.ad.short_desc} />
                    <meta property="og:title" content={this.ad.name} />
                    <meta property="og:description" content={this.ad.short_desc} />
                    <meta property="twitter:title" content={this.ad.name} />
                    <meta property="twitter:description" content={this.ad.short_desc} />
                </Helmet>
                <div className="epk-single-ad">
                    <Container>
                        <div className="epk-single-ad-section" style={{ fontSize: fontSize }}>
                            <Row>
                                <Col md="8">
                                    <h1>
                                        {!this.state.loaded ? <i className="title-loader w50" /> : this.ad.name}
                                        {adStatus}

                                        {this.state.isReadOnly ?
                                            (
                                                <>
                                                    <div className="after-title-info" id={"ad-info-" + this.ad.id} >
                                                        <Icon name="readonly" /> <div className="status-title-name">(Oglas je istekao)</div>
                                                        <UncontrolledPopover trigger="legacy" target={"ad-info-" + this.ad.id}>
                                                            <PopoverHeader>Oglas je istekao</PopoverHeader>
                                                            <PopoverBody>Vaš oglas je istekao. Provjerite datum trajanja oglasa ili datum trajanja vašeg članstva u EPK.</PopoverBody>
                                                        </UncontrolledPopover>
                                                    </div>
                                                </>
                                            ) : (
                                                null
                                            )}
                                    </h1>
                                </Col>
                                <Col md="4">
                                    <Nav className="epk-help-ad">
                                        <FontSize handleChangeFont={this.handleChangeFont.bind(this)} />
                                        <Share link={adlink} title={this.ad.name} />
                                    </Nav>
                                </Col>
                                {this.state.loaded ? (
                                    <>
                                        <div className="col-md-4">
                                            <Images ad={this.ad} />
                                            <InfoDetails ad={this.ad} loaded={this.state.loaded} />
                                        </div>

                                        <Col md="8">
                                            <Row>

                                                <Col xl="8">
                                                    <Row>
                                                        <Col xs="12">
                                                            <BreadcrumbNav
                                                                catName={cat.ad_category.name}
                                                                catSlug={cat.ad_category.slug} catID={cat.ad_category.id}
                                                                subID={cat.id} subName={cat.name}
                                                                loaded={this.state.loaded}
                                                            />
                                                        </Col>
                                                        <InfoBox
                                                            price={price}
                                                            location={this.ad.municipality.name}
                                                            condition={this.ad.ad_item_condition ? this.ad.ad_item_condition.caption : "Nepoznato"}
                                                            loaded={this.state.loaded}
                                                        />
                                                        <Col xs="12">
                                                            <div className="epk-ad-detail">
                                                                <Row>
                                                                    <Info
                                                                        shortDesc={this.ad.short_desc}
                                                                        exchangeDesc={exchange}
                                                                        desc={this.ad.description ? this.ad.description : 'Oglas nema opis'}
                                                                        loaded={this.state.loaded}
                                                                    />
                                                                    <Col xs="12">
                                                                        <h4 className="epk-ad-info-title epk-add-margin">Javna
                                                                pitanja</h4>
                                                                        <PublicMessages
                                                                            messages={this.state.messages}
                                                                            ad={this.ad} />
                                                                        {this.state.isReadOnly ? null : publicMessagesForm}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xl="4">
                                                    <Profile ad={this.ad} loaded={this.state.loaded} isReadOnly={this.state.isReadOnly} />
                                                    { (this.state.loaded && this.state.banners !== null && this.state.banners.length > 0) ? 
                                                        <Link to={this.state.banners[this.state.bannerToShowIndex].url} target="_blank" >
                                                            <img style={{marginTop: '1rem'}} className="d-block img-fluid" src={this.state.banners[this.state.bannerToShowIndex].image_path}
                                                                alt={this.state.banners[this.state.bannerToShowIndex].name} />
                                                        </Link> :
                                                        null
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>)
                                    : null}

                            </Row>
                        </div>
                    </Container>
                    {messageModalText}
                </div>
            </>
        );
    }
}
