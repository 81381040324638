import React from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Button, Alert, Form, FormGroup, Label, Input, FormFeedback, FormText, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup, Spinner } from 'reactstrap';
import { Path } from "../container/Path";
import { withRouter } from "../../common/components/routing/Router";
import EpkPage from "./EpkPage";
import { Container as PageContainer } from "../../index";
import { User, IUser } from "../models/User";
import { IDestroy } from "../../common/components/pages/IDestroy";
import { AxiosError, AxiosResponse } from "axios";
import Identity from "../user/Identity";
import Select from 'react-select';
import { customStyles } from './user/selectstyles';
import Icon from '../custom/Icon';
import { Municipality } from '../models/Municipality';
import Util from '../custom/Util';
import { EmailInput } from '../../common/components/widgets/form/input/EmailInput';
import { TextInput } from '../../common/components/widgets/form/input/TextInput';
import { PasswordInput } from '../../common/components/widgets/form/input/PasswordInput';
import Noty from "noty";
import { Role } from '../user/Role';
import { withSecurity } from '../../common/security/Security';

@withRouter(Path.USEREDIT.toString(), PageContainer)
@withSecurity([Role.USER.toString(), Role.CLIENT.toString(), Role.LOAN_OFFICER.toString()], Identity, Path.HOMEPAGE)
export default class EditUserPage extends EpkPage {

    private municipality: Municipality = new Municipality();
    private user: User = new User();


    private inputs: { [key: string]: React.RefObject<any>; } = {

        email: React.createRef(),
        phone_number: React.createRef(),
        password: React.createRef(),
        password_confirmed: React.createRef()

    };


    constructor(props) {
        super(props);

        if (Identity.id != this.props.match.params.id) {
            if (typeof window !== 'undefined') {
                document.location.pathname = Path.FORBIDDEN
            }
        }

        this.saveMunicipality = this.saveMunicipality.bind(this);
        this.saveEmail = this.saveEmail.bind(this);
        this.savePhone = this.savePhone.bind(this);
        this.getValidateHandle = this.getValidateHandle.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.getValidateHandle = this.getValidateHandle.bind(this);
        this.sendVerificationEmail = this.sendVerificationEmail.bind(this);
        this.sendVerificationSms = this.sendVerificationSms.bind(this);
        this.verifyCode = this.verifyCode.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.uploadFileToServer = this.uploadFileToServer.bind(this);
        this.deletePicture = this.deletePicture.bind(this);

        this.state = {
            isLoading: true,
            modalPhone: false,
            municipalities: [],
            selectedMunicipality: null,
            emailValue: "",
            phoneValue: "",
            verificationCode: null,
            emailVerified: true,
            phoneVerified: true,
            reload: false,
            smsSent: false
        };

        this.togglePhone = this.togglePhone.bind(this);
    }

    togglePhone() {

        if (!this.state.smsSent) {
            this.sendVerificationSms();

            this.setState({
                smsSent: true
            });
        }


        this.setState({
            modalPhone: !this.state.modalPhone
        });
    }

    sendVerificationEmail() {

        this.user.verifyData({ email: this.state.emailValue }).then((response: AxiosResponse) => {
            Util.notification("success", "Poslat Vam je verifikacijski email.", 10000);
        }).catch((error: AxiosError) => {
            const aError: AxiosError = error;
            Util.notification("error", "Desila se greška. Email nije mogao biti poslat.", 10000);
        });
    }


    sendVerificationSms() {

        this.user.verifyData({ phone_number: this.state.phoneValue })
            .catch((error: AxiosError) => {
                const aError: AxiosError = error;
                Util.notification("error", "Desila se greška. SMS nije mogao biti poslat.", 10000);
            });
    }

    verifyCode() {

        if (this.state.verificationCode) {

            this.user.verifyToken(this.state.verificationCode)
                .then((response: AxiosResponse) => {
                    this.setState({
                        modalPhone: !this.state.modalPhone,
                        phoneVerified: true
                    });
                    Util.notification("success", "Uspješno ste verifikovali svoj broj telefona.", 10000);
                })
                .catch((error: AxiosError) => {
                    Util.notification("error", "Desila se greška. Kod nije validan.", 10000);
                });
        } else {
            Util.notification("error", "Vrijednost koju ste unijeli nije validna.", 10000);

        }
    }

    changePassword(event: React.MouseEvent<HTMLInputElement>) {

        this.user.changeScenario(User.SCENARIO.CHANGE_PASSWORD);

        if (this.getValidateHandle(event)) {
            this.user.updatePassword(this.user)
                .then((response: AxiosResponse) => {
                    Util.notification("success", "Uspješno ste promijenili lozinku.", 10000);
                })
                .catch((error: AxiosError) => {
                    Util.notification("error", "Desila se greška. Promjena lozinke nije uspjela.", 10000);
                });
        }
    }

    pageTitle() {
        return "Postavke profila - " + process.env.REACT_APP_TITLE_PREFIX;
    }

    getDestroyableMembers(): IDestroy[] {
        return [
            this.user,
            this.municipality
        ];
    }

    private readInfo(): IUser {

        const plainObject: IUser = {};

        for (const key in this.inputs) {

            if (this.inputs.hasOwnProperty(key) && this.inputs[key].current) {
                plainObject[key] = this.inputs[key].current.getValue();
                this.inputs[key].current.removeError();
            }
        }

        this.user.setFromPlainObject(plainObject);

        return plainObject;
    }

    getValidateHandle(event: React.MouseEvent<HTMLInputElement>) {

        this.readInfo();
        const valid = this.user.validate();

        if (!valid) {
            const errors: { [attr: string]: string } = this.user.getErrors();
            this.fillInputsWithErrors(errors, this.inputs);
        }

        event.preventDefault();

        return valid;
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                inputs[key].current.setError(errors[key]);
            }
        }
    }

    public componentDidMount() {

        const id = this.props.match.params.id;

        this.user.loadById(id).then((response: IUser | AxiosError) => {
            const aError: AxiosError = response as AxiosError;
            const users: IUser = response as IUser;

            if (!aError.response) {
                this.user.setFromPlainObject(users);

                if (this.user.id != Identity.id) {
                    this.user.newView(this.user.id, {
                        ip_address: Identity.ip_address,
                        user_agent: Identity.user_agent,
                        user_id: Identity.id == 0 ? null : Identity.id
                    })
                }

                const municipality = JSON.parse(this.user.municipality as any);

                this.setState({
                    isLoading: false,
                    selectedMunicipality: municipality ? { value: municipality.id, label: municipality.name } : null,
                    emailValue: this.user.email ? this.user.email : "",
                    phoneValue: this.user.phone_number ? this.user.phone_number : "",
                    emailVerified: this.user.email_verified,
                    phoneVerified: this.user.phone_number_verified
                });
            }

            super.componentDidMount();

        });


        this.municipality.createDataSource(this.municipality.resourceNamePlural + "?sort=name").getListPlain({})
            .then((response: AxiosResponse) => {

                this.setState({
                    municipalities: response.data
                });

            }).catch((error: AxiosError) => {
                Util.notification("error", "Opštine ne mogu biti učitane.", 1500)
            });
    }


    handleMunicipalityChange = (selectedOption) => {

        this.setState({ selectedMunicipality: selectedOption });
    }

    handleEmailChange = (event: React.MouseEvent<HTMLElement>) => {
        const target = event.target as HTMLInputElement;

        this.setState({ emailValue: target.value });
    }

    handleCodeChange = (event: React.MouseEvent<HTMLElement>) => {
        const target = event.target as HTMLInputElement;

        this.setState({ verificationCode: target.value });
    }

    handlePhoneChange = (event: React.MouseEvent<HTMLElement>) => {
        const target = event.target as HTMLInputElement;

        this.setState({ phoneValue: target.value });
    }

    saveMunicipality(event: React.MouseEvent<HTMLInputElement>) {

        if (this.state.selectedMunicipality) {
            const data: IUser = { municipality_id: this.state.selectedMunicipality.value ? this.state.selectedMunicipality.value : null }

            this.user.update(data)
                .then((response: AxiosResponse) => {
                    this.user.setFromPlainObject(response as IUser);
                    Util.notification("success", "Vaša lokacija je uspješno spremljena.", 10000);
                }).catch((error: AxiosError) => {
                    Util.notification("error", "Vaša lokacija nije mogla biti spremljena.", 10000);
                });
        } else {
            Util.notification("warning", "Morate odabrati opštinu.", 10000);
        }
    }

    saveEmail(event: React.MouseEvent<HTMLInputElement>) {

        if (this.state.emailValue && this.getValidateHandle(event)) {
            const data: IUser = {
                email: this.state.emailValue,
                email_verified: false
            }

            this.user.update(data)
                .then((response: AxiosResponse) => {
                    this.user.setFromPlainObject(response as IUser);
                    Util.notification("success", "Vaš email je uspješno spremljen.", 10000);
                    this.setState({ emailVerified: false })
                }).catch((error: AxiosError) => {
                    Util.notification("error", "Vaš email nije mogao biti spremljen.", 10000);
                });
        } else {
            Util.notification("warning", "Morate upisati ispravnu vrijednost.", 10000);
        }
    }

    savePhone(event: React.MouseEvent<HTMLInputElement>) {

        this.user.changeScenario(User.SCENARIO.CHANGE_PASSWORD_PHONE);

        const phoneNumber = this.state.phoneValue;

        if (phoneNumber.slice(0, 4) == "3876") {
            if (this.state.phoneValue && this.getValidateHandle(event)) {
                const data: IUser = {
                    phone_number: this.state.phoneValue,
                    phone_number_verified: false
                }

                this.user.update(data)
                    .then((response: AxiosResponse) => {
                        this.user.setFromPlainObject(response as IUser);
                        Util.notification("success", "Vaš broj telefona je uspješno spremljen.", 10000);
                        this.user.changeScenario();

                        this.setState({ phoneVerified: false })
                    }).catch((error: AxiosError) => {
                        Util.notification("error", "Vaš broj telefona nije mogao biti spremljen.", 10000);
                    });
            } else {
                Util.notification("warning", "Morate upisati ispravnu vrijednost.", 10000);
            }
        } else {
            Util.notification("warning", "Format telefona nije validan (npr. 3876xxxxxxx)", 10000);
        }
    }

    private uploadFileToServer() {
        const fileInputs: HTMLInputElement = typeof window !== 'undefined' ? document.getElementById("avatar-upload") as HTMLInputElement : null;
        const data = new FormData();

        //Append files to form data
        const files = fileInputs.files;
        for (let i = 0; i < files.length; i++) {

            const pattern = /image-*/;

            if (!files[i].type.match(pattern)) {
                Util.notification("error", "Format slike nije validan.", 10000);
                return;
            } else {
                data.append('files', files[i], files[i].name);
            }
        }

        this.user.uploadImage(data).then((response: AxiosResponse) => {
            this.user.saveImage(response as IUser).then((response: AxiosResponse) => {
                Util.notification("success", "Vaša profila slika je uspješno spremljena.", 10000);

                this.user.setFromPlainObject(response.data);
                this.setState({ reload: true });
            })
        }).catch((error: AxiosError) => {
            Util.notification("error", "Vaša profilna slika nije mogla biti spremljena.", 10000);
        })

    }

    deletePicture() {

        const n = new Noty({
            text: "Jeste li sigurni da želite izbrisati?",
            buttons: [
                Noty.button('DA', 'btn btn-primary', () => {

                    const data: IUser = { avatar: null };
                    this.updateUser(data);
                    n.close();
                    if (typeof window !== 'undefined') {
                        document.location.reload();
                    }

                }, { id: 'button1', 'data-status': 'ok' }),

                Noty.button('NE', 'btn btn-secondary', function () {
                    n.close();
                })
            ],
            theme: 'bootstrap-v4',
            layout: 'center',
            progressBar: true
        });

        n.show();

    }

    updateUser(data: IUser) {

        this.user.update(data)
            .then((response: AxiosResponse) => {
                Util.notification("success", "Uspješno ste izbrisali profilnu sliku.", 10000);

                this.user.setFromPlainObject(response as IUser);
            }).catch((error: AxiosError) => {
                Util.notification("error", "Vaša slika nije mogla biti izbrisana.", 10000);
            })
    }

    renderContent() {

        const opcine = this.state.municipalities
            .map((element, index) => { return { 'label': element.name, 'value': element.id } })


        let imageSettings;

        if (this.state.isLoading) {
            imageSettings = <> <i className="title-loader w100" /> <i className="title-loader w100" /> </>
        } else {
            imageSettings = (
                <ButtonGroup>
                    <Button
                        color="primary"
                        onClick={this.deletePicture}
                    ><Icon name="trash" /> Izbriši</Button>
                    <label
                        className="btn btn-primary"
                        style={{ marginBottom: "0px", fontSize: "16px" }}
                        color="primary"
                        onChange={this.uploadFileToServer}
                    >
                        <Icon name="upload" />
                        Dodaj novu
                                                            <input
                            id="avatar-upload"
                            type="file"
                            accept="image/*"
                            ref="input"
                            style={{ display: "none" }}
                        />
                    </label>
                </ButtonGroup>
            )
        }

        const listFormLoader = [];

        for (let i = 1; i <= 7; i++) {
            listFormLoader.push(
                <React.Fragment key={i}>
                    <i className="content-loader w30" />
                    <i className="title-loader w100" />
                    <i className="title-loader w100" />
                    <i className="content-loader w100" />
                </React.Fragment>
            )
        }

        let otherSettings;

        let phoneVerificationText;
        let phoneVerificationButton;

        if (this.user.phone_number) {
            phoneVerificationText = this.state.phoneVerified ? null : 'Verifikovani korisnici primaju i besplatne SMS notifikacije!';
            phoneVerificationButton = this.state.phoneVerified ? null : <a onClick={this.togglePhone}> Verifikuj</a>;
        }

        if (this.state.isLoading) {
            otherSettings = <> {listFormLoader} </>
        } else {
            otherSettings = (
                <>
                    <Form>
                        <FormGroup>
                            <Row className="no-gutters">
                                <Label for="Email"> Verifikacija e-mail adrese za slučaj zaboravljene lozinke </Label>
                                <div className="verfiy-info">{this.state.emailVerified ? null : 'Verifikovani korisnici primaju i besplatne email notifikacije!'}
                                    {this.state.emailVerified ? null : <a onClick={this.sendVerificationEmail}> Verifikuj</a>}
                                </div>
                            </Row>
                            <Row className="no-gutters">
                                <Col xs="12" md="8">
                                    <FormGroup>
                                        <EmailInput
                                            id="Email"
                                            ref={this.inputs.email}
                                            initialValue={this.state.emailValue}
                                            onChange={this.handleEmailChange}
                                            onBlur={this.getValidateHandle}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="4"><Button color="epk-verify" onClick={this.saveEmail}><Icon name="edit" /> Izmijeni</Button></Col>
                            </Row>
                        </FormGroup>
                    </Form>

                    <Form>
                        <FormGroup>
                            <Label for="Phone">Verifikacija broja telefona za slučaj zaboravljene lozinke</Label>
                            <div className="verfiy-info">{phoneVerificationText} {phoneVerificationButton}</div>
                            <Modal isOpen={this.state.modalPhone} toggle={this.togglePhone}>
                                <ModalHeader toggle={this.togglePhone}>Verifikuj telefon</ModalHeader>
                                <ModalBody>
                                    Verifikacija broja mobilnog telefona će omogućiti lakšu komunikaciju sa drugim članovima EPK portala a ujedno će biti i dodatna sigurnosna mjera za zaštitu vaših podataka. <b>{this.state.phoneValue ? this.state.phoneValue : '38760000000'}</b> <br></br>
                                    <br></br>
                                    <Form>
                                        <FormGroup>
                                            <Label for="phoneId">Upišite četverocifreni kod</Label>
                                            <TextInput
                                                name="address"
                                                id="phoneId"
                                                onChange={this.handleCodeChange}
                                            />
                                        </FormGroup>
                                        <div className="submitbox">
                                            <Button color="primary" onClick={this.verifyCode}><Icon name="verify" /> Verifikuj</Button>
                                        </div>
                                    </Form>
                                </ModalBody>
                            </Modal>
                            <Row className="no-gutters">
                                <Col xs="12" md="8">
                                    <FormGroup>
                                        <TextInput
                                            id="Phone"
                                            placeholder="npr. 38760000000"
                                            ref={this.inputs.phone_number}
                                            initialValue={this.state.phoneValue}
                                            onChange={this.handlePhoneChange}
                                            onBlur={this.getValidateHandle}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="4"><Button color="epk-verify" onClick={this.savePhone}><Icon name="edit" /> Izmijeni</Button></Col>
                            </Row>
                        </FormGroup>
                    </Form>

                    <Form>
                        <FormGroup>
                            <Label for="Location">Opština u kojoj živite</Label>
                            <Row className="no-gutters">
                                <Col xs="12" md="8">
                                    <FormGroup>
                                        <Select
                                            styles={customStyles}
                                            value={this.state.selectedMunicipality}
                                            onChange={this.handleMunicipalityChange}
                                            options={opcine}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Sve"
                                            id="Location"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="4"><Button color="epk-verify" onClick={this.saveMunicipality}><Icon name="sacuvaj" /> Spremi</Button></Col>
                            </Row>
                        </FormGroup>
                    </Form>

                    <Form>
                        <Label>Promjena lozinke</Label>
                        <div className="password-re">
                            <Row className="no-gutters">
                                <Col xs="12" md="12">
                                    <FormGroup>
                                        <PasswordInput
                                            id="Password"
                                            placeholder="Nova lozinka"
                                            ref={this.inputs.password}
                                            onBlur={this.getValidateHandle}
                                            autoComplete="new-password"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="12">
                                    <FormGroup>
                                        <PasswordInput
                                            id="rePassword"
                                            placeholder="Ponovite lozinku"
                                            ref={this.inputs.password_confirmed}
                                            onBlur={this.getValidateHandle}
                                            autoComplete="new-password"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md={{ size: 4, offset: 8 }}>
                                    <Button color="epk-verify" onClick={this.changePassword}><Icon name="sacuvaj" /> Spremi lozinku</Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </>
            )
        }

        return (
            <div className="epk-edit-user-profile">
                <Container>
                    <div className="epk-edit-user-profile-section epk-section">
                        <Row>
                            <Col xs="12">
                                <h1 className="epk-container-h1">{this.state.isLoading ? <i className="content-loader w30" /> : 'Postavke profila'}</h1>
                            </Col>
                            <Col xl="4">
                                <div className="epk-profile-info">
                                    <Form id="avatar-upload-form">
                                        <FormGroup>
                                            <div className="epk-profile-info-section">
                                                <div className="epk-profile-image">
                                                    {this.state.isLoading ?
                                                        <Spinner color="primary" /> :
                                                        <img title={this.user.username} src={this.user.avatar != null ? this.user.avatar : "/profile/no-img-profile.png"} />
                                                    }
                                                </div>
                                                <Label className="edit-title">{this.state.isLoading ? '' : 'Profilna slika'}</Label>
                                                <div className="edit-profile-photo">
                                                    {imageSettings}
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </Col>
                            <Col xl="8">
                                {otherSettings}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div >
        );
    }
}

