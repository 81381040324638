import { IUserIdentity, IUserIdentityParams } from "../../common/identity/UserIdentity";
import { Role } from "./Role";
import { User } from "../models/User";
import { AxiosResponse, AxiosError } from "axios";

/**
 * Identity class designed as singleton to be used through whole application
 * and keep current user information.
 */

class Identity implements IUserIdentity {
    private _id?: number;
    private _username?: string;
    private _auth_key?: string;
    private _auth_key_expires_at?: Date;
    private _email?: string;
    private _role: string = Role.GUEST;
    private _isGuest: boolean = true;
    private _ip_address: string;
    private _user_agent: string;
    private _is_epk_info: boolean;
    private _remember_me: boolean;

    public constructor() {
        const cookieInfo: string | null = this.getCookie("user_info");

        if (null != cookieInfo) {
            const userInfo: any = JSON.parse(cookieInfo);
            userInfo.auth_key_expires_at = new Date(userInfo.auth_key_expires_at);
            this.setUserIdentity(userInfo);
        }

        if (typeof window !== 'undefined' && localStorage.getItem('user_info')) {
            this.getUserIdentityFromStorage();
        }
    }

    private setCookie(name: string, value: any, expireDate: Date): void {

        if (typeof window !== 'undefined') {
            const expires: string = "; expires=" + (this._remember_me ? expireDate.toUTCString() : "0");
            document.cookie = name + "=" + (value || "") + ";path=/;" + expires;
        }
    }

    private getCookie(name: string): string | null {
        const nameEQ: string = name + "=";
        if (typeof window !== 'undefined') {
            const ca: string[] = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1, c.length);
                }

                if (c.indexOf(nameEQ) == 0) {
                    return c.substring(nameEQ.length, c.length);
                }
            }
        }

        return null;
    }

    private eraseCookie(name: string): void {
        if (typeof window !== 'undefined') {
            document.cookie = name + '=; Max-Age=-99999999'
        }
    }

    private emptylocalStorage(name: string): void {
        if (typeof window !== 'undefined') {
            localStorage.removeItem(name)
        }
    }

    public setUserIdentity(userIdentityParams: IUserIdentityParams): void {
        this._id = userIdentityParams.id;
        this._auth_key = userIdentityParams.auth_key;
        this._email = userIdentityParams.email;
        this._role = userIdentityParams.role;
        this._username = userIdentityParams.username;
        this._auth_key_expires_at = userIdentityParams.auth_key_expires_at;
        this._isGuest = false;
        this._remember_me = userIdentityParams.remember_me;

        this.setCookie('user_info', JSON.stringify(userIdentityParams), this._auth_key_expires_at);

    }

    public getUserIdentityFromStorage() {
        if (typeof window !== 'undefined') {
            const guestUserInfo = JSON.parse(localStorage.getItem('user_info'));

            this._ip_address = guestUserInfo.ip_address;
            this._user_agent = guestUserInfo.user_agent;
            this._is_epk_info = guestUserInfo.is_epk_info;
        }
    }

    public setGuestUserIdentity(): void {
        if (typeof window !== 'undefined') {

            if (localStorage.getItem('user_info') == undefined || this._is_epk_info) {

                (new User(false)).getIpFromServer().then((response: AxiosResponse | AxiosError) => {

                    const aError: AxiosError = response as AxiosError;
                    const aResponse: AxiosResponse = response as AxiosResponse;

                    const userIdentityParams: any = {
                        user_agent: navigator.userAgent,
                        is_epk_info: this._is_epk_info
                    };

                    this._user_agent = userIdentityParams.user_agent;

                    if (!aError.response) {

                        if (aResponse.data.ip) {
                            userIdentityParams.ip_address = aResponse.data.ip;
                        }
                    }

                    localStorage.setItem('user_info', JSON.stringify(userIdentityParams));
                })
            }
        }
    }

    public eraseUserIdentity(): void {
        this._id = 0;
        this._username = "";
        this._auth_key = "";
        this._auth_key_expires_at = new Date();
        this._email = "";
        this._role = Role.GUEST;
        this._isGuest = true;
        this._ip_address = null;
        this._user_agent = null;
        this._is_epk_info = false;
        this._remember_me = false;

        return this.eraseCookie('user_info');
    }

    public getRequestHeaders(): { [key: string]: string } {
        return (this.isGuest) ? {
            "Content-Type": "application/json"
        } : {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.auth_key
            };
    }

    get id(): number | undefined {
        return this._id;
    }

    get username(): string | undefined {
        return this._username;
    }

    get email(): string | undefined {
        return this._email;
    }

    get auth_key(): string | undefined {
        return this._auth_key;
    }

    get role(): string {
        return this._role;
    }

    get auth_key_expires_at(): Date {
        return this._auth_key_expires_at || new Date();
    }

    get isGuest(): boolean {
        return this._isGuest;
    }

    get user_agent(): string {
        return this._user_agent;
    }

    set user_agent(user_agent: string) {
        this._user_agent = user_agent;
    }

    get remember_me(): boolean {
        return this._remember_me;
    }

    set remember_me(remember_me: boolean) {
        this._remember_me = remember_me;
    }

    get is_epk_info(): boolean {
        return this._is_epk_info;
    }

    set is_epk_info(clicked: boolean) {
        this._is_epk_info = clicked;
        this.setGuestUserIdentity();
    }

    get ip_address(): string {
        return this._ip_address;
    }

    set ip_address(ip: string) {
        this._ip_address = ip;
    }

}

export default new Identity();
