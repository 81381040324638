import React from 'react';
import { Container, Row, Col, Button, Alert, Form, FormGroup, NavLink, } from 'reactstrap';
import { Path } from "../container/Path";
import { withRouter } from "../../common/components/routing/Router";
import EpkPage from "./EpkPage";
import { Container as PageContainer } from "../../index";
import { IDestroy } from "../../common/components/pages/IDestroy";
import Icon from '../custom/Icon';
import { Link } from 'react-router-dom';
import MessageSingle from './messages/MessageSingle';
import { IPrivateMessage, PrivateMessage } from "../models/PrivateMessage";
import { AxiosError, AxiosResponse } from "axios";
import Util from "../custom/Util";
import { IUser, User } from "../models/User";
import Identity from "../user/Identity";
import { TextAreaInput } from "../../common/components/widgets/form/input/TextAreaInput";
import moment from "moment";
import Noty from "noty";
import { Role } from '../user/Role';

@withRouter(Path.MESSAGE.toString(), PageContainer)
export default class MessagePage extends EpkPage {

    private privateMessageModel: PrivateMessage = new PrivateMessage();
    private user: User = new User();
    private customerId: number;

    private inputs: { [attr: string]: React.RefObject<any> } = {
        content: React.createRef(),
    };

    constructor(props) {
        super(props);

        this.deleteConvo = this.deleteConvo.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.readInput = this.readInput.bind(this);
        this.fillInputsWithErrors = this.fillInputsWithErrors.bind(this);
        this.validate = this.validate.bind(this);
        this.readMessage = this.readMessage.bind(this);

        this.state = {
            messages: [],
            customer: this.user,
            loaded: false,
            isFavorited: true,
        };

    }

    pageTitle() {
        return "Poruka";
    }

    getDestroyableMembers(): IDestroy[] {
        return [
            this.privateMessageModel
        ];
    }

    deleteConvo(event: React.MouseEvent<HTMLElement>) {

        const messages = this.state.messages;


        const n = new Noty({
            text: "Jeste li sigurni da želite izbrisati?",
            buttons: [
                Noty.button('DA', 'btn btn-primary', () => {


                    if (messages.length > 0) {
                        for (let i = 0; i < messages.length; i++) {
                            this.privateMessageModel.del(messages[i].id);
                        }
                    }
                    Util.notification("success", "Razgovor je uspješno izbrisan", 1000);
                    n.close();
                    if (typeof window !== 'undefined') {
                        document.location.pathname = Path.MESSAGES;
                    }

                }, { id: 'button1', 'data-status': 'ok' }),

                Noty.button('NE', 'btn btn-secondary', function () {
                    n.close();
                })
            ],
            theme: 'bootstrap-v4',
            layout: 'center',
            progressBar: true
        });

        n.show();
    }

    saveUser(event: React.MouseEvent<HTMLElement>) {
        this.user.addFavorite(this.user.id)
            .then((response: AxiosResponse) => {

                Util.notification("success", "Uspješno ste sačuvali korisnika", 1000);
            })
            .catch((error: AxiosError) => {

                Util.notification("error", "Desila se greška", 1000);
            });
    }

    readInput() {
        const plainObject: IPrivateMessage = {};

        for (const key in this.inputs) {

            if (this.inputs.hasOwnProperty(key) && this.inputs[key].current) {
                plainObject[key] = this.inputs[key].current.getValue();
                this.inputs[key].current.removeError();
            }
        }

        plainObject.from_user = Identity.id;
        plainObject.to_user = this.customerId;
        plainObject.subject = "Odgovor na poruku";
        plainObject.date_sent = (moment().format("YYYY-MM-DD HH:mm:ss").toString());
        plainObject.parent_id = this.state.messages[this.state.messages.length - 1].id;

        this.privateMessageModel.setFromPlainObject(plainObject);

        return plainObject;
    }

    readMessage(id: number | string) {
        this.privateMessageModel.read(id)
    }

    validate(event: any): boolean {

        this.readInput();

        const valid = this.privateMessageModel.validate();

        if (!valid) {
            const errors: { [attr: string]: string } = this.privateMessageModel.getErrors();
            this.fillInputsWithErrors(errors, this.inputs);

        }

        event.preventDefault();

        return valid;
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }, inputs: any) {

        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                inputs[key].current.setError(errors[key]);
            }
        }
    }

    sendMessage(event: React.MouseEvent<HTMLInputElement>) {

        let plainObject = this.readInput();

        this.privateMessageModel.setFromPlainObject(plainObject);

        const valid = this.validate(event);

        if (valid) {
            this.privateMessageModel.createNew().then((response: AxiosResponse) => {
                plainObject = response.data;

                Util.notification("success", "Poruka je poslata.", 15000);
                setTimeout(() => {
                    if (typeof window !== 'undefined') {
                        document.location.pathname = Path.MESSAGES
                    }
                }, 1550)

            }).catch((error: AxiosError) => {
                Util.notification("error", "Desila se greška, poruka nije poslata.", 1000);
            });
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.privateMessageModel.loadByParentId(this.props.match.params.id)
            .then((response: AxiosResponse) => {
                this.setState({ messages: response.data });
                const unparsedFirst = response.data[0];
                const fromUser = JSON.parse(unparsedFirst.from_user);
                const toUser = JSON.parse(unparsedFirst.to_user);

                this.customerId = (fromUser.id == Identity.id) ? toUser.id : fromUser.id;

                this.user.loadById(this.customerId)
                    .then((response: IUser) => {
                        this.user.setFromPlainObject(response);

                        this.setState({ customer: this.user, loaded: true })
                    })
            })
            .then(() => {
                this.user.favorites()
                    .then((response: AxiosResponse) => {
                        if (response.data && response.data.length > 0) {
                            response.data.forEach((element) => {
                                if (element.user_id == Identity.id && element.favorite_user_id != this.state.customer.id) {
                                    this.setState({ isLoading: false, isFavorited: false })
                                }
                            });
                        } else {
                            this.setState({ isLoading: false, isFavorited: false })
                        }
                    })
                    .catch((error: AxiosError) => {
                        Util.notification("error", "Desila se greška. Korisnik nije učitan.", 15000);

                    });
            })
            .catch((error: AxiosError) => {
                if (typeof window !== 'undefined') {
                    window.location.replace(Path.NOT_FOUND);
                }
                //Util.notification("error", "Poruke ne mogu biti učitane", 1500);
            })
    }

    renderContent() {

        let messageProfile;

        if (this.state.loaded) {

            const thumb = !Util.isAbsolutePath(this.state.customer.avatar) ? '/thumb' : '';

            messageProfile = (
                <>
                    <div className="epk-ad-profile">

                        <Link to={this.state.customer.role == Role.ADMIN
                            ? "#"
                            : Path.USER_PROFILE + "/" + this.state.customer.id + "/" + this.state.customer.username}
                            className="ad-author">
                            <div className="row align-items-center">
                                <Col xs="3"><img
                                    src={this.state.customer.avatar ? thumb + this.state.customer.avatar : "/profile/no-img-profile.png"}
                                    className="img-thumbnail epk-image" /></Col>
                                <Col xs="9">
                                    <div className="ad-author-info">
                                        <div
                                            className="ad-author-name">
                                            {this.state.customer.role == Role.ADMIN
                                                ? "ADMINISTRATOR"
                                                : this.state.customer.username}</div>
                                    </div>
                                </Col>
                            </div>
                        </Link>
                    </div>
                    <div className="epk-profile-actions-section">
                        <NavLink onClick={this.deleteConvo}><Icon name="trash" /> Izbriši
                                                    konverzaciju</NavLink>
                        {this.state.isFavorited || this.state.customer.role == Role.ADMIN ? null :
                            <NavLink onClick={this.saveUser}><Icon name="sacuvaj" /> Sačuvaj
                                                    korisnika</NavLink>
                        }

                    </div>
                </>
            )
        } else {
            messageProfile = (
                <>
                    <div className="epk-ad-profile">
                        <div className="ad-author">
                            <div className="content-loader w100" />
                        </div>
                        <div className="epk-profile-actions-section">
                            <div className="content-loader w100" />
                            <div className="content-loader w100" />
                        </div>
                    </div>
                </>
            )
        }

        let messageTitle;

        if (this.state.loaded) {
            messageTitle = (
                <h3>{this.state.messages.length > 0 ?
                    this.state.messages[this.state.messages.length - 1].subject
                    : "Poruke sa korisnikom " + this.state.customer.username
                }</h3>
            )
        } else {
            messageTitle = <h3><div className="title-loader w50" /></h3>
        }

        let messageContent;

        if (this.state.loaded) {
            messageContent = (
                this.state.messages.map(single => (
                    this.state.loaded ?
                        <MessageSingle
                            key={single.id}
                            customerRole={this.state.customer.role}
                            msg={single}
                            readMessage={this.readMessage}
                        /> : null
                ))
            )
        } else {
            messageContent = (
                <>
                    <Row>
                        <div className="epk-customer-say">
                            <Col xs="2" xl="1" className="epk-public-message-margin">
                                <div className="title-loader-small w100" />
                                <div className="title-loader-small w100" />
                            </Col>
                            <Col xs="10" xl="11" className="epk-public-message-margin">
                                <div className="content-loader w100" />
                                <div className="content-loader w70" />
                            </Col>
                        </div>
                    </Row>
                </>
            )
        }

        return (
            <div className="epk-message">
                <Container>
                    <div className="epk-message-section epk-section">
                        <Row>
                            <Col md="12">
                                <h1 className="epk-container-h1">Poruke</h1>
                            </Col>
                            <Col md="12" className="message-list">
                                <Row>
                                    <Col md="4" xl="3">
                                        {messageProfile}
                                    </Col>
                                    <Col md="8" xl="9">
                                        <div className="epk-message-header">
                                            {this.state.loaded ? <Link to={Path.MESSAGES.toString()}>Vrati na sve poruke</Link> : <div className="content-loader w30" />}
                                            {messageTitle}
                                        </div>

                                        {messageContent}

                                        <div className="epk-message">
                                            <Form>
                                                <FormGroup>
                                                    <TextAreaInput
                                                        rows={2}
                                                        ref={this.inputs.content}
                                                        onBlur={this.validate}
                                                    />
                                                    <Button onClick={this.sendMessage}
                                                        color="epk-primary">Pošalji</Button>
                                                </FormGroup>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }
}

