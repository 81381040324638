import React from 'react';
import { NavItem, Button } from 'reactstrap';
import Icon from "./Icon"

interface IShareProps {
    link: string,
    title: string,
}

function Share(props: IShareProps) {
    return (
        <>
            <div className="epk-help-ad nav left-f">
                <NavItem>
                    <Button color="facebook" className="social-button facebook" target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u=" + props.link}>
                        <Icon name="facebook" />
                    </Button>
                </NavItem>
                <NavItem>
                    <Button color="google" className="btn social-button google" href={"mailto:?&subject=" + props.title + "&body=" + props.link}>
                        <Icon name="poruka" />
                    </Button>
                </NavItem>
                <NavItem>
                    <Button color="twitter" className="btn social-button twitter" target="_blank" href={"https://twitter.com/intent/tweet?url=" + props.link+"&text=" + props.title}>
                        <Icon name="twitter" />
                    </Button>
                </NavItem>
                <NavItem>
                    <Button color="viber" className="btn social-button viber" href={"viber://forward?text=" + encodeURIComponent(props.title + " " + props.link)}>
                        <Icon name="viber" />
                    </Button>
                </NavItem>
                <NavItem>
                    <Button color="whatsapp" className="btn social-button whatsapp" href={"whatsapp://send?text=" + props.link}>
                        <Icon name="whatsapp" />
                    </Button>
                </NavItem>
            </div>
        </>
    );
}

export default Share;

