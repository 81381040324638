import { Container } from "../../index";
import { PageType } from "../../common/components/pages/Page";
import { DataModel } from "../../common/model/DataModel";
import { DataProvider } from "../../common/model/DataProvider";
import AdPage from "../pages/AdPage";
import HomePage from "../pages/HomePage";
import Categories from "../pages/Categories";
import StaticContentPage from "../pages/StaticContentPage";
import SearchPage from "../pages/SearchPage";
import { Ad } from "../models/Ad";
import { User } from "../models/User";
import { AdCategory } from "../models/AdCategory";
import { AdItemCondition } from "../models/AdItemCondition";
import { AdSubcategory } from "../models/AdSubcategory";
import { AdType } from "../models/AdType";
import { Municipality } from "../models/Municipality";
import { Page } from "../models/Page";
import { AdImage } from "../models/AdImage";
import { Search } from "../models/Search";
import UserPage from "../pages/UserPage";
import RegisterPage from "../pages/RegisterPage";
import EditUserPage from "../pages/EditUserPage";
import MessagesPage from "../pages/MessagesPage";
import MessagePage from "../pages/MessagePage";
import LoginPage from "../pages/LoginPage";
import ForgottenPasswordPage from "../pages/ForgottenPasswordPage";
import ForgottenPasswordChangePage from "../pages/ForgottenPasswordChangePage";
import AddAdPage from "../pages/AddAdPage"
import EducationPage from "../pages/EducationPage";
import EducationCategoryPage from "../pages/EducationCategoryPage";
import EmailVerificationPage from "../pages/EmailVerificationPage";
import { PublicMessage } from "../models/PublicMessage";
import ForbiddenPage from "../pages/ForbiddenPage";
import {Config} from "../models/Config";
import UserFavoritesPage from "../pages/UserFavoritesPage";
import AdFavoritesPage from "../pages/AdFavoritesPage";
import ContactPage from "../pages/ContactPage";
import AdvertisementsPage from "../pages/AdvertisementsPage";
import EducationSinglePage from "../pages/EducationSinglePage";

/**
 * Util static class for managing Container Elements.
 */
export abstract class ContainerElements {
    /**
     * Add all necessary items to container.
     */
    public static addAll() {
        this.addPages();
        this.addModels();
    }

    /**
     * Add needed pages to container.
     */
    private static addPages() {
        const pages: PageType[] = [
            UserPage,
            HomePage,
            Categories,
            AdPage,
            StaticContentPage,
            SearchPage,
            RegisterPage,
            EditUserPage,
            MessagesPage,
            MessagePage,
            LoginPage,
            ForgottenPasswordPage,
            ForgottenPasswordChangePage,
            AddAdPage,
            EducationPage,
            EducationCategoryPage,
            EmailVerificationPage,
            ForbiddenPage,
            UserFavoritesPage,
            AdFavoritesPage,
            ContactPage,
            AdvertisementsPage,
            EducationSinglePage,
        ];

        pages.forEach((page: PageType) => {
            Container.addPage(page);
        });
    }

    /**
     * Add models to container.
     */
    private static addModels() {
        const models: DataModel<any>[] = [
            new DataProvider(undefined, false),
            new Ad(false),
            new User(false),
            new Municipality(false),
            new AdCategory(false),
            new AdSubcategory(false),
            new AdType(false),
            new AdItemCondition(false),
            new Page(false),
            new Search(false),
            new AdImage(false),
            new PublicMessage(false),
            new Config(false),
        ];

        models.forEach((model: DataModel<any>) => {
            Container.addModel(model);
        });
    }
}
