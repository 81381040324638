import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import Identity from "../user/Identity";

export interface IErrorLog {
    ip_address?: string,
    user_agent?: string,
    error?: any,
    stack_trace?: any,
    location?: string,
    display?: string,
}

export class ErrorLog extends DataModel<IErrorLog> {

    public resourceName = "log-error";

    private _ip_address: string;
    private _user_agent: string;
    private _error: any;
    private _stack_trace: any;
    private _location: string;
    private _display: string;


    protected getDefaultValues(): IErrorLog {
        return {};
    }

    public createDataSource(resourceName: string = this.resourceName): RestDataSource<IErrorLog> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, {});
    }

    protected createValidator(scenario: string): IDataModelValidator<IErrorLog> {
        return new DataModelValidator<IErrorLog>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IErrorLog>, action: AnyAction): IDataModelState<IErrorLog> {
        return state;
    }

    getStoreKey(): string {
        return "ERROR_LOG";
    }

    protected setFromObj(data: IErrorLog): void {
        this._ip_address = Identity.ip_address;
        this._user_agent = Identity.user_agent;
        this._error = DataModel.safeGet(data.error, this._error);
        this._stack_trace = DataModel.safeGet(data.stack_trace, this._stack_trace);
        this._location = typeof window !== 'undefined' ? window.location.href : null;
        this._display = typeof window !== 'undefined' ? window.innerWidth + 'X' + window.innerHeight : null;

    }

    protected toObj(): IErrorLog {
        return {
            ip_address: this._ip_address,
            user_agent: this._user_agent,
            error: this._error,
            stack_trace: this._stack_trace,
            location: this._location,
            display: this._display,
        };
    }

    public createNew(data: IErrorLog): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName).plainPostOperation({
            ip_address: data.ip_address,
            user_agent: data.user_agent,
            location: data.location,
            stack_trace: data.stack_trace,
            display: data.display,
            error: data.error,
        });
    }

}

