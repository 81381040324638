import React from 'react';

function Copyright() {
    return (
        <>
            Sva prava zadržana EKI © {(new Date().getFullYear())} | Design and Development by <a href="//leftor.com" target='_blank'>LEFTOR d.o.o.</a>
        </>
    );
}

export default Copyright;
