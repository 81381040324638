import React, { Component } from 'react';
import {  Collapse, ListGroup, ListGroupItem, Input, Label } from 'reactstrap';
import { IQuery } from '../../models/Search';

interface ISearchOtherState {
    collapse: boolean;
    sa_cijenom: boolean;
    sa_zamjenom: boolean;
    sa_slikama: boolean;
}

class SearchOther extends Component<{ filterSearch: any, search: IQuery }, ISearchOtherState> {
    constructor(props: any) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            collapse: true,
            sa_zamjenom:  props.search.sa_zamjenom,
            sa_cijenom: props.search.sa_cijenom,
            sa_slikama:  props.search.sa_slikama,
        };
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    reloadSearch = (e) => {

        const search = {};
        search[e.target.id] = e.target.checked;

        this.props.filterSearch(search);

    }

    render() {
        return (
            <>
                <ListGroupItem tag="a" className="epk-filter-mod dropdown-toggle" onClick={this.toggle}>Ostali filteri</ListGroupItem>
                <Collapse isOpen={this.state.collapse}>
                    <div className="card card-body">
                        <ListGroup tag="ul" className="list-group-flush epk-filter-mod-category">
                            <div className="form-check">
                                <Input defaultChecked={this.state.sa_cijenom} type="checkbox" className="form-check-input" id="sa_cijenom" onClick={this.reloadSearch} />
                                <Label className="form-check-label" for="sa_cijenom">Samo sa unešenom cijenom</Label>
                            </div>
                            <div className="form-check">
                                <Input defaultChecked={this.state.sa_slikama} type="checkbox" className="form-check-input" id="sa_slikama" onClick={this.reloadSearch} />
                                <Label className="form-check-label" for="sa_slikama">Samo sa slikom</Label>
                            </div>
                            <div className="form-check">
                                <Input defaultChecked={this.state.sa_zamjenom} type="checkbox" className="form-check-input" id="sa_zamjenom" onClick={this.reloadSearch} />
                                <Label className="form-check-label" for="sa_zamjenom">Mogućnost zamjene</Label>
                            </div>
                        </ListGroup>
                    </div>
                </Collapse >
            </>
        );
    }
}

export default SearchOther;
