import React from 'react';
import { ILayout } from "../../common/components/layouts/ILayout";
import Header from "./Header";
import Footer from "./Footer";
import Helmet from 'react-helmet';

export default class MainLayout implements ILayout {

    public renderAroundContent(content: React.ReactNode): React.ReactNode {

        const title = "EKI OGLASI",
            desc = "EPK portal članovima EKI Poslovnog kluba omogućava jednostavno objavljivanje malih oglasa za prodaju, kupovinu, razmjenu/zamjenu, pružanje usluga, iznajmljivanje i sl. S druge strane, članovi i svi drugi posjetioci EPK portala su u mogućnosti da nesmetano pregledaju sve oglase i da međusobno komuniciraju. Nakon isticanja članstva u EKI Poslovnom klubu prestaje i mogućnost objave oglasa na EPK portalu. Tada prelazi u status posjetioca sajta koji može samo da pregleda objave i da komunicira sa korisnicima portala. Nakon reaktiviranja članstva u EPK (realizacija novog kreditnog plasmana u EKI-ju) član EPK ima ponovo pristup EPK portalu, sa svim mogućnostima koje ima svaki  EPK član.";

        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={desc} />
                    <meta name="keywords" content="eki, oglasi, mikrokrediti, mkf, poslovni, klub" />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={desc} />
                    <meta property="og:image" content="/img/epk-logo.png" />
                </Helmet>
                <Header />
                <div className="epk-content" id="epk-content">
                    <div id="epk-content-section">
                        {content}
                    </div>
                </div>
                <Footer />
            </>
        );

    }
}