import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { IBanner } from '../../models/Banner';

class CatBanner extends Component<{ banner: IBanner }, unknown> {

    render() {
        const item = this.props.banner;
        const content = (
            <Link to={item.url} target="_blank" >
                <img className="d-block img-fluid" src={item.image_path} alt={item.name} />
            </Link>
        )


        return (
            <Col lg="12" className="epk-top-slider-section">
                <br />
                <div>
                    {content}
                </div>
                <br />
            </Col>
        );

    }
}

export default CatBanner;
