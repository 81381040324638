import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IUserIdentityParams } from "../../common/identity/UserIdentity";
import { Role } from "../user/Role";
import ReactGA from 'react-ga4';

export interface ISocialLogin {
    id?: number,
    username?: string,
    provider?: string,
    identifier?: string,
    profile_cache?: unknown,
    created_at?: string,
}


export class SocialLogin extends DataModel<ISocialLogin> {

    private resourceName = "user/social-connect";

    private _username: string;
    private _id: number;
    private _provider: string;
    private _identifier: string;
    private _profile_cache: unknown;
    private _created_at: string;


    protected getDefaultValues(): ISocialLogin {
        return {};
    }


    public createDataSource(resourceName: string = this.resourceName): RestDataSource<ISocialLogin> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<ISocialLogin> {
        return new DataModelValidator<ISocialLogin>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<ISocialLogin>, action: AnyAction): IDataModelState<ISocialLogin> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<ISocialLogin | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public createNew(): Promise<any> {
        return this.createDataSource(this.resourceName)
            .plainPostOperation({
                provider: this._provider,
                username: this._username,
                identifier: this._identifier,
                profile_cache: this._profile_cache ? this._profile_cache : null
            }).then((userInfo: any) => {

                if (process.env.REACT_APP_ENV === "prod") {
                    // Slanje eventa Google Analytics-u
                    ReactGA.event({
                        category: 'Korisnik - Login',
                        action: 'Korisnik - Login'
                    });
                }

                if (userInfo.data.auth_key_expires_at) {
                    userInfo.data.auth_key_expires_at = new Date(userInfo.data.auth_key_expires_at);
                }

                if (userInfo.data.agreement_number) {
                    userInfo.data.role = Role.CLIENT;
                } else {
                    userInfo.data.role = Role.USER;
                }


                return userInfo.data;
            })
            .then((userInfo: IUserIdentityParams | AxiosError) => {
                const userIdentity: IUserIdentityParams = <IUserIdentityParams>userInfo;

                if (userIdentity.auth_key) {
                    UserIdentity.setUserIdentity(userIdentity);
                    return userInfo;
                } else {
                    return <AxiosError>userInfo;
                }
            });
    }

    public update(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({

            });
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    getStoreKey(): string {
        return "SOCIAL_LOGIN";
    }

    protected setFromObj(data: ISocialLogin): void {
        this._username = DataModel.safeGet(data.username, this._username) as string;
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._provider = DataModel.safeGet(data.provider, this._provider) as string;
        this._identifier = DataModel.safeGet(data.identifier, this._identifier) as string;
        this._profile_cache = DataModel.safeGet(data.profile_cache, this._profile_cache);
        this._created_at = DataModel.safeGet(data.created_at, this._created_at) as string;
    }


    protected toObj(): ISocialLogin {
        return {
            id: this._id,
            username: this._username,
            provider: this._provider,
            identifier: this._identifier,
            profile_cache: this._profile_cache,
            created_at: this._created_at
        };
    }


    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get provider() {
        return this._provider;
    }


    get username() {
        return this._username;
    }


    get identifier() {
        return this._identifier;
    }


    get created_at() {
        return this._created_at;
    }

    get profile_cache() {
        return this._profile_cache;
    }
}
