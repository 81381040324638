import React from 'react';
import { Container, Row, Col, Media, ListGroup, ListGroupItem, } from 'reactstrap';
import { Path } from "../container/Path";
import { withRouter } from "../../common/components/routing/Router";
import EpkPage from "./EpkPage";
import { Container as PageContainer } from "../../index";
import { Blog, IBlog } from '../models/Blog';
import { AxiosError, AxiosResponse } from 'axios';
import Util from '../custom/Util';
import { Link } from 'react-router-dom';
import { BlogCategory } from '../models/BlogCategory';
import Helmet from 'react-helmet';
import Share from '../custom/Share';
import cheerio from 'cheerio';

@withRouter(Path.EDUCATION_SINGLE.toString(), PageContainer)
export default class EducationSinglePage extends EpkPage {

    private blog: Blog = new Blog();
    private blogCategory: BlogCategory = new BlogCategory();
    private defaultBlogImage = "/files/images/edukacija.jpg";

    constructor(props) {
        super(props);
        let data = this.props.data || null;

        if (typeof window !== 'undefined') {
            const hydrationMarker = document.getElementById("hydration-marker");

            if (hydrationMarker !== null && hydrationMarker.innerHTML.length > 0) {
                data = JSON.parse(hydrationMarker.innerHTML.replace(/(&quot\;)/g, "\""));
            }
        }

        if (data) {
            if (data.blog) {
                this.blog.setFromPlainObject(data.blog);
                this.blogCategory = data.categories.find(item => item.id === data.blog.category_id);
            }
        }
        this.getHelmetImage = this.getHelmetImage.bind(this);
        this.getVideoImage = this.getVideoImage.bind(this);


        this.state = {
            postId: this.props.match.params.id,
            isLoading: true,
            blogItem: [],
            blogCategoryList: [],
            mostViews: [],
            categoryName: "",
            categoryInfo: []
        };
    }

    pageTitle() {
        return "Edukacija" + super.pageTitle();
    }


    static fetchData(match) {

        const blog = new Blog(false);

        return {
            blog: new Promise((resolve, reject) => {
                blog.loadById(match.params.id)
                    .then((response: any) => {
                        return resolve(response);
                    }).catch((error: AxiosError) => {
                        reject(error)
                    });
            }),
            categories: new Promise((resolve, reject) => {
                blog.loadCategories().then((response) => {

                    return resolve(response)
                }).catch((error: AxiosError) => {
                    reject(error)
                })
            })
        }

    }

    public componentDidMount() {

        this.blog.loadCategories().then((response) => {

            this.setState({
                blogCategoryList: response,
            })

            return response;

        }).then((res: any) => {
            this.blog.loadById(this.state.postId)
                .then((response: any) => {


                    this.blog.setFromPlainObject(response);
                    this.setState({
                        blogItem: response,
                        isLoading: response.id ? false : true,
                        categoryInfo: res ? res.find(item => item.id === response.category_id) : [], //get category info
                    });

                    this.blog.increaseViewCount(this.state.postId, response.view_count);

                }).then(() => {
                    this.blog.loadMostViews((this.state.categoryInfo !== undefined ? this.state.categoryInfo.is_video : false) ? 'most-viewed' : 'most-read').then((response) => {
                        this.setState({
                            mostViews: response
                        })
                    })
                }).catch((error: AxiosError) => {
                    Util.notification("error", "Desila se greška, blogovi ne mogu biti učitani.", 1500)
                });
        })

        super.componentDidMount();
    }

    getDestroyableMembers() {
        return [
            this.blog
        ];
    }

    getHelmetImage() {
        const categoryImage = process.env.REACT_APP_URL + this.blogCategory.image_path;
        let contentImage;

        if (this.blog.content) {
            const $ = cheerio.load(this.blog.content)
            contentImage = $('img').attr('src');
        }

        return contentImage ? contentImage : categoryImage;
    }

    getVideoImage(video) {

        if (typeof video !== 'undefined') {
            let videoId = video.substring(video.lastIndexOf("/"), video.length);
            if(videoId.includes('?')) {
                videoId = videoId.substring(0, videoId.indexOf('?'));
            }
            return "https://i.ytimg.com/vi" + videoId + "/hqdefault.jpg";
        } else return this.getHelmetImage();

    }

    renderContent() {

        const blog = this.state.blogItem;

        const is_video = this.state.categoryInfo !== undefined ? this.state.categoryInfo.is_video : false;
        let blogCategoryList;
        if (this.state.blogCategoryList) {
            const catList = this.state.blogCategoryList.filter(function (element) {
                return element.slug !== process.env.REACT_APP_CPP_CATEGORY_SLUG;
            })
            blogCategoryList = (
                catList.filter(function (a) { return a.is_video == is_video }).map((cat) => {
                    return (<ListGroupItem key={cat.id} tag="a" href={Path.EDUCATION_CATEGORY.toString() + "/" + cat.slug}>{cat.name}</ListGroupItem>);
                })
            )
        }
        const link = typeof window !== 'undefined' ? window.location.href : (process.env.REACT_APP_URL + "/edukacija/" + this.blog.id + "/" + this.blog.slug)
        const description = this.blog.description ? this.blog.description : (this.blog.content ? this.blog.content.replace(/<[^>]*>?/gm, '') : '&nbsp');
        let video = '&nbsp';


        if (this.blog.content) {
            const $ = cheerio.load(this.blog.content)
            video = $('iframe').attr('src');
        }


        return (
            <>
                <Helmet>
                    <title>{this.blog.title + " - EKI OGLASI"}</title>
                    <link rel="img_src" href={video != '&nbsp' ? this.getVideoImage(video) : this.getHelmetImage()} />
                    <meta property="fb:app_id" content="676072626394321" />
                    <meta name="description" content={description} />
                    <meta property="og:url" content={link} />
                    <meta property="og:type" content={video != '&nbsp' ? 'video.movie' : 'website'} />
                    <meta property="og:title" content={this.blog.title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={video != '&nbsp' ? this.getVideoImage(video) : this.getHelmetImage()} />
                    <meta property="og:image:secure_url" content={video != '&nbsp' ? this.getVideoImage(video) : this.getHelmetImage()} />
                    <meta property="og:image:width" content="480" />
                    <meta property="og:image:height" content="360" />
                    {/* twitter */}
                    <meta property="twitter:title" content={this.blog.title} />
                    <meta property="twitter:description" content={description} />
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:image" content={video != '&nbsp' ? this.getVideoImage(video) : this.getHelmetImage()} />

                    {video != '&nbsp' ?

                        <meta property="og:video" content={video} />
                        : null}
                </Helmet>
                <div className="epk-education-single">
                    <Container>
                        <div className="epk-education-single-section epk-section">
                            <Row>

                                <Col lg="9">
                                    {this.state.isLoading ?
                                        <>
                                            <i className="title-loader w100" />
                                            <i className="content-loader w100" />
                                            <i className="content-loader w100" />
                                            <div className="image-loader" />
                                            <div className="image-loader" />
                                        </>
                                        :
                                        <>
                                            <div className="category-path">{this.state.categoryInfo ? <Link to={is_video ? Path.EDUCATION_VIDEO.toString() : Path.EDUCATION.toString()}>Edukacija</Link> > <Link to={Path.EDUCATION_CATEGORY.toString() + "/" + this.state.categoryInfo.slug}>{this.state.categoryInfo.name}</Link> : null}</div>

                                            <Row>
                                                <Col md="8">
                                                    <h1 className="epk-container-h1">{blog.title}</h1>
                                                    <div className="education-info">
                                                        <b>Datum objave:</b> {Util.formatDatetime(blog.published_at)} | <b>Broj pregleda</b>: {blog.view_count}
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <Share link={link} title={blog.title} />
                                                </Col>
                                            </Row>

                                            <div dangerouslySetInnerHTML={{ __html: blog.content }} ></div>
                                        </>
                                    }
                                </Col>

                                <Col lg="3">
                                    <div className="education-widget">
                                        <h5 className="education-widget-title">Kategorije</h5>
                                        <ListGroup>
                                            <ListGroupItem key="0" tag="a" href={is_video ? Path.EDUCATION_VIDEO.toString() : Path.EDUCATION.toString()}>Sve</ListGroupItem>

                                            {
                                                this.state.isLoading ?
                                                    <>
                                                        <i className="content-loader w100" />
                                                        <i className="content-loader w100" />
                                                        <i className="content-loader w100" />
                                                        <br />
                                                    </>
                                                    :
                                                    blogCategoryList
                                            }
                                        </ListGroup>
                                    </div>

                                    <div className="education-widget mt-top">
                                        <h5 className="education-widget-title">{is_video ? "Najgledanije" : "Najčitanije"}</h5>

                                        {this.state.mostViews.map((blog) => {
                                            const imagePath = blog.cover_image ? blog.cover_image : (blog.category.image_path ? blog.category.image_path : this.defaultBlogImage);

                                            return this.state.isLoading ? null : (<Media className="most-viewed" key={blog.id + "" + blog.name}>
                                                <Media left href={Path.EDUCATION.toString() + "/" + blog.id + "/" + blog.slug}>
                                                    <Media className="most-viewed-img" object src={imagePath} />
                                                </Media>
                                                <Media body>
                                                    <a href={Path.EDUCATION.toString() + "/" + blog.id + "/" + blog.slug}>{blog.title}</a>
                                                    <div className="most-viewed-text" dangerouslySetInnerHTML={{ __html: Util.shortenContent(blog.content, 100) }} ></div>
                                                </Media>
                                            </Media>)
                                        })
                                        }

                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div >
            </>
        );
    }
}
