import React, { Component, Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import Identity from '../../user/Identity';
import { Path } from "../../container/Path";
import Util from '../../custom/Util';
import { Role } from '../../user/Role';

class MessageSingle extends Component<{ key: any, msg: any, readMessage: any, customerRole: string }, { from_user, to_user: any }> {

    constructor(props) {
        super(props)

        this.state = {
            from_user: JSON.parse(this.props.msg.from_user),
            to_user: JSON.parse(this.props.msg.to_user)
        }
    }
    componentDidMount() {
        if (this.props.msg.date_read == null && JSON.parse(this.props.msg.to_user).id == Identity.id) {
            this.props.readMessage(this.props.msg.id);
        }

        if (typeof window !== 'undefined') {
            if (document.getElementById(this.props.msg.id) != null) {
                document.getElementById(this.props.msg.id).innerHTML = this.props.msg.content;
            }
        }
    }

    render() {

        let sender;

        if (this.state.from_user.id !== Identity.id) {

            const thumb = !Util.isAbsolutePath(this.state.from_user.avatar) ? '/thumb' : '';

            sender = (
                <a href={this.props.customerRole == Role.ADMIN ? '#' : Path.USER_PROFILE + "/" + this.state.from_user.id + "/" + this.state.from_user.username} title={this.props.customerRole == Role.ADMIN ? "ADMINISTRATOR" : this.state.from_user.username}>
                    <img className="img-thumbnail epk-image"
                        src={this.state.from_user.avatar && this.props.customerRole != Role.ADMIN ? thumb + this.state.from_user.avatar : "/profile/no-img-profile.png"} />
                </a>
            )
        }

        if ((this.state.from_user.id == Identity.id && this.props.msg.from_user_hidden_at != null) || (this.state.to_user.id == Identity.id && this.props.msg.to_user_hidden_at != null)) {
            return null;
        } else return (
            <>
                <Row>
                    <div className={this.state.from_user.id == Identity.id ? 'epk-author-say' : 'epk-customer-say'}>
                        <Col xs="2" xl="1" className="epk-public-message-margin">
                            {sender}
                        </Col>
                        <Col xs="10" xl="11" className="epk-public-message-margin">
                            <div id={this.props.msg.id}
                                className={this.state.from_user.id == Identity.id ? 'epk-author-message' : 'epk-customer-message'}>

                                <div className="epk-message-date">{Util.formatDatetime(this.props.msg.date_sent, true)}</div>
                            </div>
                        </Col>
                    </div>
                </Row>
            </>
        );
    }

}

export default MessageSingle;

