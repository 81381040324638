import React from 'react';
import { Nav, NavLink } from 'reactstrap';
import Icon from '../../custom/Icon';

function SocialNav() {
    const socialnavlist = [
        { id: 1, name: "facebook", url: "http://www.facebook.com/MKOEKI" },
        { id: 2, name: "viber", url: "https://viber.com/ekimkd" },
        { id: 3, name: "instagram", url: "http://www.instagram.com/mkoeki" },
        { id: 4, name: "twitter", url: "https://twitter.com/MKO_EKI" },
        { id: 5, name: "youtube", url: "https://www.youtube.com/channel/UCjfng88DHlpbEdC-9Q1piqw" },
        //{ id: 6, name: "google-plus", url: "http://www.googleplus.com/" },
        { id: 7, name: "linkedin", url: "https://www.linkedin.com/company/mikrokreditna-organizacija-eki/" },
        { id: 8, name: "rss", url: "https://www.eki.ba/feed" }
    ];

    return (
        <Nav className="epk-social-nav">
            {socialnavlist.map(socialnavsingle => (
                <NavLink tag="a" href={socialnavsingle.url} target="_blank" key={socialnavsingle.id}><Icon name={socialnavsingle.name} /></NavLink>
            ))}
        </Nav>
    );
}

export default SocialNav;
