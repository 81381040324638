import React from 'react';

interface IInfoProps {
    whichFrom: string
}

function Info(props: IInfoProps) {
    let infoRegister;
    if (props.whichFrom == 'epkMember') {
        infoRegister = (
            <div>
                <img className="img-fluid" src="/img/sporazum.jpg" />
            </div>
        )
    } else {
        infoRegister = (
            <div>
                <img className="img-fluid" src="/img/register.jpg" />
                <br></br>
                <p>Ukoliko niste član EPK, prilikom registracije potvrdite opciju "Ne, nisam član". Nakon toga, popunite tražene podatke i odaberite opciju "Registruj se".</p>
                <p>Ukoliko niste član EPK, registraciju možete izvršiti  i putem vašeg Facebook, Google ili Email naloga.</p>
            </div>
        )
    }
    return (
        <div className="epk-info-register">
            {infoRegister}
        </div>
    )
}

export default Info;