import React, { Component } from 'react';
import { Row, Col, Collapse, ListGroupItem, Input, Button, Form } from 'reactstrap';
import { IQuery } from '../../models/Search';

interface ISearchOtherState {
    collapse: boolean;
    isButtonVisible: boolean;
    cijena_od?: number;
    cijena_do?: number;
}

class SearchPrice extends Component<{ filterSearch: any, search: IQuery }, ISearchOtherState> {
    constructor(props: any) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            collapse: true,
            isButtonVisible: false,
            cijena_od: props.search.cijena_od != undefined ? props.search.cijena_od : '',
            cijena_do: props.search.cijena_do != undefined ? props.search.cijena_do : ''
        };
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    handleChange = (event) => {

        const target = event.target as HTMLInputElement;
        const update = {};

        update[target.id] = target.value;

        this.setState(update);

        if (!this.state.isButtonVisible) {
            this.setState({
                isButtonVisible: true,
            });
        }

    }

    filterSearch = () => {
        const search = {};

        search['cijena_do'] = this.state.cijena_do ? this.state.cijena_do : null;

        search['cijena_od'] = this.state.cijena_od ? this.state.cijena_od : null;

        this.props.filterSearch(search);
    }

    render() {
        return (
            <>
                <ListGroupItem tag="a" className="epk-filter-mod dropdown-toggle" onClick={this.toggle}>Cijena</ListGroupItem>
                <Collapse isOpen={this.state.collapse}>

                    <Form>
                        <div className="card-body card-with-button">
                            <Row>
                                <Col xs="5">
                                    <Input type="text" id="cijena_od" className="form-control filter-price" value={this.state.cijena_od} onChange={this.handleChange} placeholder="Od" />
                                </Col>
                                <Col xs="2"></Col>
                                <Col xs="5">
                                    <Input type="text" id="cijena_do" className="form-control filter-price" value={this.state.cijena_do} onChange={this.handleChange} placeholder="Do" />
                                </Col>
                            </Row>
                            {this.state.isButtonVisible ?
                                <Button onClick={this.filterSearch} type="button" color="reload">
                                    Osvježi pretragu</Button>
                                : null}
                        </div>
                    </Form>

                </Collapse >
            </>
        );
    }
}

export default SearchPrice;
