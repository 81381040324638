import React from 'react';
import { NavLink } from 'reactstrap';
import { IFooterNavLink } from '../Footer';

interface IFooterNavProps {
    title: string;
    links: IFooterNavLink[]
}

function FooterNav(props: IFooterNavProps) {
    return (
        <>
            <h5 className="footer-title">{props.title}</h5>
            <div className="line"></div>
            {props.links.map(navsingle => (
                <NavLink tag="a" href={navsingle.link} target={navsingle.newTab ? '_blank' : ''} key={navsingle.id}>{navsingle.name}</NavLink>
            ))}
        </>
    );
}

export default FooterNav;
