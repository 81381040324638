import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Identity from '../user/Identity';
import { Path } from '../container/Path';

interface IEpkInfoCustomer {
    hide: boolean
}

class EpkInfoCustomer extends Component<unknown, IEpkInfoCustomer> {
    constructor(props) {
        super(props);

        this.state = {
            hide: true
        }

        this.infoHide = this.infoHide.bind(this);
    }

    infoHide() {
        Identity.is_epk_info = true;

        this.setState({
            hide: true
        })
    }

    componentDidMount() {

        this.setState({
            hide: typeof window !== 'undefined' ? Identity.is_epk_info : true
        })
    }

    render() {

        let infoEpk;

        if (!this.state.hide) {
            infoEpk = (
                <div className="epk-info">
                    <div className="container">
                        <h4>Informacije za članove EKI Poslovnog kluba (EPK) i posjetioce stranice</h4>
                        <p><a href={Path.HOMEPAGE}>EPK Portal</a> članovima EKI Poslovnog kluba (EPK) omogućava jednostavno objavljivanje malih oglasa za prodaju, kupovinu, zamjenu, pružanje usluga, iznajmljivanje i sl. Članovi EPK, klijenti EKI-ja, su osnovni korisnici ove stranice koji imaju pristup svim njenim sadržajima, pod uslovom da izvrše verifikaciju svog profila prema uputstvima koja su dobili. Ostali posjetioci mogu pregledati oglase i komunicirati sa oglašivačima, uz uslov da registruju svoj profil putem e-maila ili Facebook-a. Više informacije o tome možete pronaći u sekciji <Link to={Path.FAQ}>Često postavljana pitanja</Link>.</p>
                        <div className="epk-info-link">
                            <a onClick={this.infoHide} className="epk-info-hide">Ne prikazuj više</a>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <>
                {infoEpk}
            </>
        );
    }
}

export default EpkInfoCustomer;
