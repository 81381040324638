import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";
import { IDropdownListItem } from "../../common/components/widgets/form/input/DropdownInput";
import { IMessage } from "./Message";
import moment from "moment";
import { IUser } from "./User";
import { RequiredValidator } from "../../common/components/validators/RequiredValidator";

export interface IPublicMessage {
    id?: number,
    message_id?: number,
    ad_id?: number,
    from_user?: IUser,
    to_user?: number,
    hidden_at?: string,
    content?: string,
    subject?: string,
    date_sent?: string,
    message?: IMessage
}


export class PublicMessage extends DataModel<IPublicMessage> {

    private resourceName = "public-message";
    private resourceNamePlural = "public-messages";

    private _id: number;
    private _message_id: number;
    private _ad_id: number;
    private _from_user: IUser;
    private _to_user: number;
    private _hidden_at: string;
    private _message: IMessage;
    private _content: string;
    private _subject: string;
    private _date_sent: string;


    protected getDefaultValues(): IPublicMessage {
        return {
            message: {},
            from_user: {}
        };
    }


    public createDataSource(resourcesubject: string = this.resourceNamePlural): RestDataSource<IPublicMessage> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourcesubject, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IPublicMessage> {

        return new DataModelValidator<IPublicMessage>({
            content: [new RequiredValidator()],
        }, ErrorMessages.getDefaultErrorMessageCallbacks());

    }

    protected modelReducer(state: IDataModelState<IPublicMessage>, action: AnyAction): IDataModelState<IPublicMessage> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource('public-messages').getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public getListItems(params: IRestDataSourceParams): Promise<IDropdownListItem[]> {
        return this.getListPlain(params)
            .then((response: AxiosResponse) => {
                const messages: IPublicMessage[] = response.data as IPublicMessage[];

                const result: IDropdownListItem[] = [];

                messages.forEach((message: IPublicMessage) => {
                    result.push({
                        id: message.id,
                        name: message.message_id.toString()
                    });
                });

                return result;
            });
    }

    public loadByAdId(id: number): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural)
            .addCustomPath('/' + id)
            .plainGetOperation({});
    }

    public createNew(data: IPublicMessage): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + data.ad_id)
            .plainPostOperation({
                content: data.content,
                subject: data.subject,
                to_user: data.to_user,
                ad_id: data.ad_id,
                date_sent: moment().format()
            });
    }

    public update(): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + this.id)
            .plainPatchOperation({
                hidden_at: this._hidden_at ? this._hidden_at : null
            });
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    getStoreKey(): string {
        return "PUBLIC_MESSAGE";
    }

    protected setFromObj(data: IPublicMessage): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._message_id = DataModel.safeGet(data.message_id, this._message_id);
        this._message = DataModel.safeGet(data.message, this._message);
        this._content = DataModel.safeGet(data.content, this._content);
        this._subject = DataModel.safeGet(data.subject, this._subject);
        this._ad_id = DataModel.safeGet(data.ad_id, this._ad_id);
        this._from_user = DataModel.safeGet(data.from_user, this._from_user);
        this._to_user = DataModel.safeGet(data.to_user, this._to_user);
        this._hidden_at = DataModel.safeGet(data.hidden_at, this._hidden_at);
    }


    protected toObj(): IPublicMessage {
        return {
            id: this._id,
            message_id: this._message_id,
            message: this._message,
            content: this._content,
            subject: this._subject,
            ad_id: this._ad_id,
            from_user: this._from_user,
            to_user: this._to_user,
            hidden_at: this._hidden_at
        };
    }


    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get message_id() {
        return this._message_id;
    }

    get message() {
        return this._message;
    }

    get content() {
        return this._content;
    }

    get subject() {
        return this._subject;
    }

    get date_sent() {
        return this._date_sent;
    }

    get ad_id() {
        return this._ad_id;
    }


    get from_user() {
        return this._from_user;
    }

    get to_user() {
        return this._to_user
    }

    get hidden_at() {
        return this._hidden_at
    }

}
