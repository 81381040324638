import React from 'react';
import { Col } from 'reactstrap';

interface IInfoProps {
    shortDesc: string,
    exchangeDesc: string,
    desc: string,
    loaded: boolean,
}

function Info(props: IInfoProps) {
    const loadercontent = <i className="title-loader-small">____________ ________ ___________ _______</i>;
    return (
        <>
            <Col md="12">
                <h4 className="epk-ad-info-title">Informacije o oglasu</h4>
                <div className="epk-info-detail epk-info-first"><span> Kratak opis oglasa:</span> {!props.loaded ? loadercontent : props.shortDesc}</div>
                <div className="epk-info-detail"><span>Zamjena:</span> {!props.loaded ? loadercontent : props.exchangeDesc}</div>
            </Col>
            <Col xl="12">
                <h4 className="epk-ad-info-title">Opis oglasa</h4>
                <div className="epk-info-detail epk-info-first">{!props.loaded ? loadercontent : props.desc}</div>
            </Col>
        </>
    );
}

export default Info;

