import { DataModel, IDataModelState } from "../../common/model/DataModel";
import { RestDataSource } from "../../common/dataSource/RestDataSource";
import { IRestDataSourceParams, RestParamsQueryBuilder } from "../../common/dataSource/IRestDataSourceParams";
import { DataModelValidator, IDataModelValidator } from "../../common/components/validators/DataModelValidator";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { ErrorMessages } from "../custom/ErrorMessages";
import UserIdentity from "../user/Identity";

export interface IAdImage {
    id?: number,
    ad_id?: number,
    image_path?: string,
    sort_key?: number,
    status_id?: number
}


export class AdImage extends DataModel<IAdImage> {

    public resourceName = "ad-image";
    public resourceNamePlural = "ad-images";

    private _ad_id: number;
    private _id: number;
    private _image_path: string;
    private _sort_key: number;
    private _status_id: number;


    protected getDefaultValues(): IAdImage {
        return {};
    }


    public createDataSource(resourceName: string = this.resourceNamePlural): RestDataSource<IAdImage> {
        return new RestDataSource(process.env.REACT_APP_API_BASE_URL + resourceName, UserIdentity.getRequestHeaders());
    }

    protected createValidator(scenario: string): IDataModelValidator<IAdImage> {
        return new DataModelValidator<IAdImage>({}, ErrorMessages.getDefaultErrorMessageCallbacks());
    }

    protected modelReducer(state: IDataModelState<IAdImage>, action: AnyAction): IDataModelState<IAdImage> {
        return state;
    }

    public getListPlain(params: IRestDataSourceParams): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceNamePlural).getListPlain(RestParamsQueryBuilder.buildRestParams(params))
    }

    public loadById(id: number): Promise<IAdImage | AxiosError> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .getOperation({});
    }

    public createNew(adId: number): Promise<AxiosResponse> {
        return this.createDataSource("oglas/" + adId + "/save-image")
            .plainPostOperation({
                sort_key: this._sort_key,
                image_path: this._image_path

            });
    }

    public update(data: IAdImage): Promise<AxiosResponse> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + data.id)
            .plainPatchOperation(data);
    }

    public del(id: number): Promise<void> {
        return this.createDataSource(this.resourceName)
            .addCustomPath('/' + id)
            .plainDelOperation();
    }


    getStoreKey(): string {
        return "AD_IMAGE";
    }

    protected setFromObj(data: IAdImage): void {
        this.id = DataModel.safeGet(data.id, this._id) as number;
        this._ad_id = DataModel.safeGet(data.ad_id, this._ad_id) as number;
        this._sort_key = DataModel.safeGet(data.sort_key, this._sort_key);
        this._image_path = DataModel.safeGet(data.image_path, this._image_path);
    }


    protected toObj(): IAdImage {
        return {
            id: this._id,
            ad_id: this._ad_id,
            sort_key: this._sort_key,
            image_path: this._image_path
        };
    }




    get id(): number {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get ad_id() {
        return this._ad_id;
    }


    get sort_key() {
        return this._sort_key;
    }

    set sort_key(key: number) {
        this._sort_key = key;
    }


    get image_path() {
        return this._image_path;
    }

    set image_path(path: string) {
        this._image_path = path;
    }

    get status_id() {
        return this._status_id;
    }

    set status_id(status_id: number) {
        this._status_id = status_id;
    }
}
