import React from "react";
import EpkPage from "./EpkPage";
import { Container, Row, Col, Button, Form, FormGroup, Label, Alert } from 'reactstrap';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import axios from 'axios'
import Icon from "../custom/Icon";

@withRouter(Path.DOWNLOAD_ADVERTISEMENTS.toString(), PageContainer)
export default class AdvertisementsPage extends EpkPage {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            advertisementsPerPage: 18,
            activeAdvertisementsPage: 0,
            advertisementsList: [],
            totalAdvertisements: 0,
        };

        this.loadMore = this.loadMore.bind(this);
        this.axiosRequest = this.axiosRequest.bind(this);
    }

    pageTitle() {
        return "Preuzimanje oglasnika" + super.pageTitle();
    }

    getDestroyableMembers() {
        return [];
    }

    axiosRequest() {
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            }
        };

        axios.get('https://eki.ba/api/oglasnici.php?limit=' + this.state.advertisementsPerPage + '&page=' + parseInt(this.state.activeAdvertisementsPage + 1), axiosConfig)
            .then((res) => {
                const advertisementsListNew = this.state.advertisementsList

                this.setState({
                    isLoading: false,
                    activeAdvertisementsPage: this.state.activeAdvertisementsPage + 1,
                    advertisementsList: advertisementsListNew.concat(res.data.data),
                    totalAdvertisements: res.data.total
                })
            })
            .catch((err) => {
                this.setState({
                    isLoading: false,
                    error: err
                });
            })
    }

    componentDidMount() {
        this.axiosRequest();
    }

    loadMore() {
        this.axiosRequest();
    }

    renderContent() {
        const loadMore = (
            <div className="epk-load-more">
                <Button onClick={this.loadMore} color="epk-primary" ><Icon name="vise" /> Učitaj više</Button>
            </div>
        )

        let listOfAdvertisements;

        const listOfAdvertisementsLoading = [];

        for (let i = 1; i <= 18; i++) {
            listOfAdvertisementsLoading.push(
                <Col md="2" sm="6" xs="6" key={i}>
                    <div className="oglasnik-loader"></div>
                    <p><li className="title-loader oglasnik-loader-title" /></p>
                </Col>
            )
        }

        if (!this.state.isLoading) {
            listOfAdvertisements = (
                this.state.advertisementsList.map((single, index) => (
                    <Col md="2" sm="6" xs="6" key={index}>
                        <a href={single.post_download_file}>
                            <div className="oglasnik-image">
                                <img className="img-fluid" src={single.main_image} />
                            </div>
                            <p>{single.post_title}</p>
                        </a>
                    </Col>
                ))
            )
        } else {
            listOfAdvertisements = listOfAdvertisementsLoading
        }


        return (
            <>
                <div className="epk-static-content">
                    <Container>
                        <div className="epk-static-content-section epk-section">
                            <Row>
                                <Col md="12">
                                    <h1 className="epk-container-h1">Preuzimanje oglasnika</h1>
                                    <h5>
                                        <p>ISSN 2566-4263 (Print)<br></br>
                                            ISSN 2637-2657 (Online)</p>
                                    </h5>
                                </Col>
                                <Col md="12">
                                    <div className="content">
                                        <Row>
                                            {listOfAdvertisements}
                                        </Row>
                                    </div>
                                </Col>
                                <Col md="12">
                                    {this.state.advertisementsPerPage * this.state.activeAdvertisementsPage <= this.state.totalAdvertisements ? loadMore : ``}
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }
}
