import React from 'react';
import { Container, Row, Col, ListGroup, Collapse, ListGroupItem } from 'reactstrap';
import { Path } from "../container/Path";
import { withRouter } from "../../common/components/routing/Router";
import EpkPage from "./EpkPage";
import { Container as PageContainer } from "../../index";
import SearchCategory from "./search/Categories";
import SearchLocation from "./search/Location";
import SearchCondition from "./search/Condition";
import SearchType from "./search/Type";
import SearchOther from "./search/Other";
import SearchPrice from "./search/Price";
import SearchResult from "./search/SearchResult";
import * as qs from 'query-string';
import { IQuery } from "../models/Search";
import { AdCategory } from '../models/AdCategory';
import { AxiosResponse, AxiosError } from 'axios';

@withRouter(Path.SEARCH.toString(), PageContainer)
@withRouter(Path.CATEGORY.toString(), PageContainer)
export default class SearchPage extends EpkPage {

    constructor(props: any) {
        super(props);

        let search: IQuery = {};
        let caption: string = "";

        this.filterSearch = this.filterSearch.bind(this);
        this.toggle = this.toggle.bind(this);
        this.getBannerBreakPoint = this.getBannerBreakPoint.bind(this);


        if (typeof window !== 'undefined') {
            if (props.location.search != undefined) {
                search
                    = qs.parse(props.location.search) as IQuery;

                caption = search.upit ? 'Pretraga za "' + search.upit + '"' : "";

            }


            if (this.props.match.params.id) {
                search.kategorija = this.props.match.params.id as number;
            }
            if(this.props.data){
                search.kategorija = this.props.data.activeCategory as number;
            }
        }

        this.state = {
            collapse: true,
            search,
            caption,
            bannerBreakPoint: 1
        };
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }


    pageTitle() {
        return "Pretraga" + super.pageTitle();
    }

    getDestroyableMembers() {
        return [];
    }

    componentDidMount() {
        super.componentDidMount();
        this.getBannerBreakPoint();
        this.resize();
    }

    resize() {
        this.setState({ collapse: typeof window !== 'undefined' ? window.innerWidth > 1199 : null });
    }

    static fetchData(match) {

        const categoryModel = new AdCategory(false);

        return {
            categories: new Promise((resolve, reject) => {
                categoryModel.createDataSource(categoryModel.resourceNamePlural + "/subcategories").getListPlain({})
                    .then((response: AxiosResponse) => {

                        return resolve(response.data);

                    }).catch((error: AxiosError) => {

                        reject(error)
                    });
            }),
            activeCategory: match.params.id || -1
        }

    }

    componentWillReceiveProps(nextProps) {


        if (typeof window !== 'undefined') {
            if (qs.parse(nextProps.location.search).upit != this.state.search.upit) {
                const upit = qs.parse(nextProps.location.search).upit;

                this.setState({
                    search: { ...this.state.search, upit },
                    caption: 'Pretraga za "' + upit + '"'
                });
            }
        }
    }


    getBannerBreakPoint() {
        let itemsInRow = 1;

        if (typeof window !== 'undefined') {
            if (window.innerWidth < 768) {
                itemsInRow = 1;
            } else if (window.innerWidth < 1200) {
                itemsInRow = 2;
            } else if (window.innerWidth >= 1200) {
                itemsInRow = 3;
            }
        }

        this.setState({ bannerBreakPoint: itemsInRow })
    }

    filterSearch(data: IQuery) {



        if (typeof window !== 'undefined') {
            const search: IQuery = qs.parse(this.props.location.search) as IQuery;

            if (data.opstina) {
                data.opstina != null
                    ? search.opstina = data.opstina
                    : delete search.opstina;
            }

            if (data.cijena_do !== undefined) {
                data.cijena_do != null
                    ? search.cijena_do = data.cijena_do
                    : delete search.cijena_do;
            }


            if (data.cijena_od !== undefined) {
                data.cijena_od != null
                    ? search.cijena_od = data.cijena_od
                    : delete search.cijena_od;
            }


            if (data.stanje !== undefined) {
                data.stanje != null
                    ? search.stanje = data.stanje
                    : delete search.stanje;
            }

            if (data.tip !== undefined) {
                data.tip != null
                    ? search.tip = data.tip
                    : delete search.tip;
            }


            if (data.sa_slikama != undefined) {
                data.sa_slikama
                    ? search.sa_slikama = data.sa_slikama
                    : delete search.sa_slikama;
            }

            if (data.sa_zamjenom != undefined) {
                data.sa_zamjenom
                    ? search.sa_zamjenom = data.sa_zamjenom
                    : delete search.sa_zamjenom;
            }

            if (data.sa_cijenom != undefined) {
                data.sa_cijenom
                    ? search.sa_cijenom = data.sa_cijenom
                    : delete search.sa_cijenom;
            }

            if (data.kategorija != undefined) {
                data.kategorija
                    ? search.kategorija = data.kategorija
                    : delete search.kategorija;
            }

            if (data.podkategorija != undefined) {
                data.podkategorija
                    ? search.podkategorija = data.podkategorija
                    : delete search.podkategorija;
            }

            this.setState({ search: search });
        }

    }

    renderContent() {

        return (
            <div className="epk-search-single">
                <Container>
                    <div className="epk-search-single-section epk-section">
                        <Row>
                            <Col md="12">
                                <h1 className="epk-container-h1">{this.state.caption}</h1>
                            </Col>
                            <Col xl="3">
                                <ListGroup className="list-group-flush epk-filters-selector">

                                    <ListGroupItem tag="a" className="epk-filter-mod epk-filter-selector epk-mobile-filter dropdown-toggle" onClick={this.toggle}>Filteri</ListGroupItem>
                                    <Collapse isOpen={this.state.collapse}>
                                        <ul className="list-group list-group-flush epk-filters">
                                            <SearchCategory filterSearch={this.filterSearch} search={this.state.search} data={this.props.data} />
                                            <SearchLocation filterSearch={this.filterSearch} search={this.state.search} />
                                            <SearchPrice filterSearch={this.filterSearch} search={this.state.search} />
                                            <SearchCondition filterSearch={this.filterSearch} search={this.state.search} />
                                            <SearchType filterSearch={this.filterSearch} search={this.state.search} />
                                            <SearchOther filterSearch={this.filterSearch} search={this.state.search} />
                                        </ul>
                                    </Collapse>
                                </ListGroup>
                            </Col>
                            <Col xl="9">
                                <SearchResult search={this.state.search} bannerBreakpoint={this.state.bannerBreakPoint} />
                            </Col>
                        </Row>
                    </div>
                </Container>

            </div>
        );
    }
}

