import React from 'react';
import { Col } from 'reactstrap';
import Icon from '../../custom/Icon'

interface IInfoBoxProps {
    price: number | string,
    location: string,
    condition: string,
    loaded: boolean,
}

function InfoBox(props: IInfoBoxProps) {
    const loadercontent = <i className="title-loader-small">_______________</i>;
    return (
        <>
            <Col md="4">
                <a className="epk-info-box">
                    <div className="epk-info-box-name"><Icon name="cijena" /> Cijena</div>
                    <div className="epk-info-box-value epk-info-box-value-price">{!props.loaded ? loadercontent : props.price}</div>
                </a>
            </Col>
            <Col md="4">
                <a className="epk-info-box">
                    <div className="epk-info-box-name"><Icon name="lokacija" /> Lokacija</div>
                    <div className="epk-info-box-value">{!props.loaded ? loadercontent : props.location}</div>
                </a>
            </Col>
            <Col md="4">
                <a className="epk-info-box">
                    <div className="epk-info-box-name"><Icon name="stanje" /> Stanje</div>
                    <div className="epk-info-box-value">{!props.loaded ? loadercontent : props.condition}</div>
                </a>
            </Col>
        </>
    );
}

export default InfoBox;

