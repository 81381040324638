import * as React from "react";
import {SSRConsumer} from "./ssrContext";

export function withFetchData(DecoratedComponent) {

    return (props: any): typeof DecoratedComponent => {

        class WithFetchData extends React.Component<{ props }, unknown > {

            render() {
                return (
                    <SSRConsumer>
                        {(providerProps) => {

                            return <>
                                <DecoratedComponent  {...this.props} data={providerProps} />
                                <script type="application/hydration-marker" id="hydration-marker">
                                    {JSON.stringify(providerProps)}
                                </script>
                            </>
                        }}
                    </SSRConsumer>
                )
            }
        }

        return <WithFetchData {...props} />;
    }

}